import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';

import { checkLoggedIn } from 'src/redux/selectors';
import TemplateNothing from 'src/components/layout/TemplateNothing';
import TemplateSidebar from 'src/components/layout/TemplateSidebar';

import SignInContainer from 'src/containers/SignIn';
import SignInComponent from 'src/components/views/SignIn';
import SignUpContainer from 'src/containers/SignUp';
import SignUpComponent from 'src/components/views/SignUp';

import UserDashboardContainer from 'src/containers/restricted/user/Dashboard';
import UserDashboardComponent from 'src/components/views/user/Dashboard';
import UserAccountSettingsContainer from 'src/containers/restricted/user/AccountSettings';
import UserAccountSettingsComponent from 'src/components/views/user/AccountSettings';
import UserAddVehicleContainer from 'src/containers/restricted/user/AddVehicle';
import UserAddVehicleComponent from 'src/components/views/user/AddVehicle';
import UserEditVehicleContainer from 'src/containers/restricted/user/EditVehicle';
import UserEditVehicleComponent from 'src/components/views/user/EditVehicle';
import UserMyVehiclesContainer from 'src/containers/restricted/user/MyVehicles';
import UserMyVehiclesComponent from 'src/components/views/user/MyVehicles';
import UserLandingPageContainer from 'src/containers/restricted/user/LandingPage';
import UserLandingPageComponent from 'src/components/views/user/LandingPage';

import DealerDashboardContainer from 'src/containers/restricted/dealer/Dashboard';
import DealerDashboardComponent from 'src/components/views/dealer/Dashboard';
import DealerAccountSettingsContainer from 'src/containers/restricted/dealer/AccountSettings';
import DealerAccountSettingsComponent from 'src/components/views/dealer/AccountSettings';
import DealerAuctionVehiclesContainer from 'src/containers/restricted/dealer/AuctionVehicles';
import DealerAuctionVehiclesComponent from 'src/components/views/dealer/AuctionVehicles';
import DealerMyBasketContainer from 'src/containers/restricted/dealer/MyBasket';
import DealerMyBasketComponent from 'src/components/views/dealer/MyBasket';
import DealerVehicleDetailsContainer from 'src/containers/restricted/dealer/VehicleDetails';
import DealerVehicleDetailsComponent from 'src/components/views/dealer/VehicleDetails';
import DealerHistoryContainer from 'src/containers/restricted/dealer/History';
import DealerHistoryComponent from 'src/components/views/dealer/History';

import AdminDashboardContainer from 'src/containers/restricted/admin/Dashboard';
import AdminDashboardComponent from 'src/components/views/admin/Dashboard';
import AdminAccountSettingsContainer from 'src/containers/restricted/admin/AccountSettings';
import AdminAccountSettingsComponent from 'src/components/views/admin/AccountSettings';
import AdminSignInContainer from 'src/containers/restricted/admin/SignIn';
import AdminSignInComponent from 'src/components/views/admin/SignIn';
import AdminVehiclesContainer from 'src/containers/restricted/admin/Vehicles';
import AdminVehiclesComponent from 'src/components/views/admin/Vehicles';
import AdminAuctionContainer from 'src/containers/restricted/admin/Auction';
import AdminAuctionComponent from 'src/components/views/admin/Auction';
import AdminAuctionHistoryContainer from 'src/containers/restricted/admin/AuctionHistory';
import AdminAuctionHistoryComponent from 'src/components/views/admin/AuctionHistory';
import AdminDealsContainer from 'src/containers/restricted/admin/Deals';
import AdminDealsComponent from 'src/components/views/admin/Deals';
import AdminVehicleDetailsContainer from 'src/containers/restricted/admin/VehicleDetails';
import AdminVehicleDetailsComponent from 'src/components/views/admin/VehicleDetails';
import AdminUsersContainer from 'src/containers/restricted/admin/Users';
import AdminUsersComponent from 'src/components/views/admin/Users';

const RestrictedRoute = ({
  layout,
  template: Template,
  container: Container,
  component: Component,
  isLoggedIn,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <Template {...props} background={rest.background}>
          <Container {...props} Layout={layout} />
        </Template>
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

const OpenRoute = ({
  layout,
  template: Template,
  container: Container,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      <Template {...props} background={rest.background}>
        <Container {...props} Layout={layout} />
      </Template>
    )}
  />
);
const Routes = ({ history, isLoggedIn }) => {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <OpenRoute
          path="/admin"
          exact
          template={TemplateNothing}
          container={AdminSignInContainer}
          layout={AdminSignInComponent}
        />
        <OpenRoute
          path="/signin"
          template={TemplateNothing}
          container={SignInContainer}
          layout={SignInComponent}
        />
        <OpenRoute
          path="/signup"
          template={TemplateNothing}
          container={SignUpContainer}
          layout={SignUpComponent}
        />
        <OpenRoute
          exact
          path="/"
          template={TemplateNothing}
          container={SignInContainer}
          layout={SignInComponent}
        />
        <RestrictedRoute
          path="/user/landingpage"
          template={TemplateSidebar}
          container={UserLandingPageContainer}
          layout={UserLandingPageComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/user/dashboard"
          template={TemplateSidebar}
          container={UserDashboardContainer}
          layout={UserDashboardComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/user/account"
          template={TemplateSidebar}
          container={UserAccountSettingsContainer}
          layout={UserAccountSettingsComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/user/addvehicle"
          template={TemplateSidebar}
          container={UserAddVehicleContainer}
          layout={UserAddVehicleComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/user/editvehicle"
          template={TemplateSidebar}
          container={UserEditVehicleContainer}
          layout={UserEditVehicleComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/user/myvehicles"
          template={TemplateSidebar}
          container={UserMyVehiclesContainer}
          layout={UserMyVehiclesComponent}
          isLoggedIn={isLoggedIn}
          background
        />

        <RestrictedRoute
          path="/dealer/dashboard"
          template={TemplateSidebar}
          container={DealerDashboardContainer}
          layout={DealerDashboardComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/dealer/auctionvehicles"
          template={TemplateSidebar}
          container={DealerAuctionVehiclesContainer}
          layout={DealerAuctionVehiclesComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/dealer/mybasket"
          template={TemplateSidebar}
          container={DealerMyBasketContainer}
          layout={DealerMyBasketComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/dealer/vehicledetails"
          template={TemplateSidebar}
          container={DealerVehicleDetailsContainer}
          layout={DealerVehicleDetailsComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/dealer/account"
          template={TemplateSidebar}
          container={DealerAccountSettingsContainer}
          layout={DealerAccountSettingsComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/dealer/history"
          template={TemplateSidebar}
          container={DealerHistoryContainer}
          layout={DealerHistoryComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/admin/dashboard"
          template={TemplateSidebar}
          container={AdminDashboardContainer}
          layout={AdminDashboardComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/admin/account"
          template={TemplateSidebar}
          container={AdminAccountSettingsContainer}
          layout={AdminAccountSettingsComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/admin/vehicles"
          template={TemplateSidebar}
          container={AdminVehiclesContainer}
          layout={AdminVehiclesComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/admin/vehicledetails"
          template={TemplateSidebar}
          container={AdminVehicleDetailsContainer}
          layout={AdminVehicleDetailsComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/admin/users"
          template={TemplateSidebar}
          container={AdminUsersContainer}
          layout={AdminUsersComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/admin/auction"
          template={TemplateSidebar}
          container={AdminAuctionContainer}
          layout={AdminAuctionComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/admin/auctionhistory"
          template={TemplateSidebar}
          container={AdminAuctionHistoryContainer}
          layout={AdminAuctionHistoryComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/admin/deals"
          template={TemplateSidebar}
          container={AdminDealsContainer}
          layout={AdminDealsComponent}
          isLoggedIn={isLoggedIn}
          background
        />
      </Switch>
    </ConnectedRouter>
  );
};

export default connect(state => ({
  isLoggedIn: checkLoggedIn(state)
}))(Routes);
