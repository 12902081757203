import React from 'react';
import styled from 'styled-components';
import {
  Form,
  Row,
  Col,
  Spin,
  Input,
  Tabs,
  Checkbox,
  Modal,
  Select,
  Radio
} from 'antd';
import { ButtonRadius } from 'src/components/elements/Button';
import Notify from 'src/components/meta/Notification';
import CarPic from 'src/static/icons/login-car-pic1.png';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import logo from 'src/static/logo.svg';
const StyledSelect = styled(Select)`
  border: none;
  .ant-select-selection {
    border: none;
    box-shadow: none;
  }
  .ant-select-selection:hover {
    border: none;
    box-shadow: none;
  }
  .ant-select-selection:focus {
    border: none;
    box-shadow: none;
  }
  .ant-select-selection:active {
    border: none;
    box-shadow: none;
  }
  .ant-select-focused {
    border: none;
    box-shadow: none;
  }
`;
const FormItem = Form.Item;
const { TabPane } = Tabs;
class SignUp extends React.Component {
  componentDidMount = () => {
    if (new URLSearchParams(window.location.search).has('type')) {
      const type = new URLSearchParams(window.location.search).get('type');
      if (type === 'dealer') {
        this.setState({ activeKey: '2' });
      }
    }
  };
  handleSubmitUser = event => {
    event.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.signUpUser(values);
      }
    });
  };

  handleSubmitDealer = event => {
    event.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.signUpDealer(values);
      }
    });
  };

  signUpUser = values => {
    this.setState({ loading: true });
    values.lang = window.i18n.props.locale;
    this.props
      .signUpUser(values)
      .then(() => {
        Notify('success', 'Success', 'Created a customer account');
        if (new URLSearchParams(window.location.search).has('year')) {
          this.props
            .signIn(values, 'user')
            .then(() => {
              this.setState({ loading: false });
              this.props.push('/user/addvehicle' + window.location.search);
            })
            .catch(e => {
              this.setState({ loading: false });
              Notify('error', 'Failed', e);
            });
        } else {
          this.props
            .signIn(values, 'user')
            .then(() => {
              this.setState({ loading: false });
              this.props.push('/user/landingpage');
            })
            .catch(e => {
              this.setState({ loading: false });
              Notify('error', 'Failed', e);
            });
        }
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', 'Failed', e);
      });
  };

  signUpDealer = values => {
    this.setState({ loading: true });
    values.lang = window.i18n.props.locale;
    this.props
      .signUpDealer(values)
      .then(() => {
        this.setState({ loading: false });
        Notify('success', 'Success', 'Created a dealer account');
        this.props
          .signIn(values, 'dealer')
          .then(() => {
            this.setState({ loading: false });
            this.props.push('/dealer/dashboard');
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', 'Failed', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', 'Failed', e);
      });
  };

  toggleShowPass = () => {
    this.state.passShow === 'password'
      ? this.setState({ passShow: 'text' })
      : this.setState({ passShow: 'password' });
  };

  constructor(props) {
    super(props);
    this.state = {
      email: props.member && props.member.email ? props.member.email : '',
      password: '',
      focus: {
        email: false,
        password: false
      },
      loading: false,
      passShow: 'password',
      activeKey: '1'
    };
  }

  handleLocaleSwitch = locale => {
    this.setState({ loading: true });

    this.props
      .changeLocale(locale.target.value)
      .then(() => {
        this.props.form.resetFields();
        this.setState({ loading: false });
        // location.reload();
      })
      .catch(e => {
        console.log(e);
      });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };
  showTC(e) {
    e.preventDefault();
    Modal.info({
      width: '90%',
      style: { top: 20 },
      title: 'TERMS and CONDITIONS',
      iconType: 'file-done',
      content: (
        <div style={{ height: '60vh', overflow: 'auto' }}>
          <iframe width="100%" height="100%" src="./tc.html" />
        </div>
      ),
      onOk() {}
    });
  }
  showCustomerTC(e) {
    e.preventDefault();
    Modal.info({
      width: '90%',
      style: { top: 20 },
      title: 'TERMS and CONDITIONS',
      iconType: 'file-done',
      content: (
        <div style={{ height: '60vh', overflow: 'auto' }}>
          <iframe width="100%" height="100%" src="./customerTc.html" />
        </div>
      ),
      onOk() {}
    });
  }
  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      // <Section className="panel" {...this.props} >
      <div style={{ background: 'white' }}>
        {' '}
        <a href="https://www.devvcsappcloud.com/verkoopnouweb/">
          {' '}
          <img src={logo} className="sign-in-logo-right" />
        </a>
        <Row type="flex" justify="start" align="middle">
          <Col
            className="sign-in-left"
            style={{ background: '#00888E', display: 'flex', height: '100vh' }}
            span={10}
          >
            <img
              src={CarPic}
              alt="Smiley face"
              height="100%"
              width="70%"
              style={{
                position: 'absolute',
                left: '0%'
              }}
            />
            <div style={{ margin: 'auto', textAlign: 'center' }}>
              {' '}
              <h1 style={{ color: 'white', fontSize: '55px' }}>
                {window.i18n.translate('Welcome')}
              </h1>
              <p style={{ color: 'white', fontSize: '25px' }}>
                {window.i18n.translate('SignIn') +
                  ' ' +
                  window.i18n.translate('ContinueSubHeading')}
              </p>
              <ButtonRadius
                style={{
                  height: 80,
                  fontSize: 30,
                  width: 300,
                  color: '#00888e'
                }}
                onClick={() => {
                  this.props.push('/signin');
                }}
                className="submit-button"
                htmlType="submit"
                size="large"
                block
              >
                {window.i18n.translate('SignIn')}
              </ButtonRadius>
            </div>
          </Col>
          <Col offset={5} sm={24} md={7} pull={2} className="sign-up-right">
            {' '}
            <Row type="flex" justify="space-around">
              <div
                className="hidden-comps-mobile"
                style={{
                  display: 'flex',
                  float: 'right',
                  borderRadius: '0.3em',
                  border: '1px solid #00888E',
                  marginBottom: '2em'
                }}
              >
                <Radio.Group
                  style={{ margin: 'auto' }}
                  buttonStyle="solid"
                  defaultValue={this.props.currentLocale}
                  onChange={this.handleLocaleSwitch}
                >
                  <Radio.Button value="af">Afrikaans</Radio.Button>
                  <Radio.Button value="en">English</Radio.Button>
                </Radio.Group>
              </div>

              <Radio.Group
                className="hidden-comps-webview"
                style={{
                  margin: 'auto',
                  marginBottom: '-4.5em',
                  display: 'flex',
                  float: 'right',

                  borderRadius: '0.3em',
                  border: '1px solid #00888E'
                }}
                buttonStyle="solid"
                defaultValue={this.props.currentLocale}
                onChange={this.handleLocaleSwitch}
              >
                <Radio.Button value="af">Afrikaans</Radio.Button>
                <Radio.Button value="en">English</Radio.Button>
              </Radio.Group>
            </Row>
            <h1
              className="welcome-sign-up-text"
              style={{
                lineHeight: 0.9,
                fontSize: '50px',
                color: '#808285',
                textAlign: 'center'
              }}
            >
              {window.i18n.translate('Welcome')}
            </h1>
            <h1
              className="create-sign-up-text"
              style={{
                lineHeight: 0.9,
                fontSize: '50px',
                color: '#808285',
                textAlign: 'center'
              }}
            >
              {window.i18n.translate('CreateAccountHeading')}
            </h1>
            <Spin spinning={this.state.loading}>
              <Tabs
                type={'card'}
                onChange={e => this.setState({ activeKey: e })}
                activeKey={this.state.activeKey}
              >
                <TabPane tab={window.i18n.translate('Customer')} key="1">
                  <Form onSubmit={this.handleSubmitUser}>
                    <Row type="flex" align="middle">
                      <Col span={11}>
                        <FormItem
                        // label={
                        //   <span style={{ color: "#00B4BC", fontSize: 20 }}>
                        //   First Name
                        //</Col>  </span>
                        // }
                        >
                          {getFieldDecorator('name', {
                            rules: [
                              {
                                required: true,
                                message: window.i18n.translate('NameRequired')
                              }
                            ]
                          })(
                            <TextField
                              label={window.i18n.translate('EnterName')}
                              style={{
                                marginTop: '1.5em',

                                width: '100%',
                                border: 0,
                                borderRadius: 0
                              }}
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col span={11}>
                        <FormItem
                        // label={
                        // <span style={{ color: "#00B4BC", fontSize: 20 }}>
                        //   Last Name
                        // </span>
                        //  }
                        >
                          {getFieldDecorator('surname', {
                            rules: [
                              {
                                required: true,
                                message: window.i18n.translate(
                                  'SurnameRequired'
                                )
                              }
                            ]
                          })(
                            <TextField
                              label={window.i18n.translate('EnterLastName')}
                              style={{
                                marginTop: '1.5em',
                                width: '110%',
                                marginLeft: '1em',
                                border: 0,
                                borderRadius: 0
                              }}
                            />
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                    <Row type="flex" align="middle">
                      <Col xs={24} md={11}>
                        <FormItem
                        // label={
                        //  <span style={{ color: "#00B4BC", fontSize: 20 }}>
                        //    Email Address
                        //   </span>
                        //  }
                        >
                          {getFieldDecorator('username', {
                            rules: [
                              {
                                required: true,
                                message: window.i18n.translate('EmailRequired')
                              },
                              {
                                type: 'email',
                                message: window.i18n.translate('EmailRequired')
                              }
                            ]
                          })(
                            <TextField
                              label={window.i18n.translate('EnterEmail')}
                              style={{
                                width: '100%',
                                border: 0,
                                borderRadius: 0
                              }}
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col xs={24} md={11}>
                        <FormItem
                        // label={
                        //<span style={{ color: "#00B4BC", fontSize: 20 }}>
                        //  Number
                        // </span>
                        // }
                        >
                          {getFieldDecorator('number', {
                            rules: [
                              {
                                required: true,
                                message: window.i18n.translate('NumberRequired')
                              },
                              {
                                pattern: RegExp(/\d/g),
                                message: window.i18n.translate('NumberRequired')
                              }
                            ]
                          })(
                            <TextField
                              className="login-number-field"
                              inputProps={{
                                maxLength: 10
                              }}
                              label={window.i18n.translate('EnterCellNumber')}
                              style={{
                                width: '115%',

                                border: 0,
                                borderRadius: 0
                              }}
                            />
                          )}
                        </FormItem>
                      </Col>
                    </Row>

                    <FormItem
                    //  label={
                    //  <span style={{ color: "#00B4BC", fontSize: 20 }}>
                    //    Create Password
                    //   </span>
                    //  }
                    >
                      {getFieldDecorator('password', {
                        rules: [
                          {
                            required: true,
                            message: window.i18n.translate('PasswordRequired')
                          }
                        ]
                      })(
                        <TextField
                          label={window.i18n.translate('EnterPassword')}
                          type="password"
                          style={{
                            width: '100%',
                            border: 0,
                            borderRadius: 0
                          }}
                        />
                      )}
                    </FormItem>
                    <FormItem
                    //  label={
                    //  <span style={{ color: "#00B4BC", fontSize: 20 }}>
                    //</FormItem>     Confirm Password
                    //  </span>
                    //  }
                    >
                      {getFieldDecorator('confirm', {
                        rules: [
                          {
                            required: true,
                            message:
                              window.i18n.translate('Confirm') +
                              ' ' +
                              window.i18n.translate('Password')
                          },
                          {
                            validator: this.compareToFirstPassword
                          }
                        ]
                      })(
                        <TextField
                          label={
                            window.i18n.translate('Confirm') +
                            ' ' +
                            window.i18n.translate('Password')
                          }
                          onBlur={this.handleConfirmBlur}
                          type="password"
                          style={{
                            width: '100%',
                            border: 0,
                            borderRadius: 0
                          }}
                        />
                      )}
                    </FormItem>
                    <FormItem>
                      {getFieldDecorator('TC', {
                        rules: [
                          {
                            transform: value => value || undefined,
                            type: 'boolean',
                            required: true,
                            message:
                              window.i18n.translate('Accept') +
                              ' ' +
                              window.i18n.translate('TermsConditions')
                          }
                        ]
                      })(
                        <div>
                          <Checkbox className="hidden-comps-mobile">
                            {window.i18n.translate('Accept') + ' '}
                            <a className="tc" onClick={this.showCustomerTC}>
                              {window.i18n.translate('TermsConditions')}
                            </a>{' '}
                          </Checkbox>{' '}
                          <Checkbox className="hidden-comps-webview">
                            {window.i18n.translate('Accept') + ' '}

                            <a className="tc" href="./customerTc.html" download>
                              {window.i18n.translate('TermsConditions')}
                            </a>
                          </Checkbox>
                        </div>
                      )}
                    </FormItem>
                    <FormItem>
                      <ButtonRadius
                        style={{ height: 80, fontSize: 30, marginTop: '1em' }}
                        className="submit-button-customer"
                        type="primary"
                        htmlType="submit"
                        size="large"
                        block
                      >
                        {window.i18n.translate('SignUp') +
                          ' ' +
                          window.i18n.translate('Customer')}
                      </ButtonRadius>{' '}
                      <ButtonRadius
                        style={{
                          height: 80,
                          fontSize: 30,
                          width: 300,
                          color: '#00888e'
                        }}
                        onClick={() => {
                          this.props.push('/signin');
                        }}
                        className="submit-button-sign-in"
                        htmlType="submit"
                        size="large"
                        block
                      >
                        <span style={{ fontSize: '1.4em' }}>
                          {window.i18n.translate('AlreadyRegistered') + '?'}
                        </span>
                      </ButtonRadius>
                    </FormItem>
                  </Form>
                </TabPane>
                <TabPane tab={window.i18n.translate('Dealer')} key="2">
                  <Form onSubmit={this.handleSubmitDealer}>
                    <Row type="flex" align="middle">
                      <Col span={11}>
                        <FormItem
                        // label={
                        //   <span style={{ color: "#00B4BC", fontSize: 20 }}>
                        //     First Name
                        //   </span>
                        //   }
                        >
                          {getFieldDecorator('name', {
                            rules: [
                              {
                                required: true,
                                message: window.i18n.translate('NameRequired')
                              }
                            ]
                          })(
                            <TextField
                              label={window.i18n.translate('EnterName')}
                              style={{
                                marginTop: '1.5em',
                                width: '100%',
                                border: 0,
                                borderRadius: 0
                              }}
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col span={11}>
                        <FormItem
                        //    label={
                        //      <span style={{ color: "#00B4BC", fontSize: 20 }}>
                        //       Last Name
                        //     </span>
                        // }
                        >
                          {getFieldDecorator('surname', {
                            rules: [
                              {
                                required: true,
                                message: window.i18n.translate(
                                  'SurnameRequired'
                                )
                              }
                            ]
                          })(
                            <TextField
                              label={window.i18n.translate('EnterLastName')}
                              style={{
                                marginTop: '1.5em',
                                width: '140%',
                                marginLeft: '1em',
                                border: 0,
                                borderRadius: 0
                              }}
                            />
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                    <Row type="flex" align="middle">
                      <Col xs={24} md={11}>
                        <FormItem
                        //   label={
                        //     <span style={{ color: "#00B4BC", fontSize: 20 }}>
                        //     Email Address
                        //     </span>
                        //  }
                        >
                          {getFieldDecorator('username', {
                            rules: [
                              {
                                required: true,
                                message: window.i18n.translate('EmailRequired')
                              },
                              {
                                type: 'email',
                                message: window.i18n.translate('EmailRequired')
                              }
                            ]
                          })(
                            <TextField
                              label={window.i18n.translate('EnterEmail')}
                              style={{
                                width: '100%',
                                border: 0,
                                borderRadius: 0
                              }}
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col xs={24} md={11}>
                        <FormItem
                        //    label={
                        //    <span style={{ color: "#00B4BC", fontSize: 20 }}>
                        //       Number
                        //     </span>
                        // }
                        >
                          {getFieldDecorator('number', {
                            rules: [
                              {
                                required: true,
                                message: window.i18n.translate('NumberRequired')
                              },
                              {
                                pattern: RegExp(/\d/g),
                                message: window.i18n.translate('NumberRequired')
                              }
                            ]
                          })(
                            <TextField
                              className="login-number-field"
                              label={window.i18n.translate('EnterCellNumber')}
                              inputProps={{
                                maxLength: 10
                              }}
                              style={{
                                width: '115%',

                                border: 0,
                                borderRadius: 0
                              }}
                            />
                          )}
                        </FormItem>
                      </Col>
                    </Row>

                    <FormItem
                    //  label={
                    //   <span style={{ color: "#00B4BC", fontSize: 20 }}>
                    //     Create Password
                    //  </span>
                    // }
                    >
                      {getFieldDecorator('password', {
                        rules: [
                          {
                            required: true,
                            message: window.i18n.translate('PasswordRequired')
                          }
                        ]
                      })(
                        <TextField
                          label={window.i18n.translate('EnterPassword')}
                          style={{
                            width: '100%',
                            border: 0,
                            borderRadius: 0
                          }}
                        />
                      )}
                    </FormItem>
                    <FormItem
                    //   label={
                    //    <span style={{ color: "#00B4BC", fontSize: 20 }}>
                    //      Confirm Password
                    //    </span>
                    //    }
                    >
                      {getFieldDecorator('confirm', {
                        rules: [
                          {
                            required: true,
                            message:
                              window.i18n.translate('Confirm') +
                              ' ' +
                              window.i18n.translate('Confirm')
                          },
                          {
                            validator: this.compareToFirstPassword
                          }
                        ]
                      })(
                        <TextField
                          label={
                            window.i18n.translate('Confirm') +
                            ' ' +
                            window.i18n.translate('Password')
                          }
                          onBlur={this.handleConfirmBlur}
                          type="password"
                          style={{
                            width: '100%',
                            border: 0,
                            borderRadius: 0
                          }}
                        />
                      )}
                    </FormItem>
                    <FormItem>
                      {getFieldDecorator('TC', {
                        rules: [
                          {
                            transform: value => value || undefined,
                            type: 'boolean',
                            required: true,
                            message:
                              window.i18n.translate('Accept') +
                              ' ' +
                              window.i18n.translate('TermsConditions')
                          }
                        ]
                      })(
                        <div>
                          <Checkbox className="hidden-comps-mobile">
                            {window.i18n.translate('Accept') + ' '}
                            <a className="tc" onClick={this.showTC}>
                              {window.i18n.translate('TermsConditions')}
                            </a>{' '}
                          </Checkbox>{' '}
                          <Checkbox className="hidden-comps-webview">
                            {window.i18n.translate('Accept') + ' '}

                            <a className="tc" href="./tc.html" download>
                              {window.i18n.translate('TermsConditions')}
                            </a>
                          </Checkbox>
                        </div>
                      )}
                    </FormItem>
                    <FormItem>
                      <ButtonRadius
                        style={{ height: 80, fontSize: 30, marginTop: '1em' }}
                        className="submit-button-customer"
                        type="primary"
                        htmlType="submit"
                        size="large"
                        block
                      >
                        {window.i18n.translate('SignUp') +
                          ' ' +
                          window.i18n.translate('Dealer')}
                      </ButtonRadius>
                      <ButtonRadius
                        style={{
                          height: 80,
                          fontSize: 30,
                          width: 300,
                          color: '#00888e'
                        }}
                        onClick={() => {
                          this.props.push('/signin');
                        }}
                        className="submit-button-sign-in"
                        htmlType="submit"
                        size="large"
                        block
                      >
                        <span style={{ fontSize: '1.4em' }}>
                          {window.i18n.translate('AlreadyRegistered') + '?'}
                        </span>
                      </ButtonRadius>
                    </FormItem>
                  </Form>
                </TabPane>
              </Tabs>
            </Spin>
          </Col>
        </Row>
      </div>
    );
  }
}

const WrappedSignUp = Form.create()(SignUp);

export default WrappedSignUp;
