import { getToken } from 'src/redux/selectors';

export function findMakes(year) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/cddata/findmakes?year=' +
          year,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: 'CDDATA_FIND_MAKES',
                data: parsedResponse
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findModels(make, year) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/cddata/findmodels?make=' +
          make +
          '&year=' +
          year,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: 'CDDATA_FIND_MODELS',
                data: parsedResponse
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findVariants(model, year) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/cddata/findvariants?model=' +
          model +
          '&year=' +
          year,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: 'CDDATA_FIND_VARIANTS',
                data: parsedResponse
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findYears() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch('https://www.devvcsappcloud.com/verkoopnou/api/cddata/findyears', {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        }
      })
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: 'CDDATA_FIND_YEARS',
                data: parsedResponse
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findCities(province) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/cities/findcities?province=' +
          province,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: 'CDDATA_FIND_CITIES',
                data: parsedResponse
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}
