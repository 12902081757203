import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  findMyVehicles,
  setEditVehicle,
  deleteVehicle,
  acceptOffer,
  rejectOffer,
  acceptAdminOffer,
  rejectAdminOffer
} from 'src/redux/actions/vehicles';
import { sendNotification } from 'src/redux/actions/notifications';
import { getLocale, getMyVehicles } from 'src/redux/selectors';

class MyVehicles extends Component {
  render = () => {
    const {
      Layout,
      currentLocale,
      vehicles,
      findMyVehicles,
      setEditVehicle,
      deleteVehicle,
      acceptOffer,
      rejectOffer,
      acceptAdminOffer,
      rejectAdminOffer,
      sendNotification,
      push
    } = this.props;
    return (
      <Layout
        currentLocale={currentLocale}
        vehicles={vehicles}
        findMyVehicles={findMyVehicles}
        setEditVehicle={setEditVehicle}
        deleteVehicle={deleteVehicle}
        acceptOffer={acceptOffer}
        rejectOffer={rejectOffer}
        acceptAdminOffer={acceptAdminOffer}
        rejectAdminOffer={rejectAdminOffer}
        sendNotification={sendNotification}
        push={push}
      />
    );
  };
}

const mapStateToProps = state => ({
  currentLocale: getLocale(state) || {},
  vehicles: getMyVehicles(state) || {}
});

const mapDispatchToProps = {
  getLocale,
  findMyVehicles,
  setEditVehicle,
  deleteVehicle,
  acceptOffer,
  rejectOffer,
  acceptAdminOffer,
  rejectAdminOffer,
  sendNotification,
  push
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyVehicles);
