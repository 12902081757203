import React from 'react';
import { Icon, Row, Col, Spin, Button, List, Modal, Table, Card } from 'antd';
import styled from 'styled-components';
import { ButtonSquare } from 'src/components/elements/Button';
import Notify from 'src/components/meta/Notification';
import noImage from 'src/static/icons/carImageUpload/no-image-available.svg';
const { Column, ColumnGroup } = Table;
const numeral = require('numeral');
const hammer = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6475 17.3418H1.65195C0.740018 17.3418 0 18.0817 0 18.9938V21.9977H14.2994V18.9938C14.2994 18.0817 13.5595 17.3418 12.6475 17.3418Z"
      fill="#AD5E00"
    />
    <path
      d="M21.7036 17.5744L11.6665 7.53725L13.6051 5.60203C13.9248 5.88391 14.4163 5.87013 14.7188 5.56766C15.0351 5.248 15.0351 4.7358 14.7188 4.41614L10.539 0.239743C10.2228 -0.0799143 9.70715 -0.0799143 9.39094 0.239743C9.07128 0.555998 9.07128 1.0716 9.39094 1.38781L9.35657 1.35344L4.04924 6.65728L4.08361 6.69505C3.76736 6.37539 3.25175 6.37539 2.93554 6.69505C2.61589 7.01131 2.61589 7.52691 2.93554 7.84317L7.11194 12.023C7.4316 12.3392 7.9472 12.3392 8.26346 12.023C8.57971 11.7033 8.57971 11.1912 8.26346 10.8715L8.29783 10.9058L10.2365 8.9706L20.2702 19.0043C20.6655 19.3996 21.3083 19.3996 21.7036 19.0043C22.0989 18.609 22.0989 17.9697 21.7036 17.5744Z"
      fill="#AD5E00"
    />
  </svg>
);
const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    text-align: center !important;
  }
  .ant-table-placeholder {
    display: none !important;
  }
`;
const StyledCard = styled(Card)`
  .ant-card-body {
    background-color: #f5f5f5;
    padding: 0px !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
    zoom: 1;
  }
`;
class MyVehicles extends React.Component {
  componentWillMount = () => {
    this.setState({ loading: true });
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('dealeroffer')) {
      if (searchParams.get('dealeroffer') === 'accept') {
        var that = this;
        this.props
          .acceptOffer(searchParams.get('vehicleid'))
          .then(res => {
            if (res === 'Over') {
              Modal.info({
                centered: true,
                title: 'Cannot accept offer',
                content: (
                  <div>
                    <h4>24 Hours have past since the offer was made.</h4>
                    <h4>Please contact us for further instructions.</h4>
                  </div>
                ),
                onOk() {
                  that.props
                    .findMyVehicles()
                    .then(() => {
                      that.setState({ loading: false });
                      window.location.search = '';
                    })
                    .catch(e => {
                      that.setState({ loading: false });
                      Notify('error', e);
                    });
                }
              });
            } else {
              this.props
                .findMyVehicles()
                .then(() => {
                  this.setState({ loading: false });
                  window.location.search = '';
                  Notify('success', 'Successfully accepted the offer');
                })
                .catch(e => {
                  this.setState({ loading: false });
                  Notify('error', e);
                });
            }
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      } else {
        this.props
          .rejectOffer(searchParams.get('vehicleid'))
          .then(() => {
            this.props
              .findMyVehicles()

              .then(() => {
                this.setState({ loading: false });
                window.location.search = '';
                Notify('success', 'Successfully rejected the offer');
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      }
    } else if (searchParams.has('adminoffer')) {
      if (searchParams.get('adminoffer') === 'accept') {
        this.props
          .acceptAdminOffer(searchParams.get('vehicleid'))
          .then(() => {
            this.props
              .findMyVehicles()
              .then(() => {
                this.setState({ loading: false });
                window.location.search = '';
                Notify('success', 'Successfully accepted the offer');
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      } else {
        this.props
          .rejectAdminOffer(searchParams.get('vehicleid'))
          .then(() => {
            this.props
              .findMyVehicles()
              .then(() => {
                this.props
                  .sendNotification(
                    'admin',
                    ['admin'],
                    window.i18n.translate('CustomerRrejectedOffer'),

                    'userRejectedOffer',
                    searchParams.get('vehicleid'),
                    true,
                    'Verkoopnou Admin'
                  )
                  .then(() => {
                    this.setState({ loading: false });
                    window.location.search = '';
                    Notify('success', 'Successfully rejected the offer');
                  })
                  .catch(e => {
                    this.setState({ loading: false });
                    Notify('error', e);
                  });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      }
    } else {
      this.props
        .findMyVehicles()
        .then(() => {
          this.setState({ loading: false });
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify('error', e);
        });
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      deleteVisible: false,
      deleteVehicle: ''
    };
  }
  formatNumber = number => {
    return 'R' + numeral(number).format('0,0');
  };
  handleEditVehicle = e => {
    const id = e.currentTarget.getAttribute('data');
    let editVehicle;
    for (var i = 0; i < this.props.vehicles.length; i++) {
      if (this.props.vehicles[i]._id === id) {
        editVehicle = this.props.vehicles[i];
        break;
      }
    }
    this.props.setEditVehicle(editVehicle);
    this.props.push('/user/editvehicle');
  };

  handleDeleteVehicle = e => {
    this.setState({ loading: true, deleteVisible: false });
    this.props
      .deleteVehicle(this.state.deleteVehicle)
      .then(() => {
        this.props
          .findMyVehicles()
          .then(() => {
            this.setState({ loading: false });
            Notify(
              'success',
              'Deleted vehicle',
              'Successfully deleted vehicle.'
            );
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };

  handleDeleteVehicleShow = e => {
    const id = e.currentTarget.getAttribute('data');
    this.setState({ deleteVisible: true, deleteVehicle: id });
  };

  cancelDelete = e => {
    this.setState({ deleteVisible: false, deleteVehicle: '' });
  };
  handleViewOffer = e => {
    const data = JSON.parse(e.currentTarget.getAttribute('data'));
    var that = this;
    Modal.confirm({
      okText: 'Yes',
      cancelText: 'No',
      centered: true,
      content: (
        <div>
          <h4>A dealer has won an auction on this vehicle.</h4>
          <h4>The winning amount is {that.formatNumber(data.clientAmount)}.</h4>
          <h4>Do you accept this offer?</h4>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .acceptOffer(data._id)
          .then(res => {
            if (res === 'Over') {
              that.setState({ loading: false });
              Modal.info({
                centered: true,
                title: 'Cannot accept offer',
                content: (
                  <div>
                    <h4>24 Hours have past since the offer was made.</h4>
                    <h4>Please contact us for further instructions.</h4>
                  </div>
                ),
                onOk() {}
              });
            } else {
              that.props
                .findMyVehicles()
                .then(() => {
                  that.setState({ loading: false });
                  Notify('success', 'Successfully accepted the offer');
                })
                .catch(e => {
                  that.setState({ loading: false });
                  Notify('error', e);
                });
            }
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify('error', e);
          });
      },
      onCancel() {
        that.setState({ loading: true });
        that.props
          .rejectOffer(data._id)
          .then(() => {
            that.props
              .findMyVehicles()
              .then(() => {
                this.setState({ loading: false });
                window.location.search = '';
                Notify('success', 'Successfully rejected the offer');
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify('error', e);
          });
      }
    });
  };
  handleViewAdminOffer = e => {
    const data = JSON.parse(e.currentTarget.getAttribute('data'));
    var that = this;
    Modal.confirm({
      okText: 'Yes',
      cancelText: 'No',
      centered: true,
      content: (
        <div>
          <h4>Admin has made an offer on your vehicle.</h4>
          <h4>
            The amount on offer is +- {that.formatNumber(data.offerAmount)}
          </h4>
          <h4>
            This offer is an estimate and can be more or less based on the
            inspection results of the vehicle.
          </h4>
          <h4>Do you accept this offer?</h4>
          <h4>
            If you decline then your vehicle will be added to the auction.
          </h4>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .acceptAdminOffer(data._id)
          .then(() => {
            that.props
              .findMyVehicles()
              .then(() => {
                that.setState({ loading: false });
                Notify('success', 'Successfully accepted the offer');
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify('error', e);
          });
      },
      onCancel() {
        that.setState({ loading: true });
        that.props
          .rejectAdminOffer(data._id)
          .then(() => {
            that.props
              .findMyVehicles()
              .then(() => {
                that.props
                  .sendNotification(
                    'admin',
                    ['admin'],
                    window.i18n.translate('CustomerRrejectedYourOffer'),
                    'userRejectedOffer',
                    data._id,
                    true,
                    'Verkoopnou Admin'
                  )
                  .then(() => {
                    that.setState({ loading: false });
                    Notify('success', 'Successfully rejected the offer');
                  })
                  .catch(e => {
                    that.setState({ loading: false });
                    Notify('error', e);
                  });
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify('error', e);
          });
      }
    });
  };
  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let vehicles = this.props.vehicles;
    if (order === 'ascend') {
      vehicles.sort((a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey]));
    } else if (order === 'descend') {
      vehicles.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
    }

    // debugger;
  };
  render() {
    return (
      <div>
        <Row type="flex" justify="start" align="middle">
          <Col className="my-vehicles-header" xs={24} md={12}>
            <Icon
              style={{ fontSize: 36, color: '#00888E', marginRight: 20 }}
              type="car"
              theme="filled"
            />
            <span
              style={{ fontWeight: 750, fontSize: 36, whiteSpace: 'nowrap' }}
            >
              {window.i18n.translate('MyVehicles')}
            </span>
          </Col>
          <Col className="my-vehicles-header" xs={24} md={12}>
            <ButtonSquare
              className="my-vehicles-header"
              style={{
                height: 46,
                background: '#00888E',
                width: 171,
                color: 'white',
                float: 'right'
              }}
              onClick={e => this.props.push('/user/addvehicle')}
            >
              {window.i18n.translate('AddVehicle')}
            </ButtonSquare>
          </Col>
        </Row>
        <Spin spinning={this.state.loading}>
          <Row
            className="my-vehicles-list-hidden"
            type="flex"
            justify="start"
            align="middle"
          >
            <Col span={24}>
              <StyledTable
                style={{ background: 'white' }}
                rowKey="_id"
                size="middle"
                onChange={this.handleSort}
              >
                <Column
                  title={window.i18n.translate('Image')}
                  width={'12.5%'}
                />
                <Column
                  title={window.i18n.translate('Details')}
                  width={'12.5%'}
                />
                <Column
                  title={window.i18n.translate('Completion')}
                  width={'12.5%'}
                />
                <Column
                  title={window.i18n.translate('Status')}
                  width={'12.5%'}
                />
                <Column
                  title={window.i18n.translate('Bidding')}
                  width={'12.5%'}
                />
                <Column
                  title={window.i18n.translate('Action')}
                  width={'12.5%'}
                />
              </StyledTable>
            </Col>
          </Row>
          <Row
            className="my-vehicles-list-hidden"
            type="flex"
            justify="start"
            align="middle"
          >
            <Col span={24}>
              <List
                dataSource={this.props.vehicles}
                renderItem={vehicle => (
                  <List.Item>
                    <div
                      style={{
                        width: '100%',
                        height: 154,
                        background: 'white',
                        display: 'flex',
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10
                      }}
                    >
                      <Col style={{ margin: 'auto', display: 'flex' }} span={3}>
                        <img
                          alt="Main"
                          style={{
                            maxHeight: 124,
                            maxWidth: 155,
                            margin: 'auto'
                          }}
                          src={
                            vehicle.mainImage
                              ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                vehicle.mainImage
                              : noImage
                          }
                        />
                      </Col>
                      <Col style={{ margin: 'auto', marginLeft: 0 }} span={3}>
                        <h2 style={{ marginBottom: 4 }}>{vehicle.year}</h2>
                        <h2 style={{ color: '#00888E' }}>
                          {vehicle.make} {vehicle.model}
                        </h2>
                        <h4>
                          {vehicle.colour} | {vehicle.transmission}
                        </h4>
                      </Col>
                      <Col style={{ margin: 'auto', display: 'flex' }} span={3}>
                        {vehicle.overallProgress >= '100' ? (
                          <div style={{ display: 'flex', margin: 'auto' }}>
                            <div
                              style={{
                                width: 11,
                                height: 11,
                                background: 'green',
                                borderRadius: '50%',
                                position: 'relative',
                                top: 6,
                                marginRight: 10
                              }}
                            />
                            <span style={{ fontSize: 18 }}>
                              {window.i18n.translate('Complete')}
                            </span>
                          </div>
                        ) : (
                          <div style={{ display: 'flex', margin: 'auto' }}>
                            <div
                              style={{
                                width: 11,
                                height: 11,
                                background: 'red',
                                borderRadius: '50%',
                                position: 'relative',
                                top: 6,
                                marginRight: 10
                              }}
                            />
                            <span style={{ fontSize: 18 }}>
                              {' '}
                              {window.i18n.translate('Incomplete')}
                            </span>
                          </div>
                        )}
                      </Col>
                      <Col style={{ margin: 'auto', display: 'flex' }} span={3}>
                        {vehicle.onAuction ? (
                          <div style={{ margin: 'auto' }}>
                            <Icon
                              style={{
                                color: 'green',
                                marginRight: 10,
                                fontSize: 23,
                                position: 'relative',
                                top: 4
                              }}
                              theme="filled"
                              type="check-circle"
                            />
                            <span style={{ fontSize: 18 }}>On Auction</span>
                          </div>
                        ) : vehicle.offerAccepted === true ||
                          vehicle.adminOfferAccepted === true ? (
                          <div style={{ margin: 'auto' }}>
                            <Icon
                              style={{
                                color: 'green',
                                marginRight: 10,
                                fontSize: 23,
                                position: 'relative',
                                top: 4
                              }}
                              theme="filled"
                              type="check-circle"
                            />
                            <span style={{ fontSize: 18 }}>
                              {' '}
                              {window.i18n.translate('OfferAccepted')}
                            </span>
                          </div>
                        ) : vehicle.offerAccepted === false ||
                          vehicle.adminOfferAccepted === false ? (
                          <div style={{ margin: 'auto' }}>
                            <Icon
                              style={{
                                color: 'red',
                                marginRight: 10,
                                fontSize: 23,
                                position: 'relative',
                                top: 4
                              }}
                              theme="filled"
                              type="close-circle"
                            />
                            <span style={{ fontSize: 18 }}>
                              {window.i18n.translate('OfferRejected')}
                            </span>
                          </div>
                        ) : vehicle.sentWinningBid ||
                          vehicle.offerAmount != undefined ? (
                          <div style={{ margin: 'auto' }}>
                            <Icon
                              style={{
                                color: '#FFB800',
                                marginRight: 10,
                                fontSize: 23,
                                position: 'relative',
                                top: 4
                              }}
                              theme="filled"
                              type="reconciliation"
                            />
                            <span style={{ fontSize: 18 }}>
                              {window.i18n.translate('AwaitingOfferReview')}
                            </span>
                          </div>
                        ) : vehicle.approved ? (
                          <div style={{ margin: 'auto' }}>
                            <Icon
                              style={{
                                color: 'green',
                                marginRight: 10,
                                fontSize: 23,
                                position: 'relative',
                                top: 4
                              }}
                              theme="filled"
                              type="check-circle"
                            />
                            <span style={{ fontSize: 18 }}>
                              {' '}
                              {window.i18n.translate('Approved')}
                            </span>
                          </div>
                        ) : vehicle.approved === false ? (
                          <div style={{ margin: 'auto' }}>
                            <Icon
                              style={{
                                color: 'red',
                                marginRight: 10,
                                fontSize: 23,
                                position: 'relative',
                                top: 4
                              }}
                              theme="filled"
                              type="close-circle"
                            />
                            <span style={{ fontSize: 18 }}>Rejected</span>
                          </div>
                        ) : vehicle.reviewForAuction ? (
                          <div style={{ margin: 'auto' }}>
                            <Icon
                              style={{
                                color: '#FFB800',
                                marginRight: 10,
                                fontSize: 23,
                                position: 'relative',
                                top: 4
                              }}
                              theme="filled"
                              type="reconciliation"
                            />
                            <span style={{ fontSize: 18 }}>
                              {window.i18n.translate('AwaitingApproval')}
                            </span>
                          </div>
                        ) : vehicle.overallProgress < '100' ? (
                          <div style={{ margin: 'auto' }}>
                            <Icon
                              style={{
                                color: 'red',
                                marginRight: 10,
                                fontSize: 23,
                                position: 'relative',
                                top: 4
                              }}
                              theme="filled"
                              type="close-circle"
                            />
                            <span style={{ fontSize: 18 }}>
                              {window.i18n.translate('AwaitingCompletion')}
                            </span>
                          </div>
                        ) : (
                          <div style={{ margin: 'auto' }}>
                            <Icon
                              style={{
                                color: 'red',
                                marginRight: 10,
                                fontSize: 23,
                                position: 'relative',
                                top: 4
                              }}
                              theme="filled"
                              type="close-circle"
                            />
                            <span style={{ fontSize: 18 }}>
                              {window.i18n.translate(' AwaitingSubmission')}
                            </span>
                          </div>
                        )}
                      </Col>
                      <Col style={{ margin: 'auto', display: 'flex' }} span={3}>
                        {vehicle.offerAccepted === true ? (
                          <div style={{ display: 'flex', margin: 'auto' }}>
                            <span style={{ fontSize: 18 }}>
                              {window.i18n.translate('Amount') + ':'}
                              {this.formatNumber(vehicle.clientAmount)}
                            </span>
                          </div>
                        ) : vehicle.offerAccepted === false ? (
                          <div style={{ display: 'flex', margin: 'auto' }}>
                            <span style={{ fontSize: 18 }}>
                              {window.i18n.translate('Amount') + ':'}
                              {this.formatNumber(vehicle.clientAmount)}
                            </span>
                          </div>
                        ) : vehicle.adminOfferAccepted === true ? (
                          <div style={{ display: 'flex', margin: 'auto' }}>
                            <span style={{ fontSize: 18 }}>
                              {window.i18n.translate('Amount') + ':'}
                              {this.formatNumber(vehicle.offerAmount)}
                            </span>
                          </div>
                        ) : vehicle.adminOfferAccepted === false ? (
                          <div style={{ display: 'flex', margin: 'auto' }}>
                            <span style={{ fontSize: 18 }}>
                              {window.i18n.translate('Amount') + ':'}{' '}
                              {this.formatNumber(vehicle.offerAmount)}
                            </span>
                          </div>
                        ) : vehicle.sentWinningBid ? (
                          <div style={{ display: 'flex', margin: 'auto' }}>
                            <Button
                              data={JSON.stringify(vehicle)}
                              onClick={this.handleViewOffer}
                              style={{
                                height: 46,
                                background: '#E5E5E5',
                                width: 171,
                                margin: 'auto'
                              }}
                            >
                              <Icon
                                style={{ color: '#6D6E70' }}
                                theme="filled"
                                type="check-circle"
                              />
                              {window.i18n.translate('ViewOffer')}
                            </Button>
                          </div>
                        ) : vehicle.offerAmount ? (
                          <div style={{ display: 'flex', margin: 'auto' }}>
                            <Button
                              data={JSON.stringify(vehicle)}
                              onClick={this.handleViewAdminOffer}
                              style={{
                                height: 46,
                                background: '#E5E5E5',
                                width: 171,
                                margin: 'auto'
                              }}
                            >
                              <Icon
                                style={{ color: '#6D6E70' }}
                                theme="filled"
                                type="check-circle"
                              />
                              {window.i18n.translate('ViewOffer')}
                            </Button>
                          </div>
                        ) : (
                          <div style={{ margin: 'auto' }}>
                            <Icon
                              style={{
                                color: '#FFB800',
                                marginRight: 10,
                                fontSize: 23,
                                position: 'relative',
                                top: 4
                              }}
                              theme="filled"
                              component={hammer}
                            />
                            <span style={{ fontSize: 18 }}>{vehicle.bids}</span>
                          </div>
                        )}
                      </Col>
                      <Col style={{ margin: 'auto' }} span={3}>
                        <div style={{ display: 'flex' }}>
                          <Button
                            // disabled={
                            //   vehicle.offerAccepted ||
                            //   vehicle.sentWinningBid ||
                            //   vehicle.reviewForAuction
                            // }
                            data={vehicle._id}
                            onClick={this.handleEditVehicle}
                            style={{
                              height: 46,
                              background: 'white',
                              color: 'rgb(0, 136, 142)',
                              border: '1px solid rgb(0, 136, 142)',
                              width: 171,
                              margin: 'auto',
                              marginBottom: 20
                            }}
                          >
                            <Icon
                              style={{ color: 'rgb(0, 136, 142)' }}
                              theme="filled"
                              type="edit"
                            />
                            {window.i18n.translate('EditVehicle')}
                          </Button>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <Button
                            //   disabled={
                            //   vehicle.offerAccepted ||
                            //   vehicle.sentWinningBid ||
                            //   vehicle.reviewForAuction
                            //}
                            data={vehicle._id}
                            onClick={this.handleDeleteVehicleShow}
                            style={{
                              height: 46,
                              background: 'white',
                              border: '1px solid rgb(185, 16, 50)',
                              color: 'rgb(185, 16, 50)',
                              width: 171,
                              margin: 'auto'
                            }}
                          >
                            <Icon
                              style={{ color: 'rgb(185, 16, 50)' }}
                              theme="filled"
                              type="delete"
                            />
                            {window.i18n.translate('DeleteVehicle')}
                          </Button>
                        </div>
                      </Col>
                    </div>
                  </List.Item>
                )}
              />
            </Col>
          </Row>{' '}
          <Col className="my-vehicles-list-hidden-mobile" span={24}>
            <List
              dataSource={this.props.vehicles}
              renderItem={vehicle => (
                <List.Item style={{ border: 'none' }}>
                  <StyledCard
                    style={{
                      background: 'white',
                      margin: 'auto',
                      textAlign: 'left',
                      borderRadius: '0.5em',

                      width: '85%',
                      boxShadow: '2px 2px 5px 0px rgba(207, 207, 207, 0.596)'
                    }}
                    cover={
                      <img
                        alt="Main"
                        style={{ borderRadius: '0.5em 0.5em 0em 0em' }}
                        src={
                          vehicle.mainImage
                            ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                              vehicle.mainImage
                            : noImage
                        }
                      />
                    }
                  >
                    <Col
                      style={{
                        margin: 'auto',
                        textAlign: 'left',
                        marginTop: '1em'
                      }}
                      span={24}
                    >
                      <h4 style={{ marginBottom: -8 }}>{vehicle.year}</h4>
                      <h2
                        style={{
                          color: '#00888E',
                          marginBottom: -5
                        }}
                      >
                        {vehicle.make} {vehicle.model}
                      </h2>
                      <h4>
                        {vehicle.colour} | {vehicle.transmission}
                      </h4>
                    </Col>
                    <Col
                      style={{
                        textAlign: 'left'
                      }}
                      span={24}
                    >
                      {vehicle.onAuction ? (
                        <div style={{ margin: 'auto' }}>
                          <Icon
                            style={{
                              color: 'green',
                              marginRight: 6,
                              fontSize: 18,
                              position: 'relative',
                              top: 1
                            }}
                            theme="filled"
                            type="check-circle"
                          />
                          <span style={{ fontSize: 16, fontWeight: 500 }}>
                            On Auction
                          </span>
                        </div>
                      ) : vehicle.offerAccepted === true ||
                        vehicle.adminOfferAccepted === true ? (
                        <div style={{ margin: 'auto' }}>
                          <Icon
                            style={{
                              color: 'green',
                              marginRight: 6,
                              fontSize: 18,
                              position: 'relative',
                              top: 1
                            }}
                            theme="filled"
                            type="check-circle"
                          />
                          <span style={{ fontSize: 16, fontWeight: 500 }}>
                            {window.i18n.translate('ViewAccepted')}
                          </span>
                        </div>
                      ) : vehicle.offerAccepted === false ||
                        vehicle.adminOfferAccepted === false ? (
                        <div style={{ margin: 'auto' }}>
                          <Icon
                            style={{
                              color: 'red',
                              marginRight: 6,
                              fontSize: 18,
                              position: 'relative',
                              top: 1
                            }}
                            theme="filled"
                            type="close-circle"
                          />
                          <span style={{ fontSize: 16, fontWeight: 500 }}>
                            {window.i18n.translate('OfferRejected')}
                          </span>
                        </div>
                      ) : vehicle.sentWinningBid ||
                        vehicle.offerAmount != undefined ? (
                        <div style={{ margin: 'auto' }}>
                          <Icon
                            style={{
                              color: '#FFB800',
                              marginRight: 6,
                              fontSize: 18,
                              position: 'relative',
                              top: 1
                            }}
                            theme="filled"
                            type="reconciliation"
                          />
                          <span style={{ fontSize: 16, fontWeight: 500 }}>
                            {window.i18n.translate('AwaitingOfferReview')}
                          </span>
                        </div>
                      ) : vehicle.approved ? (
                        <div style={{ margin: 'auto' }}>
                          <Icon
                            style={{
                              color: 'green',
                              marginRight: 6,
                              fontSize: 18,
                              position: 'relative',
                              top: 1
                            }}
                            theme="filled"
                            type="check-circle"
                          />
                          <span style={{ fontSize: 16, fontWeight: 500 }}>
                            {window.i18n.translate('Approved')}
                          </span>
                        </div>
                      ) : vehicle.approved === false ? (
                        <div style={{ margin: 'auto' }}>
                          <Icon
                            style={{
                              color: 'red',
                              marginRight: 6,
                              fontSize: 18,
                              position: 'relative',
                              top: 1
                            }}
                            theme="filled"
                            type="close-circle"
                          />
                          <span style={{ fontSize: 16, fontWeight: 500 }}>
                            Rejected
                          </span>
                        </div>
                      ) : vehicle.reviewForAuction ? (
                        <div style={{ margin: 'auto' }}>
                          <Icon
                            style={{
                              color: '#FFB800',
                              marginRight: 6,
                              fontSize: 18,
                              position: 'relative',
                              top: 1
                            }}
                            theme="filled"
                            type="reconciliation"
                          />
                          <span style={{ fontSize: 16, fontWeight: 500 }}>
                            {window.i18n.translate('AwaitingApproval')}
                          </span>
                        </div>
                      ) : vehicle.overallProgress < '100' ? (
                        <div style={{ margin: 'auto' }}>
                          <Icon
                            style={{
                              color: 'red',
                              marginRight: 6,
                              fontSize: 18,
                              position: 'relative',
                              top: 1
                            }}
                            theme="filled"
                            type="close-circle"
                          />
                          <span style={{ fontSize: 16, fontWeight: 500 }}>
                            {window.i18n.translate('AwaitingCompletion')}
                          </span>
                        </div>
                      ) : (
                        <div style={{ margin: 'auto' }}>
                          <Icon
                            style={{
                              color: 'red',
                              marginRight: 6,
                              fontSize: 18,
                              position: 'relative',
                              top: 1
                            }}
                            theme="filled"
                            type="close-circle"
                          />
                          <span style={{ fontSize: 16, fontWeight: 500 }}>
                            {window.i18n.translate('AwaitingSubmission')}
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col
                      style={{
                        margin: 'auto',
                        margin: 'auto',
                        textAlign: 'left'
                      }}
                      span={24}
                    >
                      {vehicle.offerAccepted === true ? (
                        <div
                          style={{
                            display: 'flex',
                            margin: 'auto',
                            marginLeft: '1.85em'
                          }}
                        >
                          <span style={{ fontSize: 16, fontWeight: 500 }}>
                            {window.i18n.translate('Amount') + ':'}{' '}
                            {this.formatNumber(vehicle.clientAmount)}
                          </span>
                        </div>
                      ) : vehicle.offerAccepted === false ? (
                        <div
                          style={{
                            display: 'flex',
                            margin: 'auto',
                            marginLeft: '1.85em'
                          }}
                        >
                          <span style={{ fontSize: 16, fontWeight: 500 }}>
                            {window.i18n.translate('Amount') + ':'}{' '}
                            {this.formatNumber(vehicle.clientAmount)}
                          </span>
                        </div>
                      ) : vehicle.adminOfferAccepted === true ? (
                        <div
                          style={{
                            display: 'flex',
                            margin: 'auto',
                            marginLeft: '1.85em'
                          }}
                        >
                          <span style={{ fontSize: 16, fontWeight: 500 }}>
                            {window.i18n.translate('Amount') + ':'}{' '}
                            {this.formatNumber(vehicle.offerAmount)}
                          </span>
                        </div>
                      ) : vehicle.adminOfferAccepted === false ? (
                        <div
                          style={{
                            display: 'flex',
                            margin: 'auto',
                            marginLeft: '1.85em'
                          }}
                        >
                          <span style={{ fontSize: 16, fontWeight: 500 }}>
                            {window.i18n.translate('Amount') + ':'}
                            {this.formatNumber(vehicle.offerAmount)}
                          </span>
                        </div>
                      ) : vehicle.sentWinningBid ? (
                        <div style={{ display: 'flex', margin: 'auto' }}>
                          <Button
                            data={JSON.stringify(vehicle)}
                            onClick={this.handleViewOffer}
                            style={{
                              background: 'white',
                              borderRadius: '3em',
                              border: '1px solid rgb(0, 136, 142)',
                              width: 171,
                              height: '2.7em',
                              margin: 'auto',
                              marginTop: '1em',
                              marginBottom: '0.5em',
                              color: 'rgb(0, 136, 142)'
                            }}
                          >
                            <Icon
                              style={{ color: 'rgb(0, 136, 142)' }}
                              theme="filled"
                              type="check-circle"
                            />
                            {window.i18n.translate('ViewOffer')}
                          </Button>
                        </div>
                      ) : vehicle.offerAmount ? (
                        <div style={{ display: 'flex', margin: 'auto' }}>
                          <Button
                            data={JSON.stringify(vehicle)}
                            onClick={this.handleViewAdminOffer}
                            style={{
                              background: 'white',
                              borderRadius: '3em',
                              border: '1px solid rgb(0, 136, 142)',
                              width: 171,
                              height: '2.7em',
                              margin: 'auto',
                              marginTop: '1em',
                              marginBottom: '0.5em',
                              color: 'rgb(0, 136, 142)'
                            }}
                          >
                            <Icon
                              style={{ color: 'rgb(0, 136, 142)' }}
                              theme="filled"
                              type="check-circle"
                            />
                            {window.i18n.translate('ViewOffer')}
                          </Button>
                        </div>
                      ) : (
                        <div
                          style={{
                            margin: 'auto',
                            float: 'right',
                            marginBottom: '15px',
                            textAlign: 'left'
                          }}
                        >
                          <Icon
                            style={{
                              color: '#FFB800',
                              marginRight: 6,
                              fontSize: 23,
                              position: 'relative',
                              top: 4
                            }}
                            theme="filled"
                            component={hammer}
                          />
                          <span style={{ fontSize: 16, fontWeight: 500 }}>
                            {vehicle.bids}
                          </span>
                        </div>
                      )}
                    </Col>{' '}
                    <Col
                      style={{
                        position: 'absolute',
                        top: 7,
                        right: 0
                      }}
                      span={24}
                    >
                      {vehicle.overallProgress >= '100' ? (
                        <div
                          style={{
                            display: 'flex',
                            margin: 'auto',
                            borderRadius: '0.3em 0em 0em 0.3em',
                            padding: '0.5em',
                            backgroundColor: '#fffffff5',
                            textAlign: 'center',
                            float: 'right'
                          }}
                        >
                          <div
                            style={{
                              width: 11,
                              height: 11,
                              background: 'green',
                              borderRadius: '50%',
                              position: 'relative',
                              top: 6.9,
                              marginRight: 10
                            }}
                          />
                          <span style={{ fontSize: 16, fontWeight: 500 }}>
                            {window.i18n.translate('Complete')}
                          </span>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            margin: 'auto',
                            borderRadius: '0.3em 0em 0em 0.3em',
                            padding: '0.5em',
                            backgroundColor: '#fffffff5',
                            textAlign: 'center',
                            float: 'right'
                          }}
                        >
                          <div
                            style={{
                              width: 11,
                              height: 11,
                              background: 'red',
                              borderRadius: '50%',
                              position: 'relative',
                              top: 6.9,
                              marginRight: 10
                            }}
                          />
                          <span style={{ fontSize: 16, fontWeight: 500 }}>
                            {window.i18n.translate('Incomplete')}
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col style={{}} span={12}>
                      <div style={{ display: 'flex', marginTop: '1em' }}>
                        <Button
                          //disabled={
                          //    vehicle.offerAccepted ||
                          //   vehicle.sentWinningBid ||
                          //    vehicle.reviewForAuction
                          //  }
                          data={vehicle._id}
                          onClick={this.handleEditVehicle}
                          style={{
                            height: 46,
                            background: 'white',
                            color: 'rgb(0, 136, 142)',
                            border: '1px solid rgb(0, 136, 142)',
                            width: 138,
                            margin: 'auto',
                            marginBottom: 20
                          }}
                        >
                          <Icon
                            style={{ color: 'rgb(0, 136, 142)' }}
                            theme="filled"
                            type="edit"
                          />
                          {window.i18n.translate('EditVehicle')}
                        </Button>
                      </div>
                    </Col>
                    <Col style={{ marginTop: '1em' }} span={12}>
                      <div style={{ display: 'flex' }}>
                        <Button
                          disabled={
                            vehicle.offerAccepted ||
                            vehicle.sentWinningBid ||
                            vehicle.reviewForAuction
                          }
                          data={vehicle._id}
                          onClick={this.handleDeleteVehicleShow}
                          style={{
                            height: 46,
                            background: 'white',
                            border: '1px solid rgb(185, 16, 50)',
                            color: 'rgb(185, 16, 50)',
                            width: 138,
                            margin: 'auto'
                          }}
                        >
                          <Icon
                            style={{ color: 'rgb(185, 16, 50)' }}
                            theme="filled"
                            type="delete"
                          />
                          {window.i18n.translate('DeleteVehicle')}
                        </Button>
                      </div>
                    </Col>
                  </StyledCard>
                </List.Item>
              )}
            />
          </Col>
        </Spin>
        <Modal
          title={window.i18n.translate('DeleteVehicle')}
          visible={this.state.deleteVisible}
          onOk={this.handleDeleteVehicle}
          onCancel={this.cancelDelete}
          okText={window.i18n.translate('Accept')}
          cancelText={window.i18n.translate('Cancel')}
        >
          <span>{window.i18n.translate('AreYouSureDeleteVehicle')}</span>
        </Modal>
      </div>
    );
  }
}

export default MyVehicles;
