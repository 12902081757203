import React from 'react';
import styled from 'styled-components';
import {
  Icon,
  Row,
  Col,
  Spin,
  Button,
  List,
  Tabs,
  Popover,
  Input,
  Table,
  Divider,
  Modal,
  Calendar,
  TimePicker,
  DatePicker
} from 'antd';
import moment from 'moment';
import Notify from 'src/components/meta/Notification';
import noImage from 'src/static/icons/carImageUpload/no-image-available.svg';
import Countdown from 'react-countdown-now';
import Highlighter from 'react-highlight-words';
const { Column, ColumnGroup } = Table;
const { TextArea } = Input;
const numeral = require('numeral');
const { TabPane } = Tabs;
const hammer = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6475 17.3418H1.65195C0.740018 17.3418 0 18.0817 0 18.9938V21.9977H14.2994V18.9938C14.2994 18.0817 13.5595 17.3418 12.6475 17.3418Z"
      fill="#00888E"
    />
    <path
      d="M21.7036 17.5744L11.6665 7.53725L13.6051 5.60203C13.9248 5.88391 14.4163 5.87013 14.7188 5.56766C15.0351 5.248 15.0351 4.7358 14.7188 4.41614L10.539 0.239743C10.2228 -0.0799143 9.70715 -0.0799143 9.39094 0.239743C9.07128 0.555998 9.07128 1.0716 9.39094 1.38781L9.35657 1.35344L4.04924 6.65728L4.08361 6.69505C3.76736 6.37539 3.25175 6.37539 2.93554 6.69505C2.61589 7.01131 2.61589 7.52691 2.93554 7.84317L7.11194 12.023C7.4316 12.3392 7.9472 12.3392 8.26346 12.023C8.57971 11.7033 8.57971 11.1912 8.26346 10.8715L8.29783 10.9058L10.2365 8.9706L20.2702 19.0043C20.6655 19.3996 21.3083 19.3996 21.7036 19.0043C22.0989 18.609 22.0989 17.9697 21.7036 17.5744Z"
      fill="#00888E"
    />
  </svg>
);

const StyledTable = styled(Table)`
  // .ant-table-thead > tr > th {
  //   text-align: center !important;

  // }
  .ant-table-thead > tr > th {
    line-height: 1em;
    font-size: 14px;
    color: grey;

    border-bottom: 1px solid#00878e34;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #e8e8e8;
    -webkit-transition: all 0.3s, border 0s;
    transition: all 0.3s, border 0s;
    font-weight: 700;
    font-size: 11px;
  }
`;
class Auction extends React.Component {
  componentWillMount = () => {
    this.setState({ loading: true });
    this.props
      .findApprovedVehicles()
      .then(() => {
        this.props
          .findCalendar()
          .then(() => {
            this.props
              .updateBidsList({ vehicles: this.props.vehicleIds })
              .then(() => {
                let vehicleBidState = {};
                this.props.vehicles.forEach(vehicle => {
                  let closed = false;
                  if (
                    vehicle.auctionDate.start !== null ||
                    vehicle.auctionDate.end !== null
                  ) {
                    if (
                      parseInt(moment().format('YYYYMMDD')) <
                        vehicle.auctionDate.start ||
                      parseInt(moment().format('YYYYMMDD')) >
                        vehicle.auctionDate.end
                    ) {
                      closed = true;
                    }
                  }
                  const timeLeft = this.timeStampTime(vehicle).valueOf();
                  const currentTime = moment(this.props.timeStamp).valueOf();
                  const timeDifference = (timeLeft - currentTime) / 60 / 1000;
                  if (timeDifference <= 0) {
                    closed = true;
                  }

                  vehicleBidState[vehicle._id] = {
                    closed: closed
                  };
                });
                this.setState({
                  bidData: vehicleBidState,
                  auctionTimes: this.props.calendar,
                  loading: false
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  updateBidSignal;
  updateTimeInterval;
  componentDidMount = () => {
    this.updateBidSignal = {
      signal: msg => {
        this.props
          .findApprovedVehicles()
          .then(() => {
            this.props
              .updateBidsList({ vehicles: this.props.vehicleIds })
              .then(() => {
                let vehicleBidState = {};
                this.props.vehicles.forEach(vehicle => {
                  let closed = false;
                  if (
                    vehicle.auctionDate.start !== null ||
                    vehicle.auctionDate.end !== null
                  ) {
                    if (
                      parseInt(moment().format('YYYYMMDD')) <
                        vehicle.auctionDate.start ||
                      parseInt(moment().format('YYYYMMDD')) >
                        vehicle.auctionDate.end
                    ) {
                      closed = true;
                    }
                  }
                  const timeLeft = this.timeStampTime(vehicle).valueOf();
                  const currentTime = moment(this.props.timeStamp).valueOf();
                  const timeDifference = (timeLeft - currentTime) / 60 / 1000;
                  if (timeDifference <= 0) {
                    closed = true;
                  }

                  vehicleBidState[vehicle._id] = {
                    closed: closed
                  };
                });
                this.setState({
                  bidData: vehicleBidState
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      }
    };
    window.pubnub.addListener(this.updateBidSignal);
    this.updateTimeInterval = setInterval(() => {
      this.setState({
        timeStamp: moment(this.state.timeStamp)
          .add(1, 'second')
          .valueOf()
      });
    }, 1000);
  };
  componentWillUnmount = () => {
    window.pubnub.removeListener(this.updateBidSignal);
    clearInterval(this.updateTimeInterval);
  };
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      rejectReason: '',
      dateValue: moment(),
      timeStart: '08:00:00',
      timeEnd: '16:00:00',
      auctionTimes: {},
      calendarVisible: false,
      vehiclesVisible: true,
      selectedVehicles: []
    };
  }
  parseString = string => {
    return parseInt(string.split('R')[1].replace(/,/g, ''));
  };
  formatNumber = number => {
    return 'R' + numeral(number).format('0,0');
  };
  stopProp = e => {
    e.stopPropagation();
  };

  handleSelectVehicle = e => {
    this.props.setVehicleDetailsId(e.currentTarget.getAttribute('data'));
    this.props.push('/admin/vehicledetails');
  };
  handleTabChange = e => {
    if (e === '2') {
      let basketVehicles = [];
      this.props.vehicles.forEach(vehicle => {
        if (this.props.user.basket.indexOf(vehicle._id) > -1) {
          basketVehicles.push(vehicle);
        }
      });
      this.setState({ basketVehicles: basketVehicles });
    }
  };
  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let vehicles = this.props.vehicles;
    if (order === 'ascend') {
      vehicles.sort((a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey]));
    } else if (order === 'descend') {
      vehicles.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
    }

    // debugger;
  };
  handleAuctionVehicle = e => {
    this.setState({ loading: true });
    const vehicle = JSON.parse(e.currentTarget.getAttribute('data'));
    this.props
      .auctionVehicle(vehicle._id, !vehicle.onAuction)
      .then(() => {
        this.props
          .findApprovedVehicles()
          .then(() => {
            let vehicleBidState = {};
            this.props.vehicles.forEach(vehicle => {
              let closed = false;
              if (
                vehicle.auctionDate.start !== null ||
                vehicle.auctionDate.end !== null
              ) {
                if (
                  parseInt(moment().format('YYYYMMDD')) <
                    vehicle.auctionDate.start ||
                  parseInt(moment().format('YYYYMMDD')) >
                    vehicle.auctionDate.end
                ) {
                  closed = true;
                }
              }
              const timeLeft = this.timeStampTime(vehicle).valueOf();
              const currentTime = moment(this.state.timeStamp).valueOf();
              const timeDifference = (timeLeft - currentTime) / 60 / 1000;
              if (timeDifference <= 0) {
                closed = true;
              }
              vehicleBidState[vehicle._id] = {
                closed: closed
              };
            });
            this.setState({ bidData: vehicleBidState, loading: false });
            Notify('success', 'Successfully changed vehicle auction status');
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, sorter);
    this.setState({
      sortedInfo: sorter
    });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {window.i18n.translate('Search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          {window.i18n.translate('Reset')}
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  onSelectDate = value => {
    this.setState({
      dateValue: value,
      selectedValue: value
    });
  };

  onPanelChange = value => {
    this.setState({ dateValue: value });
  };
  toggleAuctionActive = e => {
    const date = e.currentTarget.getAttribute('data');

    if (this.state.auctionTimes[date]) {
      let data = this.state.auctionTimes[date];
      data.status = !data.status;
      this.setState({ auctionTimes: { ...this.state.auctionTimes, ...data } });
    } else {
      let data = {};
      data[date] = {
        status: false,
        timeStart: '08:00:00',
        timeEnd: '16:00:00'
      };
      this.setState({ auctionTimes: { ...this.state.auctionTimes, ...data } });
    }
  };
  onChangeTimeStart = (time, date) => {
    if (this.state.auctionTimes[date]) {
      let data = this.state.auctionTimes[date];
      data.timeStart = time.format('HH:mm:ss');
      this.setState({ auctionTimes: { ...this.state.auctionTimes, ...data } });
    } else {
      let data = {};
      data[date] = {
        status: true,
        timeStart: time.format('HH:mm:ss'),
        timeEnd: '16:00:00'
      };
      this.setState({ auctionTimes: { ...this.state.auctionTimes, ...data } });
    }
  };
  onChangeTimeEnd = (time, date) => {
    if (this.state.auctionTimes[date]) {
      let data = this.state.auctionTimes[date];
      data.timeEnd = time.format('HH:mm:ss');
      this.setState({ auctionTimes: { ...this.state.auctionTimes, ...data } });
    } else {
      let data = {};
      data[date] = {
        status: true,
        timeStart: '08:00:00',
        timeEnd: time.format('HH:mm:ss')
      };
      this.setState({ auctionTimes: { ...this.state.auctionTimes, ...data } });
    }
  };
  getAuctionTimeData = (date, auctionTimes) => {
    let auctionTime;
    if (
      parseInt(moment().format('YYYYMMDD')) <= parseInt(date.format('YYYYMMDD'))
    ) {
      if (auctionTimes[date.format('YYYYMMDD')]) {
        const prop = date.format('YYYYMMDD');
        auctionTime = (
          <div style={{ textAlign: 'center' }}>
            <a
              style={{ width: '100%' }}
              data={prop}
              onClick={this.toggleAuctionActive}
            >
              <span
                style={{ color: auctionTimes[prop].status ? 'green' : 'red' }}
              >
                {auctionTimes[prop].status
                  ? window.i18n.translate('AuctionActive')
                  : window.i18n.translate('AuctionClosed')}
              </span>
            </a>
            <Col>
              <span>{window.i18n.translate('Start')}: </span>
              <TimePicker
                style={{ width: 90 }}
                allowClear={false}
                data={prop}
                value={moment(auctionTimes[prop].timeStart, 'HH:mm:ss')}
                onChange={e => this.onChangeTimeStart(e, prop)}
                size="small"
              />
            </Col>
            <Col>
              <span>{window.i18n.translate('End')}: </span>
              <TimePicker
                style={{ width: 90 }}
                allowClear={false}
                data={prop}
                value={moment(auctionTimes[prop].timeEnd, 'HH:mm:ss')}
                onChange={e => this.onChangeTimeEnd(e, prop)}
                size="small"
              />
            </Col>
          </div>
        );
      }
      return (
        auctionTime || (
          <div style={{ textAlign: 'center' }}>
            <a
              style={{ width: '100%' }}
              data={date.format('YYYYMMDD')}
              onClick={this.toggleAuctionActive}
            >
              <span style={{ color: 'green' }}>
                {' '}
                {window.i18n.translate('AuctionActive')}
              </span>
            </a>
            <Col>
              <span>{window.i18n.translate('Start')}: </span>
              <TimePicker
                style={{ width: 90 }}
                allowClear={false}
                data={date.format('YYYYMMDD')}
                value={moment(this.state.timeStart, 'HH:mm:ss')}
                onChange={e =>
                  this.onChangeTimeStart(e, date.format('YYYYMMDD'))
                }
                size="small"
              />
            </Col>
            <Col>
              <span>{window.i18n.translate('End')}: </span>
              <TimePicker
                style={{ width: 90 }}
                allowClear={false}
                data={date.format('YYYYMMDD')}
                value={moment(this.state.timeEnd, 'HH:mm:ss')}
                onChange={e => this.onChangeTimeEnd(e, date.format('YYYYMMDD'))}
                size="small"
              />
            </Col>
          </div>
        )
      );
    } else {
      return (
        <span style={{ color: 'red' }}>
          {window.i18n.translate('AuctionClosed')}
        </span>
      );
    }
  };
  dateCellRender = date => {
    let auctionTimes = this.getAuctionTimeData(date, this.state.auctionTimes);
    return auctionTimes;
  };
  handleTabChange = e => {
    if (e === '2') {
      this.setState({ calendarVisible: true, vehiclesVisible: false });
    } else {
      this.setState({ loading: true });
      this.props
        .findApprovedVehicles()
        .then(() => {
          this.props
            .findCalendar()
            .then(() => {
              this.props
                .updateBidsList({ vehicles: this.props.vehicleIds })
                .then(() => {
                  let vehicleBidState = {};
                  this.props.vehicles.forEach(vehicle => {
                    let closed = false;
                    if (
                      vehicle.auctionDate.start !== null ||
                      vehicle.auctionDate.end !== null
                    ) {
                      if (
                        parseInt(moment().format('YYYYMMDD')) <
                          vehicle.auctionDate.start ||
                        parseInt(moment().format('YYYYMMDD')) >
                          vehicle.auctionDate.end
                      ) {
                        closed = true;
                      }
                    }
                    const timeLeft = this.timeStampTime(vehicle).valueOf();
                    const currentTime = moment(this.props.timeStamp).valueOf();
                    const timeDifference = (timeLeft - currentTime) / 60 / 1000;
                    if (timeDifference <= 0) {
                      closed = true;
                    }

                    vehicleBidState[vehicle._id] = {
                      closed: closed
                    };
                  });
                  this.setState({
                    bidData: vehicleBidState,
                    auctionTimes: this.props.calendar,
                    loading: false
                  });
                })
                .catch(e => {
                  this.setState({ loading: false });
                  Notify('error', e);
                });
            })
            .catch(e => {
              this.setState({ loading: false });
              Notify('error', e);
            });
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify('error', e);
        });
      this.setState({ calendarVisible: false, vehiclesVisible: true });
    }
  };
  saveCalendarChanges = e => {
    this.setState({ loading: true });
    this.props
      .saveCalendar(this.state.auctionTimes)
      .then(() => {
        this.setState({ loading: false });
        Notify('success', 'Successfully saved calendar');
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  vehicleSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({ selectedVehicles: selectedRowKeys });
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name
    })
  };
  auctionAllVehicles = e => {
    this.setState({ loading: true });
    this.props
      .auctionVehicles(this.props.vehicleIds, true)
      .then(() => {
        this.props
          .findApprovedVehicles()
          .then(() => {
            let vehicleBidState = {};
            this.props.vehicles.forEach(vehicle => {
              let closed = false;
              if (
                vehicle.auctionDate.start !== null ||
                vehicle.auctionDate.end !== null
              ) {
                if (
                  parseInt(moment().format('YYYYMMDD')) <
                    vehicle.auctionDate.start ||
                  parseInt(moment().format('YYYYMMDD')) >
                    vehicle.auctionDate.end
                ) {
                  closed = true;
                }
              }
              vehicleBidState[vehicle._id] = {
                closed: closed
              };
            });
            this.setState({ bidData: vehicleBidState, loading: false });
            Notify(
              'success',
              'Successfully changed selected vehicle auction status'
            );
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  addSelectedVehicles = e => {
    this.setState({ loading: true });
    this.props
      .auctionVehicles(this.state.selectedVehicles, true)
      .then(() => {
        this.props
          .findApprovedVehicles()
          .then(() => {
            let vehicleBidState = {};
            this.props.vehicles.forEach(vehicle => {
              let closed = false;
              if (
                vehicle.auctionDate.start !== null ||
                vehicle.auctionDate.end !== null
              ) {
                if (
                  parseInt(moment().format('YYYYMMDD')) <
                    vehicle.auctionDate.start ||
                  parseInt(moment().format('YYYYMMDD')) >
                    vehicle.auctionDate.end
                ) {
                  closed = true;
                }
              }
              vehicleBidState[vehicle._id] = {
                closed: closed
              };
            });
            this.setState({ bidData: vehicleBidState, loading: false });
            Notify(
              'success',
              'Successfully changed selected vehicle auction status'
            );
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  removeSelectedVehicles = e => {
    this.setState({ loading: true });
    this.props
      .auctionVehicles(this.state.selectedVehicles, false)
      .then(() => {
        this.props
          .findApprovedVehicles()
          .then(() => {
            let vehicleBidState = {};
            this.props.vehicles.forEach(vehicle => {
              let closed = false;
              if (
                vehicle.auctionDate.start !== null ||
                vehicle.auctionDate.end !== null
              ) {
                if (
                  parseInt(moment().format('YYYYMMDD')) <
                    vehicle.auctionDate.start ||
                  parseInt(moment().format('YYYYMMDD')) >
                    vehicle.auctionDate.end
                ) {
                  closed = true;
                }
              }
              vehicleBidState[vehicle._id] = {
                closed: closed
              };
            });
            this.setState({ bidData: vehicleBidState, loading: false });
            Notify(
              'success',
              'Successfully changed selected vehicle auction status'
            );
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  handleAuctionStart = (e, vehicle) => {
    this.setState({ loading: true });
    vehicle.auctionDate.start = parseInt(e.format('YYYYMMDD'));
    this.props
      .setVehicleAuctionDate(vehicle)
      .then(() => {
        this.props
          .findApprovedVehicles()
          .then(() => {
            let vehicleBidState = {};
            this.props.vehicles.forEach(vehicle => {
              let closed = false;
              if (
                vehicle.auctionDate.start !== null ||
                vehicle.auctionDate.end !== null
              ) {
                if (
                  parseInt(moment().format('YYYYMMDD')) <
                    vehicle.auctionDate.start ||
                  parseInt(moment().format('YYYYMMDD')) >
                    vehicle.auctionDate.end
                ) {
                  closed = true;
                }
              }
              vehicleBidState[vehicle._id] = {
                closed: closed
              };
            });
            this.setState({ bidData: vehicleBidState, loading: false });
            Notify('success', 'Successfully set vehicle auction start date');
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  handleAuctionEnd = (e, vehicle) => {
    this.setState({ loading: true });
    vehicle.auctionDate.end = parseInt(e.format('YYYYMMDD'));
    this.props
      .setVehicleAuctionDate(vehicle)
      .then(() => {
        this.props
          .findApprovedVehicles()
          .then(() => {
            let vehicleBidState = {};
            this.props.vehicles.forEach(vehicle => {
              let closed = false;
              if (
                vehicle.auctionDate.start !== null ||
                vehicle.auctionDate.end !== null
              ) {
                if (
                  parseInt(moment().format('YYYYMMDD')) <
                    vehicle.auctionDate.start ||
                  parseInt(moment().format('YYYYMMDD')) >
                    vehicle.auctionDate.end
                ) {
                  closed = true;
                }
              }
              vehicleBidState[vehicle._id] = {
                closed: closed
              };
            });
            this.setState({ bidData: vehicleBidState, loading: false });
            Notify('success', 'Successfully set vehicle auction end date');
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  timeStampTime = vehicle => {
    let time = moment(this.props.timeStamp)
      .hour(parseInt(this.props.customTime.timeEnd.split(':')[0]))
      .minute(parseInt(this.props.customTime.timeEnd.split(':')[1]))
      .second(parseInt(this.props.customTime.timeEnd.split(':')[2]));
    time.add(vehicle.addAuctionMinutes, 'minutes');
    return time;
  };
  render() {
    const { user } = this.props;

    return (
      <div>
        <Row type="flex" justify="start" align="middle">
          <Col span={12}>
            <Icon
              style={{ fontSize: 36, color: '#00888E', marginRight: 20 }}
              component={hammer}
              theme="filled"
            />
            <span style={{ fontWeight: 750, fontSize: 36 }}>
              {window.i18n.translate('ManageAuction')}
            </span>
          </Col>
          <Col
            style={{ display: 'flex', justifyContent: 'flex-end' }}
            span={12}
          >
            <Button
              style={{
                display: this.state.calendarVisible ? 'block' : 'none',
                height: 46,
                width: 171,
                marginRight: 15
              }}
              onClick={this.saveCalendarChanges}
              type="primary"
            >
              {window.i18n.translate('SaveChanges')}
            </Button>

            <Button
              style={{
                display: this.state.vehiclesVisible ? 'block' : 'none',
                height: 46,

                marginRight: 15
              }}
              onClick={this.auctionAllVehicles}
              type="primary"
            >
              {window.i18n.translate('AuctionAllVehicles')}
            </Button>
            <Button
              style={{
                display: this.state.vehiclesVisible ? 'block' : 'none',
                height: 46,

                marginRight: 15
              }}
              onClick={this.addSelectedVehicles}
              type="primary"
            >
              {' '}
              {window.i18n.translate('AddSelectedVehicles')}
            </Button>
            <Button
              style={{
                display: this.state.vehiclesVisible ? 'block' : 'none',
                height: 46,

                marginRight: 15
              }}
              onClick={this.removeSelectedVehicles}
              type="primary"
            >
              {' '}
              {window.i18n.translate('RemoveSelectedVehicles')}
            </Button>
          </Col>
        </Row>
        <Spin spinning={this.state.loading}>
          <Tabs style={{ overflow: 'visible' }} onChange={this.handleTabChange}>
            <TabPane tab={window.i18n.translate('Vehicles')} key="1">
              {this.state.bidData && this.props.customTime ? (
                <Row type="flex" justify="start" align="middle">
                  <Col span={24}>
                    <StyledTable
                      rowSelection={this.vehicleSelection}
                      style={{ background: 'white' }}
                      rowKey="_id"
                      size="middle"
                      dataSource={this.props.vehicles}
                      onChange={this.handleSort}
                    >
                      <Column
                        sorter={() => {}}
                        title="ID"
                        dataIndex="displayId"
                        key="displayId"
                      />
                      {/* <Column title="Image" dataIndex="mainImage" key="mainImage" render={image => <img style={{maxWidth:40, maxHeight:40}} alt="Main" src={'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' + image} />} /> */}
                      <Column
                        {...this.getColumnSearchProps('make')}
                        title={window.i18n.translate('Make')}
                        dataIndex="make"
                        key="make"
                      />
                      <Column
                        {...this.getColumnSearchProps('model')}
                        title={window.i18n.translate('Model')}
                        dataIndex="model"
                        key="model"
                      />
                      <Column
                        {...this.getColumnSearchProps('variant')}
                        title={window.i18n.translate('Variant')}
                        dataIndex="variant"
                        key="variant"
                      />
                      <Column
                        {...this.getColumnSearchProps('mileage')}
                        sorter={() => {}}
                        title={window.i18n.translate('Mileage')}
                        dataIndex="mileage"
                        key="mileage"
                      />
                      <Column
                        {...this.getColumnSearchProps('price')}
                        sorter={() => {}}
                        title={window.i18n.translate('CustomerAskingPrice')}
                        dataIndex="price"
                        key="price"
                      />
                      <Column
                        {...this.getColumnSearchProps('reserve')}
                        sorter={() => {}}
                        title={window.i18n.translate('ReservePrice')}
                        dataIndex="reserve"
                        key="reserve"
                      />
                      <Column
                        {...this.getColumnSearchProps('province')}
                        title={window.i18n.translate('Province')}
                        dataIndex="province"
                        key="province"
                      />
                      <Column
                        {...this.getColumnSearchProps('city')}
                        title={window.i18n.translate('City')}
                        dataIndex="city"
                        key="city"
                      />
                      <Column
                        sorter={() => {}}
                        title={window.i18n.translate('HighestBid')}
                        dataIndex="highestBid"
                        key="highestBid"
                        render={(text, record) => {
                          return <span>{this.formatNumber(text)}</span>;
                        }}
                      />
                      <Column
                        title={window.i18n.translate('Start')}
                        render={(text, record) => {
                          if (record.auctionDate.start === null) {
                            return (
                              <DatePicker
                                allowClear={false}
                                placeholder={window.i18n.translate(
                                  'CurrentDay'
                                )}
                                style={{ width: 113 }}
                                onChange={e =>
                                  this.handleAuctionStart(e, record)
                                }
                              />
                            );
                          } else {
                            return (
                              <DatePicker
                                allowClear={false}
                                style={{ width: 113 }}
                                value={moment(
                                  record.auctionDate.start.toString()
                                )}
                                onChange={e =>
                                  this.handleAuctionStart(e, record)
                                }
                              />
                            );
                          }
                        }}
                      />
                      <Column
                        title={window.i18n.translate('End')}
                        render={(text, record) => {
                          if (record.auctionDate.end === null) {
                            return (
                              <DatePicker
                                allowClear={false}
                                placeholder={window.i18n.translate(
                                  'CurrentDay'
                                )}
                                style={{ width: 113 }}
                                onChange={e => this.handleAuctionEnd(e, record)}
                              />
                            );
                          } else {
                            return (
                              <DatePicker
                                allowClear={false}
                                style={{ width: 113 }}
                                value={moment(
                                  record.auctionDate.end.toString()
                                )}
                                onChange={e => this.handleAuctionEnd(e, record)}
                              />
                            );
                          }
                        }}
                      />
                      <Column
                        title={window.i18n.translate('OnAuction')}
                        render={(text, record) => (
                          <span
                            style={{
                              color: record.onAuction ? 'green' : 'red'
                            }}
                          >
                            {record.onAuction ? 'True' : 'False'}
                          </span>
                        )}
                      />
                      <Column
                        title={window.i18n.translate('TimeLeft')}
                        render={(text, vehicle) => {
                          if (
                            vehicle.onAuction &&
                            !this.state.bidData[vehicle._id].closed
                          ) {
                            return (
                              <Countdown
                                onComplete={e => {
                                  const data = this.state.bidData[vehicle._id];
                                  data.closed = true;
                                  this.setState({
                                    bidData: { ...this.state.bidData, ...data }
                                  });
                                }}
                                style={{ fontSize: '1.5em' }}
                                date={this.timeStampTime(vehicle).valueOf()}
                                now={e =>
                                  moment(this.state.timeStamp).valueOf()
                                }
                              />
                            );
                          } else {
                            return (
                              <span>{window.i18n.translate('Closed')}</span>
                            );
                          }
                        }}
                      />
                      <Column
                        title={window.i18n.translate('Action')}
                        render={(text, vehicle) => (
                          <span>
                            <a
                              data={JSON.stringify(vehicle)}
                              onClick={this.handleAuctionVehicle}
                            >
                              {vehicle.onAuction === true
                                ? window.i18n.translate('RemoveFromAuction')
                                : window.i18n.translate('PutOnAuction')}
                            </a>
                          </span>
                        )}
                      />
                    </StyledTable>
                  </Col>
                </Row>
              ) : null}
            </TabPane>
            <TabPane tab={window.i18n.translate('Calendar')} key="2">
              {this.state.bidData && this.props.customTime ? (
                <Calendar
                  dateCellRender={this.dateCellRender}
                  style={{ background: 'white' }}
                  value={this.state.dateValue}
                  onSelect={this.onSelectDate}
                  onPanelChange={this.onPanelChange}
                />
              ) : null}
            </TabPane>
          </Tabs>
        </Spin>
      </div>
    );
  }
}

export default Auction;
