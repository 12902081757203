export const initialState = {
  allVehicles: [],
  allVehicleIds: [],
  dealVehicles: [],
  approvedVehicles: [],
  approvedVehicleIds: [],
  auctionHistoryVehicles: [],
  vehicleDetails: undefined,
  vehicleDetailsId: '',
  allUsers: [],
  allDealers: [],
  calendar: {},
  fromHistory: false,
  biddingInfo: []
};

export default function adminReducer(state = initialState, action) {
  switch (action.type) {
    case 'ADMIN_FIND_CALENDAR': {
      if (action.data) {
        const calendar = action.data.calendar;
        return {
          ...state,
          calendar: calendar
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_VEHICLEDETAILS': {
      if (action.data) {
        const vehicleDetails = action.data.vehicleDetails;

        return {
          ...state,
          vehicleDetails: vehicleDetails
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_VEHICLEBIDINFO': {
      if (action.data) {
        const biddingInfo = action.data.biddingInfo;

        return {
          ...state,
          biddingInfo: biddingInfo
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_ALLVEHICLES': {
      if (action.data) {
        const allVehicles = action.data.allVehicles;
        let allVehicleIds = [];
        allVehicles.forEach(vehicle => {
          allVehicleIds.push(vehicle._id);
        });
        return {
          ...state,
          allVehicles: allVehicles,
          allVehicleIds: allVehicleIds
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_DEALVEHICLES': {
      if (action.data) {
        const dealVehicles = action.data.dealVehicles;
        return {
          ...state,
          dealVehicles: dealVehicles
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_APPROVEDVEHICLES': {
      if (action.data) {
        const approvedVehicles = action.data.approvedVehicles;
        let approvedVehicleIds = [];
        approvedVehicles.forEach(vehicle => {
          approvedVehicleIds.push(vehicle._id);
        });
        return {
          ...state,
          approvedVehicles: approvedVehicles,
          approvedVehicleIds: approvedVehicleIds
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_AUCTIONHISTORYVEHICLES': {
      if (action.data) {
        const auctionHistoryVehicles = action.data.auctionHistoryVehicles;
        return {
          ...state,
          auctionHistoryVehicles: auctionHistoryVehicles
        };
      }
      return initialState;
    }
    case 'ADMIN_SET_VEHICLEDETAILSID': {
      if (action.data) {
        const id = action.data;
        return {
          ...state,
          vehicleDetailsId: id
        };
      }
      return initialState;
    }
    case 'ADMIN_SET_FROMHISTORY': {
      if (action.data) {
        const boolean = action.data;
        return {
          ...state,
          fromHistory: boolean
        };
      }
      return initialState;
    }
    case 'ADMIN_BIDDING_UPDATE_LIST': {
      if (action.data) {
        let auctionVehicles = state.auctionVehicles;
        let updatedVehicles = action.data.vehicles;
        auctionVehicles.map(obj => {
          obj.highestBid = updatedVehicles.find(
            o => o._id === obj._id
          ).highestBid;
          obj.addAuctionMinutes = updatedVehicles.find(
            o => o._id === obj._id
          ).addAuctionMinutes;
        });
        const timeStamp = action.data.timeStamp;
        return {
          ...state,
          auctionVehicles: auctionVehicles,
          timeStamp: timeStamp
        };
      }
      return initialState;
    }
    case 'ADMIN_BIDDING_UPDATE_SINGLE': {
      if (action.data) {
        let vehicleDetails = state.vehicleDetails || {};
        vehicleDetails.highestBid = action.data.vehicle.highestBid;
        vehicleDetails.addAuctionMinutes =
          action.data.vehicle.addAuctionMinutes;
        const timeStamp = action.data.timeStamp;

        return {
          ...state,
          vehicleDetails: vehicleDetails,
          timeStamp: timeStamp
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_ALLUSERS': {
      if (action.data) {
        const allUsers = action.data.allUsers;
        return {
          ...state,
          allUsers: allUsers
        };
      }
      return initialState;
    }
    case 'ADMIN_FIND_ALLDEALERS': {
      if (action.data) {
        const allDealers = action.data.allDealers;
        return {
          ...state,
          allDealers: allDealers
        };
      }
      return initialState;
    }
    default:
      return state;
  }
}
