import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  findBasketVehicles,
  setVehicleDetailsId,
  addBasketVehicle,
  removeBasketVehicle
} from 'src/redux/actions/vehicles';
import {
  updateBidsBasket,
  placeBid,
  findBidData
} from 'src/redux/actions/bidding';
import {
  getLocale,
  getBasketVehicles,
  getBasketVehicleIds,
  getUser,
  getTimeStamp,
  getCustomTime,
  getBidData
} from 'src/redux/selectors';

class MyBasket extends Component {
  render = () => {
    const {
      Layout,
      currentLocale,
      vehicles,
      vehicleIds,
      user,
      findBasketVehicles,
      setVehicleDetailsId,
      addBasketVehicle,
      removeBasketVehicle,
      updateBidsBasket,
      placeBid,
      findBidData,
      bidData,
      timeStamp,
      customTime,
      push
    } = this.props;
    return (
      <Layout
        currentLocale={currentLocale}
        vehicles={vehicles}
        vehicleIds={vehicleIds}
        user={user}
        findBasketVehicles={findBasketVehicles}
        setVehicleDetailsId={setVehicleDetailsId}
        addBasketVehicle={addBasketVehicle}
        removeBasketVehicle={removeBasketVehicle}
        updateBidsBasket={updateBidsBasket}
        placeBid={placeBid}
        findBidData={findBidData}
        bidData={bidData}
        timeStamp={timeStamp}
        customTime={customTime}
        push={push}
      />
    );
  };
}

const mapStateToProps = state => ({
  currentLocale: getLocale(state) || {},
  vehicles: getBasketVehicles(state) || {},
  vehicleIds: getBasketVehicleIds(state) || [],
  bidData: getBidData(state) || {},
  timeStamp: getTimeStamp(state) || '',
  customTime: getCustomTime(state) || '',
  user: getUser(state) || {}
});

const mapDispatchToProps = {
  getLocale,
  findBasketVehicles,
  setVehicleDetailsId,
  addBasketVehicle,
  updateBidsBasket,
  removeBasketVehicle,
  placeBid,
  findBidData,
  push
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyBasket);
