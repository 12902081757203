import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push, goBack } from 'connected-react-router';
import {
  findVehicleDetails,
  addBasketVehicle,
  removeBasketVehicle
} from 'src/redux/actions/vehicles';
import { updateBid, placeBid, findBidData } from 'src/redux/actions/bidding';
import {
  getLocale,
  getVehicleDetails,
  getVehicleDetailsId,
  getTimeStamp,
  getBidData,
  getCustomTime,
  getUser
} from 'src/redux/selectors';

class VehicleDetails extends Component {
  render = () => {
    const {
      Layout,
      user,
      currentLocale,
      vehicleDetails,
      findVehicleDetails,
      vehicleDetailsId,
      addBasketVehicle,
      removeBasketVehicle,
      updateBid,
      findBidData,
      bidData,
      placeBid,
      timeStamp,
      customTime,
      push,
      goBack
    } = this.props;
    return (
      <Layout
        currentLocale={currentLocale}
        user={user}
        vehicleDetails={vehicleDetails}
        findVehicleDetails={findVehicleDetails}
        vehicleDetailsId={vehicleDetailsId}
        addBasketVehicle={addBasketVehicle}
        removeBasketVehicle={removeBasketVehicle}
        updateBid={updateBid}
        placeBid={placeBid}
        findBidData={findBidData}
        bidData={bidData}
        timeStamp={timeStamp}
        customTime={customTime}
        push={push}
        goBack={goBack}
      />
    );
  };
}

const mapStateToProps = state => ({
  currentLocale: getLocale(state) || {},
  vehicleDetails: getVehicleDetails(state) || {},
  vehicleDetailsId: getVehicleDetailsId(state) || '',
  bidData: getBidData(state) || {},
  timeStamp: getTimeStamp(state) || '',
  customTime: getCustomTime(state) || '',
  user: getUser(state) || {}
});

const mapDispatchToProps = {
  getLocale,
  findVehicleDetails,
  addBasketVehicle,
  removeBasketVehicle,
  updateBid,
  placeBid,
  findBidData,
  push,
  goBack
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleDetails);
