import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { signUpUser, signUpDealer, signIn } from 'src/redux/actions/user';
import { getLocale } from 'src/redux/selectors';
import changeLocale from 'src/redux/actions/locale';

class SignUp extends Component {
  componentWillMount = () => {
    if (window.location.pathname.search('/admin') > -1) {
      this.props.push('/admin');
    }
  };
  render = () => {
    const {
      Layout,
      signUpUser,
      signUpDealer,
      signIn,
      currentLocale,
      changeLocale,
      push
    } = this.props;

    return (
      <Layout
        signUpUser={signUpUser}
        signUpDealer={signUpDealer}
        signIn={signIn}
        currentLocale={currentLocale}
        changeLocale={changeLocale}
        push={push}
      />
    );
  };
}

const mapStateToProps = state => ({
  currentLocale: getLocale(state) || {}
});

const mapDispatchToProps = {
  signUpUser,
  signUpDealer,
  signIn,
  changeLocale,
  push
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp);
