import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { signIn } from 'src/redux/actions/user';
import { getLocale } from 'src/redux/selectors';
import changeLocale from 'src/redux/actions/locale';

class SignIn extends Component {
  componentWillMount = () => {
    if (window.location.pathname.search('/admin') === -1) {
      this.props.push('/');
    }
  };
  render = () => {
    const { Layout, signIn, currentLocale, changeLocale, push } = this.props;

    return (
      <Layout
        signIn={signIn}
        currentLocale={currentLocale}
        changeLocale={changeLocale}
        push={push}
      />
    );
  };
}

const mapStateToProps = state => ({
  currentLocale: getLocale(state)
});

const mapDispatchToProps = {
  signIn,
  changeLocale,
  push
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);
