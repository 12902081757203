import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  findAllVehicles,
  setVehicleDetailsId,
  approveVehicle,
  rejectVehicle
} from 'src/redux/actions/admin';
import {
  updateBidsList,
  placeBid,
  findBidData
} from 'src/redux/actions/bidding';
import { sendNotification } from 'src/redux/actions/notifications';
import {
  getLocale,
  getAllVehicles,
  getAllVehicleIds,
  getUser,
  getTimeStamp,
  getBidData
} from 'src/redux/selectors';
import { deleteVehicle } from 'src/redux/actions/vehicles';

class Vehicles extends Component {
  render = () => {
    const {
      Layout,
      currentLocale,
      vehicles,
      vehicleIds,
      user,
      findAllVehicles,
      setVehicleDetailsId,
      approveVehicle,
      rejectVehicle,
      updateBidsList,
      placeBid,
      findBidData,
      bidData,
      timeStamp,
      deleteVehicle,
      sendNotification,
      push
    } = this.props;
    return (
      <Layout
        currentLocale={currentLocale}
        vehicles={vehicles}
        vehicleIds={vehicleIds}
        user={user}
        findAllVehicles={findAllVehicles}
        setVehicleDetailsId={setVehicleDetailsId}
        approveVehicle={approveVehicle}
        rejectVehicle={rejectVehicle}
        updateBidsList={updateBidsList}
        placeBid={placeBid}
        findBidData={findBidData}
        bidData={bidData}
        timeStamp={timeStamp}
        deleteVehicle={deleteVehicle}
        sendNotification={sendNotification}
        push={push}
      />
    );
  };
}

const mapStateToProps = state => ({
  currentLocale: getLocale(state) || {},
  vehicles: getAllVehicles(state) || {},
  vehicleIds: getAllVehicleIds(state) || [],
  bidData: getBidData(state) || {},
  timeStamp: getTimeStamp(state) || '',
  user: getUser(state) || {}
});

const mapDispatchToProps = {
  getLocale,
  findAllVehicles,
  setVehicleDetailsId,
  updateBidsList,
  approveVehicle,
  rejectVehicle,
  placeBid,
  findBidData,
  deleteVehicle,
  sendNotification,
  push
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Vehicles);
