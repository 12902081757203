import React from 'react';
import styled from 'styled-components';
import {
  Icon,
  Select,
  Row,
  Col,
  Spin,
  Input,
  Collapse,
  DatePicker,
  Card,
  Button,
  Upload,
  Radio,
  Popover,
  Descriptions,
  Modal
} from 'antd';
import { CirclePicker } from 'react-color';
import Resizer from 'react-image-file-resizer';
import { Stage, Layer, Text, Image, Circle, Group } from 'react-konva';
import Konva from 'konva';
import { ButtonSquare } from 'src/components/elements/Button';
import Notify from 'src/components/meta/Notification';
import halfWornTyre from 'src/static/tyreImages/halfWorn.jpg';
import newTyre from 'src/static/tyreImages/new.jpg';
import wornTyre from 'src/static/tyreImages/worn.jpg';
import dropImage from 'src/static/icons/carImageUpload/placeholder.png';
import leftSedan from 'src/static/damageImages/Sedan_Left.svg';
import rightSedan from 'src/static/damageImages/Sedan_Right.svg';
import frontSedan from 'src/static/damageImages/Sedan_Front.svg';
import backSedan from 'src/static/damageImages/Sedan_Back.svg';
import topSedan from 'src/static/damageImages/Sedan_Top.svg';
import leftSUV from 'src/static/damageImages/SUV_Left.svg';
import rightSUV from 'src/static/damageImages/SUV_Right.svg';
import frontSUV from 'src/static/damageImages/SUV_Front.svg';
import backSUV from 'src/static/damageImages/SUV_Back.svg';
import topSUV from 'src/static/damageImages/SUV_Top.svg';
import leftHatchback from 'src/static/damageImages/Hatchback_Left.svg';
import rightHatchback from 'src/static/damageImages/Hatchback_Right.svg';
import frontHatchback from 'src/static/damageImages/Hatchback_Front.svg';
import backHatchback from 'src/static/damageImages/Hatchback_Back.svg';
import topHatchback from 'src/static/damageImages/Hatchback_Top.svg';
import leftBakkie from 'src/static/damageImages/Bakkie_Left.svg';
import rightBakkie from 'src/static/damageImages/Bakkie_Right.svg';
import frontBakkie from 'src/static/damageImages/Bakkie_Front.svg';
import backBakkie from 'src/static/damageImages/Bakkie_Back.svg';
import topBakkie from 'src/static/damageImages/Bakkie_Top.svg';
import leftVan from 'src/static/damageImages/Van_Left.svg';
import rightVan from 'src/static/damageImages/Van_Right.svg';
import frontVan from 'src/static/damageImages/Van_Front.svg';
import backVan from 'src/static/damageImages/Van_Back.svg';
import topVan from 'src/static/damageImages/Van_Top.svg';
import upArrow from 'src/static/icons/collapse/up-arrow.svg';
import downArrow from 'src/static/icons/collapse/down-arrow.svg';
import prepDefinition from 'src/components/meta/PdfDefinition';
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
const damageImageObject = {
  leftSedan: leftSedan,
  rightSedan: rightSedan,
  frontSedan: frontSedan,
  backSedan: backSedan,
  topSedan: topSedan,
  leftSUV: leftSUV,
  rightSUV: rightSUV,
  frontSUV: frontSUV,
  backSUV: backSUV,
  topSUV: topSUV,
  leftHatchback: leftHatchback,
  rightHatchback: rightHatchback,
  frontHatchback: frontHatchback,
  backHatchback: backHatchback,
  topHatchback: topHatchback,
  leftBakkie: leftBakkie,
  rightBakkie: rightBakkie,
  frontBakkie: frontBakkie,
  backBakkie: backBakkie,
  topBakkie: topBakkie,
  leftVan: leftVan,
  rightVan: rightVan,
  frontVan: frontVan,
  backVan: backVan,
  topVan: topVan
};
const numeral = require('numeral');
const Option = Select.Option;
const { Meta } = Card;
const Selection = styled(Select)`
  .ant-select-selection {
    border: 1px solid #bdb2b2;
    height: 45px;
    font-size: 12px;
  }
  .ant-select-selection__placeholder {
    top: 21px;
  }
  .ant-select-selection__rendered {
    height: 44px;
  }
  .ant-select-selection-selected-value {
    position: relative;
    top: 6px;
  }
`;
const DatePick = styled(DatePicker)`
  .ant-calendar-picker-input {
    border: 1px solid #bdb2b2;
    height: 45px;
    font-size: 12px;
  }
`;
const RadioButtonNo = styled(Radio.Button)`
  color: red !important;
  border: 1px solid red !important;
  border-radius: 50% !important;
  &&.ant-radio-button-wrapper-checked {
    background: red;
    color: white !important;
    border: 1px solid red !important;
    box-shadow: none;
  }
  &&.ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
`;
const RadioButtonYes = styled(Radio.Button)`
  color: green !important;
  border: 1px solid green !important;
  border-radius: 50% !important;
  &&.ant-radio-button-wrapper-checked {
    background: green;
    color: white !important;
    border: 1px solid green !important;
    box-shadow: none;
  }
  &&.ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
`;
const Cards = styled(Card)`
  .ant-card-body {
    display: none !important;
  }
`;
const DescriptionsStyle = styled(Descriptions)`
  .ant-descriptions-view {
    background: white;
  }
`;

const DescriptionsStyle2 = styled(Descriptions)`
  .ant-descriptions-title {
    text-align: center;
  }
  .ant-descriptions-view {
    background: white;
  }
  &&.ant-descriptions-bordered .ant-descriptions-item-label,
  .ant-descriptions-bordered .ant-descriptions-item-content {
    padding: 5px 24px !important;
    border-right: 1px solid #e8e8e8;
  }
  &&.ant-descriptions-bordered .ant-descriptions-item-content {
    padding: 5px 24px !important;
    border-right: 1px solid #e8e8e8;
  }
`;

const customPanelStyle = {
  background: 'white',
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
  marginBottom: 7,
  border: 0,
  overflow: 'hidden',
  color: '#00B4BC',
  paddingLeft: 20,
  paddingRight: 20
};
const customInputStyle = {
  border: '1px solid #BDB2B2',
  height: 45,
  fontSize: 12
};
class URLImage extends React.Component {
  state = {
    image: null
  };
  componentDidMount() {
    this.loadImage();
  }
  componentDidUpdate(oldProps) {
    if (oldProps.src !== this.props.src) {
      this.loadImage();
    }
  }
  componentWillUnmount() {
    this.image.removeEventListener('load', this.handleLoad);
  }
  loadImage() {
    this.image = new window.Image();
    this.image.setAttribute('crossOrigin', 'anonymous');
    this.image.src = this.props.src;
    this.image.addEventListener('load', this.handleLoad);
  }
  handleLoad = () => {
    this.setState({
      image: this.image
    });
  };
  render() {
    return (
      <Image
        width={this.props.width}
        height={this.props.height}
        x={this.props.x}
        y={this.props.y}
        image={this.state.image}
        ref={node => {
          this.imageNode = node;
        }}
      />
    );
  }
}
class EditVehicle extends React.Component {
  exportToPdf = () => {
    this.setState({ loading: true });
    const { vfs } = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;
    let data = Object.assign({}, this.state);
    if (this.stageRef.current) {
      data.damage = this.stageRef.current.toDataURL({
        width: 950,
        height: 470
      });
    }
    prepDefinition(data).then(definition => {
      pdfMake.createPdf(definition).download('Vehicle_Details.pdf', () => {
        this.setState({ loading: false });
      });
    });
  };

  editVehicle = event => {
    let editHistory = this.props.originalVehicle.editHistory;
    for (var prop in this.props.originalVehicle) {
      if (prop !== 'editHistory') {
        if (this.state[prop] !== this.props.originalVehicle[prop]) {
          editHistory[prop] = {
            user: this.props.user.username,
            timeStamp: moment().format('YYYY/MM/DD HH:mm:ss')
          };
        }
      }
    }
    this.setState(
      {
        loading: true,
        whereBought:
          this.state.whereBoughtSelection + ' - ' + this.state.whereBought,
        licenseExpire: this.state.licenseExpireDate
          ? this.state.licenseExpire + ' - ' + this.state.licenseExpireDate
          : this.state.licenseExpire,
        editHistory: editHistory
      },
      () => {
        let data = this.state;
        if (this.stageRef.current) {
          data.damage = this.stageRef.current.toDataURL({
            width: 950,
            height: 470
          });
        }
        this.props
          .saveVehicle(data)
          .then(vehicleId => {
            this.props
              .sendNotification(
                data.userId,
                [data.userId],
                window.i18n.translate('WeMadeChangesToVehicleOnauction'),
                'We have made some changes to your vehicle on our auction platform.',
                'adminChanged',
                data._id,
                true,
                'Verkoopnou Admin'
              )
              .then(() => {
                this.setState({ loading: false });
                Notify('success', 'Vehicle edited succesfully');
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      }
    );
  };
  componentWillMount = () => {
    this.setState({ ...this.props.editVehicle });
  };
  componentDidMount = () => {
    this.componentDidUpdate();
  };
  // componentWillUnmount = () => {
  //   this.props.setEditVehicle(undefined);
  // };
  editHistory = type => {
    return (
      <p>
        {this.state.editHistory[type]
          ? 'Edit history: ' +
            this.state.editHistory[type].user +
            ' - ' +
            this.state.editHistory[type].timeStamp
          : ''}
      </p>
    );
  };
  update = true;
  componentDidUpdate = (prevProps, prevState) => {
    if (this.update) {
      this.update = false;
      let progress = 0;
      const {
        make,
        model,
        variant,
        year,
        colour,
        province,
        city,
        transmission,
        mileage,
        sellingReason,
        owners,
        licenseExpire,
        fuel,
        whereBought
      } = this.state;
      if (
        make !== undefined &&
        model !== undefined &&
        variant !== undefined &&
        year !== undefined &&
        fuel !== undefined &&
        province !== undefined &&
        city !== undefined &&
        transmission !== undefined &&
        mileage !== '' &&
        whereBought !== undefined
      ) {
        this.setState({ panel1Status: 'Complete', panel1Color: 'green' });
      } else if (
        make !== undefined ||
        model !== undefined ||
        variant !== undefined ||
        year !== undefined ||
        fuel !== undefined ||
        province !== undefined ||
        city !== undefined ||
        transmission !== undefined ||
        mileage !== '' ||
        whereBought !== undefined
      ) {
        this.setState({ panel1Status: 'Incomplete', panel1Color: 'red' });
      } else {
        this.setState({ panel1Status: 'Outstanding', panel1Color: '#BDB2B2' });
      }
      progress = make !== undefined ? progress + 1 : progress;
      progress = model !== undefined ? progress + 1 : progress;
      progress = variant !== undefined ? progress + 1 : progress;
      progress = year !== undefined ? progress + 1 : progress;
      progress = fuel !== undefined ? progress + 1 : progress;
      progress = province !== undefined ? progress + 1 : progress;
      progress = city !== undefined ? progress + 1 : progress;
      progress = transmission !== undefined ? progress + 1 : progress;
      progress = mileage !== '' ? progress + 1 : progress;
      progress = whereBought !== undefined ? progress + 1 : progress;
      const {
        condition,
        spareKeys,
        mechanicalIssues,
        bodyWork,
        windscreenDamage,
        magsDamage
      } = this.state;
      if (
        spareKeys !== '' &&
        mechanicalIssues !== '' &&
        bodyWork !== undefined &&
        windscreenDamage !== undefined &&
        magsDamage !== ''
      ) {
        this.setState({ panel3Status: 'Complete', panel3Color: 'green' });
      } else if (
        spareKeys !== '' ||
        mechanicalIssues !== '' ||
        bodyWork !== undefined ||
        windscreenDamage !== undefined ||
        magsDamage !== ''
      ) {
        this.setState({ panel3Status: 'Incomplete', panel3Color: 'red' });
      } else {
        this.setState({ panel3Status: 'Outstanding', panel3Color: '#BDB2B2' });
      }
      progress = spareKeys !== '' ? progress + 1 : progress;
      progress = mechanicalIssues !== '' ? progress + 1 : progress;
      progress = bodyWork !== undefined ? progress + 1 : progress;
      progress = windscreenDamage !== undefined ? progress + 1 : progress;
      progress = magsDamage !== '' ? progress + 1 : progress;
      const { serviceHistory, financed, servicePlan, warranty } = this.state;
      if (
        serviceHistory !== undefined &&
        financed !== '' &&
        servicePlan !== '' &&
        warranty !== ''
      ) {
        this.setState({ panel4Status: 'Complete', panel4Color: 'green' });
      } else if (
        serviceHistory !== undefined ||
        financed !== '' ||
        servicePlan !== '' ||
        warranty !== ''
      ) {
        this.setState({ panel4Status: 'Incomplete', panel4Color: 'red' });
      } else {
        this.setState({ panel4Status: 'Outstanding', panel4Color: '#BDB2B2' });
      }
      progress = serviceHistory !== undefined ? progress + 1 : progress;
      progress = financed !== '' ? progress + 1 : progress;
      progress = servicePlan !== '' ? progress + 1 : progress;
      progress = warranty !== '' ? progress + 1 : progress;
      const { frontTyres, backTyres } = this.state;
      if (frontTyres !== undefined && backTyres !== undefined) {
        this.setState({ panelTyreStatus: 'Complete', panelTyreColor: 'green' });
      } else if (frontTyres !== undefined || backTyres !== undefined) {
        this.setState({ panelTyreStatus: 'Incomplete', panelTyreColor: 'red' });
      } else {
        this.setState({
          panelTyreStatus: 'Outstanding',
          panelTyreColor: '#BDB2B2'
        });
      }
      progress = frontTyres !== undefined ? progress + 1 : progress;
      progress = backTyres !== undefined ? progress + 1 : progress;
      const { vehicleType } = this.state;
      if (vehicleType !== undefined) {
        this.setState({
          panelDamageStatus: 'Complete',
          panelDamageColor: 'green'
        });
      } else if (vehicleType !== undefined) {
        this.setState({
          panelDamageStatus: 'Incomplete',
          panelDamageColor: 'red'
        });
      } else {
        this.setState({
          panelDamageStatus: 'Outstanding',
          panelDamageColor: '#BDB2B2'
        });
      }
      progress = vehicleType !== undefined ? progress + 1 : progress;
      const {
        mainImage,
        frontImage,
        leftImage,
        rightImage,
        backImage,
        interiorImage,
        engineImage,
        bootImage,
        service1Image,
        service2Image
      } = this.state;
      if (mainImage !== undefined) {
        this.setState({ panel5Status: 'Complete', panel5Color: 'green' });
      } else if (
        mainImage !== undefined ||
        frontImage !== undefined ||
        leftImage !== undefined ||
        rightImage !== undefined ||
        backImage !== undefined ||
        interiorImage !== undefined ||
        engineImage !== undefined ||
        bootImage !== undefined ||
        service1Image !== undefined ||
        service2Image !== undefined
      ) {
        this.setState({ panel5Status: 'Incomplete', panel5Color: 'red' });
      } else {
        this.setState({ panel5Status: 'Outstanding', panel5Color: '#BDB2B2' });
      }
      progress = mainImage !== undefined ? progress + 1 : progress;
      const { price } = this.state;
      if (price !== '') {
        this.setState({ panel6Status: 'Complete', panel6Color: 'green' });
      } else if (price !== '') {
        this.setState({ panel6Status: 'Incomplete', panel6Color: 'red' });
      } else {
        this.setState({ panel6Status: 'Outstanding', panel6Color: '#BDB2B2' });
      }
      progress = price !== '' ? progress + 1 : progress;
      this.setState({ overallProgress: ((progress / 24) * 100).toFixed(0) });
    }
    setTimeout(() => {
      this.update = true;
    }, 50);
  };
  constructor(props) {
    super(props);
    this.stageRef = React.createRef();
    this.state = {
      vehicleType: undefined,
      whereBoughtSelection: undefined,
      colourVisible: false,
      loading: false,
      review: false,
      overallProgress: 0,
      panel1Status: 'Outstanding',
      panel2Status: 'Outstanding',
      panel3Status: 'Outstanding',
      panel4Status: 'Outstanding',
      panelTyreStatus: 'Outstanding',
      panelDamageStatus: 'Outstanding',
      panel5Status: 'Outstanding',
      panel6Status: 'Outstanding',
      panel1Color: '#BDB2B2',
      panel2Color: '#BDB2B2',
      panel3Color: '#BDB2B2',
      panel4Color: '#BDB2B2',
      panelTyreColor: '#BDB2B2',
      panelDamageColor: '#BDB2B2',
      panel5Color: '#BDB2B2',
      panel6Color: '#BDB2B2',
      make: undefined,
      model: undefined,
      variant: undefined,
      year: undefined,
      colour: '',
      fuel: undefined,
      extras: '',
      province: undefined,
      city: undefined,
      transmission: undefined,
      mileage: '',
      whereBought: undefined,
      sellingReason: '',
      owners: '',
      licenseExpire: '',
      comments: '',
      condition: '',
      spareKeys: '',
      mechanicalIssues: '',
      interiorCondition: '',
      bodyWork: undefined,
      windscreenDamage: undefined,
      magsDamage: '',
      serviceHistory: undefined,
      financed: '',
      servicePlan: '',
      warranty: '',
      frontTyres: undefined,
      backTyres: undefined,
      mainImage: undefined,
      frontImage: undefined,
      leftImage: undefined,
      rightImage: undefined,
      backImage: undefined,
      interiorImage: undefined,
      engineImage: undefined,
      bootImage: undefined,
      service1Image: undefined,
      service2Image: undefined,
      price: '',
      damageType: {
        Spray: [],
        Scratch: [],
        Chip: [],
        Dent: [],
        Rust: [],
        Hail: [],
        Cracked: [],
        Broken: []
      }
    };
  }

  onResetForm = e => {
    this.setState({
      whereBoughtSelection: undefined,
      loading: false,
      overallProgress: 0,
      panel1Status: 'Outstanding',
      panel2Status: 'Outstanding',
      panel3Status: 'Outstanding',
      panel4Status: 'Outstanding',
      panelTyreStatus: 'Outstanding',
      panelDamageStatus: 'Outstanding',
      panel5Status: 'Outstanding',
      panel6Status: 'Outstanding',
      panel1Color: '#BDB2B2',
      panel2Color: '#BDB2B2',
      panel3Color: '#BDB2B2',
      panel4Color: '#BDB2B2',
      panelTyreColor: '#BDB2B2',
      panelDamageColor: '#BDB2B2',
      panel5Color: '#BDB2B2',
      panel6Color: '#BDB2B2',
      make: undefined,
      model: undefined,
      variant: undefined,
      year: undefined,
      colour: '',
      fuel: undefined,
      extras: '',
      province: undefined,
      city: undefined,
      transmission: undefined,
      mileage: '',
      whereBought: undefined,
      sellingReason: '',
      owners: '',
      licenseExpire: '',
      comments: '',
      condition: '',
      spareKeys: '',
      mechanicalIssues: '',
      interiorCondition: '',
      bodyWork: undefined,
      windscreenDamage: undefined,
      magsDamage: '',
      serviceHistory: undefined,
      financed: '',
      servicePlan: '',
      warranty: '',
      frontTyres: undefined,
      backTyres: undefined,
      mainImage: undefined,
      frontImage: undefined,
      leftImage: undefined,
      rightImage: undefined,
      backImage: undefined,
      interiorImage: undefined,
      engineImage: undefined,
      bootImage: undefined,
      service1Image: undefined,
      service2Image: undefined,
      price: '',
      damageType: {
        Spray: [],
        Scratch: [],
        Chip: [],
        Dent: [],
        Rust: [],
        Hail: [],
        Cracked: [],
        Broken: []
      }
    });
  };
  handleDragStart = e => {
    e.target.setAttrs({
      shadowOffset: {
        x: 15,
        y: 15
      },
      scaleX: 1.1,
      scaleY: 1.1
    });
  };
  handleDragEnd = e => {
    e.target.to({
      duration: 0.5,
      easing: Konva.Easings.ElasticEaseOut,
      scaleX: 1,
      scaleY: 1,
      shadowOffsetX: 5,
      shadowOffsetY: 5
    });
  };
  addDamageType = type => {
    let damageType = this.state.damageType;
    damageType[type].push({ key: damageType[type].length + 1 });
    this.setState({
      damageType
    });
  };
  removeAllDamage = view => {
    let damageType = this.state.damageType;
    let exist = this.state;
    exist['damage'] = undefined;
    damageType['Spray'] = [];
    damageType['Scratch'] = [];
    damageType['Chip'] = [];
    damageType['Dent'] = [];
    damageType['Rust'] = [];
    damageType['Hail'] = [];
    damageType['Cracked'] = [];
    damageType['Broken'] = [];
    this.setState({
      damageType,
      exist
    });
  };
  onSelectMake = e => {
    this.setState({
      loading: true,
      variant: undefined,
      model: undefined,
      make: e
    });
    this.props
      .findModels(e, this.state.year)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(e => {
        console.log(e);
      });
  };
  onSelectModel = e => {
    this.setState({ loading: true, variant: undefined, model: e });
    this.props
      .findVariants(e, this.state.year)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(e => {
        console.log(e);
      });
  };
  onSelectVariant = e => {
    this.setState({ variant: e });
  };
  onSelectYear = e => {
    this.setState({
      loading: true,
      make: undefined,
      variant: undefined,
      model: undefined,
      year: e
    });
    this.props
      .findMakes(e)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(e => {
        console.log(e);
      });
  };
  onSelectProvince = e => {
    this.setState({ loading: true, province: e });
    this.props
      .findCities(e)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(e => {
        console.log(e);
      });
  };
  handleMainImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        mainImage: imageUrl
      });
    });
  };
  handleFrontImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        frontImage: imageUrl
      });
    });
  };
  handleLeftImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        leftImage: imageUrl
      });
    });
  };
  handleRightImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        rightImage: imageUrl
      });
    });
  };
  handleBackImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        backImage: imageUrl
      });
    });
  };
  handleInteriorImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        interiorImage: imageUrl
      });
    });
  };
  handleEngineImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        engineImage: imageUrl
      });
    });
  };
  handleBootImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        bootImage: imageUrl
      });
    });
  };
  handleService1Image = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        service1Image: imageUrl
      });
    });
  };
  handleService2Image = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        service2Image: imageUrl
      });
    });
  };
  handleImageUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };
  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      Notify('error', window.i18n.translate('ImageSmaller'));
    }
    return false;
  };
  getBase64 = (img, callback) => {
    Resizer.imageFileResizer(img, 800, 800, 'JPG', 100, 0, uri => {
      callback(uri);
    });
  };
  onCancel = e => {
    this.props.push('/user/myvehicles');
  };
  checkIfNumber = e => {
    if (e.currentTarget.value.length > 12) {
      if (Number.isInteger(parseInt(e.currentTarget.value.substr(12)))) {
        this.setState({ owners: e.currentTarget.value.substr(12) });
      } else {
        this.setState({ owners: "I don't know" });
      }
    } else if (e.currentTarget.value === "I don't kno") {
      this.setState({ owners: '' });
    } else if (Number.isInteger(parseInt(e.currentTarget.value))) {
      this.setState({ owners: e.currentTarget.value });
    } else {
      this.setState({ owners: "I don't know" });
    }
  };
  getColour = e => {
    const colours = [
      { hex: '#f0efe8', string: 'White' },
      { hex: '#191919', string: 'Black' },
      { hex: '#d01f05', string: 'Red' },
      { hex: '#006bea', string: 'Blue' },
      { hex: '#1ac736', string: 'Green' },
      { hex: '#565656', string: 'Grey' },
      { hex: '#d0b485', string: 'Champagne' },
      { hex: '#bbbbbb', string: 'Silver' },
      { hex: '#f9dc2b', string: 'Yellow' },
      { hex: '#ea961d', string: 'Orange' },
      { hex: '#75591e', string: 'Brown' },
      { hex: '#63142c', string: 'Burgundy' }
    ];
    colours.forEach(colour => {
      if (colour.hex === e.hex) {
        this.setState({
          colour: colour.string,
          hex: colour.hex,
          colourVisible: false
        });
      }
    });
  };
  handleVisibleChange = colourVisible => {
    this.setState({ colourVisible });
  };

  getImageType = (type, view) => {
    if (
      this.state[view + 'Damage'] &&
      this.state[view + 'Damage'].search(this.state._id) > -1
    ) {
      return (
        'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
        this.state[view + 'Damage']
      );
    } else {
      return damageImageObject[view + type];
    }
  };
  getViewImageTypes = view => {
    if (this.state[view + 'Image'].search(this.state._id) > -1) {
      return (
        'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
        this.state[view + 'Image']
      );
    } else {
      return this.state[view + 'Image'];
    }
  };
  stageGroup = (i, text, colour) => {
    return (
      <Group
        key={i.key}
        draggable
        onDragStart={this.handleDragStart}
        onDragEnd={this.handleDragEnd}
        x={50}
        y={50}
      >
        <Circle
          key={i.key}
          x={8}
          y={8}
          numPoints={5}
          radius={8}
          fill={colour}
          opacity={0.8}
          shadowColor="black"
          shadowBlur={10}
          shadowOpacity={0.6}
        />
        <Text
          text={text}
          fontSize={13}
          x={20}
          y={2}
          fontWeight={700}
          fontFamily="Calibri"
          fill="#19999c"
          shadowColor="black"
          shadowBlur={10}
          shadowOpacity={0.6}
        />
      </Group>
    );
  };
  processReview = () => {
    let data = this.state;
    if (this.stageRef.current) {
      data.damage = this.stageRef.current.toDataURL({
        width: 950,
        height: 470
      });
    }
    this.setState({ data, review: !this.state.review });
  };
  render() {
    return (
      <div>
        <Spin spinning={this.state.loading}>
          <Row type="flex" justify="start" align="middle">
            <Col span={24}>
              <Collapse
                defaultActiveKey={['1']}
                expandIconPosition={'right'}
                accordion
                style={{ background: 'transparent' }}
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <img alt="Up" src={upArrow} />
                  ) : (
                    <img alt="Down" src={downArrow} />
                  )
                }
              >
                <Collapse.Panel
                  style={customPanelStyle}
                  header={
                    <span style={{ color: '#00B4BC', fontWeight: 700 }}>
                      General Information
                    </span>
                  }
                  key="1"
                  extra={
                    <div style={{ display: 'flex' }}>
                      <span style={{ marginRight: 10, color: '#6D6E70' }}>
                        {this.state.panel1Status}
                      </span>
                      <div
                        style={{
                          width: 15,
                          height: 15,
                          background: this.state.panel1Color,
                          borderRadius: '50%',
                          position: 'relative',
                          top: 4,
                          marginRight: 15
                        }}
                      />
                    </div>
                  }
                >
                  <Row type="flex" justify="space-between" align="middle">
                    <Col span={7}>
                      <span>
                        1. Year <span style={{ color: 'red' }}>*</span>
                      </span>
                      <Selection
                        showSearch
                        value={this.state.year}
                        style={{ width: '100%' }}
                        placeholder="Select a year"
                        optionFilterProp="children"
                        onChange={this.onSelectYear}
                      >
                        {this.props.years.map(year => {
                          return (
                            <Option key={year} value={year}>
                              {year}
                            </Option>
                          );
                        })}
                      </Selection>
                      {this.editHistory('year')}
                    </Col>
                    <Col span={7}>
                      <span>
                        2. Make <span style={{ color: 'red' }}>*</span>
                      </span>
                      <Selection
                        disabled={!this.state.year}
                        showSearch
                        value={this.state.make}
                        style={{ width: '100%', height: 45 }}
                        placeholder="Select a make"
                        optionFilterProp="children"
                        onChange={this.onSelectMake}
                      >
                        {this.props.makes.map(make => {
                          return (
                            <Option key={make} value={make}>
                              {make}
                            </Option>
                          );
                        })}
                      </Selection>
                      {this.editHistory('make')}
                    </Col>
                    <Col span={7}>
                      <span>
                        3. Model <span style={{ color: 'red' }}>*</span>
                      </span>
                      <Selection
                        disabled={!this.state.make}
                        showSearch
                        value={this.state.model}
                        style={{ width: '100%' }}
                        placeholder="Select a model"
                        optionFilterProp="children"
                        onChange={this.onSelectModel}
                      >
                        {this.props.models.map(model => {
                          return (
                            <Option key={model} value={model}>
                              {model}
                            </Option>
                          );
                        })}
                      </Selection>
                      {this.editHistory('model')}
                    </Col>
                    <Col span={7}>
                      <span>
                        4. Model Type <span style={{ color: 'red' }}>*</span>
                      </span>
                      <Selection
                        disabled={!this.state.model}
                        showSearch
                        value={this.state.variant}
                        style={{ width: '100%' }}
                        placeholder="Select a model type"
                        optionFilterProp="children"
                        onChange={this.onSelectVariant}
                      >
                        {this.props.variants.map(variant => {
                          return (
                            <Option key={variant} value={variant}>
                              {variant}
                            </Option>
                          );
                        })}
                      </Selection>
                      {this.editHistory('variant')}
                    </Col>
                    <Col span={7}>
                      <span>
                        5. Transmission <span style={{ color: 'red' }}>*</span>
                      </span>
                      <Selection
                        showSearch
                        value={this.state.transmission}
                        style={{ width: '100%' }}
                        placeholder="Select a transmission"
                        optionFilterProp="children"
                        onChange={e => this.setState({ transmission: e })}
                      >
                        <Option value="Automatic">Automatic</Option>
                        <Option value="Manual">Manual</Option>
                      </Selection>
                      {this.editHistory('transmission')}
                    </Col>
                    <Col span={7}>
                      <span>
                        6. Mileage <span style={{ color: 'red' }}>*</span>
                      </span>
                      <Input
                        style={customInputStyle}
                        suffix="KM"
                        placeholder="Mileage"
                        value={this.state.mileage}
                        onChange={e =>
                          this.setState({
                            mileage: numeral(e.currentTarget.value).format(
                              '0,0'
                            )
                          })
                        }
                      />
                      {this.editHistory('mileage')}
                    </Col>
                    <Col span={7}>
                      <Col span={23}>
                        <span>7. Colour</span>
                      </Col>

                      <Col style={{ display: 'flex', height: 45 }} span={5}>
                        <Popover
                          content={
                            <CirclePicker
                              color={this.state.hex}
                              width={'100%'}
                              colors={[
                                '#f0efe8',
                                '#191919',
                                '#d01f05',
                                '#006bea',
                                '#1ac736',
                                '#565656',
                                '#d0b485',
                                '#bbbbbb',
                                '#f9dc2b',
                                '#ea961d',
                                '#75591e',
                                '#63142c'
                              ]}
                              onChange={this.getColour}
                            />
                          }
                          title="Colour"
                          trigger="click"
                          visible={this.state.colourVisible}
                          onVisibleChange={this.handleVisibleChange}
                        >
                          <ButtonSquare
                            style={{
                              margin: 'auto',
                              height: 45,
                              maxWidth: '100%'
                            }}
                            type="primary"
                          >
                            Select
                          </ButtonSquare>
                        </Popover>
                      </Col>
                      <Col span={19}>
                        <Input
                          style={{ ...customInputStyle }}
                          placeholder="Other colour"
                          value={this.state.colour}
                          onChange={e =>
                            this.setState({
                              colour: e.currentTarget.value,
                              hex: ''
                            })
                          }
                        />
                      </Col>
                      {this.editHistory('colour')}
                    </Col>
                    <Col span={7}>
                      <span>
                        8. Fuel Type <span style={{ color: 'red' }}>*</span>
                      </span>
                      <Selection
                        showSearch
                        value={this.state.fuel}
                        style={{ width: '100%' }}
                        placeholder="Select a type"
                        optionFilterProp="children"
                        onChange={e => this.setState({ fuel: e })}
                      >
                        <Option key={'Petrol'} value={'Petrol'}>
                          {'Petrol'}
                        </Option>
                        <Option key={'Diesel'} value={'Diesel'}>
                          {'Diesel'}
                        </Option>
                        <Option key={'Hybrid'} value={'Hybrid'}>
                          {'Hybrid'}
                        </Option>
                        <Option key={'Electric'} value={'Electric'}>
                          {'Electric'}
                        </Option>
                      </Selection>
                      {this.editHistory('fuel')}
                    </Col>
                    <Col span={7}>
                      <span>9. Optional Extras</span>
                      <Input
                        style={customInputStyle}
                        placeholder="eg. sunroof, mags"
                        value={this.state.extras}
                        onChange={e =>
                          this.setState({
                            extras: e.currentTarget.value
                          })
                        }
                      />
                      {this.editHistory('extras')}
                    </Col>
                    <Col span={7}>
                      <span>
                        10. Province <span style={{ color: 'red' }}>*</span>
                      </span>
                      <Selection
                        showSearch
                        value={this.state.province}
                        style={{ width: '100%' }}
                        placeholder="Select a province"
                        optionFilterProp="children"
                        onChange={this.onSelectProvince}
                      >
                        <Option key={'GP'} value={'Gauteng'}>
                          {'Gauteng'}
                        </Option>
                        <Option key={'MP'} value={'Mpumalanga'}>
                          {'Mpumalanga'}
                        </Option>
                        <Option key={'LP'} value={'Limpopo'}>
                          {'Limpopo'}
                        </Option>
                        <Option key={'KZN'} value={'KwaZulu Natal'}>
                          {'KwaZulu Natal'}
                        </Option>
                        <Option key={'FS'} value={'Free State'}>
                          {'Free State'}
                        </Option>
                        <Option key={'NW'} value={'North West'}>
                          {'North West'}
                        </Option>
                        <Option key={'NC'} value={'Northern Cape'}>
                          {'Northern Cape'}
                        </Option>
                        <Option key={'EC'} value={'Eastern Cape'}>
                          {'Eastern Cape'}
                        </Option>
                        <Option key={'WC'} value={'Western Cape'}>
                          {'Western Cape'}
                        </Option>
                      </Selection>
                      {this.editHistory('province')}
                    </Col>
                    <Col span={7}>
                      <span>
                        11. City/Town <span style={{ color: 'red' }}>*</span>
                      </span>
                      <Selection
                        disabled={!this.state.province}
                        showSearch
                        value={this.state.city}
                        style={{ width: '100%' }}
                        placeholder="Select a city"
                        optionFilterProp="children"
                        onChange={e => this.setState({ city: e })}
                      >
                        {this.props.cities.map(city => {
                          return (
                            <Option key={city} value={city}>
                              {city}
                            </Option>
                          );
                        })}
                      </Selection>
                      {this.editHistory('city')}
                    </Col>
                    <Col span={7}>
                      <Col span={23}>
                        <span>
                          12. Where did you buy your vehicle?{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </span>
                      </Col>
                      <Col span={10}>
                        <Selection
                          showSearch
                          // value={this.state.whereBought}
                          style={{ width: '100%' }}
                          placeholder="Select an option"
                          optionFilterProp="children"
                          onChange={e => {
                            this.setState({
                              whereBoughtSelection: e,
                              whereBought: ''
                            });
                          }}
                        >
                          <Option key={'Dealer'} value={'Dealer'}>
                            Dealer
                          </Option>
                          <Option key={'Private'} value={'Private'}>
                            Private
                          </Option>
                          <Option key={'Auction'} value={'Auction'}>
                            Auction
                          </Option>
                          <Option key={'Other'} value={'Other'}>
                            Other
                          </Option>
                        </Selection>
                      </Col>
                      <Col span={14}>
                        <Input
                          style={{
                            ...customInputStyle,
                            display: 'block',
                            position: 'absolute'
                          }}
                          placeholder="Provide details"
                          value={this.state.whereBought}
                          onChange={e =>
                            this.setState({
                              whereBought: e.currentTarget.value
                            })
                          }
                        />
                      </Col>
                      {this.editHistory('whereBought')}
                    </Col>
                    <Col span={7}>
                      <span>13. Reason for selling</span>
                      <Input
                        style={customInputStyle}
                        placeholder="Reason for selling"
                        value={this.state.sellingReason}
                        onChange={e =>
                          this.setState({
                            sellingReason: e.currentTarget.value
                          })
                        }
                      />
                      {this.editHistory('sellingReason')}
                    </Col>
                    <Col span={7}>
                      <span>
                        14. Including you, how many owners did the vehicle have?
                      </span>
                      <Input
                        style={customInputStyle}
                        placeholder="Number of owners / I don’t know"
                        value={this.state.owners}
                        onChange={this.checkIfNumber}
                      />
                      {this.editHistory('owners')}
                    </Col>
                    <Col span={7}>
                      <Col span={23}>
                        <span>15. License disc expiry date</span>
                      </Col>
                      <Col span={12}>
                        <Selection
                          showSearch
                          style={{ width: '100%' }}
                          placeholder="Select an option"
                          optionFilterProp="children"
                          onChange={e => {
                            this.setState({
                              licenseExpire: e
                            });
                          }}
                        >
                          <Option
                            key={'Not sure but up to date'}
                            value={'Not sure but up to date'}
                          >
                            Not sure but up to date
                          </Option>
                          <Option
                            key={'Not sure but expired'}
                            value={'Not sure but expired'}
                          >
                            Not sure but expired
                          </Option>
                          <Option
                            key={'As per selected date'}
                            value={'As per selected date'}
                          >
                            As per selected date
                          </Option>
                        </Selection>
                      </Col>
                      <Col span={12}>
                        <DatePick
                          style={{ width: '100%' }}
                          onChange={e =>
                            this.setState({
                              licenseExpireDate: e.format('YYYYMMDD')
                            })
                          }
                        />
                      </Col>
                      {this.editHistory('licenseExpire')}
                    </Col>
                    <Col span={7}>
                      <span>16. Additional comment</span>
                      <Input
                        style={customInputStyle}
                        placeholder="Additional comment"
                        value={this.state.comments}
                        onChange={e =>
                          this.setState({
                            comments: e.currentTarget.value
                          })
                        }
                      />
                      {this.editHistory('comments')}
                    </Col>
                  </Row>
                </Collapse.Panel>

                <Collapse.Panel
                  style={customPanelStyle}
                  header={
                    <span style={{ color: '#00B4BC', fontWeight: 700 }}>
                      Damage Assessment
                    </span>
                  }
                  key="3"
                  extra={
                    <div style={{ display: 'flex' }}>
                      <span style={{ marginRight: 10, color: '#6D6E70' }}>
                        {this.state.panel3Status}
                      </span>
                      <div
                        style={{
                          width: 15,
                          height: 15,
                          background: this.state.panel3Color,
                          borderRadius: '50%',
                          position: 'relative',
                          top: 4,
                          marginRight: 15
                        }}
                      />
                    </div>
                  }
                >
                  <Row type="flex" justify="space-between" align="middle">
                    <Col span={11}>
                      <span>Vehicle condition</span>
                      <Selection
                        showSearch
                        value={this.state.condition}
                        style={{ width: '100%' }}
                        placeholder="Select a rating"
                        optionFilterProp="children"
                        dropdownMenuStyle={{ maxHeight: '100%' }}
                        onChange={e => this.setState({ condition: e })}
                      >
                        <Option value="1">1 - Bad</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                        <Option value="4">4</Option>
                        <Option value="5">5 - Good</Option>
                        <Option value="6">6</Option>
                        <Option value="7">7</Option>
                        <Option value="8">8</Option>
                        <Option value="9">9</Option>
                        <Option value="10">10 - Excellent</Option>
                      </Selection>
                      {this.editHistory('condition')}
                    </Col>
                    <Col span={11}>
                      <Col span={23}>
                        <span>
                          Do you have spare keys for your vehicle?{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </span>
                      </Col>

                      <Col style={{ display: 'flex', height: 45 }} span={6}>
                        <Radio.Group
                          style={{ margin: 'auto' }}
                          onChange={e =>
                            this.setState({ spareKeys: e.target.value })
                          }
                          defaultValue={
                            this.state.spareKeys === 'No' ? 'No' : 'Yes'
                          }
                        >
                          <RadioButtonYes value="Yes">Yes</RadioButtonYes>
                          <RadioButtonNo value="No">No</RadioButtonNo>
                        </Radio.Group>
                      </Col>
                      <Col span={18}>
                        <Input
                          disabled={this.state.spareKeys.search('No') > -1}
                          style={customInputStyle}
                          type="number"
                          placeholder="Number of spare keys"
                          value={this.state.spareKeys}
                          onChange={e => {
                            if (e.currentTarget.value.search('-') === -1) {
                              this.setState({
                                spareKeys: e.currentTarget.value
                              });
                            }
                          }}
                        />
                      </Col>
                      {this.editHistory('spareKeys')}
                    </Col>
                    <Col span={11}>
                      <Col span={23}>
                        <span>
                          Does your vehicle have any mechanical issues
                          currently? <span style={{ color: 'red' }}>*</span>
                        </span>
                      </Col>
                      <Col style={{ display: 'flex', height: 45 }} span={6}>
                        <Radio.Group
                          style={{ margin: 'auto' }}
                          onChange={e => {
                            e.target.value === 'Yes'
                              ? this.setState({ mechanicalIssues: undefined })
                              : this.setState({
                                  mechanicalIssues: e.target.value
                                });
                          }}
                          defaultValue={
                            this.state.mechanicalIssues === 'No' ? 'No' : 'Yes'
                          }
                        >
                          <RadioButtonYes value="Yes">Yes</RadioButtonYes>
                          <RadioButtonNo value="No">No</RadioButtonNo>
                        </Radio.Group>
                      </Col>
                      <Col span={18}>
                        <Input
                          disabled={
                            this.state.mechanicalIssues
                              ? this.state.mechanicalIssues.search('No') > -1
                              : false
                          }
                          style={customInputStyle}
                          placeholder="If Yes, please provide details"
                          value={this.state.mechanicalIssues}
                          onChange={e =>
                            this.setState({
                              mechanicalIssues: e.currentTarget.value
                            })
                          }
                        />
                      </Col>
                      {this.editHistory('mechanicalIssues')}
                    </Col>
                    <Col span={11}>
                      <span>Interior condition</span>
                      <Selection
                        showSearch
                        value={this.state.interiorCondition}
                        style={{ width: '100%' }}
                        placeholder="Select a rating"
                        optionFilterProp="children"
                        onChange={e => this.setState({ interiorCondition: e })}
                      >
                        <Option value="Excellent">Excellent</Option>
                        <Option value="Good">Good</Option>
                        <Option value="Average">Average</Option>
                        <Option value="Poor">Poor</Option>
                      </Selection>
                      {this.editHistory('interiorCondition')}
                    </Col>
                    <Col span={11}>
                      <Col span={23}>
                        <span>
                          Has your vehicle ever been in an accident, repaired,
                          or had any spray work to fix scratches or any hail
                          damage? <span style={{ color: 'red' }}>*</span>
                        </span>
                      </Col>
                      <Col style={{ display: 'flex', height: 45 }} span={6}>
                        <Radio.Group
                          style={{ margin: 'auto' }}
                          onChange={e => {
                            e.target.value === 'Yes'
                              ? this.setState({ bodyWork: undefined })
                              : this.setState({ bodyWork: e.target.value });
                          }}
                          defaultValue={
                            this.state.bodyWork === 'No' ? 'No' : 'Yes'
                          }
                        >
                          <RadioButtonYes value="Yes">Yes</RadioButtonYes>
                          <RadioButtonNo value="No">No</RadioButtonNo>
                        </Radio.Group>
                      </Col>
                      <Col span={18}>
                        <Selection
                          showSearch
                          disabled={
                            this.state.bodyWork
                              ? this.state.bodyWork.search('No') > -1
                              : false
                          }
                          value={this.state.bodyWork}
                          style={{ width: '100%' }}
                          placeholder="Select a type"
                          optionFilterProp="children"
                          onChange={e => this.setState({ bodyWork: e })}
                        >
                          <Option value="Accident">Accident</Option>
                          <Option value="Hail damage">Hail damage</Option>
                          <Option value="Cosmetic Spraywork to Fix scratches">
                            Cosmetic Spraywork to Fix scratches{' '}
                          </Option>
                        </Selection>
                      </Col>
                      {this.editHistory('bodyWork')}
                    </Col>
                    <Col span={11}>
                      <Col span={23}>
                        <span>
                          Does the windscreen have any cracks?{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </span>
                      </Col>
                      <Col style={{ display: 'flex', height: 45 }} span={6}>
                        <Radio.Group
                          style={{ margin: 'auto' }}
                          onChange={e => {
                            e.target.value === 'Yes'
                              ? this.setState({ windscreenDamage: undefined })
                              : this.setState({
                                  windscreenDamage: e.target.value
                                });
                          }}
                          defaultValue={
                            this.state.windscreenDamage === 'No' ? 'No' : 'Yes'
                          }
                        >
                          <RadioButtonYes value="Yes">Yes</RadioButtonYes>
                          <RadioButtonNo value="No">No</RadioButtonNo>
                        </Radio.Group>
                      </Col>
                      <Col span={18}>
                        <Selection
                          showSearch
                          disabled={
                            this.state.windscreenDamage
                              ? this.state.windscreenDamage.search('No') > -1
                              : false
                          }
                          value={this.state.windscreenDamage}
                          style={{ width: '100%' }}
                          placeholder="Select a type"
                          optionFilterProp="children"
                          onChange={e => this.setState({ windscreenDamage: e })}
                        >
                          <Option value="Can be fixed">Can be fixed</Option>
                          <Option value="Needs to be replaced">
                            Needs to be replaced
                          </Option>
                        </Selection>
                      </Col>
                      {this.editHistory('windscreenDamage')}
                    </Col>
                    <Col span={11}>
                      <Col span={23}>
                        <span>
                          Are the mags or rims scratched, dented or buckled?{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </span>
                      </Col>
                      <Col style={{ display: 'flex', height: 45 }} span={6}>
                        <Radio.Group
                          style={{ margin: 'auto' }}
                          onChange={e => {
                            e.target.value === 'Yes'
                              ? this.setState({ magsDamage: undefined })
                              : this.setState({ magsDamage: e.target.value });
                          }}
                          defaultValue={
                            this.state.magsDamage === 'No' ? 'No' : 'Yes'
                          }
                        >
                          <RadioButtonYes value="Yes">Yes</RadioButtonYes>
                          <RadioButtonNo value="No">No</RadioButtonNo>
                        </Radio.Group>
                      </Col>
                      <Col span={18}>
                        <Input
                          disabled={
                            this.state.magsDamage
                              ? this.state.magsDamage.search('No') > -1
                              : false
                          }
                          style={customInputStyle}
                          placeholder="If Yes, please provide details"
                          value={this.state.magsDamage}
                          onChange={e =>
                            this.setState({ magsDamage: e.currentTarget.value })
                          }
                        />
                      </Col>
                      {this.editHistory('magsDamage')}
                    </Col>
                  </Row>
                </Collapse.Panel>
                <Collapse.Panel
                  style={customPanelStyle}
                  header={
                    <span style={{ color: '#00B4BC', fontWeight: 700 }}>
                      Service History and Finance
                    </span>
                  }
                  key="4"
                  extra={
                    <div style={{ display: 'flex' }}>
                      <span style={{ marginRight: 10, color: '#6D6E70' }}>
                        {this.state.panel4Status}
                      </span>
                      <div
                        style={{
                          width: 15,
                          height: 15,
                          background: this.state.panel4Color,
                          borderRadius: '50%',
                          position: 'relative',
                          top: 4,
                          marginRight: 15
                        }}
                      />
                    </div>
                  }
                >
                  <Row type="flex" justify="space-between" align="middle">
                    <Col span={11}>
                      <span>
                        Service History <span style={{ color: 'red' }}>*</span>
                      </span>
                      <Selection
                        showSearch
                        value={this.state.serviceHistory}
                        style={{ width: '100%' }}
                        placeholder="Select a type"
                        optionFilterProp="children"
                        onChange={e => this.setState({ serviceHistory: e })}
                      >
                        <Option value="Full Service History with agents">
                          Full Service History with{' '}
                          {this.state.make ? this.state.make : 'Agents'}
                        </Option>
                        <Option value="Full Service History ( Agents and Private workshops)">
                          Full Service History with{' '}
                          {this.state.make ? this.state.make : 'Agents'} and
                          Private Workshops
                        </Option>
                        <Option value="Partial Service History">
                          Partial Service History
                        </Option>
                        <Option value="No Service History">
                          No Service History
                        </Option>
                      </Selection>
                      {this.editHistory('serviceHistory')}
                    </Col>
                    <Col span={11}>
                      <Col span={23}>
                        <span>
                          Is your vehicle financed?{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </span>
                      </Col>
                      <Col style={{ display: 'flex', height: 45 }} span={6}>
                        <Radio.Group
                          style={{ margin: 'auto' }}
                          onChange={e => {
                            e.target.value === 'Yes'
                              ? this.setState({ financed: undefined })
                              : this.setState({ financed: e.target.value });
                          }}
                          defaultValue={
                            this.state.financed === 'No' ? 'No' : 'Yes'
                          }
                        >
                          <RadioButtonYes value="Yes">Yes</RadioButtonYes>
                          <RadioButtonNo value="No">No</RadioButtonNo>
                        </Radio.Group>
                      </Col>
                      <Col span={18}>
                        <Input
                          disabled={
                            this.state.financed
                              ? this.state.financed.search('No') > -1
                              : false
                          }
                          style={customInputStyle}
                          placeholder="Outstanding amount"
                          value={this.state.financed}
                          onChange={e =>
                            this.setState({
                              financed:
                                'R' +
                                numeral(e.currentTarget.value).format('0,0')
                            })
                          }
                        />
                      </Col>
                      {this.editHistory('financed')}
                    </Col>
                    <Col span={11}>
                      <Col span={23}>
                        <span>
                          Does your vehicle have a service/maintenance plan?{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </span>
                      </Col>
                      <Col style={{ display: 'flex', height: 45 }} span={6}>
                        <Radio.Group
                          style={{ margin: 'auto' }}
                          onChange={e => {
                            e.target.value === 'Yes'
                              ? this.setState({ servicePlan: undefined })
                              : this.setState({ servicePlan: e.target.value });
                          }}
                          defaultValue={
                            this.state.servicePlan === 'No' ? 'No' : 'Yes'
                          }
                        >
                          <RadioButtonYes value="Yes">Yes</RadioButtonYes>
                          <RadioButtonNo value="No">No</RadioButtonNo>
                        </Radio.Group>
                      </Col>
                      <Col span={18}>
                        <Input
                          disabled={
                            this.state.servicePlan
                              ? this.state.servicePlan.search('No') > -1
                              : false
                          }
                          style={customInputStyle}
                          placeholder="Provide details"
                          value={this.state.servicePlan}
                          onChange={e =>
                            this.setState({
                              servicePlan: e.currentTarget.value
                            })
                          }
                        />
                      </Col>
                      {this.editHistory('servicePlan')}
                    </Col>
                    <Col span={11}>
                      <Col span={23}>
                        <span>
                          Is your vehicle under warranty?{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </span>
                      </Col>
                      <Col style={{ display: 'flex', height: 45 }} span={6}>
                        <Radio.Group
                          style={{ margin: 'auto' }}
                          onChange={e => {
                            e.target.value === 'Yes'
                              ? this.setState({ warranty: undefined })
                              : this.setState({ warranty: e.target.value });
                          }}
                          defaultValue={
                            this.state.warranty === 'No' ? 'No' : 'Yes'
                          }
                        >
                          <RadioButtonYes value="Yes">Yes</RadioButtonYes>
                          <RadioButtonNo value="No">No</RadioButtonNo>
                        </Radio.Group>
                      </Col>
                      <Col span={18}>
                        <Input
                          disabled={
                            this.state.warranty
                              ? this.state.warranty.search('No') > -1
                              : false
                          }
                          style={customInputStyle}
                          placeholder="Provide details"
                          value={this.state.warranty}
                          onChange={e =>
                            this.setState({ warranty: e.currentTarget.value })
                          }
                        />
                      </Col>
                      {this.editHistory('warranty')}
                    </Col>
                  </Row>
                </Collapse.Panel>
                <Collapse.Panel
                  style={customPanelStyle}
                  header={
                    <span style={{ color: '#00B4BC', fontWeight: 700 }}>
                      Tyre Condition
                    </span>
                  }
                  key="tyres"
                  extra={
                    <div style={{ display: 'flex' }}>
                      <span style={{ marginRight: 10, color: '#6D6E70' }}>
                        {this.state.panelTyreStatus}
                      </span>
                      <div
                        style={{
                          width: 15,
                          height: 15,
                          background: this.state.panelTyreColor,
                          borderRadius: '50%',
                          position: 'relative',
                          top: 4,
                          marginRight: 15
                        }}
                      />
                    </div>
                  }
                >
                  <Row
                    type="flex"
                    justify="space-between"
                    style={{ marginBottom: 30 }}
                  >
                    <Col style={{ marginBottom: 10 }} span={24}>
                      <span style={{ fontWeight: 700 }}>Front Tyres</span>
                      {this.editHistory('frontTyres')}
                    </Col>
                    <Col span={7}>
                      <Card
                        hoverable
                        onClick={e => this.setState({ frontTyres: 'New' })}
                        style={{
                          width: 240,
                          border:
                            this.state.frontTyres === 'New'
                              ? '2px solid green'
                              : '2px solid #00888e',
                          boxShadow:
                            this.state.frontTyres === 'New'
                              ? '0px 0px 10px 4px green'
                              : 'none',
                          borderRadius: 10
                        }}
                        cover={
                          <img
                            style={{
                              width: 236,
                              borderRadius: 10
                            }}
                            src={newTyre}
                          />
                        }
                      >
                        <Meta title="New" description="Tyres are new" />
                      </Card>
                    </Col>
                    <Col span={7}>
                      <Card
                        hoverable
                        onClick={e =>
                          this.setState({ frontTyres: 'Half Worn' })
                        }
                        style={{
                          width: 240,
                          border:
                            this.state.frontTyres === 'Half Worn'
                              ? '2px solid green'
                              : '2px solid #00888e',
                          boxShadow:
                            this.state.frontTyres === 'Half Worn'
                              ? '0px 0px 10px 4px green'
                              : 'none',
                          borderRadius: 10
                        }}
                        cover={
                          <img
                            style={{
                              width: 236,
                              borderRadius: 10
                            }}
                            src={halfWornTyre}
                          />
                        }
                      >
                        <Meta
                          title="Half Worn"
                          description="Tyres are half worn"
                        />
                      </Card>
                    </Col>
                    <Col span={7}>
                      <Card
                        hoverable
                        onClick={e => this.setState({ frontTyres: 'Worn' })}
                        style={{
                          width: 240,
                          border:
                            this.state.frontTyres === 'Worn'
                              ? '2px solid green'
                              : '2px solid #00888e',
                          boxShadow:
                            this.state.frontTyres === 'Worn'
                              ? '0px 0px 10px 4px green'
                              : 'none',
                          borderRadius: 10
                        }}
                        cover={
                          <img
                            style={{
                              width: 236,
                              borderRadius: 10
                            }}
                            src={wornTyre}
                          />
                        }
                      >
                        <Meta title="Worn" description="Tyres are worn" />
                      </Card>
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-between">
                    <Col style={{ marginBottom: 10 }} span={24}>
                      <span style={{ fontWeight: 700 }}>Back Tyres</span>
                      {this.editHistory('backTyres')}
                    </Col>
                    <Col span={7}>
                      <Card
                        hoverable
                        onClick={e => this.setState({ backTyres: 'New' })}
                        style={{
                          width: 240,
                          border:
                            this.state.backTyres === 'New'
                              ? '2px solid green'
                              : '2px solid #00888e',
                          boxShadow:
                            this.state.backTyres === 'New'
                              ? '0px 0px 10px 4px green'
                              : 'none',
                          borderRadius: 10
                        }}
                        cover={
                          <img
                            style={{
                              width: 236,
                              borderRadius: 10
                            }}
                            src={newTyre}
                          />
                        }
                      >
                        <Meta title="New" description="Tyres are new" />
                      </Card>
                    </Col>
                    <Col span={7}>
                      <Card
                        hoverable
                        onClick={e => this.setState({ backTyres: 'Half Worn' })}
                        style={{
                          width: 240,
                          border:
                            this.state.backTyres === 'Half Worn'
                              ? '2px solid green'
                              : '2px solid #00888e',
                          boxShadow:
                            this.state.backTyres === 'Half Worn'
                              ? '0px 0px 10px 4px green'
                              : 'none',
                          borderRadius: 10
                        }}
                        cover={
                          <img
                            style={{
                              width: 236,
                              borderRadius: 10
                            }}
                            src={halfWornTyre}
                          />
                        }
                      >
                        <Meta
                          title="Half Worn"
                          description="Tyres are half worn"
                        />
                      </Card>
                    </Col>
                    <Col span={7}>
                      <Card
                        hoverable
                        onClick={e => this.setState({ backTyres: 'Worn' })}
                        style={{
                          width: 240,
                          border:
                            this.state.backTyres === 'Worn'
                              ? '2px solid green'
                              : '2px solid #00888e',
                          boxShadow:
                            this.state.backTyres === 'Worn'
                              ? '0px 0px 10px 4px green'
                              : 'none',
                          borderRadius: 10
                        }}
                        cover={
                          <img
                            style={{
                              width: 236,
                              borderRadius: 10
                            }}
                            src={wornTyre}
                          />
                        }
                      >
                        <Meta title="Worn" description="Tyres are worn" />
                      </Card>
                    </Col>
                  </Row>
                </Collapse.Panel>
                <Collapse.Panel
                  style={customPanelStyle}
                  header={
                    <span style={{ color: '#00B4BC', fontWeight: 700 }}>
                      Vehicle Damage
                    </span>
                  }
                  key="damage"
                  extra={
                    <div style={{ display: 'flex' }}>
                      <span style={{ marginRight: 10, color: '#6D6E70' }}>
                        {this.state.panelDamageStatus}
                      </span>
                      <div
                        style={{
                          width: 15,
                          height: 15,
                          background: this.state.panelDamageColor,
                          borderRadius: '50%',
                          position: 'relative',
                          top: 4,
                          marginRight: 15
                        }}
                      />
                    </div>
                  }
                >
                  <Row
                    type="flex"
                    justify="space-between"
                    style={{ marginBottom: 30 }}
                  >
                    <Col span={12} style={{ display: 'flex' }}>
                      {this.editHistory('vehicleType')}
                      <span>
                        Does the vehicle have any damage and / or previous body
                        work done?
                      </span>

                      <Radio.Group
                        style={{ margin: 'auto' }}
                        onChange={e => {
                          e.target.value === 'Yes'
                            ? this.setState({
                                vehicleType: undefined,
                                selectedDamage: true
                              })
                            : this.setState({
                                vehicleType: 'No Damage',
                                selectedDamage: false
                              });
                        }}
                        defaultValue={
                          this.state.vehicleType === 'No Damage' ? 'No' : 'Yes'
                        }
                      >
                        <RadioButtonYes value="Yes">Yes</RadioButtonYes>
                        <RadioButtonNo value="No">No</RadioButtonNo>
                      </Radio.Group>
                    </Col>
                    {this.state.selectedDamage ? (
                      <Col span={24}>
                        <Col span={14}>
                          <span>Select the closest match to your vehicle.</span>
                        </Col>
                        <Col span={11}>
                          <Selection
                            showSearch
                            value={this.state.vehicleType}
                            style={{ width: '100%' }}
                            placeholder="Select a type"
                            optionFilterProp="children"
                            onChange={e => this.setState({ vehicleType: e })}
                          >
                            <Option value="Sedan">Sedan</Option>
                            <Option value="Bakkie">Bakkie</Option>
                            <Option value="Hatchback">Hatchback</Option>
                            <Option value="SUV">SUV</Option>
                            <Option value="Van">Van/Kombi</Option>
                          </Selection>
                        </Col>
                      </Col>
                    ) : null}
                    {this.state.vehicleType &&
                    this.state.vehicleType !== 'No Damage' ? (
                      <Col
                        style={{
                          textAlign: 'center',
                          marginTop: 20,
                          fontSize: 15,
                          fontWeight: 700
                        }}
                        span={24}
                      >
                        <span style={{ color: 'red', fontSize: 18 }}>
                          Select the type of damage to add and drag the dot to
                          the relevant location.
                        </span>
                      </Col>
                    ) : null}
                  </Row>
                  {this.state.vehicleType &&
                  this.state.vehicleType !== 'No Damage' ? (
                    <div>
                      <Row
                        type="flex"
                        justify="space-between"
                        align="middle"
                        style={{
                          marginBottom: 10,
                          borderBottom: '1px solid #00000024'
                        }}
                      >
                        <Col span={22}>
                          <Row
                            type="flex"
                            justify="space-between"
                            align="middle"
                            style={{
                              marginBottom: 10
                            }}
                          >
                            {/* <span style={{ margin: 'auto', fontWeight: 700 }}>
                                Left View
                              </span> */}
                          </Row>
                          <Row
                            type="flex"
                            justify="space-around"
                            align="middle"
                            style={{
                              marginBottom: 10
                            }}
                          >
                            <Col span={16} style={{ display: 'flex' }}>
                              <Button
                                style={{ margin: 'auto' }}
                                onClick={e => this.addDamageType('Spray')}
                              >
                                Spraywork Done
                              </Button>
                              <Button
                                style={{ margin: 'auto' }}
                                onClick={e => this.addDamageType('Scratch')}
                              >
                                Scratch
                              </Button>
                              <Button
                                style={{ margin: 'auto' }}
                                onClick={e => this.addDamageType('Chip')}
                              >
                                Chip(s)
                              </Button>
                              <Button
                                style={{ margin: 'auto' }}
                                onClick={e => this.addDamageType('Dent')}
                              >
                                Dent(s)
                              </Button>
                              <Button
                                style={{ margin: 'auto' }}
                                onClick={e => this.addDamageType('Rust')}
                              >
                                Rust
                              </Button>
                              <Button
                                style={{ margin: 'auto' }}
                                onClick={e => this.addDamageType('Hail')}
                              >
                                Hail
                              </Button>
                              <Button
                                style={{ margin: 'auto' }}
                                onClick={e => this.addDamageType('Cracked')}
                              >
                                Cracked
                              </Button>
                              <Button
                                style={{ margin: 'auto' }}
                                onClick={e => this.addDamageType('Broken')}
                              >
                                Broken
                              </Button>
                            </Col>
                          </Row>
                          <Row
                            type="flex"
                            justify="space-between"
                            align="middle"
                            style={{
                              marginBottom: 10
                            }}
                          >
                            <ButtonSquare
                              type="primary"
                              style={{ margin: 'auto', zIndex: 99 }}
                              onClick={e => this.removeAllDamage()}
                            >
                              Remove All
                            </ButtonSquare>
                          </Row>
                          <Row
                            type="flex"
                            justify="space-between"
                            align="middle"
                            style={{
                              marginBottom: 10
                            }}
                          >
                            {this.editHistory('damage')}
                            <Stage
                              style={{ margin: 'auto' }}
                              ref={this.stageRef}
                              width={950}
                              height={470}
                            >
                              {this.state.damage ? (
                                <Layer>
                                  <URLImage
                                    width={950}
                                    height={470}
                                    src={
                                      'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                      this.state.damage
                                    }
                                  />
                                  {this.state.damageType.Spray.map(i =>
                                    this.stageGroup(i, 'Spraywork Done', 'red')
                                  )}
                                  {this.state.damageType.Scratch.map(i =>
                                    this.stageGroup(i, 'Scratch', 'yellow')
                                  )}
                                  {this.state.damageType.Chip.map(i =>
                                    this.stageGroup(i, 'Chip(s)', 'orange')
                                  )}
                                  {this.state.damageType.Dent.map(i =>
                                    this.stageGroup(i, 'Dent(s)', 'blue')
                                  )}
                                  {this.state.damageType.Rust.map(i =>
                                    this.stageGroup(i, 'Rust', 'purple')
                                  )}
                                  {this.state.damageType.Hail.map(i =>
                                    this.stageGroup(i, 'Hail', 'grey')
                                  )}
                                  {this.state.damageType.Cracked.map(i =>
                                    this.stageGroup(i, 'Cracked', 'brown')
                                  )}
                                  {this.state.damageType.Broken.map(i =>
                                    this.stageGroup(i, 'Broken', 'green')
                                  )}
                                </Layer>
                              ) : (
                                <Layer>
                                  <URLImage
                                    width={440}
                                    height={140}
                                    x={30}
                                    src={this.getImageType(
                                      this.state.vehicleType,
                                      'left'
                                    )}
                                  />
                                  <URLImage
                                    width={440}
                                    height={140}
                                    x={480}
                                    src={this.getImageType(
                                      this.state.vehicleType,
                                      'right'
                                    )}
                                  />
                                  <URLImage
                                    width={250}
                                    height={150}
                                    x={140}
                                    y={140}
                                    src={this.getImageType(
                                      this.state.vehicleType,
                                      'front'
                                    )}
                                  />
                                  <URLImage
                                    width={250}
                                    height={150}
                                    x={580}
                                    y={140}
                                    src={this.getImageType(
                                      this.state.vehicleType,
                                      'back'
                                    )}
                                  />
                                  <URLImage
                                    width={440}
                                    height={180}
                                    y={300}
                                    x={280}
                                    src={this.getImageType(
                                      this.state.vehicleType,
                                      'top'
                                    )}
                                  />
                                </Layer>
                              )}
                            </Stage>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  ) : null}
                </Collapse.Panel>
                <Collapse.Panel
                  style={customPanelStyle}
                  header={
                    <span style={{ color: '#00B4BC', fontWeight: 700 }}>
                      Vehicle Images
                    </span>
                  }
                  key="5"
                  extra={
                    <div style={{ display: 'flex' }}>
                      <span style={{ marginRight: 10, color: '#6D6E70' }}>
                        {this.state.panel5Status}
                      </span>
                      <div
                        style={{
                          width: 15,
                          height: 15,
                          background: this.state.panel5Color,
                          borderRadius: '50%',
                          position: 'relative',
                          top: 4,
                          marginRight: 15
                        }}
                      />
                    </div>
                  }
                >
                  <Row type="flex" justify="space-between" align="middle">
                    <Col
                      style={{
                        textAlign: 'center',
                        marginTop: 5,
                        fontSize: 15,
                        fontWeight: 700,
                        marginBottom: 20
                      }}
                      span={24}
                    >
                      <span>
                        Main view image is required. The more images you add the
                        better price you will get.
                      </span>
                      {this.editHistory('mainImage')}
                    </Col>
                    <Col span={7}>
                      <Cards
                        style={{
                          width: '100%',
                          border: '1px solid #00888e',
                          marginBottom: 10
                        }}
                        extra={
                          this.state.mainImage ? (
                            <div
                              onClick={e =>
                                this.setState({ mainImage: undefined })
                              }
                            >
                              <Icon
                                style={{ color: 'red' }}
                                type="close-circle"
                                theme="filled"
                              />
                              <span style={{ color: 'red' }}>Remove</span>
                            </div>
                          ) : (
                            ''
                          )
                        }
                        title={
                          <span style={{ color: '#00888E' }}>
                            Main View <span style={{ color: 'red' }}>*</span>
                          </span>
                        }
                        cover={
                          this.state.mainImage ? (
                            <img
                              alt="example"
                              src={
                                this.state.mainImage.search('data') > -1
                                  ? this.state.mainImage
                                  : 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                    this.state.mainImage
                              }
                            />
                          ) : (
                            <img alt="example" src={dropImage} />
                          )
                        }
                        actions={[
                          <Upload
                            name="mainImage"
                            showUploadList={false}
                            beforeUpload={this.beforeUpload}
                            customRequest={this.handleImageUpload}
                            onChange={this.handleMainImage}
                            loading={this.state.loading}
                            accept="image/*"
                          >
                            <ButtonSquare>Upload Image</ButtonSquare>
                          </Upload>
                        ]}
                      />
                    </Col>
                    <Col span={7}>
                      <Cards
                        style={{
                          width: '100%',
                          border: '1px solid #00888e',
                          marginBottom: 15
                        }}
                        extra={
                          this.state.frontImage ? (
                            <div
                              onClick={e =>
                                this.setState({ frontImage: undefined })
                              }
                            >
                              <Icon
                                style={{ color: 'red' }}
                                type="close-circle"
                                theme="filled"
                              />
                              <span style={{ color: 'red' }}>Remove</span>
                            </div>
                          ) : (
                            ''
                          )
                        }
                        title={
                          <span style={{ color: '#00888E' }}>Front View</span>
                        }
                        cover={
                          this.state.frontImage ? (
                            <img
                              alt="example"
                              src={
                                this.state.frontImage.search('data') > -1
                                  ? this.state.frontImage
                                  : 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                    this.state.frontImage
                              }
                            />
                          ) : (
                            <img alt="example" src={dropImage} />
                          )
                        }
                        actions={[
                          <Upload
                            name="frontImage"
                            showUploadList={false}
                            beforeUpload={this.beforeUpload}
                            customRequest={this.handleImageUpload}
                            onChange={this.handleFrontImage}
                            loading={this.state.loading}
                            accept="image/*"
                          >
                            <Button>Upload Image</Button>
                          </Upload>
                        ]}
                      />
                    </Col>
                    <Col span={7}>
                      <Cards
                        style={{
                          width: '100%',
                          border: '1px solid #00888e',
                          marginBottom: 15
                        }}
                        extra={
                          this.state.leftImage ? (
                            <div
                              onClick={e =>
                                this.setState({ mainImage: undefined })
                              }
                            >
                              <Icon
                                style={{ color: 'red' }}
                                type="close-circle"
                                theme="filled"
                              />
                              <span style={{ color: 'red' }}>Remove</span>
                            </div>
                          ) : (
                            ''
                          )
                        }
                        title={
                          <span style={{ color: '#00888E' }}>Left View</span>
                        }
                        cover={
                          this.state.leftImage ? (
                            <img
                              alt="example"
                              src={
                                this.state.leftImage.search('data') > -1
                                  ? this.state.leftImage
                                  : 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                    this.state.leftImage
                              }
                            />
                          ) : (
                            <img alt="example" src={dropImage} />
                          )
                        }
                        actions={[
                          <Upload
                            name="leftImage"
                            showUploadList={false}
                            beforeUpload={this.beforeUpload}
                            customRequest={this.handleImageUpload}
                            onChange={this.handleLeftImage}
                            loading={this.state.loading}
                            accept="image/*"
                          >
                            <Button>Upload Image</Button>
                          </Upload>
                        ]}
                      />
                    </Col>
                    <Col span={7}>
                      <Cards
                        style={{
                          width: '100%',
                          border: '1px solid #00888e',
                          marginBottom: 15
                        }}
                        extra={
                          this.state.rightImage ? (
                            <div
                              onClick={e =>
                                this.setState({ rightImage: undefined })
                              }
                            >
                              <Icon
                                style={{ color: 'red' }}
                                type="close-circle"
                                theme="filled"
                              />
                              <span style={{ color: 'red' }}>Remove</span>
                            </div>
                          ) : (
                            ''
                          )
                        }
                        title={
                          <span style={{ color: '#00888E' }}>Right View</span>
                        }
                        cover={
                          this.state.rightImage ? (
                            <img
                              alt="example"
                              src={
                                this.state.rightImage.search('data') > -1
                                  ? this.state.rightImage
                                  : 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                    this.state.rightImage
                              }
                            />
                          ) : (
                            <img alt="example" src={dropImage} />
                          )
                        }
                        actions={[
                          <Upload
                            name="rightImage"
                            showUploadList={false}
                            beforeUpload={this.beforeUpload}
                            customRequest={this.handleImageUpload}
                            onChange={this.handleRightImage}
                            loading={this.state.loading}
                            accept="image/*"
                          >
                            <Button>Upload Image</Button>
                          </Upload>
                        ]}
                      />
                    </Col>
                    <Col span={7}>
                      <Cards
                        style={{
                          width: '100%',
                          border: '1px solid #00888e',
                          marginBottom: 15
                        }}
                        extra={
                          this.state.backImage ? (
                            <div
                              onClick={e =>
                                this.setState({ backImage: undefined })
                              }
                            >
                              <Icon
                                style={{ color: 'red' }}
                                type="close-circle"
                                theme="filled"
                              />
                              <span style={{ color: 'red' }}>Remove</span>
                            </div>
                          ) : (
                            ''
                          )
                        }
                        title={
                          <span style={{ color: '#00888E' }}>Rear View</span>
                        }
                        cover={
                          this.state.backImage ? (
                            <img
                              alt="example"
                              src={
                                this.state.backImage.search('data') > -1
                                  ? this.state.backImage
                                  : 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                    this.state.backImage
                              }
                            />
                          ) : (
                            <img alt="example" src={dropImage} />
                          )
                        }
                        actions={[
                          <Upload
                            name="backImage"
                            showUploadList={false}
                            beforeUpload={this.beforeUpload}
                            customRequest={this.handleImageUpload}
                            onChange={this.handleBackImage}
                            loading={this.state.loading}
                            accept="image/*"
                          >
                            <Button>Upload Image</Button>
                          </Upload>
                        ]}
                      />
                    </Col>
                    <Col span={7}>
                      <Cards
                        style={{
                          width: '100%',
                          border: '1px solid #00888e',
                          marginBottom: 15
                        }}
                        extra={
                          this.state.interiorImage ? (
                            <div
                              onClick={e =>
                                this.setState({ interiorImage: undefined })
                              }
                            >
                              <Icon
                                style={{ color: 'red' }}
                                type="close-circle"
                                theme="filled"
                              />
                              <span style={{ color: 'red' }}>Remove</span>
                            </div>
                          ) : (
                            ''
                          )
                        }
                        title={
                          <span style={{ color: '#00888E' }}>Interior</span>
                        }
                        cover={
                          this.state.interiorImage ? (
                            <img
                              alt="example"
                              src={
                                this.state.interiorImage.search('data') > -1
                                  ? this.state.interiorImage
                                  : 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                    this.state.interiorImage
                              }
                            />
                          ) : (
                            <img alt="example" src={dropImage} />
                          )
                        }
                        actions={[
                          <Upload
                            name="interiorImage"
                            showUploadList={false}
                            beforeUpload={this.beforeUpload}
                            customRequest={this.handleImageUpload}
                            onChange={this.handleInteriorImage}
                            loading={this.state.loading}
                            accept="image/*"
                          >
                            <Button>Upload Image</Button>
                          </Upload>
                        ]}
                      />
                    </Col>
                    <Col span={7}>
                      <Cards
                        style={{
                          width: '100%',
                          border: '1px solid #00888e',
                          marginBottom: 15
                        }}
                        extra={
                          this.state.engineImage ? (
                            <div
                              onClick={e =>
                                this.setState({ engineImage: undefined })
                              }
                            >
                              <Icon
                                style={{ color: 'red' }}
                                type="close-circle"
                                theme="filled"
                              />
                              <span style={{ color: 'red' }}>Remove</span>
                            </div>
                          ) : (
                            ''
                          )
                        }
                        title={<span style={{ color: '#00888E' }}>Engine</span>}
                        cover={
                          this.state.engineImage ? (
                            <img
                              alt="example"
                              src={
                                this.state.engineImage.search('data') > -1
                                  ? this.state.engineImage
                                  : 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                    this.state.engineImage
                              }
                            />
                          ) : (
                            <img alt="example" src={dropImage} />
                          )
                        }
                        actions={[
                          <Upload
                            name="engineImage"
                            showUploadList={false}
                            beforeUpload={this.beforeUpload}
                            customRequest={this.handleImageUpload}
                            onChange={this.handleEngineImage}
                            loading={this.state.loading}
                            accept="image/*"
                          >
                            <Button>Upload Image</Button>
                          </Upload>
                        ]}
                      />
                    </Col>
                    <Col span={7}>
                      <Cards
                        style={{
                          width: '100%',
                          border: '1px solid #00888e',
                          marginBottom: 15
                        }}
                        extra={
                          this.state.bootImage ? (
                            <div
                              onClick={e =>
                                this.setState({ bootImage: undefined })
                              }
                            >
                              <Icon
                                style={{ color: 'red' }}
                                type="close-circle"
                                theme="filled"
                              />
                              <span style={{ color: 'red' }}>Remove</span>
                            </div>
                          ) : (
                            ''
                          )
                        }
                        title={
                          <span style={{ color: '#00888E' }}>
                            Boot (Interior)
                          </span>
                        }
                        cover={
                          this.state.bootImage ? (
                            <img
                              alt="example"
                              src={
                                this.state.bootImage.search('data') > -1
                                  ? this.state.bootImage
                                  : 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                    this.state.bootImage
                              }
                            />
                          ) : (
                            <img alt="example" src={dropImage} />
                          )
                        }
                        actions={[
                          <Upload
                            name="bootImage"
                            showUploadList={false}
                            beforeUpload={this.beforeUpload}
                            customRequest={this.handleImageUpload}
                            onChange={this.handleBootImage}
                            loading={this.state.loading}
                            accept="image/*"
                          >
                            <Button>Upload Image</Button>
                          </Upload>
                        ]}
                      />
                    </Col>
                    <Col span={7}>
                      <Cards
                        style={{
                          width: '100%',
                          border: '1px solid #00888e',
                          marginBottom: 15
                        }}
                        extra={
                          this.state.service1Image ? (
                            <div
                              onClick={e =>
                                this.setState({ service1Image: undefined })
                              }
                            >
                              <Icon
                                style={{ color: 'red' }}
                                type="close-circle"
                                theme="filled"
                              />
                              <span style={{ color: 'red' }}>Remove</span>
                            </div>
                          ) : (
                            ''
                          )
                        }
                        title={
                          <span style={{ color: '#00888E' }}>
                            Service book 1 (Stamped)
                          </span>
                        }
                        cover={
                          this.state.service1Image ? (
                            <img
                              alt="example"
                              src={
                                this.state.service1Image.search('data') > -1
                                  ? this.state.service1Image
                                  : 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                    this.state.service1Image
                              }
                            />
                          ) : (
                            <img alt="example" src={dropImage} />
                          )
                        }
                        actions={[
                          <Upload
                            name="service1Image"
                            showUploadList={false}
                            beforeUpload={this.beforeUpload}
                            customRequest={this.handleImageUpload}
                            onChange={this.handleService1Image}
                            loading={this.state.loading}
                            accept="image/*"
                          >
                            <Button>Upload Image</Button>
                          </Upload>
                        ]}
                      />
                    </Col>
                    <Col span={7}>
                      <Cards
                        style={{
                          width: '100%',
                          border: '1px solid #00888e',
                          marginBottom: 15
                        }}
                        extra={
                          this.state.service2Image ? (
                            <div
                              onClick={e =>
                                this.setState({ service2Image: undefined })
                              }
                            >
                              <Icon
                                style={{ color: 'red' }}
                                type="close-circle"
                                theme="filled"
                              />
                              <span style={{ color: 'red' }}>Remove</span>
                            </div>
                          ) : (
                            ''
                          )
                        }
                        title={
                          <span style={{ color: '#00888E' }}>
                            Service book 2 (Stamped)
                          </span>
                        }
                        cover={
                          this.state.service2Image ? (
                            <img
                              alt="example"
                              src={
                                this.state.service2Image.search('data') > -1
                                  ? this.state.service2Image
                                  : 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                    this.state.service2Image
                              }
                            />
                          ) : (
                            <img alt="example" src={dropImage} />
                          )
                        }
                        actions={[
                          <Upload
                            name="service2Image"
                            showUploadList={false}
                            beforeUpload={this.beforeUpload}
                            customRequest={this.handleImageUpload}
                            onChange={this.handleService2Image}
                            loading={this.state.loading}
                            accept="image/*"
                          >
                            <Button>Upload Image</Button>
                          </Upload>
                        ]}
                      />
                    </Col>
                  </Row>
                </Collapse.Panel>
                <Collapse.Panel
                  style={customPanelStyle}
                  header={
                    <span style={{ color: '#00B4BC', fontWeight: 700 }}>
                      Asking Price
                    </span>
                  }
                  key="6"
                  extra={
                    <div style={{ display: 'flex' }}>
                      <span style={{ marginRight: 10, color: '#6D6E70' }}>
                        {this.state.panel6Status}
                      </span>
                      <div
                        style={{
                          width: 15,
                          height: 15,
                          background: this.state.panel6Color,
                          borderRadius: '50%',
                          position: 'relative',
                          top: 4,
                          marginRight: 15
                        }}
                      />
                    </div>
                  }
                >
                  <Row type="flex" justify="space-between" align="middle">
                    <Col span={24}>
                      <span>
                        Please give an indication of the amount you would like
                        for this vehicle.{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </span>
                    </Col>
                  </Row>
                  <Row
                    type="flex"
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: 10 }}
                  >
                    <Col span={24}>
                      <Input
                        style={customInputStyle}
                        value={this.state.price}
                        onChange={e =>
                          this.setState({
                            price:
                              'R' + numeral(e.currentTarget.value).format('0,0')
                          })
                        }
                      />
                      {this.editHistory('price')}
                    </Col>
                  </Row>
                </Collapse.Panel>
              </Collapse>
            </Col>
          </Row>
        </Spin>
        <Row
          style={{ marginBottom: 15 }}
          type="flex"
          justify="center"
          align="middle"
        >
          <Col style={{ display: 'flex' }} span={5}>
            <Button
              type="primary"
              style={{ height: 46, width: 171 }}
              // disabled={this.validateRequired()}
              onClick={this.editVehicle}
            >
              Save Changes
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default EditVehicle;
