import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  findMakes,
  findModels,
  findVariants,
  findYears,
  findCities
} from 'src/redux/actions/cddata';
import { addVehicle, setEditVehicle } from 'src/redux/actions/vehicles';
import { sendNotification } from 'src/redux/actions/notifications';
import {
  getLocale,
  getMakes,
  getModels,
  getVariants,
  getYears,
  getCities,
  getEditVehicle
} from 'src/redux/selectors';

class AddVehicle extends Component {
  render = () => {
    const {
      Layout,
      currentLocale,
      findMakes,
      makes,
      findModels,
      models,
      findVariants,
      variants,
      findYears,
      findCities,
      cities,
      years,
      addVehicle,
      push,
      editVehicle,
      setEditVehicle,
      sendNotification
    } = this.props;
    return (
      <Layout
        currentLocale={currentLocale}
        findMakes={findMakes}
        makes={makes}
        findModels={findModels}
        models={models}
        findVariants={findVariants}
        variants={variants}
        findYears={findYears}
        cities={cities}
        findCities={findCities}
        years={years}
        push={push}
        addVehicle={addVehicle}
        editVehicle={editVehicle}
        setEditVehicle={setEditVehicle}
        sendNotification={sendNotification}
      />
    );
  };
}

const mapStateToProps = state => ({
  currentLocale: getLocale(state) || {},
  makes: getMakes(state) || {},
  models: getModels(state) || {},
  variants: getVariants(state) || {},
  years: getYears(state) || {},
  cities: getCities(state) || [],
  editVehicle: getEditVehicle(state) || undefined
});

const mapDispatchToProps = {
  getLocale,
  findMakes,
  findModels,
  findVariants,
  findYears,
  findCities,
  addVehicle,
  setEditVehicle,
  sendNotification,
  push
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddVehicle);
