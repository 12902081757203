export default {
  WelcomeBack: 'Welkom terug',
  SignInHeading: 'Teken in op Verkoopnou',
  Welcome: 'Welkom',
  CreateAccountHeading: "Stig 'n nuwe rekening",
  Dealer: 'Handelaar',
  Customer: 'Klient',
  SignIn: 'Teken In',
  SignOut: 'Teken Uit',
  SignUp: 'Teken Aan',
  Login: 'Teken in',
  WithGoogle: 'met Google',
  WithFacebook: 'met Facebook',
  Username: 'Gebruikersnaam',
  Name: 'Naam',
  Password: 'Sleutelwoord',
  Forgot: 'Sleutelwoord vergeet?',
  AlreadyRegistered: 'Klaar Geregistreer',
  RegisterNow: 'Registreer nou om jou reis te begin!',
  ContinueSubHeading: 'om voort te gaan',
  EnterName: 'Vul voornaam in',
  EnterLastName: 'Vul van in',
  EnterEmail: 'Vul geldige epos-adres in',
  EnterCellNumber: 'Vul selfoon nommer in',
  EnterPassword: 'Vul sleutelwoord in',
  Confirm: 'Bevestig',
  Accept: 'Aanvaar',
  TermsConditions: 'Terme en Voorwaardes?',
  WelcomeToHeading:
    "Welkom by Verkoopnou, 'n veilige ruimte waar jy jou motor aan geloofwaardige handelaars kan verkoop",
  VettedByHeading: '(deur ons gereël) teen die beste pryse.',
  WithVerkoopnouHeading:
    'Met Verkoopnou, is die verkoop van jou motor so maklik soos kan wees.',
  ReceiveBids: 'Kry aanbiedinge vir u motor',
  OnlineAuction: "in 'n aanlynveiling",
  AcceptBids: 'Aanvaar die hoogste aanbod',
  AndYour: 'en u motor word verkoop!',
  TellUs: 'Beskryf jou motor aan ons ',
  HowMuch: 'en wat u verwagte verkoopprys is',
  GoTo: 'Gaan na',
  MyCars: 'My motors',
  AddCar: 'Verkoop',
  ToSell: '‘n motor',
  Step: 'Stap',
  GeneralInformation: 'Algemene inligting',
  Year: 'Jaarmodel',
  Make: 'Maak',
  Model: 'Model',
  ModelType: 'Model tipe',
  Transmission: 'Ratkas',
  Mileage: 'Kilometers',
  Colour: 'Kleur ',
  FuelType: 'Brandstof tipe',
  Type: 'tipe',
  OptionalExtras: 'Opsionele ekstras ',
  Province: 'Provinsie',
  CityTown: 'Dorp/stad ',
  WhereDidYou: 'Waar het jy jou voertuig gekoop?',
  ReasonFor: 'Rede vir',
  Selling: 'verkoop',
  BidAmount: 'Bodbedrag',
  IncludingYouText:
    'Insluitend jouself, hoeveel vorige eienaars het die voertuig gehad?',
  LicenseExpiry: 'Vervaldatum van lisensieskyf',
  AdditionalComment: 'Addisionele kommentaar',
  Optional: '(Opsioneel)',
  DamageAssessment: 'Skade beraming ',
  Complete1: 'Voltooi',
  CompleteDeal: 'Voltooi Ooreenkoms',
  ToUnlock: 'om te ontsluit',
  ServiceHistory: 'Diensrekord en finansiering ',
  Outstanding: 'Uitstaande',
  TyreCondition: 'Loopvlak van  bande ',
  VehicleCondition: 'Voertuig kondisie',
  VehicleDamage: 'Voertuig skade',
  DamageRepair: 'Voertuig skade Herstel',
  VehicleImages: 'Foto’s van voertuig',
  Vehicle: 'Voertuig',
  Automatic: 'Outomaties',
  Manual: 'Handrat',
  Electric: 'Elektries',
  CurrentDay: 'Vandag',
  EmailRequired: 'E-pos benodig',
  UsernameRequired: 'Gebruikersnaam benodig',
  NameRequired: 'Naam benodig',
  RemoveFromAuction: 'Verwyder van veiling',
  PutOnAuction: 'Plaas op veiling',
  NumberRequired: 'Number benodig',
  SurnameRequired: 'Van benodig',
  PasswordRequired: 'Sleutelwoord benodig',
  NewPasswordRequired: 'Nuwe Sleutelwoord benodig',
  Hybrid: 'Bastermotor',
  AskingPrice: 'Verwagte prys ',
  SendToAuction: 'Stuur na veiling',
  Closed: 'Gesluit',
  SentToAuction: 'Na veiling gestuur',
  SendtToAuction: 'Reeds op aanlynveiling',
  ExportToPDF: 'Voer uit na PDF',
  Select: 'Kies',
  an: "'n",
  option: 'opsie',
  Other: 'Ander',
  SunRoof: 'Sondak',
  Eg: 'bv.',
  Provide: 'Verskaf',
  Details: 'Besonderhede',
  NumberOfOwners: 'Hoeveelheid eienaars',
  IDontKnow: 'Nie seker nie',
  Date: 'Datum',
  Dealer: 'handelaar',
  Private: 'Privaat',
  Auction: 'Veiling',
  UpToDate: 'Onseker, maar op datum',
  ButExpired: 'Onseker, maar het verval',
  SelectedDate: 'Soos per gekose datum',
  Bad: 'Sleg',
  Good: 'Goed',
  Exellent: 'Uitstekend',
  SpareKeys: 'Het die voertuig ‘n spaarsleutel?',
  SpareKeys1: 'Spaarsleutels',
  Yes: 'Ja',
  No: 'Nee',
  NumberSpareKeys: 'Hoeveelheid spaarsleutels',
  MechanicalIssues: 'Het die voertuig huidiglik enige meganiese probleme?',
  MechanicalIssues1: 'Meganiese Probleme',
  IfYes: 'Indien wel, spesifiseer asb',
  InteriorCondition: 'Kondisie binne',
  Average: 'Gemiddeld',
  Poor: 'Swak',
  EverBeenInAnAccident:
    'Was die voertuig al ooit in ‘n ongeluk, of  is voorheen herstel, of het dit enige spuitwerk ondergaan om krappe of haelskade te herstel? ',
  HailDamage: 'Haelskade',
  Accident: 'Ongeluk',
  CosmeticSpraywork: 'Kosmetiese verf-of Spuitwerk',
  WindscreenCracks: 'Het die windskerm enige krake?',
  WindscreenCracks1: 'Windskerm Krake',
  MagsRimsDamage: 'Wieldoppe Skade',
  CanBeFixed: 'Kan herstel word',
  NeedsReplaced: 'Moet vervang word',
  RimsScratched: 'Is die wieldoppe of vellings gekrap, ingeduik of gespan? ',
  MyServiceHistory: 'Diensrekord',
  PrivateWorkshops: ' en privaat werkswinkels',
  FullServiceHistory: 'Volledige densrekord by ',
  PartialServiceHistory: 'Gedeeltelike diensrekord',
  NoServiceHistory: 'Geen diensrekord nie',
  Agents: 'Agente',
  VehicleFinanced: 'Is voertuig gefinansier?',
  Finance: 'Gefinansier',
  AmountOutstanding: 'Bedrag uitstaande',
  Amount: 'Bedrag',
  ServicePlan: 'Het die voertuig ‘n diens- of onderhoudplan?',
  Copyright: 'Kopiereg',
  ServiceMaintenance: 'Diens-Onderhoudplan',
  UnderWarranty: 'Is die voeretuig onder waarborg? ',
  FrontTyres: 'Voor Bande',
  BackTyres: 'Agter Bande',
  TyresAreNew: 'Bande soos nuut',
  New: 'Nuut',
  New1: 'Nuwe',
  TyresAre: 'Bande is',
  Worn: 'Afgeloop',
  HalfWorn: 'Half Afgeloop',
  DamageOrBodyWork:
    'Is daar enige skade op die voertuig en/ of het voertuig enige bakwerk ondergaan?',
  ClosestMatch: 'Kies die beste beskrywing van jou voertuig.',
  Hatchback: 'Luikrug',
  VanCombi: 'Bus/Kombi',
  TypeOfDamage:
    'Kies die tipe skade wat u met die kol moet byvoeg en sleep na die betrokke ligging.',
  SprayworkDone: 'Spuitwerk gedeon',
  Scratch: 'Krapmerke',
  Chips: 'Chip/s',
  Dents: 'Duik/e',
  Rust: 'Roes',
  Hail: 'Haelskade',
  Cracked: 'Gekraak',
  Broken: 'Gebreek',
  RemoveAll: 'Verwyder alles',
  MainViewImageRequired:
    'Hoofaansig is noodsaaklik. Hoe meer prente gelaai word, hoe beter word die prys.',
  MainView: 'Hoofaansig ',
  FrontView: 'Vooraansig ',
  LeftView: 'Linkeraansig',
  RightView: 'Regteraansig',
  RearView: 'Agteraansig',
  Interior: 'Binne',
  Engine: 'Motor',
  Boot: 'Kattebak',
  ServiceBook: 'Diensboekie Foto ',
  Remove: 'Verwyder',
  Stamped: '(Gestempeld)',
  UploadImage: 'Laai Foto',
  TakeImage: 'Neem Foto',
  GiveIndication:
    "Gee 'n aanduiding van die bedrag wat u vir hierdie voertuig verwag.",
  AlreadySent: 'Reeds op aanlynveiling',
  Review: 'Hersien',
  CloseReview: 'Eindig Hersien',
  AcceptedVehicles: 'Aanvaarde voertuie',
  VehicleBought: 'Motor Gekoop',
  Owners: 'Eienaars',
  Warranty: 'Waarborg',
  RearTyres: 'Agter Bande',
  FrontTyres: 'Voor Bande',
  NoDamage: 'Geen Skade',
  Damage: 'Skade',
  White: 'Wit',
  Black: 'Swart',
  Red: 'Rooi',
  Blue: 'Blou',
  Green: 'Groen',
  Grey: 'Grys',
  Champagne: 'Champagne',
  Silver: 'Silver',
  Yellow: 'Geel',
  Orange: 'Oranje',
  Brown: 'Bruin',
  Burgandy: 'Burgandy',
  AddVehicleDetails: 'Laai Voertuig Besonderhede',
  Cancel: 'Kanselleer',
  Complete: 'Volledig',
  Incomplete: 'Onvolledig',
  Progress: 'Vordering',
  Reset: 'Herstel',
  UserAddedVehicle: "Gebruiker 'n voertuig bygevoeg",
  AdminApprovedYourVehicles: 'Admin het een van u voertuie goedgekeur',
  AdminDeletedYourVehicles: 'Admin het een van u voertuie verwyder',
  AdminApprovedAuctionHistoryVehicles:
    'Admin het een van u Veilingsgeskiedenis-Voertuie goedgekeur',
  AdminRejectedAuctionHistoryVehicles:
    'Admin het een van u Veilingsgeskiedenis-Voertuie verwerp',
  AdminApprovedDealerAccount: 'Admin het u handelaarsrekening goedgekeur.',
  AdminRejectedYourVehicles: 'Admin het een van u voertuie verwerp.',
  AdminRejectedDealerAccount: 'Admin het u handelaarsrekening verwerp.',
  CustomerRrejectedWinningBidOffer: "'N Klant het 'n wenaanbieding verwerp",
  CustomerRrejectedYourOffer: "'N Klant het u aanbod van die hand gewys.",
  CustomerRrejectedOffer: "'N Klant het u aanbod van die hand gewys.",
  VehicleSubmittedForReview: "'N Voertuig is vir ondersoek ingedien",
  VehicleSentreViewSuccesfully:
    'Voertuig is suksesvol na die veiling gestuur vir hersiening',
  AdminOfferOnYourVehicles: " Admin het 'n aanbod op een van u voertuie gemaak",
  AdminMadeChangesToVehicle: 'Admin het een van u voertuie verander',
  AdminActivatedDealerAccount: 'Admin het u handelaarsrekening geaktiveer.',
  AdminDectivatedDealerAccount: 'Admin het u handelaarsrekening deaktiveer.',
  WeMadeChangesToVehicleOnauction:
    "Ons het 'n paar veranderinge aan u voertuig aangebring op ons veilingsplatform",
  YourVehicleWasSubmittedForReviewToBePlacedOnAuction:
    'U voertuig is ingedien vir beoordeling om op die veiling te plaas',
  YouWillBeNotifiedOnceThisIsDone:
    'Sodra dit gedoen is, sal u in kennis gestel word.',
  SuccessfullyDeactivatedDealer: 'Handelaar suksesvol gedeaktiveer',
  SuccessfullyDeactivatedDealerPrices: 'Handelaar pryse suksesvol gedeaktiveer',
  SuccessfullyActivatedDealerPrices: 'Handelaar pryse suksesvol geaktiveer',
  Completion: 'Voltooiing',
  YourNotifications: 'U Kennisgewings',
  ClearAll: 'Verwyder als',
  Image: 'Foto',
  Images: 'Fotos',
  Status: 'Status',
  Bidding: 'Bied',
  Action: 'Aksie',
  AuctionsWon: 'Veilings Gewen',
  AuctionsLost: 'Veilings verloor',
  MyVehicles: 'My Motors',
  AddVehicle: 'Voeg Motor',
  EditVehicle: 'Wysig voertuig',
  DeleteVehicle: 'Verwyder',
  Delete: 'Verwyder',
  Approved: 'Goedgekeur',
  Rejected: 'Verwerp',
  Approve: 'Keur goed',
  Reject: 'Verwerp',
  AwaitingResponse: 'Wag vir terugvoering',
  AwaitingApproval: 'Wag vir Goedkeuring',
  AwaitingCompletion: 'Wag vir Afhandelling',
  AwaitingSubmission: 'Wag op Voorlegging',
  AwaitingOfferReview: 'Wag op Aanbod Hersiening',
  OfferRejected: 'Aanbod verwerp',
  ViewOffer: 'Sien Aanbod',
  OfferAccepted: 'Aanbod Aanvaar',
  AreYouSureDeleteVehicle: 'Is u seker dat u hierdie voertuig wil verwyder?',
  VehiclesOnAuction: 'Voertuie op Veiling',
  Trade: 'Handel',
  Retail: 'Kleinhandel',
  Deal: 'Handel',
  Bids: 'Aanbode',
  NoBids: 'Geen Aanbode',
  YourBids: 'Jou Aanbode',
  YourBid: 'Jou Aanbod',
  Location: 'Plek',
  AuctionDate: 'Veiling Datum',
  AcceptedPrice: 'Aanvaarde Prys',
  WinningBid: 'Wen Aanbod',
  WinningBidder: 'Wenbieder',
  FirstBidder: 'First Bidder',
  VehicleDetails: 'Motor Besonderhede',
  MMCode: 'M&M Kode',
  Condition: 'Toestand',
  NotProvided: 'Nie Verskaf',
  TradePrice: 'Handelprys',
  RetailPrice: 'Kleinhandelprys',
  NewPrice: 'Nuwe Prys',
  PreviousBodyWork: 'Vorige Liggaamswerk / Ongeluk',
  General: 'Algemene',
  Recon: 'Verkenning',
  MyBasket: 'My Mandjie',
  History: 'Geskiedenis',
  AccountSettings: 'Rekeninginstellings',
  Account: 'Rekening',
  Notification: 'Kennisgewing',
  FirstName: 'Naam',
  Surname: 'Van',
  Nickname: 'Bynaam',
  Number: 'Nommer',
  AlternativeNumber: 'Alternatiewe nommer',
  WheredidYouHearAboutUs: 'Waar het jy van ons gehoor?',
  SelectPlace: 'Kies plek',
  BillboardOrStreetpoleAd: 'Advertensiebord of straatpaal',
  WordOfMouth: 'Woord van Mond',
  SaveChanges: 'Stoor Veranderinge',
  SaveVehicle: 'Stoor Moter',
  MySettings: 'My Instellings',
  UploadProfileImage: 'Laai profielfoto',
  Edit: 'Wysig',
  Or: 'of',
  ManageVehicles: 'Bestuur Voertuie',
  CustomerAskingPrice: 'Klient Verwagte Prys',
  ReservePrice: 'Reserwe Prys',
  Client: 'Klient',
  ApproveVehicle: 'Goedkeur Voertuig',
  SellingReason: 'Verkoop rede',
  ClientName: 'Klient Naam',
  DealerUsername: 'Hendelaar Gebruiksnaam',
  ClientNumber: 'Klient Nommer',
  OfferWonAmount: 'Aanbod / Wen Bedrag',
  LastFollowUp: 'Laaste Opvolg',
  City: 'Dorp',
  Notes: 'Notas',
  DealComplete: 'Deal Voltooi',
  EditHistory: 'Wysig Geskiedenis',
  OnAuction: 'Op Veiling',
  AdminOfferAccepted: 'Admin Aanbod Aanvaar',
  AdminOfferRejected: 'Admin Offer Verwerp',
  DealerOfferAccepted: 'Handelaar Aanbod Aanvaar',
  DealerOfferRejected: 'Handelaar Aanbod Verwerp',
  AwaitingOfferResponse: 'Wag Vir Goedkeuring',
  WinningBidSent: 'Wen-aanbod Gestuur',
  ManageAuction: 'Bestuur Veiling',
  AuctionAllVehicles: 'Alle Voretuie Na Veiling',
  AddSelectedVehicles: 'Voeg Geselekteerde Voertuie Toe',
  RemoveSelectedVehicles: 'Verwyder Geselekteerde Voertuie Toe',
  Variant: 'Variasie',
  HighestBid: 'Hoogste Aanbod',
  Start: 'Begin',
  End: 'Eindig',
  TimeLeft: 'Tyd Oor',
  Calendar: 'Kalender',
  Vehicles: 'Voertuie',
  Active: 'Aktief',
  AuctionActive: 'Veiling Aktief',
  AuctionClosed: 'Veiling Gesluit',
  Maand: 'Maand',
  Search: 'Soek',
  AuctionHistory: 'Veiling Geskiedenis',
  To: 'tot',
  FollowedUp: 'Ogevolg',
  Details: 'Besonderhede',
  ViewDetails: 'Sien Besonderhede',
  ViewBidInfo: 'Bekyk Bodinligting',
  PutOnAuctionList: 'Sit op die Veilingslys',
  AcceptOfferCustomer: 'Aanvaar die aanbod vir die kliënt',
  DealDone: 'Handel gedoen',
  ReAuction: 'Her-Veiling',
  DealOffersSent: 'Aanbiedinge Gestuur',
  DealOffersAccepted: 'Aanbiedinge Aanvaar',
  DealFailed: 'Aanbiedinge Misluk',
  DealDoneWithDealer: 'Handel Gedoen met Handelaar',
  DealNotDoneWithDealer: 'Handel nie Gedoen met Handelaar nie',
  DealAmount: 'Handel Bedrag',
  Invoice: 'Faktuur',
  VerkoopnouOfferAccepted: 'Verkoopnou-aanbod aanvaar',
  Never: 'Nooit',
  ManagePeople: 'Mense bestuur',
  HeardFrom: 'Van gehoor ',
  Dealers: 'Handelaars',
  CKDocument: 'CK Dokument',
  NotUploaded: 'Nie Opgelaai',
  PricesActive: 'Pryse Aktief',
  ActivatePrices: 'Aktiveer pryse',
  Activate: 'Aktiveer',
  Deactivate: 'Deaktiveer',
  DeactivatePrices: 'Deaktiveer pryse',
  ManageDeals: 'Bestuur Aanbiedinge',
  SetReservePrice: ' Stel Reserweprys',
  MakeAnOffer: 'Maak Aanbod',
  RejectVehicle: 'Verwerp Voertuig',
  ClientUsername: 'Klient Gebruiksnaam',
  ClientSurname: 'Klient Van',
  DealerName: 'Handelaar Naam',
  DealerSurname: 'Handelaar Van',
  DealerNumber: 'Handelaar Nommer',
  DealerPrice: 'Ooreenkoms Prys',
  AmountLikeToOffer: 'Wat is die bedrag wat u wil aanbied?',
  AmountLikeToReserve: 'Wat is die bedrag wat u wil bespreek?'
};
