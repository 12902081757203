import React from 'react';
import styled from 'styled-components';
import {
  Icon,
  Row,
  Col,
  Spin,
  Button,
  List,
  Tabs,
  Popover,
  Input,
  Table,
  Divider,
  Modal
} from 'antd';
import moment from 'moment';
import Notify from 'src/components/meta/Notification';
import noImage from 'src/static/icons/carImageUpload/no-image-available.svg';
import Countdown from 'react-countdown-now';
import Highlighter from 'react-highlight-words';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';
const { Column, ColumnGroup } = Table;
const { TextArea } = Input;
const numeral = require('numeral');
const { TabPane } = Tabs;
const hammer = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6475 17.3418H1.65195C0.740018 17.3418 0 18.0817 0 18.9938V21.9977H14.2994V18.9938C14.2994 18.0817 13.5595 17.3418 12.6475 17.3418Z"
      fill="#AD5E00"
    />
    <path
      d="M21.7036 17.5744L11.6665 7.53725L13.6051 5.60203C13.9248 5.88391 14.4163 5.87013 14.7188 5.56766C15.0351 5.248 15.0351 4.7358 14.7188 4.41614L10.539 0.239743C10.2228 -0.0799143 9.70715 -0.0799143 9.39094 0.239743C9.07128 0.555998 9.07128 1.0716 9.39094 1.38781L9.35657 1.35344L4.04924 6.65728L4.08361 6.69505C3.76736 6.37539 3.25175 6.37539 2.93554 6.69505C2.61589 7.01131 2.61589 7.52691 2.93554 7.84317L7.11194 12.023C7.4316 12.3392 7.9472 12.3392 8.26346 12.023C8.57971 11.7033 8.57971 11.1912 8.26346 10.8715L8.29783 10.9058L10.2365 8.9706L20.2702 19.0043C20.6655 19.3996 21.3083 19.3996 21.7036 19.0043C22.0989 18.609 22.0989 17.9697 21.7036 17.5744Z"
      fill="#AD5E00"
    />
  </svg>
);

const StyledTable = styled(Table)`
  // .ant-table-thead > tr > th {
  //   text-align: center !important;

  // }
  .ant-table-thead > tr > th {
    line-height: 1em;
    font-size: 15px;
    color: grey;

    border-bottom: 1px solid#00878e34;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #e8e8e8;
    -webkit-transition: all 0.3s, border 0s;
    transition: all 0.3s, border 0s;
    font-weight: 700;
    font-size: 11px;
  }
`;
class Vehicles extends React.Component {
  componentWillMount = () => {
    this.setState({ loading: true });
    this.props
      .findAllVehicles()
      .then(() => {
        let vehicles = this.props.vehicles;
        for (var i = 0; i < vehicles.length; i++) {
          vehicles[i].statusText = vehicles[i].dealComplete
            ? window.i18n.translate('DealComplete')
            : vehicles[i].onAuction
            ? window.i18n.translate('OnAuction')
            : vehicles[i].adminOfferAccepted === true
            ? window.i18n.translate('AdminOfferAccepted')
            : vehicles[i].adminOfferAccepted === false
            ? window.i18n.translate('AdminOfferRejected')
            : vehicles[i].offerAccepted === true
            ? window.i18n.translate('DealerOfferAccepted')
            : vehicles[i].offerAccepted === false
            ? window.i18n.translate('DealerOfferRejected')
            : vehicles[i].sentAdminOffer === true
            ? window.i18n.translate('AwaitingOfferResponse')
            : vehicles[i].sentWinningBid === true
            ? window.i18n.translate('WinningBidSent')
            : vehicles[i].approved
            ? window.i18n.translate('Approved')
            : vehicles[i].approved === false
            ? window.i18n.translate('Rejected')
            : window.i18n.translate('AwaitingApproval');
        }
        this.setState({ loading: false, vehicles: vehicles });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      filteredInfo: null,
      rejectReason: ''
    };
  }
  parseString = string => {
    return parseInt(string.split('R')[1].replace(/,/g, ''));
  };
  formatNumber = number => {
    return 'R' + numeral(number).format('0,0');
  };

  handleSelectVehicle = e => {
    this.props.setVehicleDetailsId(e.currentTarget.getAttribute('data'));
    this.props.push('/admin/vehicledetails');
  };
  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let vehicles = this.state.vehicles;
    if (order === 'ascend') {
      vehicles.sort((a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey]));
    } else if (order === 'descend') {
      vehicles.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
    }
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };
  handleApprove = e => {
    this.setState({ loading: true });
    const data = JSON.parse(e.currentTarget.getAttribute('data'));
    this.props
      .approveVehicle(data._id)
      .then(() => {
        this.props
          .findAllVehicles()
          .then(() => {
            let vehicles = this.props.vehicles;
            for (var i = 0; i < vehicles.length; i++) {
              vehicles[i].statusText = vehicles[i].dealComplete
                ? 'Deal Complete'
                : vehicles[i].onAuction
                ? 'On Auction'
                : vehicles[i].adminOfferAccepted === true
                ? 'Admin Offer Accepted'
                : vehicles[i].adminOfferAccepted === false
                ? 'Admin Offer Rejected'
                : vehicles[i].offerAccepted === true
                ? 'Dealer Offer Accepted'
                : vehicles[i].offerAccepted === false
                ? 'Dealer Offer Rejected'
                : vehicles[i].sentAdminOffer === true
                ? 'Awaiting Offer Response'
                : vehicles[i].sentWinningBid === true
                ? 'Winning Bid Sent'
                : vehicles[i].approved
                ? 'Approved'
                : vehicles[i].approved === false
                ? 'Rejected'
                : 'Awaiting Approval';
            }
            this.setState({ vehicles: vehicles });
            this.props
              .sendNotification(
                data.userId,
                [data.userId],
                window.i18n.translate('AdminApprovedYourVehicles'),
                'adminChanged',
                data._id,
                true,
                'Verkoopnou Admin'
              )
              .then(() => {
                this.setState({ loading: false });
                Notify('success', 'Successfully approved vehicle');
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  handleReject = e => {
    const data = JSON.parse(e.currentTarget.getAttribute('data'));
    var that = this;
    Modal.confirm({
      centered: true,
      content: (
        <div>
          <h4>Please provide a rejection reason to the user.</h4>
          <TextArea
            rows={4}
            onChange={e => {
              this.setState({ rejectReason: e.currentTarget.value });
            }}
          />
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .rejectVehicle(data._id, that.state.rejectReason)
          .then(() => {
            that.props
              .findAllVehicles()
              .then(() => {
                that.props
                  .sendNotification(
                    data.userId,
                    [data.userId],
                    window.i18n.translate('AdminApprovedYourVehicles'),
                    'adminChanged',
                    data._id,
                    true,
                    'Verkoopnou Admin'
                  )
                  .then(() => {
                    that.setState({ loading: false });
                    Notify('success', 'Successfully rejected vehicle');
                  })
                  .catch(e => {
                    that.setState({ loading: false });
                    Notify('error', e);
                  });
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify('error', e);
          });
      },
      oncancel() {}
    });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  handleDeleteVehicle = e => {
    this.setState({ loading: true, deleteVisible: false });
    const data = this.state.deleteVehicle;
    this.props
      .deleteVehicle(data._id)
      .then(() => {
        this.props
          .findAllVehicles()
          .then(() => {
            this.props
              .sendNotification(
                data.userId,
                [data.userId],
                window.i18n.translate('AdminDeletedYourVehicles'),

                'adminChanged',
                data._id,
                true,
                'Verkoopnou Admin'
              )
              .then(() => {
                this.setState({ loading: false });
                Notify(
                  'success',
                  'Deleted vehicle',
                  'Successfully deleted vehicle.'
                );
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };

  handleDeleteVehicleShow = e => {
    const data = JSON.parse(e.currentTarget.getAttribute('data'));
    this.setState({ deleteVisible: true, deleteVehicle: data });
  };

  cancelDelete = e => {
    this.setState({ deleteVisible: false, deleteVehicle: '' });
  };
  render() {
    const { user } = this.props;
    let { sortedInfo, filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    return (
      <div>
        <Row type="flex" justify="start" align="middle">
          <Col span={12}>
            <Icon
              style={{ fontSize: 36, color: '#00888E', marginRight: 20 }}
              type="car"
              theme="filled"
            />
            <span style={{ fontWeight: 750, fontSize: 36 }}>
              {window.i18n.translate('ManageVehicles')}
            </span>
          </Col>
          {/* <Col span={12}>
              <Button type="primary" style={{ fontWeight: 750, fontSize: 24, float: "right" }}>{moment().format("HH:mm:ss")}</Button>
            </Col> */}
        </Row>
        <Spin spinning={this.state.loading}>
          <Row type="flex" justify="start" align="middle">
            <Col span={24}>
              <StyledTable
                style={{ background: 'white' }}
                rowKey="_id"
                size="middle"
                dataSource={this.state.vehicles}
                onChange={this.handleSort}
              >
                <Column
                  sorter={() => {}}
                  title="ID"
                  dataIndex="displayId"
                  key="displayId"
                />
                {/* <Column title="Image" dataIndex="mainImage" key="mainImage" render={image => <img style={{maxWidth:40, maxHeight:40}} alt="Main" src={'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' + image} />} /> */}
                <Column
                  {...this.getColumnSearchProps('make')}
                  title={window.i18n.translate('Make')}
                  dataIndex="make"
                  key="make"
                />
                <Column
                  {...this.getColumnSearchProps('model')}
                  title={window.i18n.translate('Model')}
                  dataIndex="model"
                  key="model"
                />
                <Column
                  {...this.getColumnSearchProps('variant')}
                  title={window.i18n.translate('Variant')}
                  dataIndex="variant"
                  key="variant"
                />
                <Column
                  {...this.getColumnSearchProps('mileage')}
                  sorter={() => {}}
                  title={window.i18n.translate('Mileage')}
                  dataIndex="mileage"
                  key="mileage"
                />
                <Column
                  {...this.getColumnSearchProps('price')}
                  sorter={() => {}}
                  title={window.i18n.translate('CustomerAskingPrice')}
                  dataIndex="price"
                  key="price"
                />
                <Column
                  {...this.getColumnSearchProps('reserve')}
                  sorter={() => {}}
                  title={window.i18n.translate('ReservePrice')}
                  dataIndex="reserve"
                  key="reserve"
                />
                <Column
                  {...this.getColumnSearchProps('province')}
                  title={window.i18n.translate('Province')}
                  dataIndex="province"
                  key="province"
                />
                <Column
                  {...this.getColumnSearchProps('city')}
                  title={window.i18n.translate('City')}
                  dataIndex="city"
                  key="city"
                />
                <Column
                  {...this.getColumnSearchProps('userUsername')}
                  title={window.i18n.translate('Client')}
                  dataIndex="userUsername"
                  key="userUsername"
                />
                {/* <Column sorter={() => { }} title="Highest Bid" dataIndex="highestBid" key="highestBid" /> */}
                <Column
                  title={window.i18n.translate('Status')}
                  filters={[
                    {
                      text: window.i18n.translate('DealComplete'),
                      value: 'Deal Complete'
                    },
                    {
                      text: window.i18n.translate('OnAuction'),
                      value: 'On Auction'
                    },
                    {
                      text: window.i18n.translate('AdminOfferAccepted'),
                      value: 'Admin Offer Accepted'
                    },
                    {
                      text: window.i18n.translate('AdminOfferRejected'),
                      value: 'Admin Offer Rejected'
                    },
                    {
                      text: window.i18n.translate('DealerOfferAccepted'),
                      value: 'Dealer Offer Accepted'
                    },
                    {
                      text: window.i18n.translate('DealerOfferRejected'),
                      value: 'Dealer Offer Rejected'
                    },
                    {
                      text: window.i18n.translate('AwaitingOfferResponse'),
                      value: 'Awaiting Offer Response'
                    },
                    {
                      text: window.i18n.translate('WinningBidSent'),
                      value: 'Winning Bid Sent'
                    },
                    {
                      text: window.i18n.translate('Approved'),
                      value: 'Approved'
                    },
                    {
                      text: window.i18n.translate('Rejected'),
                      value: 'Rejected'
                    },
                    {
                      text: window.i18n.translate('AwaitingApproval'),
                      value: 'Awaiting Approval'
                    }
                  ]}
                  filteredValue={filteredInfo.statusText || null}
                  onFilter={(value, record) =>
                    record.statusText.includes(value)
                  }
                  dataIndex="statusText"
                  key="statusText"
                  render={(text, record) => (
                    <span
                      style={{
                        color: record.dealComplete
                          ? 'green'
                          : record.onAuction
                          ? 'green'
                          : record.adminOfferAccepted === true
                          ? 'green'
                          : record.adminOfferAccepted === false
                          ? 'red'
                          : record.offerAccepted === true
                          ? 'green'
                          : record.offerAccepted === false
                          ? 'red'
                          : record.sentAdminOffer === true
                          ? 'orange'
                          : record.sentWinningBid === true
                          ? 'orange'
                          : record.approved
                          ? 'green'
                          : record.approved === false
                          ? 'red'
                          : 'orange'
                      }}
                    >
                      {record.statusText}
                    </span>
                  )}
                />
                {/* <Column title="Time Left" /> */}
                <Column
                  title={window.i18n.translate('Action')}
                  render={(text, vehicle) => (
                    <span>
                      <a data={vehicle._id} onClick={this.handleSelectVehicle}>
                        {window.i18n.translate('Review')}
                      </a>
                      <Divider type="vertical" />
                      <a
                        disabled={
                          vehicle.offerAmount != undefined ||
                          vehicle.onAuction ||
                          vehicle.offerAccepted
                        }
                        data={JSON.stringify(vehicle)}
                        onClick={this.handleApprove}
                      >
                        {window.i18n.translate('Approve')}
                      </a>
                      <Divider type="vertical" />
                      <a
                        disabled={
                          vehicle.offerAmount != undefined ||
                          vehicle.onAuction ||
                          vehicle.offerAccepted
                        }
                        data={JSON.stringify(vehicle)}
                        onClick={this.handleReject}
                      >
                        {window.i18n.translate('Reject')}
                      </a>
                      <Divider type="vertical" />
                      <a
                        data={JSON.stringify(vehicle)}
                        onClick={this.handleDeleteVehicleShow}
                      >
                        {window.i18n.translate('Delete')}
                      </a>
                    </span>
                  )}
                />
              </StyledTable>
            </Col>
          </Row>
        </Spin>
        <Modal
          title={window.i18n.translate('DeleteVehicle')}
          visible={this.state.deleteVisible}
          onOk={this.handleDeleteVehicle}
          onCancel={this.cancelDelete}
          okText={window.i18n.translate('Accept')}
          cancelText={window.i18n.translate('Cancel')}
        >
          <span> {window.i18n.translate('AreYouSureDeleteVehicle')}</span>
        </Modal>
      </div>
    );
  }
}

export default Vehicles;
