import React from 'react';
import styled from 'styled-components';
import {
  Icon,
  Row,
  Col,
  Spin,
  Button,
  Modal,
  Tabs,
  Descriptions,
  Rate,
  Carousel,
  Input,
  Select,
  Comment,
  Form,
  List,
  Table
} from 'antd';
import moment from 'moment';
import Notify from 'src/components/meta/Notification';
import noImage from 'src/static/icons/carImageUpload/no-image-available.svg';
import Countdown from 'react-countdown-now';
import EditVehicle from 'src/components/meta/EditVehicle';
import Highlighter from 'react-highlight-words';
import prepDefinition from 'src/components/meta/PdfDefinition';
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
const numeral = require('numeral');
const { Column, ColumnGroup } = Table;
const { TabPane } = Tabs;
const { TextArea } = Input;
const Option = Select.Option;
const hammer = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6475 17.3418H1.65195C0.740018 17.3418 0 18.0817 0 18.9938V21.9977H14.2994V18.9938C14.2994 18.0817 13.5595 17.3418 12.6475 17.3418Z"
      fill="#AD5E00"
    />
    <path
      d="M21.7036 17.5744L11.6665 7.53725L13.6051 5.60203C13.9248 5.88391 14.4163 5.87013 14.7188 5.56766C15.0351 5.248 15.0351 4.7358 14.7188 4.41614L10.539 0.239743C10.2228 -0.0799143 9.70715 -0.0799143 9.39094 0.239743C9.07128 0.555998 9.07128 1.0716 9.39094 1.38781L9.35657 1.35344L4.04924 6.65728L4.08361 6.69505C3.76736 6.37539 3.25175 6.37539 2.93554 6.69505C2.61589 7.01131 2.61589 7.52691 2.93554 7.84317L7.11194 12.023C7.4316 12.3392 7.9472 12.3392 8.26346 12.023C8.57971 11.7033 8.57971 11.1912 8.26346 10.8715L8.29783 10.9058L10.2365 8.9706L20.2702 19.0043C20.6655 19.3996 21.3083 19.3996 21.7036 19.0043C22.0989 18.609 22.0989 17.9697 21.7036 17.5744Z"
      fill="#AD5E00"
    />
  </svg>
);

const CarouselStyled = styled(Carousel)`
  .slick-dots li button {
    background: black !important;
  }
`;
const DescriptionsStyle2 = styled(Descriptions)`
  .ant-descriptions-title {
    text-align: center;
  }
  .ant-descriptions-view {
    background: white;
  }
  &&.ant-descriptions-bordered .ant-descriptions-item-label,
  .ant-descriptions-bordered .ant-descriptions-item-content {
    padding: 5px 24px !important;
    border-right: 1px solid #e8e8e8;
  }
  &&.ant-descriptions-bordered .ant-descriptions-item-content {
    padding: 5px 24px !important;
    border-right: 1px solid #e8e8e8;
  }
`;

const StyledTable = styled(Table)`
  // .ant-table-thead > tr > th {
  //   text-align: center !important;

  // }
  .ant-table-thead > tr > th {
    line-height: 1em;
    font-size: 16px;
    color: grey;

    border-bottom: 1px solid#00878e34;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #e8e8e8;
    -webkit-transition: all 0.3s, border 0s;
    transition: all 0.3s, border 0s;
    font-weight: 700;
    font-size: 11px;
  }
`;

const CommentList = ({ notes }) => (
  <List
    dataSource={notes}
    header={`${notes.length} ${notes.length > 1 ? 'notes' : 'note'}`}
    itemLayout="horizontal"
    renderItem={props => <Comment {...props} />}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <div>
    <Form.Item>
      <TextArea
        rows={3}
        onChange={onChange}
        value={value}
        style={{ width: '70%' }}
      />
    </Form.Item>
    <Form.Item>
      <Button
        htmlType="submit"
        loading={submitting}
        onClick={onSubmit}
        type="primary"
      >
        Add Note
      </Button>
    </Form.Item>
  </div>
);
class VehicleDetails extends React.Component {
  handleSubmitNote = () => {
    if (!this.state.noteValue) {
      return;
    }

    this.setState({
      submitting: true
    });
    const note = {
      author: this.props.user.username,
      content: this.state.noteValue,
      datetime: moment().format('YYYY-MM-DD HH:mm:ss')
    };
    const id = this.props.vehicleDetailsId;
    this.props
      .updateNotes(id, note)
      .then(() => {
        this.props
          .findVehicleDetails(id)
          .then(() => {
            let vehicle = this.props.vehicleDetails;
            if (!vehicle.editHistory) {
              vehicle.editHistory = {};
            }
            this.setState({
              submitting: false,
              noteValue: '',
              vehicleDetails: vehicle
            });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };

  handleChangeNote = e => {
    this.setState({
      noteValue: e.target.value
    });
  };
  componentWillMount = () => {
    this.setState({ loading: true });
    const id = this.props.vehicleDetailsId;
    this.props
      .findVehicleDetails(id)
      .then(() => {
        let vehicle = this.props.vehicleDetails;
        if (!vehicle.editHistory) {
          vehicle.editHistory = {};
        }
        this.setState({
          vehicleDetails: vehicle
        });
        this.props
          .findYears()
          .then(() => {
            this.setState({ loading: false });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };

  componentWillUnmount = () => {
    this.props.setFromHistory(false);
  };

  constructor(props) {
    super(props);
    this.state = {
      vehicleDetails: {},
      loading: false,
      submitting: false,
      noteValue: '',
      rejectReason: '',
      offerAmount: 'R0',
      offerVisible: false,
      reserveVisible: false
    };
  }

  parseString = string => {
    return parseInt(string.split('R')[1].replace(/,/g, ''));
  };
  formatNumber = number => {
    return 'R' + numeral(number).format('0,0');
  };

  largeCarouselRef = React.createRef();
  carouselRef = React.createRef();
  largeImage = e => {
    const { vehicleDetails } = this.props;
    const index = e.currentTarget.getAttribute('data');
    Modal.info({
      centered: true,
      width: '60vw',
      height: '60vh',
      okText: 'Close',
      content: (
        <div style={{ position: 'relative', left: -18 }}>
          <div
            style={{
              display: 'flex',
              position: 'absolute',
              top: 0,
              height: '100%'
            }}
          >
            <Icon
              onClick={e => this.largeCarouselRef.current.prev()}
              style={{
                margin: 'auto',
                fontSize: 50,
                zIndex: 999999,
                cursor: 'pointer'
              }}
              type="caret-left"
            />
          </div>

          <CarouselStyled ref={this.largeCarouselRef}>
            <div style={{ display: 'flex' }}>
              <img
                alt="Main"
                style={{ margin: 'auto' }}
                src={
                  vehicleDetails.mainImage
                    ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                      vehicleDetails.mainImage
                    : noImage
                }
              />
            </div>

            <div style={{ display: 'flex' }}>
              <img
                alt="Front"
                style={{ margin: 'auto' }}
                src={
                  vehicleDetails.frontImage
                    ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                      vehicleDetails.frontImage
                    : noImage
                }
              />
            </div>

            <div style={{ display: 'flex' }}>
              <img
                alt="Left"
                style={{ margin: 'auto' }}
                src={
                  vehicleDetails.leftImage
                    ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                      vehicleDetails.leftImage
                    : noImage
                }
              />
            </div>

            <div style={{ display: 'flex' }}>
              <img
                alt="Right"
                style={{ margin: 'auto' }}
                src={
                  vehicleDetails.rightImage
                    ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                      vehicleDetails.rightImage
                    : noImage
                }
              />
            </div>

            <div style={{ display: 'flex' }}>
              <img
                alt="Back"
                style={{ margin: 'auto' }}
                src={
                  vehicleDetails.backImage
                    ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                      vehicleDetails.backImage
                    : noImage
                }
              />
            </div>

            <div style={{ display: 'flex' }}>
              <img
                alt="Interior"
                style={{ margin: 'auto' }}
                src={
                  vehicleDetails.interiorImage
                    ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                      vehicleDetails.interiorImage
                    : noImage
                }
              />
            </div>

            <div style={{ display: 'flex' }}>
              <img
                alt="Engine"
                style={{ margin: 'auto' }}
                src={
                  vehicleDetails.engineImage
                    ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                      vehicleDetails.engineImage
                    : noImage
                }
              />
            </div>

            <div style={{ display: 'flex' }}>
              <img
                alt="Boot"
                style={{ margin: 'auto' }}
                src={
                  vehicleDetails.bootImage
                    ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                      vehicleDetails.bootImage
                    : noImage
                }
              />
            </div>

            <div style={{ display: 'flex' }}>
              <img
                alt="Service1"
                style={{ margin: 'auto' }}
                src={
                  vehicleDetails.service1Image
                    ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                      vehicleDetails.service1Image
                    : noImage
                }
              />
            </div>

            <div style={{ display: 'flex' }}>
              <img
                alt="Service2"
                style={{ margin: 'auto' }}
                src={
                  vehicleDetails.service2Image
                    ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                      vehicleDetails.service2Image
                    : noImage
                }
              />
            </div>
          </CarouselStyled>

          <div
            style={{
              display: 'flex',
              position: 'absolute',
              top: 0,
              right: 0,
              height: '100%'
            }}
          >
            <Icon
              onClick={e => this.largeCarouselRef.current.next()}
              style={{
                margin: 'auto',
                fontSize: 50,
                zIndex: 999999,
                cursor: 'pointer'
              }}
              type="caret-right"
            />
          </div>
        </div>
        // <div style={{ display: 'flex', position: 'relative', left: -18 }}>
        //   <img alt="Expanded" style={{ margin: 'auto' }} src={image} />
        // </div>
      ),
      onOk() {}
    });
    setTimeout(() => {
      this.largeCarouselRef.current.goTo(index - 1, true);
    }, 100);
  };
  handleApprove = e => {
    this.setState({ loading: true });
    let data = this.state.vehicleDetails;
    this.props
      .approveVehicle(data._id)
      .then(() => {
        this.props
          .findVehicleDetails(data._id)
          .then(() => {
            this.setState({ loading: false });
            this.props.push('/admin/vehicles');
            Notify('success', 'Successfully approved vehicle');
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  handleReject = e => {
    let data = this.state.vehicleDetails;
    var that = this;
    Modal.confirm({
      centered: true,
      content: (
        <div>
          <h4>Please provide a rejection reason to the user.</h4>
          <TextArea
            rows={4}
            onChange={e => {
              this.setState({ rejectReason: e.currentTarget.value });
            }}
          />
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .rejectVehicle(data._id, that.state.rejectReason)
          .then(() => {
            that.props
              .findVehicleDetails(data._id)
              .then(() => {
                that.setState({ loading: false });
                that.props.push('/admin/vehicles');
                Notify('success', 'Successfully rejected vehicle');
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify('error', e);
          });
      },
      oncancel() {}
    });
  };
  checkIfNumber = e => {
    if (e.currentTarget.value.length > 12) {
      if (Number.isInteger(parseInt(e.currentTarget.value.substr(12)))) {
        this.setState({
          vehicleDetails: {
            ...this.state.vehicleDetails,
            owners: e.currentTarget.value.substr(12)
          }
        });
      } else {
        this.setState({
          vehicleDetails: {
            ...this.state.vehicleDetails,
            owners: "I don't know"
          }
        });
      }
    } else if (e.currentTarget.value === "I don't kno") {
      this.setState({
        vehicleDetails: {
          ...this.state.vehicleDetails,
          owners: ''
        }
      });
    } else if (Number.isInteger(parseInt(e.currentTarget.value))) {
      this.setState({
        vehicleDetails: {
          ...this.state.vehicleDetails,
          owners: e.currentTarget.value
        }
      });
    } else {
      this.setState({
        vehicleDetails: {
          ...this.state.vehicleDetails,
          owners: "I don't know"
        }
      });
    }
  };
  editVehicle = event => {
    this.setState(
      {
        loading: true,
        whereBought:
          this.state.vehicleDetails.whereBoughtSelection +
          ' - ' +
          this.state.vehicleDetails.whereBought,
        licenseExpire: this.state.vehicleDetails.licenseExpireDate
          ? this.state.vehicleDetails.licenseExpire +
            ' - ' +
            this.state.vehicleDetails.licenseExpireDate
          : this.state.vehicleDetails.licenseExpire
      },
      () => {
        let data = this.state.vehicleDetails;
        if (this.stageRef.current) {
          data.damage = this.stageRef.current.toDataURL();
        }
        data.pdf = true;
        this.props
          .saveVehicle(data)
          .then(vehicleId => {
            this.props
              .sendNotification(
                data.userId,
                [data.userId],
                window.i18n.translate('AdminMadeChangesToVehicle'),

                'adminChanged',
                data._id,
                true,
                'Verkoopnou Admin'
              )
              .then(() => {
                this.setState({ loading: false });
                Notify('success', 'Vehicle edited succesfully');
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      }
    );
  };
  handleOffer = e => {
    let data = this.state.vehicleDetails;
    var that = this;
    that.setState({ loading: true, offerVisible: false });
    that.props
      .makeAnOffer(data._id, that.state.offerAmount)
      .then(() => {
        that.props
          .findVehicleDetails(data._id)
          .then(() => {
            that.props
              .sendNotification(
                data.userId,
                [data.userId],
                window.i18n.translate('AdminOfferOnYourVehicles'),

                'adminChanged',
                data._id,
                true,
                'Verkoopnou Admin'
              )
              .then(() => {
                that.setState({ loading: false });
                Notify('success', 'Successfully made an offer for the vehicle');
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        that.setState({ loading: false });
        Notify('error', e);
      });
  };
  handleReserve = e => {
    let data = this.state.vehicleDetails;
    var that = this;
    that.setState({ loading: true, reserveVisible: false });
    that.props
      .setReserve(data._id, that.state.reserve)
      .then(() => {
        that.props
          .findVehicleDetails(data._id)
          .then(() => {
            that.setState({ loading: false });
            Notify(
              'success',
              'Successfully set a reserve price for the vehicle'
            );
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        that.setState({ loading: false });
        Notify('error', e);
      });
  };
  exportToPdf = () => {
    this.setState({ loading: true });
    const { vfs } = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;
    let data = Object.assign({}, this.state.vehicleDetails);
    prepDefinition(data).then(definition => {
      pdfMake.createPdf(definition).download('Vehicle_Details.pdf', () => {
        this.setState({ loading: false });
      });
    });
  };
  handleCompleteDeal = e => {
    this.setState({ loading: true });
    let data = this.state.vehicleDetails;
    data.highestBid = this.parseString(data.highestBid);
    this.props
      .saveVehicle(data)
      .then(vehicleId => {
        this.props
          .completeDeal(data._id)
          .then(() => {
            this.props.findVehicleDetails(data._id).then(() => {
              let vehicle = this.props.vehicleDetails;
              if (!vehicle.editHistory) {
                vehicle.editHistory = {};
              }
              this.setState({
                loading: false,
                vehicleDetails: vehicle
              });
            });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  handleChangeDealer = e => {
    this.setState({ loading: true });
    let data = this.state.vehicleDetails;
    this.props
      .changeDealer(data)
      .then(vehicleId => {
        this.props.findVehicleDetails(data._id).then(() => {
          let vehicle = this.props.vehicleDetails;
          if (!vehicle.editHistory) {
            vehicle.editHistory = {};
          }
          this.setState({
            loading: false,
            vehicleDetails: vehicle
          });
        });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  processDealerEdit = e => {
    this.setState({ loading: true });
    this.props
      .findAllDealers()
      .then(() => {
        this.setState({
          loading: false,
          dealerEdit: true
        });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  handleTabChange = e => {
    if (e === '7') {
      this.setState({ loading: true });
      this.props
        .findVehicleBidInfo(this.state.vehicleDetails._id)
        .then(() => {
          this.setState({ loading: false });
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify('error', e);
        });
    }
  };
  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let biddingInfo = this.props.biddingInfo;
    if (order === 'ascend') {
      biddingInfo.sort(
        (a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey])
      );
    } else if (order === 'descend') {
      biddingInfo.sort(
        (a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey])
      );
    }
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {window.i18n.translate('Search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          {window.i18n.translate('Reset')}
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  render() {
    const { vehicleDetails } = this.state;
    if (vehicleDetails.editHistory) {
      return (
        <div>
          <Row type="flex" justify="start" align="middle">
            <Col span={1}>
              <Icon
                style={{ fontSize: 30, color: '#00888e' }}
                onClick={e => this.props.goBack()}
                type="arrow-left"
              />
            </Col>
            <Col span={12}>
              <Icon
                style={{ fontSize: 36, color: '#00888E', marginRight: 20 }}
                type="car"
                theme="filled"
              />
              <span style={{ fontWeight: 750, fontSize: 36 }}>
                {window.i18n.translate('VehicleDetails')}
              </span>
            </Col>
            <Col
              style={{ display: 'flex', justifyContent: 'flex-end' }}
              span={12}
            >
              {!this.props.fromHistory ? (
                <span
                  style={{
                    position: 'absolute',
                    fontSize: 15,
                    top: 60,
                    right: 0,
                    color: vehicleDetails.onAuction
                      ? 'green'
                      : vehicleDetails.approved
                      ? 'green'
                      : vehicleDetails.approved === false
                      ? 'red'
                      : 'orange'
                  }}
                >
                  {vehicleDetails.onAuction
                    ? window.i18n.translate('OnAuction')
                    : vehicleDetails.approved
                    ? window.i18n.translate('Approved')
                    : vehicleDetails.approved === false
                    ? window.i18n.translate('Rejected')
                    : window.i18n.translate('AwaitingApproval')}
                </span>
              ) : null}
              {/* <Button
                disabled={
                  vehicleDetails.offerAmount != undefined ||
                  vehicleDetails.onAuction ||
                  vehicleDetails.offerAccepted
                }
                style={{ height: 46, width: 171, marginRight: 15 }}
                onClick={EditVehicle.editVehicle}
                type="primary"
              >
                Save Changes
              </Button> */}
              {!this.props.fromHistory ? (
                <Button
                  style={{ height: 46, width: 171, marginRight: 15 }}
                  onClick={e => this.setState({ reserveVisible: true })}
                >
                  {window.i18n.translate('SetReservePrice')}
                </Button>
              ) : null}
              {!this.props.fromHistory ? (
                <Button
                  disabled={
                    vehicleDetails.offerAmount != undefined ||
                    !vehicleDetails.approved ||
                    vehicleDetails.offerAccepted
                  }
                  style={{ height: 46, width: 171, marginRight: 15 }}
                  onClick={e => this.setState({ offerVisible: true })}
                >
                  {vehicleDetails.sentAdminOffer === true
                    ? vehicleDetails.adminOfferAccepted
                      ? window.i18n.translate('OfferAccepted')
                      : window.i18n.translate('AwaitingResponse')
                    : window.i18n.translate('MakeAnOffer')}
                </Button>
              ) : null}
              {!this.props.fromHistory ? (
                <Button
                  disabled={
                    vehicleDetails.offerAmount != undefined ||
                    vehicleDetails.onAuction ||
                    vehicleDetails.offerAccepted
                  }
                  style={{ height: 46, width: 171, marginRight: 15 }}
                  onClick={this.handleApprove}
                  type="primary"
                >
                  {window.i18n.translate('ApproveVehicle')}
                </Button>
              ) : null}
              {!this.props.fromHistory ? (
                <Button
                  disabled={
                    vehicleDetails.offerAmount != undefined ||
                    vehicleDetails.onAuction ||
                    vehicleDetails.offerAccepted
                  }
                  style={{ height: 46, width: 171, marginRight: 15 }}
                  onClick={this.handleReject}
                >
                  {window.i18n.translate('RejectVehicle')}
                </Button>
              ) : null}
              <Button
                onClick={this.exportToPdf}
                style={{ height: 46, width: 171, marginRight: 0 }}
                type="primary"
              >
                {window.i18n.translate('ExportToPDF')}
              </Button>
            </Col>
          </Row>
          <Spin spinning={this.state.loading}>
            <Row type="flex" justify="space-between" align="top">
              <Col span={24}>
                <Tabs onChange={this.handleTabChange}>
                  <TabPane
                    style={{ background: 'white' }}
                    tab={window.i18n.translate('General')}
                    key="1"
                  >
                    <Descriptions
                      bordered
                      column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                    >
                      <Descriptions.Item span={1} label="ID">
                        {vehicleDetails.displayId}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('ClientUsername')}
                      >
                        {vehicleDetails.userUsername}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('ClientName')}
                      >
                        {vehicleDetails.userName}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('ClientSurname')}
                      >
                        {vehicleDetails.userSurname}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('ClientNumber')}
                      >
                        {vehicleDetails.userNumber}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Condition')}
                      >
                        {vehicleDetails.condition === '' ? (
                          window.i18n.translate('NotProvided')
                        ) : (
                          <Rate
                            count={10}
                            defaultValue={parseInt(vehicleDetails.condition)}
                            disabled={true}
                          />
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Year')}
                      >
                        {vehicleDetails.year === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.year}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Vehicle')}
                      >
                        {vehicleDetails.make +
                          ' ' +
                          vehicleDetails.model +
                          ' ' +
                          vehicleDetails.variant}
                      </Descriptions.Item>
                      <Descriptions.Item span={1} label="KM">
                        {vehicleDetails.mileage === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.mileage}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('ServiceHistory')}
                      >
                        {vehicleDetails.serviceHistory === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.serviceHistory}
                      </Descriptions.Item>
                      <Descriptions.Item span={1} label="M&M Code">
                        {vehicleDetails.mmCode === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.mmCode}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('CustomerAskingPrice')}
                      >
                        {vehicleDetails.price === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.price}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('ReservePrice')}
                      >
                        {vehicleDetails.reserve === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.reserve}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('TradePrice')}
                      >
                        {vehicleDetails.valuation
                          ? 'R' +
                            numeral(
                              vehicleDetails.valuation['s:Envelope']['s:Body']
                                .GetConvergedDataRequestResponse.ConvergedData[
                                'd4p1:VehicleValueInfo'
                              ]['d4p1:VehicleValue']['d4p1:TradePrice']['_text']
                            ).format('0,0')
                          : 'R0'}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('RetailPrice')}
                      >
                        {vehicleDetails.valuation
                          ? 'R' +
                            numeral(
                              vehicleDetails.valuation['s:Envelope']['s:Body']
                                .GetConvergedDataRequestResponse.ConvergedData[
                                'd4p1:VehicleValueInfo'
                              ]['d4p1:VehicleValue']['d4p1:RetailPrice'][
                                '_text'
                              ]
                            ).format('0,0')
                          : 'R0'}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('NewPrice')}
                      >
                        {vehicleDetails.valuation
                          ? 'R' +
                            numeral(
                              vehicleDetails.valuation['s:Envelope']['s:Body']
                                .GetConvergedDataRequestResponse.ConvergedData[
                                'd4p1:VehicleValueInfo'
                              ]['d4p1:VehicleValue']['d4p1:NewListPrice'][
                                '_text'
                              ]
                            ).format('0,0')
                          : 'R0'}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Colour')}
                      >
                        {vehicleDetails.colour === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.colour}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Owners')}
                      >
                        {vehicleDetails.owners === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.owners}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Transmission')}
                      >
                        {vehicleDetails.transmission === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.transmission}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('FuelType')}
                      >
                        {vehicleDetails.fuel === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.fuel}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('City')}
                      >
                        {vehicleDetails.city === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.city}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Province')}
                      >
                        {vehicleDetails.province === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.province}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Warranty')}
                      >
                        {vehicleDetails.warranty === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.warranty}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('ServicePlan')}
                      >
                        {vehicleDetails.servicePlan === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.servicePlan}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('LicenseExpiry')}
                      >
                        {vehicleDetails.licenseExpire === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.licenseExpire}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('SellingReason')}
                      >
                        {vehicleDetails.sellingReason === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.sellingReason}
                      </Descriptions.Item>
                    </Descriptions>
                  </TabPane>
                  <TabPane
                    style={{ background: 'white' }}
                    tab={window.i18n.translate('Recon')}
                    key="2"
                  >
                    <Descriptions
                      bordered
                      column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                      layout="vertical"
                    >
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('SpareKeys')}
                      >
                        {vehicleDetails.spareKeys === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.spareKeys}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('MechanicalIssues')}
                      >
                        {vehicleDetails.mechanicalIssues === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.mechanicalIssues}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('PreviousBodyWork')}
                      >
                        {vehicleDetails.bodyWork === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.bodyWork}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('WindscreenCracks')}
                      >
                        {vehicleDetails.windscreenDamage === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.windscreenDamage}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('MagsRimsDamage')}
                      >
                        {vehicleDetails.magsDamage === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.magsDamage}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('FrontTyres')}
                      >
                        {vehicleDetails.frontTyres === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.frontTyres}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('BackTyres')}
                      >
                        {vehicleDetails.backTyres === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.backTyres}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        style={{ display: 'flex' }}
                        label="Damage"
                      >
                        <div
                          data={vehicleDetails.damage}
                          onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Left"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.damage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.damage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                    </Descriptions>
                  </TabPane>
                  <TabPane
                    style={{ background: 'white' }}
                    tab={window.i18n.translate('Images')}
                    key="3"
                  >
                    <Descriptions
                      bordered
                      column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                      layout="vertical"
                    >
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('MainView')}
                      >
                        <div
                          data={1}
                          onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Main"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.mainImage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.mainImage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('FrontView')}
                      >
                        <div
                          data={2}
                          onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Front"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.frontImage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.frontImage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('LeftView')}
                      >
                        <div
                          data={3}
                          onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Left"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.leftImage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.leftImage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('RightView')}
                      >
                        <div
                          data={4}
                          onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Right"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.rightImage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.rightImage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('BackView')}
                      >
                        <div
                          data={5}
                          onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Back"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.backImage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.backImage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Interior')}
                      >
                        <div
                          data={6}
                          onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Interior"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.interiorImage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.interiorImage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Engine')}
                      >
                        <div
                          data={7}
                          onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Engine"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.engineImage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.engineImage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Boot')}
                      >
                        <div
                          data={8}
                          onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Boot"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.bootImage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.bootImage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={
                          window.i18n.translate('ServiceBook') +
                          ' ' +
                          window.i18n.translate('Image') +
                          '1'
                        }
                      >
                        <div
                          data={9}
                          onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Service1"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.service1Image
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.service1Image
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={
                          window.i18n.translate('ServiceBook') +
                          ' ' +
                          window.i18n.translate('Image') +
                          '2'
                        }
                      >
                        <div
                          data={10}
                          onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Service2"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.service2Image
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.service2Image
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                    </Descriptions>
                  </TabPane>
                  <TabPane
                    style={{ background: 'white' }}
                    tab={window.i18n.translate('Notes')}
                    key="4"
                  >
                    <div style={{ padding: 10 }}>
                      {vehicleDetails.notes.length > 0 && (
                        <CommentList notes={vehicleDetails.notes} />
                      )}
                      <Comment
                        content={
                          <Editor
                            onChange={this.handleChangeNote}
                            onSubmit={this.handleSubmitNote}
                            submitting={this.state.submitting}
                            value={this.state.noteValue}
                          />
                        }
                      />
                    </div>
                  </TabPane>
                  {!this.props.fromHistory ? (
                    <TabPane
                      style={{ background: 'white' }}
                      tab={window.i18n.translate('Edit')}
                      key="5"
                    >
                      <EditVehicle
                        sendNotification={this.props.sendNotification}
                        editVehicle={vehicleDetails}
                        originalVehicle={vehicleDetails}
                        findMakes={this.props.findMakes}
                        makes={this.props.makes}
                        findModels={this.props.findModels}
                        models={this.props.models}
                        findVariants={this.props.findVariants}
                        variants={this.props.variants}
                        findYears={this.props.findYears}
                        cities={this.props.cities}
                        findCities={this.props.findCities}
                        years={this.props.years}
                        saveVehicle={this.props.saveVehicle}
                        user={this.props.user}
                      />
                    </TabPane>
                  ) : null}
                  {vehicleDetails.sentWinningBid ||
                  vehicleDetails.dealFailed ? (
                    <TabPane
                      style={{ background: 'white' }}
                      tab={window.i18n.translate('Deal')}
                      key="6"
                    >
                      <Descriptions
                        bordered
                        column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                      >
                        <Descriptions.Item span={1} label="ID">
                          {vehicleDetails.displayId}
                        </Descriptions.Item>
                        <Descriptions.Item
                          span={1}
                          label={window.i18n.translate('ClientUsername')}
                        >
                          {vehicleDetails.userUsername}
                        </Descriptions.Item>
                        <Descriptions.Item
                          span={1}
                          label={window.i18n.translate('ClientName')}
                        >
                          {vehicleDetails.userName}
                        </Descriptions.Item>
                        <Descriptions.Item
                          span={1}
                          label={window.i18n.translate('ClientSurname')}
                        >
                          {vehicleDetails.userSurname}
                        </Descriptions.Item>
                        <Descriptions.Item
                          span={1}
                          label={window.i18n.translate('ClientNumber')}
                        >
                          {vehicleDetails.userNumber}
                        </Descriptions.Item>
                        <Descriptions.Item
                          span={1}
                          label={window.i18n.translate('DealerUsername')}
                        >
                          <div>
                            <Col span={14}>
                              {this.state.dealerEdit ? (
                                <Select
                                  showSearch
                                  value={vehicleDetails.dealerUsername}
                                  style={{ width: '100%' }}
                                  placeholder={
                                    window.i18n.translate('Select') +
                                    ' ' +
                                    window.i18n.translate('Dealer')
                                  }
                                  optionFilterProp="children"
                                  onChange={e => {
                                    let vehicle = vehicleDetails;
                                    vehicle.editHistory = {
                                      ...vehicleDetails.editHistory,
                                      dealerUsername: {
                                        user: this.props.user.username,
                                        timeStamp: moment().format(
                                          'YYYY/MM/DD HH:mm:ss'
                                        )
                                      }
                                    };
                                    vehicle.dealerUsername = e;
                                    this.setState({
                                      vehicleDetails: {
                                        ...this.state.vehicleDetails,
                                        ...vehicle
                                      }
                                    });
                                  }}
                                >
                                  {this.props.dealers.map(dealer => {
                                    return (
                                      <Option
                                        key={dealer.username}
                                        value={dealer.username}
                                      >
                                        {dealer.username}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              ) : (
                                <span>{vehicleDetails.dealerUsername}</span>
                              )}
                            </Col>
                            <Col style={{ display: 'flex' }} span={4}>
                              <Button
                                onClick={this.processDealerEdit}
                                style={{ margin: 'auto' }}
                              >
                                Edit
                              </Button>
                            </Col>
                            <Col style={{ display: 'flex' }} span={4}>
                              <Button
                                onClick={this.handleChangeDealer}
                                style={{ margin: 'auto' }}
                                type="primary"
                              >
                                Save
                              </Button>
                            </Col>
                            <Col span={23}>
                              {vehicleDetails.editHistory.dealerUsername
                                ? window.i18n.translate('EditHistory') +
                                  ' :' +
                                  vehicleDetails.editHistory.dealerUsername
                                    .user +
                                  ' - ' +
                                  vehicleDetails.editHistory.dealerUsername
                                    .timeStamp
                                : ''}
                            </Col>
                          </div>

                          {/* {vehicleDetails.dealerUsername} */}
                        </Descriptions.Item>
                        <Descriptions.Item
                          span={1}
                          label={window.i18n.translate('DealerName')}
                        >
                          {vehicleDetails.dealerName}
                        </Descriptions.Item>
                        <Descriptions.Item
                          span={1}
                          label={window.i18n.translate('DealerSurname')}
                        >
                          {vehicleDetails.dealerSurname}
                        </Descriptions.Item>
                        <Descriptions.Item
                          span={1}
                          label={window.i18n.translate('DealerNumber')}
                        >
                          {vehicleDetails.dealerNumber}
                        </Descriptions.Item>
                        <Descriptions.Item
                          span={1}
                          label={window.i18n.translate('DealPrice')}
                        >
                          <div>
                            <Col span={14}>
                              <Input
                                style={{ zIndex: 9 }}
                                disabled={
                                  !this.state.highestBidEdit ? true : false
                                }
                                placeholder={window.i18n.translate('DealPrice')}
                                value={this.formatNumber(
                                  vehicleDetails.highestBid
                                )}
                                onChange={e => {
                                  let vehicle = vehicleDetails;
                                  vehicle.editHistory = {
                                    ...vehicleDetails.editHistory,
                                    highestBid: {
                                      user: this.props.user.username,
                                      timeStamp: moment().format(
                                        'YYYY/MM/DD HH:mm:ss'
                                      )
                                    }
                                  };
                                  vehicle.highestBid = this.formatNumber(
                                    e.currentTarget.value
                                  );
                                  this.setState({
                                    vehicleDetails: {
                                      ...this.state.vehicleDetails,
                                      ...vehicle
                                    }
                                  });
                                }}
                              />
                            </Col>
                            <Col style={{ display: 'flex' }} span={4}>
                              <Button
                                onClick={e =>
                                  this.setState({ highestBidEdit: true })
                                }
                                style={{ margin: 'auto' }}
                              >
                                Edit
                              </Button>
                            </Col>
                            <Col style={{ display: 'flex' }} span={4}>
                              <Button
                                onClick={this.handleCompleteDeal}
                                style={{ margin: 'auto' }}
                                type="primary"
                              >
                                {window.i18n.translate('CompleteDeal')}
                              </Button>
                            </Col>
                            <Col span={23}>
                              {vehicleDetails.editHistory.highestBid
                                ? window.i18n.translate('EditHistory') +
                                  ' :' +
                                  vehicleDetails.editHistory.highestBid.user +
                                  ' - ' +
                                  vehicleDetails.editHistory.highestBid
                                    .timeStamp
                                : ''}
                            </Col>
                          </div>
                          {/* {this.formatNumber(vehicleDetails.highestBid)} */}
                        </Descriptions.Item>
                      </Descriptions>
                    </TabPane>
                  ) : null}
                  <TabPane
                    style={{ background: 'white' }}
                    tab={window.i18n.translate('Bids')}
                    key="7"
                  >
                    <Row type="flex" justify="start" align="middle">
                      <Col span={24}>
                        <StyledTable
                          style={{ background: 'white' }}
                          rowKey="username"
                          size="middle"
                          dataSource={this.props.biddingInfo}
                          onChange={this.handleSort}
                        >
                          <Column
                            {...this.getColumnSearchProps('userName')}
                            title={window.i18n.translate('Username')}
                            dataIndex="userName"
                            key="userName"
                          />
                          <Column
                            sorter={() => {}}
                            title={window.i18n.translate('BidAmount')}
                            dataIndex="amount"
                            key="amount"
                            render={(text, record) => {
                              return <span>{this.formatNumber(text)}</span>;
                            }}
                          />
                        </StyledTable>
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </Spin>
          <Modal
            title={window.i18n.translate('MakeAnOffer')}
            visible={this.state.offerVisible}
            onOk={this.handleOffer}
            onCancel={e => this.setState({ offerVisible: false })}
            okText="Accept"
            cancelText="Cancel"
          >
            <div>
              <h4>{window.i18n.translate('AmountLikeToOffer')}</h4>
              <Input
                value={this.state.offerAmount}
                onChange={e =>
                  this.setState({
                    offerAmount:
                      'R' + numeral(e.currentTarget.value).format('0,0')
                  })
                }
              />
            </div>
          </Modal>
          <Modal
            title={window.i18n.translate('SetReservePrice')}
            visible={this.state.reserveVisible}
            onOk={this.handleReserve}
            onCancel={e => this.setState({ reserveVisible: false })}
            okText="Accept"
            cancelText="Cancel"
          >
            <div>
              <h4>{window.i18n.translate('AmountLikeToReserve')}</h4>
              <Input
                value={this.state.reserve}
                onChange={e =>
                  this.setState({
                    reserve: 'R' + numeral(e.currentTarget.value).format('0,0')
                  })
                }
              />
            </div>
          </Modal>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default VehicleDetails;
