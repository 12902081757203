export function collapseSidebar() {
  return dispatch =>
    new Promise(resolve =>
      resolve(
        dispatch({
          type: "SIDEBAR_COLLAPSE",
        })
      )
    )
}
