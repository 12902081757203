import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  findDealDoneVehicles,
  findAuctionWonVehicles,
  findAuctionLostVehicles,
  setVehicleDetailsId
} from 'src/redux/actions/vehicles';
import { findBidData } from 'src/redux/actions/bidding';
import {
  getLocale,
  getDealDoneVehicles,
  getAuctionWonVehicles,
  getAuctionLostVehicles,
  getUser,
  getBidData
} from 'src/redux/selectors';

class History extends Component {
  render = () => {
    const {
      Layout,
      currentLocale,
      dealDoneVehicles,
      wonVehicles,
      lostVehicles,
      user,
      findDealDoneVehicles,
      findAuctionWonVehicles,
      findAuctionLostVehicles,
      setVehicleDetailsId,
      findBidData,
      bidData,
      push
    } = this.props;
    return (
      <Layout
        currentLocale={currentLocale}
        dealDoneVehicles={dealDoneVehicles}
        wonVehicles={wonVehicles}
        lostVehicles={lostVehicles}
        user={user}
        findDealDoneVehicles={findDealDoneVehicles}
        findAuctionWonVehicles={findAuctionWonVehicles}
        findAuctionLostVehicles={findAuctionLostVehicles}
        setVehicleDetailsId={setVehicleDetailsId}
        findBidData={findBidData}
        bidData={bidData}
        push={push}
      />
    );
  };
}

const mapStateToProps = state => ({
  currentLocale: getLocale(state) || {},
  dealDoneVehicles: getDealDoneVehicles(state) || [],
  wonVehicles: getAuctionWonVehicles(state) || [],
  lostVehicles: getAuctionLostVehicles(state) || [],
  bidData: getBidData(state) || {},
  user: getUser(state) || {}
});

const mapDispatchToProps = {
  getLocale,
  findDealDoneVehicles,
  findAuctionWonVehicles,
  findAuctionLostVehicles,
  setVehicleDetailsId,
  findBidData,
  push
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(History);
