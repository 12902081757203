import { Cookies } from 'react-cookie';
export const initialState = {
  user: undefined,
  bidData: {}
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case 'USER_LOGIN': {
      if (action.data) {
        const cookies = new Cookies();
        cookies.set('token', action.data.token, { path: '/' });
        // cookies.set("remember", action.data.remember, { path: "/" })
        localStorage.isLoggedIn = true;
        // localStorage.token = action.data.token;
        // localStorage.basic = action.data.basic;
        const user = action.data.user;
        /*eslint-disable */
        window.pubnub = new PubNub({
          publishKey: 'pub-c-6a0c1b45-c15b-4e6b-b985-37f40dc921b2',
          subscribeKey: 'sub-c-9f54222a-ac64-11e9-9c97-16f61f10341e'
        });
        /*eslint-enable */
        window.pubnub.setUUID(action.data.user._id);
        let channels = [action.data.user._id, 'global'];
        if (action.data.user.type === 'admin') {
          channels.push('admin');
          channels.push('dealers');
        }
        if (action.data.user.type === 'dealer') {
          channels.push('dealers');
        }
        window.pubnub.subscribe({
          channels: channels
        });
        if (window.cordova) {
          window.FirebasePlugin.getToken(
            token => {
              window.pubnub.push.addChannels(
                {
                  channels: channels,
                  device: token,
                  pushGateway: 'gcm' // apns, gcm, mpns
                },
                function(status) {
                  if (status.error) {
                    console.log('operation failed w/ error:', status);
                  }
                }
              );
            },
            error => {
              console.error(error);
            }
          );
          window.FirebasePlugin.onNotificationOpen(
            notif => {
              notif.message = JSON.parse(notif.message);
              // this.props.setPubnub(notif)
            },
            error => {
              console.error(error);
            }
          );
        } else {
          window.pubnub.addListener({
            message: msg => {
              // this.props.setPubnub(msg)
            }
          });
        }
        return {
          ...state,
          user
        };
      }
      return initialState;
    }
    case 'USER_UPDATE': {
      if (action.data) {
        const user = action.data.user;
        return {
          ...state,
          user
        };
      }
      return initialState;
    }
    case 'USER_DETAILS_UPDATE': {
      const cookies = new Cookies();
      if (action.data && typeof cookies.get('token') !== 'undefined') {
        var userDetails = { ...action.data };
        return {
          ...state,
          loading: false,
          error: null,
          userDetails
        };
      }
      return initialState;
    }
    case 'BIDDING_FIND_BIDDATA': {
      if (action.data) {
        const bidData = action.data.bidData;
        return {
          ...state,
          bidData: bidData
        };
      }
      return initialState;
    }
    default:
      return state;
  }
}
