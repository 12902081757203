import React from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import logo from 'src/static/logo.svg';
import {
  Checkbox,
  Form,
  Icon,
  Select,
  Row,
  Col,
  Spin,
  Input,
  Button,
  Tabs,
  Card
} from 'antd';
import { ButtonRadius } from 'src/components/elements/Button';
import Notify from 'src/components/meta/Notification';
import Header from 'src/components/layout/Header';
const { TabPane } = Tabs;
const FormItem = Form.Item;
const Inputs = styled(Input)`
  .ant-input:focus {
    border-bottom: 2px solid rgba(0, 136, 142, 0.2);
  }
`;
const FacebookButton = styled(Button)`
  height: 45px;
  border-radius: 5px;
  border: 0;
  color: white;
  background: #4267b2;
  span {
    font-size: 15px;
    position: relative;
    bottom: 3px;
  }
`;
class SignIn extends React.Component {
  static defaultProps = {
    error: null,
    member: {}
  };
  handleSubmitAdmin = event => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.signIn(values);
      }
    });
  };

  signIn = values => {
    this.setState({ loading: true });
    this.props
      .signIn(values, 'admin')
      .then(() => {
        this.setState({ loading: false });
        this.props.push('/admin/users');
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', window.i18n.translate('Notify3'), e);
      });
  };

  toggleShowPass = () => {
    const { state } = this;
    this.state.passShow === 'password'
      ? this.setState({ passShow: 'text' })
      : this.setState({ passShow: 'password' });
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      passShow: 'password'
    };
  }

  handleLocaleSwitch = locale => {
    this.setState({ loading: true });
    this.props
      .changeLocale(locale)
      .then(() => {
        this.props.form.resetFields();
        this.setState({ loading: false });
        // location.reload();
      })
      .catch(e => {
        console.log(e);
      });
  };

  render() {
    const { loading } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      // <Section className="panel" {...this.props} >

      <div
        style={{
          height: '100vh',
          background:
            'linear-gradient(157deg, rgba(12,132,138,1) 7%, rgba(6,182,190,1) 44%, rgba(14,183,191,1) 52%, rgba(2,139,145,1) 100%)',
          display: 'flex'
        }}
      >
        {' '}
        <Card
          style={{
            border: 'none',
            background: 'white',
            display: 'flex',
            margin: 'auto',
            borderRadius: '1em'
          }}
        >
          <Row
            style={{ margin: 'auto' }}
            type="flex"
            justify="center"
            align="middle"
          >
            {' '}
            <img src={logo} style={{ width: '10em' }} />
            <Col span={24}>
              <h1
                style={{
                  fontSize: '50px',
                  color: '#808285',
                  textAlign: 'center'
                }}
              >
                <span style={{ color: '#00888E' }}> Admin </span>
                {window.i18n.translate('SignIn')}
              </h1>
              <Spin spinning={this.state.loading}>
                <Form onSubmit={this.handleSubmitAdmin}>
                  <FormItem
                    label={
                      <span style={{ color: '#00B4BC', fontSize: 20 }}>
                        {window.i18n.translate('Username')}
                      </span>
                    }
                  >
                    {getFieldDecorator('username', {
                      rules: [
                        {
                          required: true,
                          message: window.i18n.translate('EmailRequired')
                        }
                      ]
                    })(
                      <Inputs
                        style={{
                          width: '100%',
                          border: 0,
                          borderRadius: 0,
                          borderBottom: '1px solid rgba(189, 178, 178, 0.43)'
                        }}
                      />
                    )}
                  </FormItem>
                  <FormItem
                    label={
                      <span style={{ color: '#00B4BC', fontSize: 20 }}>
                        {window.i18n.translate('Password')}
                      </span>
                    }
                  >
                    {getFieldDecorator('password', {
                      rules: [
                        {
                          required: true,
                          message: window.i18n.translate('PasswordRequired')
                        }
                      ]
                    })(
                      <Inputs
                        type="password"
                        style={{
                          width: '100%',
                          border: 0,
                          borderRadius: 0,
                          borderBottom: '1px solid rgba(189, 178, 178, 0.43)'
                        }}
                      />
                    )}
                  </FormItem>
                  <FormItem>
                    <ButtonRadius
                      style={{ height: 80, fontSize: 30 }}
                      className="submit-button"
                      type="primary"
                      htmlType="submit"
                      size="large"
                      block
                    >
                      {window.i18n.translate('SignIn')}
                    </ButtonRadius>
                  </FormItem>
                </Form>
              </Spin>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

const WrappedSignIn = Form.create()(SignIn);

export default WrappedSignIn;
