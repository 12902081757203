import React from 'react';
import styled from 'styled-components';
import {
  Icon,
  Row,
  Col,
  Spin,
  Button,
  List,
  Tabs,
  Popover,
  Input,
  Table,
  Modal
} from 'antd';
import moment from 'moment';
import Notify from 'src/components/meta/Notification';
import noImage from 'src/static/icons/carImageUpload/no-image-available.svg';
import Countdown from 'react-countdown-now';
const { Column, ColumnGroup } = Table;
const numeral = require('numeral');
const { TabPane } = Tabs;
const hammer = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6475 17.3418H1.65195C0.740018 17.3418 0 18.0817 0 18.9938V21.9977H14.2994V18.9938C14.2994 18.0817 13.5595 17.3418 12.6475 17.3418Z"
      fill="#AD5E00"
    />
    <path
      d="M21.7036 17.5744L11.6665 7.53725L13.6051 5.60203C13.9248 5.88391 14.4163 5.87013 14.7188 5.56766C15.0351 5.248 15.0351 4.7358 14.7188 4.41614L10.539 0.239743C10.2228 -0.0799143 9.70715 -0.0799143 9.39094 0.239743C9.07128 0.555998 9.07128 1.0716 9.39094 1.38781L9.35657 1.35344L4.04924 6.65728L4.08361 6.69505C3.76736 6.37539 3.25175 6.37539 2.93554 6.69505C2.61589 7.01131 2.61589 7.52691 2.93554 7.84317L7.11194 12.023C7.4316 12.3392 7.9472 12.3392 8.26346 12.023C8.57971 11.7033 8.57971 11.1912 8.26346 10.8715L8.29783 10.9058L10.2365 8.9706L20.2702 19.0043C20.6655 19.3996 21.3083 19.3996 21.7036 19.0043C22.0989 18.609 22.0989 17.9697 21.7036 17.5744Z"
      fill="#AD5E00"
    />
  </svg>
);

const StyledListItem = styled(List.Item)``;

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    text-align: center !important;
    line-height: 1em;
    font-size: 16px;
    color: grey;

    border-bottom: 1px solid#00878e34;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #e8e8e8;
    -webkit-transition: all 0.3s, border 0s;
    transition: all 0.3s, border 0s;
    font-weight: 700;
    font-size: 11px;
  }

  .ant-table-placeholder {
    display: none !important;
  }
`;

const StyledDiv = styled.div`
  margin: auto;
  &&span {
    display: flex !important;
  }
`;
class AuctionVehicles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      basketVehicles: []
    };
  }
  componentWillMount = () => {
    this.setState({ loading: true });
    this.props
      .findAuctionVehicles()
      .then(() => {
        this.props
          .findBidData()
          .then(() => {
            let vehicleBidState = {};
            this.props.vehicles.forEach(vehicle => {
              let closed = false;
              if (
                vehicle.auctionDate.start !== null ||
                vehicle.auctionDate.end !== null
              ) {
                if (
                  parseInt(moment().format('YYYYMMDD')) <
                    vehicle.auctionDate.start ||
                  parseInt(moment().format('YYYYMMDD')) >
                    vehicle.auctionDate.end
                ) {
                  closed = true;
                }
              }
              vehicleBidState[vehicle._id] = {
                bidAmount: vehicle.reserve ? vehicle.reserve : 'R0',
                autoBidAmount: 'R0',
                customBidAmount: undefined,
                customBidLow: true,
                yourBidAmount: 'R0',
                disableReduce: true,
                bidLow: true,
                autoBidLow: true,
                autoBidActive: false,
                previousBid: 'R0',
                previousAutoBid: 'R0',
                closed: closed,
                nextAuction: undefined,
                auctionStart: undefined,
                bidVisible: false,
                autoBidVisible: false,
                autoBidVisibleMobile: false
              };
            });
            const yourBidData = this.props.bidData.bids;
            for (var prop in yourBidData) {
              if (vehicleBidState[prop]) {
                vehicleBidState[prop].yourBidAmount = this.formatNumber(
                  yourBidData[prop]
                );
                vehicleBidState[prop].autoBidAmount = this.formatNumber(
                  yourBidData[prop]
                );
                vehicleBidState[prop].previousBid = this.formatNumber(
                  yourBidData[prop]
                );
                vehicleBidState[prop].previousAutoBid = this.formatNumber(
                  yourBidData[prop]
                );
              }
            }
            this.setState({ bidData: vehicleBidState }, () => {
              this.props
                .updateBidsList({ vehicles: this.props.vehicleIds })
                .then(() => {
                  this.setState({ timeStamp: this.props.timeStamp });
                  let biddingData = this.state.bidData;
                  this.props.vehicles.forEach(vehicle => {
                    if (biddingData) {
                      if (biddingData[vehicle._id]) {
                        if (vehicle.reserve) {
                          if (
                            this.parseString(vehicle.reserve) <=
                            this.parseString(
                              biddingData[vehicle._id].bidAmount
                            ) +
                              1000
                          ) {
                            {
                              biddingData[vehicle._id].bidAmount =
                                'R' +
                                (this.parseString(vehicle.reserve) + 1000);
                              biddingData[vehicle._id].autoBidAmount =
                                'R' +
                                (this.parseString(vehicle.reserve) + 1000);
                              biddingData[vehicle._id].bidLow = false;
                            }
                          }
                        }
                        if (
                          this.parseString(
                            biddingData[vehicle._id].bidAmount
                          ) <=
                          vehicle.highestBid + 1000
                        ) {
                          biddingData[vehicle._id].bidAmount =
                            'R' + (vehicle.highestBid + 1000);
                          biddingData[vehicle._id].autoBidAmount =
                            'R' + (vehicle.highestBid + 1000);
                          biddingData[vehicle._id].bidLow = false;
                        }
                        const timeLeft = this.timeStampTime(vehicle).valueOf();
                        const currentTime = moment(
                          this.props.timeStamp
                        ).valueOf();
                        const timeDifference =
                          (timeLeft - currentTime) / 60 / 1000;
                        if (timeDifference <= 0) {
                          biddingData[vehicle._id].closed = true;
                        }
                        if (vehicle.auctionDate.end !== null) {
                          if (
                            moment('' + vehicle.auctionDate.end).isAfter(
                              moment(this.props.timeStamp)
                            ) &&
                            biddingData[vehicle._id].closed
                          ) {
                            biddingData[vehicle._id].nextAuction = moment(
                              '' + vehicle.auctionDate.end
                            ).format('YYYY-MM-DD');
                            biddingData[vehicle._id].closed = true;
                          }
                        }
                        if (vehicle.auctionDate.start !== null) {
                          if (
                            moment('' + vehicle.auctionDate.start).isAfter(
                              moment(this.props.timeStamp)
                            )
                          ) {
                            biddingData[vehicle._id].auctionStart = moment(
                              '' + vehicle.auctionDate.start
                            ).format('YYYY-MM-DD');
                            biddingData[vehicle._id].closed = true;
                          }
                        }
                      }
                    }
                  });
                  if (biddingData) {
                    this.setState({
                      bidData: { ...this.state.bidData, ...biddingData }
                    });
                  }
                  this.setState({ loading: false });
                })
                .catch(e => {
                  this.setState({ loading: false });
                  Notify('error', e);
                });
            });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  updateBidSignal;
  updateTimeInterval;
  componentDidMount = () => {
    this.updateBidSignal = {
      signal: msg => {
        if (msg.message === 'updatebid') {
          this.updateBids();
        } else if (msg.message === 'refreshvehicles') {
          this.refreshVehicles();
        }
      }
    };
    window.pubnub.addListener(this.updateBidSignal);
    this.updateTimeInterval = setInterval(() => {
      this.setState({
        timeStamp: moment(this.state.timeStamp)
          .add(1, 'second')
          .valueOf()
      });
    }, 1000);
  };
  updateBids = () => {
    clearInterval(this.updateTimeInterval);
    this.props
      .updateBidsList({ vehicles: this.props.vehicleIds })
      .then(() => {
        let biddingData = this.state.bidData;
        this.setState({ timeStamp: this.props.timeStamp }, () => {
          this.updateTimeInterval = setInterval(() => {
            this.setState({
              timeStamp: moment(this.state.timeStamp)
                .add(1, 'second')
                .valueOf()
            });
          }, 1000);
        });
        this.props.vehicles.forEach(vehicle => {
          if (biddingData) {
            if (biddingData[vehicle._id]) {
              if (
                this.parseString(biddingData[vehicle._id].bidAmount) <=
                vehicle.highestBid + 1000
              ) {
                biddingData[vehicle._id].bidAmount =
                  'R' + (vehicle.highestBid + 1000);
                biddingData[vehicle._id].bidLow = false;
              }
              const timeLeft = this.timeStampTime(vehicle).valueOf();
              const currentTime = moment(this.state.timeStamp).valueOf();
              const timeDifference = (timeLeft - currentTime) / 60 / 1000;
              if (timeDifference <= 0) {
                biddingData[vehicle._id].closed = true;
              }
            }
          }
          if (this.state.bidData[vehicle._id].autoBidActive) {
            let data = this.state.bidData[vehicle._id];
            if (
              (this.props.customTime && !this.props.customTime.status) ||
              this.state.bidData[vehicle._id].closed ||
              this.parseString(data.autoBidAmount) <=
                this.parseString(data.yourBidAmount)
            ) {
              data.autoBidLow = true;
              data.autoBidActive = false;
              this.setState({ bidData: { ...this.state.bidData, ...data } });
            } else if (
              vehicle.highestBid > this.parseString(data.yourBidAmount)
            ) {
              const id = this.props.vehicleDetailsId;
              const bidAmount = vehicle.highestBid + 1000;
              if (
                this.parseString(data.yourBidAmount) > bidAmount ||
                this.parseString(data.autoBidAmount) < bidAmount
              ) {
                data.autoBidLow = true;
                data.autoBidActive = false;
                this.setState({
                  bidData: { ...this.state.bidData, ...data }
                });
              } else {
                const timeLeft = this.timeStampTime(vehicle).valueOf();
                const currentTime = moment(this.state.timeStamp).valueOf();
                const timeDifference = (timeLeft - currentTime) / 60 / 1000;
                if (timeDifference > 0 && timeDifference < 5) {
                  vehicle.addAuctionMinutes =
                    vehicle.addAuctionMinutes + (5 - timeDifference);
                }
                data.yourBidAmount = this.formatNumber(bidAmount);
                this.setState(
                  {
                    bidData: { ...this.state.bidData, ...data }
                  },
                  () => {
                    this.props
                      .placeBid({
                        id: vehicle._id,
                        bidAmount: bidAmount,
                        addAuctionMinutes: vehicle.addAuctionMinutes
                      })
                      .then(r => {})
                      .catch(e => {
                        data.yourBidAmount = this.formatNumber(
                          bidAmount - 1000
                        );
                        this.setState({
                          bidData: { ...this.state.bidData, ...data }
                        });
                      });
                  }
                );
              }
            }
          }
        });
        if (biddingData) {
          this.setState({
            bidData: { ...this.state.bidData, ...biddingData }
          });
        }
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  refreshVehicles = () => {
    this.setState({ bidData: undefined });
    this.props
      .findAuctionVehicles()
      .then(() => {
        this.props
          .findBidData()
          .then(() => {
            let vehicleBidState = {};
            this.props.vehicles.forEach(vehicle => {
              let closed = false;
              if (
                vehicle.auctionDate.start !== null ||
                vehicle.auctionDate.end !== null
              ) {
                if (
                  parseInt(moment().format('YYYYMMDD')) <
                    vehicle.auctionDate.start ||
                  parseInt(moment().format('YYYYMMDD')) >
                    vehicle.auctionDate.end
                ) {
                  closed = true;
                }
              }
              vehicleBidState[vehicle._id] = {
                bidAmount: 'R0',
                autoBidAmount: 'R0',
                customBidAmount: undefined,
                customBidLow: true,
                yourBidAmount: 'R0',
                disableReduce: true,
                bidLow: true,
                autoBidLow: true,
                autoBidActive: false,
                previousBid: 'R0',
                previousAutoBid: 'R0',
                bidVisible: false,
                autoBidVisibleMobile: false,
                closed: closed
              };
            });
            const yourBidData = this.props.bidData.bids;
            for (var prop in yourBidData) {
              if (vehicleBidState[prop]) {
                vehicleBidState[prop].yourBidAmount = this.formatNumber(
                  yourBidData[prop]
                );
                vehicleBidState[prop].autoBidAmount = this.formatNumber(
                  yourBidData[prop]
                );
                vehicleBidState[prop].previousBid = this.formatNumber(
                  yourBidData[prop]
                );
                vehicleBidState[prop].previousAutoBid = this.formatNumber(
                  yourBidData[prop]
                );
              }
            }
            this.setState({ bidData: vehicleBidState }, () => {
              this.props
                .updateBidsList({ vehicles: this.props.vehicleIds })
                .then(() => {
                  this.setState({ timeStamp: this.props.timeStamp });
                  let biddingData = this.state.bidData;
                  this.props.vehicles.forEach(vehicle => {
                    if (biddingData) {
                      if (biddingData[vehicle._id]) {
                        if (
                          this.parseString(
                            biddingData[vehicle._id].bidAmount
                          ) <=
                          vehicle.highestBid + 1000
                        ) {
                          biddingData[vehicle._id].bidAmount =
                            'R' + (vehicle.highestBid + 1000);
                          biddingData[vehicle._id].bidLow = false;
                        }
                        const timeLeft = this.timeStampTime(vehicle).valueOf();
                        const currentTime = moment(
                          this.props.timeStamp
                        ).valueOf();
                        const timeDifference =
                          (timeLeft - currentTime) / 60 / 1000;
                        if (timeDifference <= 0) {
                          biddingData[vehicle._id].closed = true;
                        }
                      }
                    }
                  });
                  if (biddingData) {
                    this.setState({
                      bidData: { ...this.state.bidData, ...biddingData }
                    });
                  }
                  this.setState({ loading: false });
                })
                .catch(e => {
                  this.setState({ loading: false });
                  Notify('error', e);
                });
            });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  componentWillUnmount = () => {
    window.pubnub.removeListener(this.updateBidSignal);
    clearInterval(this.updateTimeInterval);
  };

  parseString = string => {
    return parseInt(string.split('R')[1].replace(/,/g, ''));
  };
  formatNumber = number => {
    return 'R' + numeral(number).format('0,0');
  };
  handleBid = vehicle => {
    this.setState({ loading: true });
    const data = this.state.bidData[vehicle._id];
    // const bidAmount = this.parseString(data.bidAmount);
    let bidAmount = this.parseString(data.bidAmount);
    const customBidAmount = data.customBidAmount
      ? this.parseString(data.customBidAmount)
      : 0;
    const timeLeft = this.timeStampTime(vehicle).valueOf();
    const currentTime = moment(this.state.timeStamp).valueOf();
    const timeDifference = (timeLeft - currentTime) / 60 / 1000;
    if (timeDifference > 0 && timeDifference < 5) {
      vehicle.addAuctionMinutes =
        vehicle.addAuctionMinutes + (5 - timeDifference);
    }
    if (customBidAmount > bidAmount) {
      bidAmount = customBidAmount;
    }
    this.props
      .placeBid({
        id: vehicle._id,
        bidAmount: bidAmount,
        addAuctionMinutes: vehicle.addAuctionMinutes
      })
      .then(r => {
        data.yourBidAmount = this.formatNumber(bidAmount);
        data.customBidAmount = undefined;
        data.bidAmount = this.formatNumber(bidAmount + 1000);
        this.setState({
          bidData: { ...this.state.bidData, ...data },
          loading: false
        });
        Notify('success', 'Success', r);
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };

  handleCustomBid = e => {
    e.stopPropagation();
    this.setState({ loading: true });
    const vehicle = JSON.parse(e.currentTarget.getAttribute('data'));
    const data = this.state.bidData[vehicle._id];
    const bidAmount = this.parseString(data.customBidAmount);
    const timeLeft = this.timeStampTime(vehicle).valueOf();
    const currentTime = moment(this.state.timeStamp).valueOf();
    const timeDifference = (timeLeft - currentTime) / 60 / 1000;
    if (timeDifference > 0 && timeDifference < 5) {
      vehicle.addAuctionMinutes =
        vehicle.addAuctionMinutes + (5 - timeDifference);
    }
    this.props
      .placeBid({
        id: vehicle._id,
        bidAmount: bidAmount,
        addAuctionMinutes: vehicle.addAuctionMinutes
      })
      .then(r => {
        data.yourBidAmount = this.formatNumber(data.customBidAmount);
        data.customBidLow = true;
        this.setState({
          bidData: { ...this.state.bidData, ...data },
          loading: false
        });
        Notify('success', 'Success', r);
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  stopProp = e => {
    e.stopPropagation();
  };
  autoBidInterval;
  handleAutoBid = (vehicle, e) => {
    e.stopPropagation();
    const data = this.state.bidData[vehicle._id];
    data.autoBidActive = true;
    data.autoBidAmountSet = data.autoBidAmount;
    this.setState({ bidData: { ...this.state.bidData, ...data } }, () => {
      if (
        (this.props.customTime && !this.props.customTime.status) ||
        this.state.bidData[vehicle._id].closed ||
        this.parseString(data.autoBidAmount) ===
          this.parseString(data.yourBidAmount)
      ) {
        data.autoBidLow = true;
        data.autoBidActive = false;
        this.setState({ bidData: { ...this.state.bidData, ...data } });
      } else if (
        vehicle.highestBid > this.parseString(data.yourBidAmount) ||
        vehicle.highestBid === 0
      ) {
        const id = this.props.vehicleDetailsId;
        const bidAmount = vehicle.highestBid + 1000;
        if (
          this.parseString(data.yourBidAmount) > bidAmount ||
          this.parseString(data.autoBidAmount) < bidAmount
        ) {
          data.autoBidLow = true;
          data.autoBidActive = false;
          this.setState({
            bidData: { ...this.state.bidData, ...data }
          });
        } else {
          const timeLeft = this.timeStampTime(vehicle).valueOf();
          const currentTime = moment(this.state.timeStamp).valueOf();
          const timeDifference = (timeLeft - currentTime) / 60 / 1000;
          if (timeDifference > 0 && timeDifference < 5) {
            vehicle.addAuctionMinutes =
              vehicle.addAuctionMinutes + (5 - timeDifference);
          }
          this.props
            .placeBid({
              id: vehicle._id,
              bidAmount: bidAmount,
              addAuctionMinutes: vehicle.addAuctionMinutes
            })
            .then(r => {
              data.yourBidAmount = this.formatNumber(bidAmount);
              this.setState({
                bidData: { ...this.state.bidData, ...data }
              });
              // Notify('success', "Success", r);
            })
            .catch(e => {
              this.setState({ loading: false });
              // Notify('error', e);
            });
        }
      }
    });
  };

  removeBidIncrement = e => {
    e.stopPropagation();
    const vehicle = JSON.parse(e.currentTarget.getAttribute('data'));
    const data = this.state.bidData[vehicle._id];
    const currentBid = this.parseString(
      this.state.bidData[vehicle._id].bidAmount
    );
    let highestBid;
    if (vehicle.reserve) {
      if (this.parseString(vehicle.reserve) > vehicle.highestBid) {
        highestBid = this.parseString(vehicle.reserve);
      } else {
        highestBid = vehicle.highestBid;
      }
    } else {
      highestBid = vehicle.highestBid;
    }
    if (currentBid - 1000 !== highestBid) {
      if (currentBid - 2000 === highestBid) {
        data.bidAmount = this.formatNumber(
          this.parseString(this.state.bidData[vehicle._id].bidAmount) - 1000
        );
        data.disableReduce = true;
        this.setState({ bidData: { ...this.state.bidData, ...data } });
      } else {
        data.bidAmount = this.formatNumber(
          this.parseString(this.state.bidData[vehicle._id].bidAmount) - 1000
        );
        this.setState({ bidData: { ...this.state.bidData, ...data } });
      }
    } else {
      data.disableReduce = true;
      this.setState({ bidData: { ...this.state.bidData, ...data } });
    }
  };

  addBidIncrement = e => {
    e.stopPropagation();
    const vehicle = JSON.parse(e.currentTarget.getAttribute('data'));
    const data = this.state.bidData[vehicle._id];
    data.bidAmount = this.formatNumber(
      this.parseString(this.state.bidData[vehicle._id].bidAmount) + 1000
    );
    data.disableReduce = false;
    this.setState({ bidData: { ...this.state.bidData, ...data } });
    if (vehicle.reserve) {
      if (
        this.parseString(vehicle.reserve) >
        this.parseString(this.state.bidData[vehicle._id].bidAmount)
      ) {
        {
          data.bidLow = true;
          this.setState({ bidData: { ...this.state.bidData, ...data } });
        }
      }
    }
    if (
      vehicle.highestBid >
      this.parseString(this.state.bidData[vehicle._id].bidAmount)
    ) {
      data.bidLow = true;
      this.setState({ bidData: { ...this.state.bidData, ...data } });
    } else {
      data.bidLow = false;
      this.setState({ bidData: { ...this.state.bidData, ...data } });
    }
  };

  removeAutoBidIncrement = (vehicle, e) => {
    e.stopPropagation();
    const data = this.state.bidData[vehicle._id];
    const currentBid = this.parseString(
      this.state.bidData[vehicle._id].autoBidAmount
    );
    let highestBid;
    if (vehicle.reserve) {
      if (this.parseString(vehicle.reserve) > vehicle.highestBid) {
        highestBid = this.parseString(vehicle.reserve);
      } else {
        highestBid = vehicle.highestBid;
      }
    } else {
      highestBid = vehicle.highestBid;
    }
    if (currentBid - 1000 !== highestBid) {
      if (currentBid - 2000 === highestBid) {
        data.autoBidAmount = this.formatNumber(
          this.parseString(this.state.bidData[vehicle._id].autoBidAmount) - 1000
        );
        data.disableReduce = true;
        this.setState({ bidData: { ...this.state.bidData, ...data } });
      } else {
        data.autoBidAmount = this.formatNumber(
          this.parseString(this.state.bidData[vehicle._id].autoBidAmount) - 1000
        );
        this.setState({ bidData: { ...this.state.bidData, ...data } });
      }
    } else {
      data.disableReduce = true;
      this.setState({ bidData: { ...this.state.bidData, ...data } });
    }
  };

  addAutoBidIncrement = (vehicle, e) => {
    e.stopPropagation();
    const data = this.state.bidData[vehicle._id];
    data.autoBidAmount = this.formatNumber(
      this.parseString(this.state.bidData[vehicle._id].autoBidAmount) + 1000
    );
    data.disableReduce = false;
    this.setState({ bidData: { ...this.state.bidData, ...data } });
    if (vehicle.reserve) {
      if (
        this.parseString(vehicle.reserve) >
        this.parseString(this.state.bidData[vehicle._id].autoBidAmount)
      ) {
        {
          data.autoBidLow = true;
          this.setState({ bidData: { ...this.state.bidData, ...data } });
        }
      }
    }
    if (
      vehicle.highestBid >
      this.parseString(this.state.bidData[vehicle._id].autoBidAmount)
    ) {
      data.autoBidLow = true;
      this.setState({ bidData: { ...this.state.bidData, ...data } });
    } else {
      data.autoBidLow = false;
      this.setState({ bidData: { ...this.state.bidData, ...data } });
    }
  };

  handleCustomBidAmount = (vehicle, e) => {
    const data = this.state.bidData[vehicle._id];
    let previousBid = data.previousBid.split(',')[1]
      ? data.previousBid.split(',')[0]
      : data.previousBid;
    let value;
    var that = this;
    let check1 = previousBid.replace(/[,]/g, '');
    let subCheck = e.currentTarget.value.replace(/[,]/g, '');
    const check2 =
      e.currentTarget.value.length === 3
        ? subCheck.replace(/[,]/g, '')
        : subCheck.substr(0, subCheck.length - 3);
    if (check1.length > check2.length) {
      if (check2.length <= 1) {
        previousBid = 'R0';
      } else {
        previousBid = previousBid.substr(0, previousBid.length - 1);
      }
    } else {
      previousBid =
        previousBid +
        e.currentTarget.value.substr(e.currentTarget.value.length - 1, 1);
    }
    data.previousBid = this.formatNumber(this.parseString(previousBid));
    value = previousBid + '000';
    let check3 = vehicle.highestBid;
    if (vehicle.reserve) {
      if (vehicle.highestBid < this.parseString(vehicle.reserve)) {
        check3 = this.parseString(vehicle.reserve);
      }
    }
    const check4 = this.parseString(value);
    if (check3 >= check4) {
      data.customBidAmount = value.split('R')[1]
        ? this.formatNumber(this.parseString(value))
        : this.formatNumber(value);
      data.bidLow = true;
      this.setState({ bidData: { ...this.state.bidData, ...data } });
    } else {
      data.customBidAmount = value.split('R')[1]
        ? this.formatNumber(this.parseString(value))
        : this.formatNumber(value);
      data.bidLow = false;
      this.setState({ bidData: { ...this.state.bidData, ...data } });
    }
  };

  handleCustomAutoBidAmount = (vehicle, e) => {
    const data = this.state.bidData[vehicle._id];
    let previousAutoBid = data.previousAutoBid.split(',')[1]
      ? data.previousAutoBid.split(',')[0]
      : data.previousAutoBid;
    let value;
    var that = this;
    let check1 = previousAutoBid.replace(/[,]/g, '');
    let subCheck = e.currentTarget.value.replace(/[,]/g, '');
    const check2 =
      e.currentTarget.value.length === 3
        ? subCheck.replace(/[,]/g, '')
        : subCheck.substr(0, subCheck.length - 3);
    if (check1.length > check2.length) {
      if (check2.length <= 1) {
        previousAutoBid = 'R0';
      } else {
        previousAutoBid = previousAutoBid.substr(0, previousAutoBid.length - 1);
      }
    } else {
      previousAutoBid =
        previousAutoBid +
        e.currentTarget.value.substr(e.currentTarget.value.length - 1, 1);
    }
    data.previousAutoBid = this.formatNumber(this.parseString(previousAutoBid));
    value = previousAutoBid + '000';
    let check3 = vehicle.highestBid;
    if (vehicle.reserve) {
      if (vehicle.highestBid < this.parseString(vehicle.reserve)) {
        check3 = this.parseString(vehicle.reserve);
      }
    }
    const check4 = this.parseString(value);
    if (check3 >= check4) {
      data.autoBidAmount = value.split('R')[1]
        ? this.formatNumber(this.parseString(value))
        : this.formatNumber(value);
      data.autoBidLow = true;
      this.setState({ bidData: { ...this.state.bidData, ...data } });
    } else {
      data.autoBidAmount = value.split('R')[1]
        ? this.formatNumber(this.parseString(value))
        : this.formatNumber(value);
      data.autoBidLow = false;
      this.setState({ bidData: { ...this.state.bidData, ...data } });
    }
  };

  handleAddBasket = e => {
    e.stopPropagation();
    this.props
      .addBasketVehicle(e.currentTarget.getAttribute('data'))
      .then(() => {
        this.setState({ loading: false });
        Notify('success', 'Added vehicle to basket');
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };

  handleRemoveBasket = e => {
    e.stopPropagation();
    let id = e.currentTarget.getAttribute('data');
    this.props
      .removeBasketVehicle(id)
      .then(() => {
        let newBasket = this.state.basketVehicles;
        newBasket.splice(newBasket.indexOf(id), 1);
        this.setState({ loading: false, basketVehicles: newBasket });
        Notify('success', 'Removed vehicle from basket');
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };

  handleSelectAuctionVehicle = e => {
    this.props.setVehicleDetailsId(e.currentTarget.getAttribute('data'));
    this.props.push('/dealer/vehicledetails');
  };
  handleTabChange = e => {
    if (e === '2') {
      let basketVehicles = [];
      this.props.vehicles.forEach(vehicle => {
        if (this.props.user.basket.indexOf(vehicle._id) > -1) {
          basketVehicles.push(vehicle);
        }
      });
      this.setState({ basketVehicles: basketVehicles });
    }
  };
  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let vehicles = this.props.vehicles;
    if (columnKey === 'make') {
      if (order === 'ascend') {
        vehicles.sort((a, b) =>
          ('' + a[columnKey]).localeCompare(b[columnKey])
        );
      } else if (order === 'descend') {
        vehicles.sort((a, b) =>
          ('' + b[columnKey]).localeCompare(a[columnKey])
        );
      }
    } else {
      if (order === 'ascend') {
        vehicles.sort(
          (a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey])
        );
      } else if (order === 'descend') {
        vehicles.sort(
          (a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey])
        );
      }
    }
  };
  timeStampTime = vehicle => {
    let time = moment(this.props.timeStamp)
      .hour(parseInt(this.props.customTime.timeEnd.split(':')[0]))
      .minute(parseInt(this.props.customTime.timeEnd.split(':')[1]))
      .second(parseInt(this.props.customTime.timeEnd.split(':')[2]));
    time.add(vehicle.addAuctionMinutes, 'minutes');
    return time;
  };
  handleBidVisibleChange = visible => {
    this.setState({ bidVisible: visible });
  };
  handleAutoBidVisibleChange = visible => {
    this.setState({ autoBidVisible: visible });
  };
  handleAutoBidVisibleChangeMobile = visible => {
    this.setState({ autoBidVisibleMobile: visible });
  };
  processComplete = vehicle => {
    const data = this.state.bidData[vehicle._id];
    if (vehicle.auctionDate.end !== null) {
      if (
        moment('' + vehicle.auctionDate.end).isAfter(
          moment(this.props.timeStamp)
        )
      ) {
        data.nextAuction = moment('' + vehicle.auctionDate.end).format(
          'YYYY-MM-DD'
        );
        data.closed = true;
      }
    } else {
      data.closed = true;
    }
    this.setState({
      bidData: {
        ...this.state.bidData,
        ...data
      }
    });
  };
  largeImage = image => {
    Modal.info({
      icon: false,

      maskClosable: true,
      centered: true,
      width: '60vw',
      height: '60vh',
      className: 'large-image-modal',
      okText: 'OK',

      content: (
        <div style={{ position: 'relative', left: -18 }}>
          <div style={{ display: 'flex' }}>
            <img
              className="large-image-modal-img"
              alt="Main"
              style={{ margin: 'auto' }}
              src={
                image
                  ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                    image
                  : noImage
              }
            />
          </div>
        </div>
      ),
      onOk() {}
    });
  };
  render() {
    const { user } = this.props;
    if (this.props.customTime) {
      return (
        <div>
          {' '}
          <Row type="flex" justify="start" align="middle">
            <Col className="hidden-comps-mobile" xs={24} md={12}>
              <Icon
                style={{ fontSize: 36, color: '#00888E', marginRight: 20 }}
                type="car"
                theme="filled"
              />
              <span style={{ fontWeight: 750, fontSize: 36 }}>
                {window.i18n.translate('VehiclesOnAuction')}
              </span>
            </Col>
            <Col xs={24} md={12} className="hidden-comps-mobile">
              <span style={{ fontWeight: 750, fontSize: 24, float: 'right' }}>
                {moment().format('HH:mm:ss')}
              </span>
            </Col>
          </Row>
          <Row type="flex" justify="start" align="middle">
            <Col xs={24} md={12} className="hidden-comps-webview">
              <span
                style={{
                  fontWeight: 750,
                  fontSize: 30,

                  textAlign: 'center',
                  margin: 'auto',
                  marginBottom: '-0.3em'
                }}
              >
                {window.i18n.translate('VehiclesOnAuction')}
              </span>
            </Col>
            <Col
              xs={24}
              md={12}
              className="hidden-comps-webview"
              style={{ borderBottom: '1px solid #e8e8e8' }}
            >
              <span
                style={{
                  fontWeight: 750,
                  fontSize: 22,
                  textAlign: 'center',
                  margin: 'auto'
                }}
              >
                {moment().format('HH:mm:ss')}
              </span>
            </Col>
          </Row>
          <Spin spinning={this.state.loading}>
            <Row
              type="flex"
              justify="start"
              align="middle"
              className="hidden-comps-mobile"
            >
              <Col span={24}>
                <StyledTable
                  style={{ background: 'white' }}
                  rowKey="_id"
                  size="middle"
                  onChange={this.handleSort}
                >
                  <Column
                    sorter={() => {}}
                    title="ID"
                    dataIndex="displayId"
                    key="displayId"
                    width={'4.16666667%'}
                  />
                  <Column
                    title={window.i18n.translate('Image')}
                    width={'8.33333333%'}
                  />
                  <Column
                    sorter={() => {}}
                    dataIndex="make"
                    key="make"
                    title={window.i18n.translate('Details')}
                    width={'12.5%'}
                  />
                  <Column
                    sorter={() => {}}
                    title={window.i18n.translate('Mileage')}
                    dataIndex="mileage"
                    key="mileage"
                    width={'8.33333333%'}
                  />

                  <Column
                    title={window.i18n.translate('Location')}
                    width={'8.33333333%'}
                  />
                  <Column
                    sorter={() => {}}
                    title={window.i18n.translate('Trade')}
                    dataIndex="tradePrice"
                    key="tradePrice"
                    width={'8.33333333%'}
                  />
                  <Column
                    sorter={() => {}}
                    title={window.i18n.translate('Retail')}
                    dataIndex="retailPrice"
                    key="retailPrice"
                    width={'8.33333333%'}
                  />
                  <Column
                    sorter={() => {}}
                    title={window.i18n.translate('Bids')}
                    dataIndex="highestBid"
                    key="highestBid"
                    width={'8.33333333%'}
                  />
                  <Column
                    title={window.i18n.translate('YourBids')}
                    width={'8.33333333%'}
                  />
                  <Column
                    title={window.i18n.translate('Status')}
                    width={'4.16666667%'}
                  />
                  <Column
                    title={window.i18n.translate('Auction')}
                    width={'8.33333333%'}
                  />
                  <Column
                    title={window.i18n.translate('Action')}
                    width={'8.33333333%'}
                  />
                </StyledTable>
              </Col>
            </Row>{' '}
            {this.state.bidData ? (
              <Row
                type="flex"
                justify="start"
                align="middle"
                className="hidden-comps-mobile"
              >
                <Col span={24}>
                  <List
                    dataSource={this.props.vehicles}
                    pagination={{
                      onChange: page => {
                        console.log(page);
                      },
                      pageSize: 10
                    }}
                    renderItem={vehicle => {
                      if (
                        this.state.bidData[vehicle._id].closed &&
                        !this.state.bidData[vehicle._id].nextAuction &&
                        !this.state.bidData[vehicle._id].auctionStart
                      ) {
                        return <div />;
                      } else {
                        return (
                          <StyledListItem>
                            <div
                              style={{
                                width: '100%',
                                height: 93,
                                background:
                                  vehicle.highestBidder === this.props.user._id
                                    ? '#d8f9d9'
                                    : this.parseString(
                                        this.state.bidData[vehicle._id]
                                          .yourBidAmount
                                      ) === 0
                                    ? 'white'
                                    : 'rgb(255, 175, 175)',
                                display: 'flex',
                                borderTopLeftRadius: 10,
                                borderTopRightRadius: 10
                              }}
                            >
                              <Col
                                style={{ margin: 'auto', textAlign: 'center' }}
                                span={1}
                              >
                                <h4>{vehicle.displayId}</h4>
                              </Col>
                              <Col
                                style={{ margin: 'auto', textAlign: 'center' }}
                                span={2}
                              >
                                <img
                                  data={vehicle._id}
                                  onClick={e =>
                                    this.largeImage(vehicle.mainImage)
                                  }
                                  alt="Main"
                                  style={{
                                    maxHeight: 90,
                                    maxWidth: 90,
                                    cursor: 'pointer'
                                  }}
                                  src={
                                    vehicle.mainImage
                                      ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                        vehicle.mainImage
                                      : noImage
                                  }
                                />
                              </Col>
                              <Col
                                data={vehicle._id}
                                onClick={this.handleSelectAuctionVehicle}
                                style={{ margin: 'auto', cursor: 'pointer' }}
                                span={3}
                              >
                                <h5
                                  style={{ color: '#00888E', marginBottom: 1 }}
                                >
                                  {vehicle.year} {vehicle.make}
                                </h5>
                                <h5
                                  style={{ color: '#00888E', marginBottom: 1 }}
                                >
                                  {vehicle.model}
                                </h5>
                                <h5
                                  style={{ color: '#00888E', marginBottom: 1 }}
                                >
                                  {vehicle.variant.replace(
                                    ' ' + vehicle.variant.split(' ').pop(),
                                    ''
                                  )}
                                </h5>
                                <h5>
                                  {vehicle.colour} | {vehicle.transmission}
                                </h5>
                              </Col>
                              <Col
                                style={{ margin: 'auto', textAlign: 'center' }}
                                span={2}
                              >
                                <h4>{vehicle.mileage} km</h4>
                              </Col>

                              <Col
                                style={{ margin: 'auto', textAlign: 'center' }}
                                span={2}
                              >
                                <h4 style={{ marginBottom: 4 }}>
                                  {vehicle.province}
                                </h4>
                                <h4>{vehicle.city}</h4>
                              </Col>
                              <Col
                                style={{ margin: 'auto', textAlign: 'center' }}
                                span={2}
                              >
                                <h4>
                                  {this.props.user.canSeePrices
                                    ? this.formatNumber(
                                        parseInt(vehicle.tradePrice)
                                      )
                                    : 'N/A'}
                                </h4>
                              </Col>
                              <Col
                                style={{ margin: 'auto', textAlign: 'center' }}
                                span={2}
                              >
                                <h4>
                                  {this.props.user.canSeePrices
                                    ? this.formatNumber(
                                        parseInt(vehicle.retailPrice)
                                      )
                                    : 'N/A'}
                                </h4>
                              </Col>
                              <Col
                                style={{ margin: 'auto', textAlign: 'center' }}
                                span={2}
                              >
                                <h4>
                                  Highest Bid:{' '}
                                  {this.formatNumber(vehicle.highestBid)}
                                </h4>
                                <h4>
                                  Your Bid:{' '}
                                  {this.formatNumber(
                                    this.state.bidData[vehicle._id]
                                      .yourBidAmount
                                  )}
                                </h4>
                              </Col>
                              <Col
                                style={{ margin: 'auto', textAlign: 'center' }}
                                md={2}
                              >
                                <div style={{ display: 'flex' }}>
                                  <Button
                                    disabled={
                                      (this.props.customTime &&
                                        !this.props.customTime.status) ||
                                      this.state.bidData[vehicle._id].closed ||
                                      this.state.bidData[vehicle._id].bidLow
                                    }
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.handleBid(vehicle);
                                    }}
                                    // onClick={e => this.setState({bidVisible: true})}
                                    style={{
                                      background: '#E5E5E5',
                                      width: 112,
                                      margin: '5px auto 0px auto',
                                      color: '#6D6E70'
                                    }}
                                  >
                                    <Icon
                                      style={{ color: '#6D6E70' }}
                                      theme="filled"
                                      component={hammer}
                                    />
                                    Place Bid
                                    <p style={{ fontSize: 10, marginTop: -4 }}>
                                      {this.state.bidData[vehicle._id]
                                        .customBidAmount
                                        ? '(Custom)'
                                        : 'R1000'}
                                    </p>
                                  </Button>
                                  {/* </StyledDiv> */}
                                  {/* </Popover> */}
                                </div>
                                <h4 style={{ marginBottom: -1 }}>
                                  <Input
                                    onClick={e => e.stopPropagation()}
                                    onBlur={e => e.stopPropagation()}
                                    onFocus={e => e.stopPropagation()}
                                    style={{ width: 110, height: 20 }}
                                    value={
                                      this.state.bidData[vehicle._id]
                                        .customBidAmount
                                        ? this.state.bidData[vehicle._id]
                                            .customBidAmount
                                        : this.formatNumber(
                                            // this.parseString(
                                            vehicle.highestBid
                                              ? vehicle.highestBid + 1000
                                              : vehicle.reserve
                                              ? this.parseString(
                                                  vehicle.reserve
                                                ) + 1000
                                              : vehicle.highestBid + 1000
                                          )
                                    }
                                    onChange={e => {
                                      e.stopPropagation();
                                      this.handleCustomBidAmount(vehicle, e);
                                    }}
                                    placeholder="Custom bid amount"
                                  />
                                  {/* {this.state.bidData[vehicle._id].yourBidAmount} */}
                                </h4>
                                <div style={{}}>
                                  <Popover
                                    placement="left"
                                    visible={
                                      this.state.bidData[vehicle._id]
                                        .autoBidVisible
                                    }
                                    onVisibleChange={e => {
                                      let data = this.state.bidData[
                                        vehicle._id
                                      ];
                                      data.autoBidVisible = e;
                                      this.setState({
                                        bidData: {
                                          ...this.state.bidData,
                                          ...data
                                        }
                                      });
                                    }}
                                    content={
                                      <div
                                        style={{
                                          margin: 'auto',
                                          textAlign: 'center',
                                          padding: 10
                                        }}
                                      >
                                        <Icon
                                          onClick={e => {
                                            let data = this.state.bidData[
                                              vehicle._id
                                            ];
                                            data.autoBidVisible = false;
                                            this.setState({
                                              bidData: {
                                                ...this.state.bidData,
                                                ...data
                                              }
                                            });
                                          }}
                                          style={{
                                            position: 'absolute',
                                            top: 5,
                                            cursor: 'pointer',
                                            right: 15
                                          }}
                                          type="close"
                                        />
                                        <Row style={{ marginBottom: 10 }}>
                                          <span
                                            style={{
                                              fontWeight: 600,
                                              fontSize: 15
                                            }}
                                          >
                                            Highest Bid:{' '}
                                            {this.formatNumber(
                                              vehicle.highestBid
                                            )}
                                          </span>
                                        </Row>
                                        <Row style={{ marginBottom: 10 }}>
                                          <span
                                            style={{
                                              fontWeight: 600,
                                              fontSize: 15,
                                              color:
                                                this.parseString(
                                                  this.state.bidData[
                                                    vehicle._id
                                                  ].yourBidAmount
                                                ) === vehicle.highestBid
                                                  ? 'green'
                                                  : 'red'
                                            }}
                                          >
                                            Your Bid: R
                                            {numeral(
                                              this.state.bidData[vehicle._id]
                                                .yourBidAmount
                                            ).format('0,0')}
                                          </span>
                                        </Row>
                                        <Row style={{ marginBottom: 10 }}>
                                          <span
                                            style={{
                                              fontWeight: 600,
                                              fontSize: 15
                                            }}
                                          >
                                            Bid Until Amount:
                                          </span>
                                        </Row>
                                        <Row style={{ marginBottom: 10 }}>
                                          <Input
                                            onClick={e => e.stopPropagation()}
                                            onBlur={e => e.stopPropagation()}
                                            onFocus={e => e.stopPropagation()}
                                            style={{ width: 150 }}
                                            disabled={
                                              (this.props.customTime &&
                                                !this.props.customTime
                                                  .status) ||
                                              this.state.bidData[vehicle._id]
                                                .closed ||
                                              this.state.bidData[vehicle._id]
                                                .autoBidActive
                                            }
                                            value={
                                              this.state.bidData[vehicle._id]
                                                .autoBidAmount
                                            }
                                            onChange={e =>
                                              this.handleCustomAutoBidAmount(
                                                vehicle,
                                                e
                                              )
                                            }
                                            placeholder="Custom auto bid amount"
                                          />
                                        </Row>
                                        <Row style={{ marginBottom: 10 }}>
                                          <Button
                                            disabled={
                                              (this.props.customTime &&
                                                !this.props.customTime
                                                  .status) ||
                                              this.state.bidData[vehicle._id]
                                                .closed ||
                                              this.state.bidData[vehicle._id]
                                                .autoBidActive
                                            }
                                            onClick={e =>
                                              this.addAutoBidIncrement(
                                                vehicle,
                                                e
                                              )
                                            }
                                            style={{ marginRight: 10 }}
                                            type="primary"
                                            shape="circle"
                                            icon="plus"
                                          />
                                          <Button
                                            disabled={
                                              (this.props.customTime &&
                                                !this.props.customTime
                                                  .status) ||
                                              this.state.bidData[vehicle._id]
                                                .closed ||
                                              this.state.bidData[vehicle._id]
                                                .autoBidActive ||
                                              this.state.disableReduce
                                            }
                                            onClick={e =>
                                              this.removeAutoBidIncrement(
                                                vehicle,
                                                e
                                              )
                                            }
                                            shape="circle"
                                            icon="minus"
                                          />
                                        </Row>
                                        <Row style={{ marginBottom: 10 }}>
                                          <Button
                                            disabled={
                                              (this.props.customTime &&
                                                !this.props.customTime
                                                  .status) ||
                                              this.state.bidData[vehicle._id]
                                                .closed ||
                                              this.state.bidData[vehicle._id]
                                                .autoBidLow ||
                                              this.state.bidData[vehicle._id]
                                                .autoBidActive
                                            }
                                            onClick={e => {
                                              this.handleAutoBid(vehicle, e);
                                            }}
                                            style={{
                                              background: this.state.bidData[
                                                vehicle._id
                                              ].autoBidActive
                                                ? 'rgb(47, 172, 84)'
                                                : '#E5E5E5',
                                              width: 150,
                                              margin: 'auto',
                                              color: this.state.bidData[
                                                vehicle._id
                                              ].autoBidActive
                                                ? 'white'
                                                : '#6D6E70'
                                            }}
                                          >
                                            <Icon
                                              style={{
                                                color: this.state.bidData[
                                                  vehicle._id
                                                ].autoBidActive
                                                  ? 'white'
                                                  : '#6D6E70'
                                              }}
                                              theme="filled"
                                              component={hammer}
                                            />
                                            {this.state.bidData[vehicle._id]
                                              .autoBidActive
                                              ? 'Placed Auto Bid'
                                              : 'Place Auto Bid'}
                                          </Button>
                                        </Row>
                                        {this.state.bidData[vehicle._id]
                                          .autoBidActive ? (
                                          <Row style={{ marginBottom: 10 }}>
                                            <span
                                              style={{
                                                fontWeight: 600,
                                                fontSize: 15
                                              }}
                                            >
                                              Until Amount: R
                                              {numeral(
                                                this.state.bidData[vehicle._id]
                                                  .autoBidAmountSet
                                              ).format('0,0')}
                                            </span>
                                          </Row>
                                        ) : null}
                                      </div>
                                    }
                                    trigger="click"
                                  >
                                    {/* <StyledDiv> */}
                                    <Button
                                      disabled={
                                        (this.props.customTime &&
                                          !this.props.customTime.status) ||
                                        this.state.bidData[vehicle._id].closed
                                      }
                                      onClick={this.stopProp}
                                      style={{
                                        background: this.state.bidData[
                                          vehicle._id
                                        ].autoBidActive
                                          ? 'rgb(47, 172, 84)'
                                          : '#E5E5E5',
                                        width: 112,
                                        margin: 'auto',
                                        color: this.state.bidData[vehicle._id]
                                          .autoBidActive
                                          ? 'white'
                                          : '#6D6E70'
                                      }}
                                    >
                                      <Icon
                                        style={{ color: '#6D6E70' }}
                                        theme="filled"
                                        component={hammer}
                                      />
                                      Auto Bid
                                    </Button>
                                    {/* </StyledDiv> */}
                                  </Popover>
                                </div>
                              </Col>
                              <Col
                                style={{ margin: 'auto', textAlign: 'center' }}
                                span={1}
                              >
                                {vehicle.highestBidder ===
                                this.props.user._id ? (
                                  <h4 style={{ color: 'green' }}>
                                    {this.state.bidData[vehicle._id].closed &&
                                    !this.state.bidData[vehicle._id].nextAuction
                                      ? 'Won'
                                      : 'Winning'}
                                  </h4>
                                ) : this.parseString(
                                    this.state.bidData[vehicle._id]
                                      .yourBidAmount
                                  ) === 0 ? (
                                  <h4 style={{ color: 'orange' }}>No Bids</h4>
                                ) : (
                                  <h4 style={{ color: 'red' }}>
                                    {this.state.bidData[vehicle._id].closed &&
                                    !this.state.bidData[vehicle._id].nextAuction
                                      ? 'Lost'
                                      : 'Losing'}
                                  </h4>
                                )}
                              </Col>
                              <Col
                                style={{ margin: 'auto', textAlign: 'center' }}
                                span={2}
                              >
                                {this.state.bidData[vehicle._id]
                                  .auctionStart ? (
                                  <div>
                                    <h4>Auction Starts</h4>
                                    <h4>
                                      {
                                        this.state.bidData[vehicle._id]
                                          .auctionStart
                                      }
                                    </h4>
                                  </div>
                                ) : this.state.bidData[vehicle._id]
                                    .nextAuction ? (
                                  <div>
                                    <h4>Next Auction</h4>
                                    <h4>
                                      {
                                        this.state.bidData[vehicle._id]
                                          .nextAuction
                                      }
                                    </h4>
                                  </div>
                                ) : (this.props.customTime &&
                                    !this.props.customTime.status) ||
                                  this.state.bidData[vehicle._id].closed ? (
                                  'Closed'
                                ) : (
                                  <div>
                                    <h4>Time Left</h4>
                                    <Countdown
                                      onComplete={e =>
                                        this.processComplete(vehicle)
                                      }
                                      style={{ fontSize: '1.5em' }}
                                      date={this.timeStampTime(
                                        vehicle
                                      ).valueOf()}
                                      now={e =>
                                        moment(this.state.timeStamp).valueOf()
                                      }
                                    />
                                  </div>
                                )}
                              </Col>
                              <Col
                                style={{ margin: 'auto', textAlign: 'center' }}
                                span={2}
                              >
                                <div style={{ display: 'flex' }}>
                                  <Button
                                    data={vehicle._id}
                                    onClick={this.handleSelectAuctionVehicle}
                                    style={{
                                      background: '#E5E5E5',
                                      width: 140,
                                      margin: '0px auto 0px auto',
                                      color: '#6D6E70'
                                    }}
                                  >
                                    <Icon
                                      style={{ color: '#6D6E70' }}
                                      type="car"
                                    />
                                    {window.i18n.translate('Details')}
                                  </Button>
                                </div>
                                <div style={{ display: 'flex' }}>
                                  <Button
                                    data={vehicle._id}
                                    onClick={
                                      user.basket.indexOf(vehicle._id) > -1
                                        ? this.handleRemoveBasket
                                        : this.handleAddBasket
                                    }
                                    style={{
                                      background: '#E5E5E5',
                                      width: 140,
                                      margin: '10px auto 0px auto',
                                      color: '#6D6E70',
                                      padding: 0
                                    }}
                                  >
                                    <Icon
                                      style={{ color: '#6D6E70' }}
                                      type="shopping-cart"
                                    />
                                    {user.basket.indexOf(vehicle._id) > -1
                                      ? 'Remove Basket'
                                      : 'Add Basket'}
                                  </Button>
                                </div>
                              </Col>
                            </div>
                          </StyledListItem>
                        );
                      }
                    }}
                  />
                </Col>
              </Row>
            ) : null}{' '}
            {/* MOBILE VIEW*/}
            {this.state.bidData ? (
              <Row
                type="flex"
                justify="start"
                align="middle"
                className="hidden-comps-webview"
              >
                <Col span={24}>
                  <List
                    dataSource={this.props.vehicles}
                    pagination={{
                      onChange: page => {
                        console.log(page);
                      },
                      pageSize: 10
                    }}
                    renderItem={vehicle => {
                      if (
                        this.state.bidData[vehicle._id].closed &&
                        !this.state.bidData[vehicle._id].nextAuction &&
                        !this.state.bidData[vehicle._id].auctionStart
                      ) {
                        return <div />;
                      } else {
                        return (
                          <StyledListItem>
                            <div
                              style={{
                                width: '100%',
                                padding: '2em',
                                paddingBottom: '0em',
                                paddingTop: '0em',
                                background:
                                  vehicle.highestBidder === this.props.user._id
                                    ? '#d8f9d9'
                                    : this.parseString(
                                        this.state.bidData[vehicle._id]
                                          .yourBidAmount
                                      ) === 0
                                    ? 'white'
                                    : 'rgb(255, 175, 175)',

                                borderTopLeftRadius: 10,
                                borderTopRightRadius: 10
                              }}
                            >
                              {' '}
                              <Col
                                data={vehicle._id}
                                onClick={this.handleSelectAuctionVehicle}
                                style={{
                                  margin: 'auto',
                                  fontSize: '13px',
                                  textAlign: 'left',
                                  cursor: 'pointer'
                                }}
                                span={24}
                              >
                                <h4
                                  style={{
                                    color: '#00888E',
                                    marginBottom: 1,
                                    lineHeight: '15px'
                                  }}
                                >
                                  {vehicle.year} <br /> {vehicle.make} -{' '}
                                  {vehicle.model} -{' '}
                                  {vehicle.variant.replace(
                                    ' ' + vehicle.variant.split(' ').pop(),
                                    ''
                                  )}
                                  <br />
                                  <span style={{ color: 'black' }}>
                                    {vehicle.colour} | {vehicle.transmission}
                                  </span>
                                </h4>
                              </Col>
                              <Col
                                style={{
                                  margin: 'auto',
                                  marginTop: 5,
                                  textAlign: 'left'
                                }}
                                span={12}
                              >
                                <img
                                  data={vehicle._id}
                                  onClick={this.handleSelectAuctionVehicle}
                                  alt="Main"
                                  style={{
                                    maxHeight: 110,
                                    maxWidth: 110,

                                    cursor: 'pointer'
                                  }}
                                  src={
                                    vehicle.mainImage
                                      ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                        vehicle.mainImage
                                      : noImage
                                  }
                                />
                              </Col>{' '}
                              {/* <Col
                                style={{
                                  margin: "auto",
                                  marginTop: "0em",
                                  textAlign: "left",
                                }}
                                span={8}
                              >
                                <h4>
                                  ID:{" "}
                                  <span style={{ color: "rgb(0, 136, 142)" }}>
                                    {vehicle.displayId}
                                  </span>
                                </h4>
                              </Col>*/}
                              <Col
                                style={{
                                  margin: 'auto',
                                  textAlign: 'left',
                                  marginTop: 0,
                                  fontSize: '13px'
                                }}
                                span={12}
                              >
                                <h4 style={{ marginBottom: 0 }}>
                                  Province:{''}{' '}
                                  <span style={{ color: 'rgb(0, 136, 142)' }}>
                                    {vehicle.province}
                                  </span>
                                </h4>
                                <h4>
                                  City:{''}{' '}
                                  <span style={{ color: 'rgb(0, 136, 142)' }}>
                                    {vehicle.city}
                                  </span>
                                </h4>
                              </Col>{' '}
                              <Col
                                style={{
                                  margin: 'auto',
                                  marginTop: -5,
                                  textAlign: 'left',
                                  fontSize: '13px'
                                }}
                                span={12}
                              >
                                <h4>
                                  {' '}
                                  Mileage:{' '}
                                  <span style={{ color: 'rgb(0, 136, 142)' }}>
                                    {vehicle.mileage}km
                                  </span>{' '}
                                </h4>
                              </Col>{' '}
                              <Col
                                style={{
                                  margin: 'auto',
                                  textAlign: 'left',
                                  marginTop: '-0.5em',
                                  fontSize: '13px'
                                }}
                                span={12}
                              >
                                {this.state.bidData[vehicle._id]
                                  .auctionStart ? (
                                  <div>
                                    <h4>
                                      Auction Starts: {''}
                                      <span
                                        style={{ color: 'rgb(0, 136, 142)' }}
                                      >
                                        {
                                          this.state.bidData[vehicle._id]
                                            .auctionStart
                                        }
                                      </span>
                                    </h4>
                                  </div>
                                ) : this.state.bidData[vehicle._id]
                                    .nextAuction ? (
                                  <div>
                                    <h4>
                                      Next Auction: {''}
                                      <span
                                        style={{ color: 'rgb(0, 136, 142)' }}
                                      >
                                        {
                                          this.state.bidData[vehicle._id]
                                            .nextAuction
                                        }
                                      </span>
                                    </h4>
                                  </div>
                                ) : (this.props.customTime &&
                                    !this.props.customTime.status) ||
                                  this.state.bidData[vehicle._id].closed ? (
                                  'Closed'
                                ) : (
                                  <div>
                                    <h4
                                      style={{
                                        marginBottom: '-0em',

                                        color: 'rgb(0, 136, 142)'
                                      }}
                                    >
                                      <span style={{ color: 'black' }}>
                                        {' '}
                                        Time Left:{' '}
                                      </span>
                                      <Countdown
                                        onComplete={e =>
                                          this.processComplete(vehicle)
                                        }
                                        date={this.timeStampTime(
                                          vehicle
                                        ).valueOf()}
                                        now={e =>
                                          moment(this.state.timeStamp).valueOf()
                                        }
                                      />
                                    </h4>
                                  </div>
                                )}
                              </Col>
                              {/*}    <Col
                                style={{ margin: "auto", textAlign: "center" }}
                                span={24}
                              >
                                <h4>
                                  {this.props.user.canSeePrices
                                    ? this.formatNumber(
                                        parseInt(vehicle.tradePrice)
                                      )
                                    : "N/A"}
                                </h4>
                              </Col>
                              <Col
                                style={{ margin: "auto", textAlign: "center" }}
                                span={24}
                              >
                                <h4>
                                  {this.props.user.canSeePrices
                                    ? this.formatNumber(
                                        parseInt(vehicle.retailPrice)
                                      )
                                    : "N/A"}
                                </h4>
                                    </Col>*/}
                              <Col
                                style={{ margin: 'auto', textAlign: 'center' }}
                                span={24}
                              >
                                <div
                                  style={{
                                    marginLeft: '-16em'
                                  }}
                                >
                                  <Button
                                    disabled={
                                      (this.props.customTime &&
                                        !this.props.customTime.status) ||
                                      this.state.bidData[vehicle._id].closed ||
                                      this.state.bidData[vehicle._id].bidLow
                                    }
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.handleBid(vehicle);
                                    }}
                                    // onClick={e => this.setState({bidVisible: true})}
                                    style={{
                                      background: '#E5E5E5',
                                      width: 112,
                                      height: 40,
                                      margin: '5px auto 0px auto',
                                      color: '#6D6E70'
                                    }}
                                  >
                                    <Icon
                                      style={{
                                        color: '#6D6E70',
                                        marginTop: 10,
                                        marginLeft: -10,
                                        position: 'absolute'
                                      }}
                                      theme="filled"
                                      component={hammer}
                                    />
                                    Place Bid
                                    <p style={{ fontSize: 12, marginTop: -3 }}>
                                      {this.state.bidData[vehicle._id]
                                        .customBidAmount
                                        ? '(Custom)'
                                        : 'R1000'}
                                    </p>
                                  </Button>
                                  {/* </StyledDiv> */}
                                  {/* </Popover> */}
                                </div>
                                <h4
                                  style={{
                                    marginBottom: -1,
                                    marginTop: '-2.85em'
                                  }}
                                >
                                  <Input
                                    onClick={e => e.stopPropagation()}
                                    onBlur={e => e.stopPropagation()}
                                    onFocus={e => e.stopPropagation()}
                                    style={{
                                      width: 110,
                                      height: 40,
                                      textAlign: 'center'
                                    }}
                                    value={
                                      this.state.bidData[vehicle._id]
                                        .customBidAmount
                                        ? this.state.bidData[vehicle._id]
                                            .customBidAmount
                                        : this.formatNumber(
                                            // this.parseString(
                                            vehicle.highestBid
                                              ? vehicle.highestBid + 1000
                                              : vehicle.reserve
                                              ? this.parseString(
                                                  vehicle.reserve
                                                ) + 1000
                                              : vehicle.highestBid + 1000
                                          )
                                    }
                                    onChange={e => {
                                      e.stopPropagation();
                                      this.handleCustomBidAmount(vehicle, e);
                                    }}
                                    placeholder="Custom bid amount"
                                  />
                                  {/* {this.state.bidData[vehicle._id].yourBidAmount} */}
                                </h4>
                                <div
                                  style={{
                                    marginTop: '-2.85em',
                                    marginLeft: '16em'
                                  }}
                                >
                                  <Popover
                                    placement="left"
                                    visible={
                                      this.state.bidData[vehicle._id]
                                        .autoBidVisibleMobile
                                    }
                                    onVisibleChange={e => {
                                      let data = this.state.bidData[
                                        vehicle._id
                                      ];
                                      data.autoBidVisibleMobile = e;
                                      this.setState({
                                        bidData: {
                                          ...this.state.bidData,
                                          ...data
                                        }
                                      });
                                    }}
                                    content={
                                      <div
                                        style={{
                                          margin: 'auto',
                                          textAlign: 'center',
                                          padding: 10
                                        }}
                                      >
                                        <Icon
                                          onClick={e => {
                                            let data = this.state.bidData[
                                              vehicle._id
                                            ];
                                            data.autoBidVisibleMobile = false;
                                            this.setState({
                                              bidData: {
                                                ...this.state.bidData,
                                                ...data
                                              }
                                            });
                                          }}
                                          style={{
                                            position: 'absolute',
                                            top: 5,
                                            cursor: 'pointer',
                                            right: 15
                                          }}
                                          type="close"
                                        />
                                        <Row style={{ marginBottom: 10 }}>
                                          <span
                                            style={{
                                              fontWeight: 600,
                                              fontSize: 15
                                            }}
                                          >
                                            Highest Bid:{' '}
                                            {this.formatNumber(
                                              vehicle.highestBid
                                            )}
                                          </span>
                                        </Row>
                                        <Row style={{ marginBottom: 10 }}>
                                          <span
                                            style={{
                                              fontWeight: 600,
                                              fontSize: 15,
                                              color:
                                                this.parseString(
                                                  this.state.bidData[
                                                    vehicle._id
                                                  ].yourBidAmount
                                                ) === vehicle.highestBid
                                                  ? 'green'
                                                  : 'red'
                                            }}
                                          >
                                            Your Bid: R
                                            {numeral(
                                              this.state.bidData[vehicle._id]
                                                .yourBidAmount
                                            ).format('0,0')}
                                          </span>
                                        </Row>
                                        <Row style={{ marginBottom: 10 }}>
                                          <span
                                            style={{
                                              fontWeight: 600,
                                              fontSize: 15
                                            }}
                                          >
                                            Bid Until Amount:
                                          </span>
                                        </Row>
                                        <Row style={{ marginBottom: 10 }}>
                                          <Input
                                            onClick={e => e.stopPropagation()}
                                            onBlur={e => e.stopPropagation()}
                                            onFocus={e => e.stopPropagation()}
                                            style={{ width: 150 }}
                                            disabled={
                                              (this.props.customTime &&
                                                !this.props.customTime
                                                  .status) ||
                                              this.state.bidData[vehicle._id]
                                                .closed ||
                                              this.state.bidData[vehicle._id]
                                                .autoBidActive
                                            }
                                            value={
                                              this.state.bidData[vehicle._id]
                                                .autoBidAmount
                                            }
                                            onChange={e =>
                                              this.handleCustomAutoBidAmount(
                                                vehicle,
                                                e
                                              )
                                            }
                                            placeholder="Custom auto bid amount"
                                          />
                                        </Row>
                                        <Row style={{ marginBottom: 10 }}>
                                          <Button
                                            disabled={
                                              (this.props.customTime &&
                                                !this.props.customTime
                                                  .status) ||
                                              this.state.bidData[vehicle._id]
                                                .closed ||
                                              this.state.bidData[vehicle._id]
                                                .autoBidActive
                                            }
                                            onClick={e =>
                                              this.addAutoBidIncrement(
                                                vehicle,
                                                e
                                              )
                                            }
                                            style={{ marginRight: 10 }}
                                            type="primary"
                                            shape="circle"
                                            icon="plus"
                                          />
                                          <Button
                                            disabled={
                                              (this.props.customTime &&
                                                !this.props.customTime
                                                  .status) ||
                                              this.state.bidData[vehicle._id]
                                                .closed ||
                                              this.state.bidData[vehicle._id]
                                                .autoBidActive ||
                                              this.state.disableReduce
                                            }
                                            onClick={e =>
                                              this.removeAutoBidIncrement(
                                                vehicle,
                                                e
                                              )
                                            }
                                            shape="circle"
                                            icon="minus"
                                          />
                                        </Row>
                                        <Row style={{ marginBottom: 10 }}>
                                          <Button
                                            disabled={
                                              (this.props.customTime &&
                                                !this.props.customTime
                                                  .status) ||
                                              this.state.bidData[vehicle._id]
                                                .closed ||
                                              this.state.bidData[vehicle._id]
                                                .autoBidLow ||
                                              this.state.bidData[vehicle._id]
                                                .autoBidActive
                                            }
                                            onClick={e => {
                                              this.handleAutoBid(vehicle, e);
                                            }}
                                            style={{
                                              background: this.state.bidData[
                                                vehicle._id
                                              ].autoBidActive
                                                ? 'rgb(47, 172, 84)'
                                                : '#E5E5E5',
                                              width: 150,
                                              margin: 'auto',
                                              color: this.state.bidData[
                                                vehicle._id
                                              ].autoBidActive
                                                ? 'white'
                                                : '#6D6E70'
                                            }}
                                          >
                                            <Icon
                                              style={{
                                                color: this.state.bidData[
                                                  vehicle._id
                                                ].autoBidActive
                                                  ? 'white'
                                                  : '#6D6E70'
                                              }}
                                              theme="filled"
                                              component={hammer}
                                            />
                                            {this.state.bidData[vehicle._id]
                                              .autoBidActive
                                              ? 'Placed Auto Bid'
                                              : 'Place Auto Bid'}
                                          </Button>
                                        </Row>
                                        {this.state.bidData[vehicle._id]
                                          .autoBidActive ? (
                                          <Row style={{ marginBottom: 10 }}>
                                            <span
                                              style={{
                                                fontWeight: 600,
                                                fontSize: 15
                                              }}
                                            >
                                              Until Amount: R
                                              {numeral(
                                                this.state.bidData[vehicle._id]
                                                  .autoBidAmountSet
                                              ).format('0,0')}
                                            </span>
                                          </Row>
                                        ) : null}
                                      </div>
                                    }
                                    trigger="click"
                                  >
                                    {/* <StyledDiv> */}
                                    <Button
                                      disabled={
                                        (this.props.customTime &&
                                          !this.props.customTime.status) ||
                                        this.state.bidData[vehicle._id].closed
                                      }
                                      onClick={this.stopProp}
                                      style={{
                                        height: 40,
                                        background: this.state.bidData[
                                          vehicle._id
                                        ].autoBidActive
                                          ? 'rgb(47, 172, 84)'
                                          : '#E5E5E5',
                                        width: 112,
                                        margin: 'auto auto 10px auto',
                                        color: this.state.bidData[vehicle._id]
                                          .autoBidActive
                                          ? 'white'
                                          : '#6D6E70'
                                      }}
                                    >
                                      <Icon
                                        style={{ color: '#6D6E70' }}
                                        theme="filled"
                                        component={hammer}
                                      />
                                      Auto Bid
                                    </Button>
                                    {/* </StyledDiv> */}
                                  </Popover>
                                </div>
                              </Col>{' '}
                              <Col
                                style={{ margin: 'auto', textAlign: '' }}
                                span={12}
                              >
                                <div style={{ display: 'flex' }}>
                                  <Button
                                    data={vehicle._id}
                                    onClick={
                                      user.basket.indexOf(vehicle._id) > -1
                                        ? this.handleRemoveBasket
                                        : this.handleAddBasket
                                    }
                                    style={{
                                      backgroundColor: 'transparent',
                                      width: 140,
                                      border: '1px solid rgb(0, 136, 142)',
                                      height: 40,
                                      borderRadius: '3em',
                                      margin: '5px 0px 10px 10px',
                                      color: 'rgb(0, 136, 142)',
                                      padding: 0
                                    }}
                                  >
                                    <Icon
                                      style={{
                                        fontSize: '1.3em',
                                        color:
                                          'rgb(0, 136, 142)rgb(0, 136, 142)'
                                      }}
                                      type="shopping-cart"
                                    />
                                    {user.basket.indexOf(vehicle._id) > -1
                                      ? 'Remove Basket'
                                      : 'Add Basket'}
                                  </Button>
                                </div>
                              </Col>
                              <Col
                                style={{
                                  margin: 'auto',
                                  textAlign: 'left',
                                  whiteSpace: 'nowrap',
                                  fontSize: '13px'
                                }}
                                span={12}
                              >
                                {' '}
                                <h4>
                                  Your Bid: {''}
                                  <span style={{ color: 'rgb(0, 136, 142)' }}>
                                    {this.formatNumber(
                                      this.state.bidData[vehicle._id]
                                        .yourBidAmount
                                    )}
                                  </span>
                                </h4>
                                <h4
                                  style={{
                                    marginTop: '-0.5em'
                                  }}
                                >
                                  Highest Bid: {''}
                                  <span style={{ color: 'rgb(0, 136, 142)' }}>
                                    {this.formatNumber(vehicle.highestBid)}
                                  </span>{' '}
                                </h4>
                              </Col>{' '}
                              <Col
                                style={{
                                  margin: 'auto',
                                  marginTop: '-0.5em',
                                  paddingRight: '2.8em',
                                  textAlign: 'right',

                                  position: 'absolute'
                                }}
                                span={24}
                              >
                                {vehicle.highestBidder ===
                                this.props.user._id ? (
                                  <h4 style={{ color: 'green' }}>
                                    {this.state.bidData[vehicle._id].closed &&
                                    !this.state.bidData[vehicle._id].nextAuction
                                      ? 'Won'
                                      : 'Winning'}
                                  </h4>
                                ) : this.parseString(
                                    this.state.bidData[vehicle._id]
                                      .yourBidAmount
                                  ) === 0 ? (
                                  <h4 style={{ color: 'orange' }}>No Bids</h4>
                                ) : (
                                  <h4 style={{ color: 'red' }}>
                                    {this.state.bidData[vehicle._id].closed &&
                                    !this.state.bidData[vehicle._id].nextAuction
                                      ? 'Lost'
                                      : 'Losing'}
                                  </h4>
                                )}
                              </Col>
                              {/*} <Col
                                style={{ margin: "auto", textAlign: "center" }}
                                span={12}
                              >
                                <div style={{ display: "flex" }}>
                                  <Button
                                    data={vehicle._id}
                                    onClick={this.handleSelectAuctionVehicle}
                                    style={{
                                      background: "#E5E5E5",
                                      width: 130,
                                      margin: "10px auto 10px auto",
                                      color: "#6D6E70",
                                    }}
                                  >
                                    <Icon
                                      style={{ color: "#6D6E70" }}
                                      type="car"
                                    />
                                    Details
                                  </Button>
                                </div>
                                  </Col>{" "}*/}
                            </div>
                          </StyledListItem>
                        );
                      }
                    }}
                  />
                </Col>
              </Row>
            ) : null}
          </Spin>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default AuctionVehicles;
