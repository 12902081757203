export const initialState = {
  myVehicles: [],
  basketVehicles: [],
  basketVehicleIds: [],
  auctionVehicles: [],
  auctionVehicleIds: [],
  dealDoneVehicles: [],
  wonVehicles: [],
  lostVehicles: [],
  editVehicle: undefined,
  vehicleDetails: undefined,
  vehicleDetailsId: '',
  customTime: undefined,
  timeStamp: undefined
};

export default function vehiclesReducer(state = initialState, action) {
  switch (action.type) {
    case 'VEHICLES_FIND_MYVEHICLES': {
      if (action.data) {
        const myVehicles = action.data.myVehicles;

        return {
          ...state,
          myVehicles: myVehicles
        };
      }
      return initialState;
    }
    case 'VEHICLES_FIND_BASKETVEHICLES': {
      if (action.data) {
        const basketVehicles = action.data.basketVehicles;
        let basketVehicleIds = [];
        basketVehicles.forEach(vehicle => {
          basketVehicleIds.push(vehicle._id);
        });
        return {
          ...state,
          basketVehicles: basketVehicles,
          basketVehicleIds: basketVehicleIds
        };
      }
      return initialState;
    }
    case 'VEHICLES_FIND_VEHICLEDETAILS': {
      if (action.data) {
        const vehicleDetails = action.data.vehicleDetails;

        return {
          ...state,
          vehicleDetails: vehicleDetails
        };
      }
      return initialState;
    }
    case 'VEHICLES_FIND_AUCTIONVEHICLES': {
      if (action.data) {
        const auctionVehicles = action.data.auctionVehicles;
        let auctionVehicleIds = [];
        auctionVehicles.forEach(vehicle => {
          auctionVehicleIds.push(vehicle._id);
        });
        return {
          ...state,
          auctionVehicles: auctionVehicles,
          auctionVehicleIds: auctionVehicleIds
        };
      }
      return initialState;
    }
    case 'VEHICLES_FIND_DEALDONEVEHICLES': {
      if (action.data) {
        const dealDoneVehicles = action.data.dealDoneVehicles;
        return {
          ...state,
          dealDoneVehicles: dealDoneVehicles
        };
      }
      return initialState;
    }
    case 'VEHICLES_FIND_WONVEHICLES': {
      if (action.data) {
        const wonVehicles = action.data.wonVehicles;
        return {
          ...state,
          wonVehicles: wonVehicles
        };
      }
      return initialState;
    }
    case 'VEHICLES_FIND_LOSTVEHICLES': {
      if (action.data) {
        const lostVehicles = action.data.lostVehicles;
        return {
          ...state,
          lostVehicles: lostVehicles
        };
      }
      return initialState;
    }
    case 'VEHICLES_SET_EDITVEHICLE': {
      if (action.data) {
        const editVehicle = action.data;

        return {
          ...state,
          editVehicle: editVehicle
        };
      }
      return initialState;
    }
    case 'VEHICLES_SET_VEHICLEDETAILSID': {
      if (action.data) {
        const id = action.data;
        return {
          ...state,
          vehicleDetailsId: id
        };
      }
      return initialState;
    }
    case 'BIDDING_UPDATE_LIST': {
      if (action.data) {
        let auctionVehicles = state.auctionVehicles;
        let updatedVehicles = action.data.vehicles;
        auctionVehicles.map(obj => {
          obj.highestBid = updatedVehicles.find(
            o => o._id === obj._id
          ).highestBid;
          obj.addAuctionMinutes = updatedVehicles.find(
            o => o._id === obj._id
          ).addAuctionMinutes;
          obj.highestBidder = updatedVehicles.find(
            o => o._id === obj._id
          ).highestBidder;
        });
        const timeStamp = action.data.timeStamp;
        const customTime = action.data.customTime
          ? action.data.customTime
          : undefined;
        return {
          ...state,
          auctionVehicles: auctionVehicles,
          timeStamp: timeStamp,
          customTime: customTime
        };
      }
      return initialState;
    }
    case 'BIDDING_UPDATE_BASKET': {
      if (action.data) {
        let basketVehicles = state.basketVehicles;
        let updatedVehicles = action.data.vehicles;
        basketVehicles.map(obj => {
          obj.highestBid = updatedVehicles.find(
            o => o._id === obj._id
          ).highestBid;
          obj.addAuctionMinutes = updatedVehicles.find(
            o => o._id === obj._id
          ).addAuctionMinutes;
          obj.highestBidder = updatedVehicles.find(
            o => o._id === obj._id
          ).highestBidder;
        });
        const timeStamp = action.data.timeStamp;
        const customTime = action.data.customTime
          ? action.data.customTime
          : undefined;
        return {
          ...state,
          basketVehicles: basketVehicles,
          timeStamp: timeStamp,
          customTime: customTime
        };
      }
      return initialState;
    }
    case 'BIDDING_UPDATE_SINGLE': {
      if (action.data) {
        let vehicleDetails = state.vehicleDetails || {};
        vehicleDetails.highestBid = action.data.vehicle.highestBid;
        vehicleDetails.addAuctionMinutes =
          action.data.vehicle.addAuctionMinutes;
        const timeStamp = action.data.timeStamp;

        return {
          ...state,
          vehicleDetails: vehicleDetails,
          timeStamp: timeStamp
        };
      }
      return initialState;
    }
    default:
      return state;
  }
}
