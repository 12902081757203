import { getToken } from 'src/redux/selectors';

export function approveVehicle(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/approvevehicle',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to approve vehicle' });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function rejectVehicle(id, reason) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/rejectvehicle',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id, rejectReason: reason })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to reject vehicle' });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function makeAnOffer(id, amount) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/makeanoffer',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id, offerAmount: amount })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to make an offer' });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function setReserve(id, amount) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/setreserve',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id, reserve: amount })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to set a reserve' });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function auctionVehicle(id, onAuction) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/auctionvehicle',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id, onAuction: onAuction })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to auction vehicle' });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function setVehicleAuctionDate(vehicle) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/setvehicleauctiondate',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify(vehicle)
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to set vehicle auction date' });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function auctionVehicles(ids, onAuction) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/auctionvehicles',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ ids: ids, onAuction: onAuction })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to auction vehicles' });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function completeDeal(id, failed) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/completedeal',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id, failed: failed })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to complete deal' });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function updateFollowUp(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/updatefollowup',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to update follow-up date' });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function updateNotes(id, note) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/updatenotes',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id, note: note })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to update follow-up date' });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function deleteVehicle(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/deletevehicle',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id })
        }
      )
        .then(res => {
          if (!res.ok) {
            return reject({ message: 'Failed to delete vehicle' });
          }
          res.text();
        })
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            return resolve({ message: response });
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function changeDealer(data) {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      return fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/changedealer',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify(data)
        }
      )
        .then(async res => {
          if (!res.ok) {
            return reject({ message: await res.text() });
          } else {
            return resolve({ message: await res.text() });
          }
        })
        .catch(async error => {
          return reject(error);
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findVehicleDetails(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/findvehicledetails?id=' +
          id,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: 'ADMIN_FIND_VEHICLEDETAILS',
                data: parsedResponse
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findVehicleBidInfo(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/findvehiclebidinfo?id=' +
          id,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: 'ADMIN_FIND_VEHICLEBIDINFO',
                data: parsedResponse
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findAllVehicles() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/findallvehicles',
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'ADMIN_FIND_ALLVEHICLES',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findDealVehicles(type) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/finddealvehicles?type=' +
          type,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'ADMIN_FIND_DEALVEHICLES',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findApprovedVehicles() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/findapprovedvehicles',
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'ADMIN_FIND_APPROVEDVEHICLES',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findAuctionHistoryVehicles(dateFrom, dateTo) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/findauctionhistoryvehicles?dateFrom=' +
          dateFrom +
          '&dateTo=' +
          dateTo,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'ADMIN_FIND_AUCTIONHISTORYVEHICLES',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function setVehicleDetailsId(id) {
  return dispatch =>
    dispatch({
      type: 'ADMIN_SET_VEHICLEDETAILSID',
      data: id
    });
}

export function setFromHistory(boolean) {
  return dispatch =>
    dispatch({
      type: 'ADMIN_SET_FROMHISTORY',
      data: boolean
    });
}

export function findAllUsers() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/findallusers',
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'ADMIN_FIND_ALLUSERS',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function deleteUser(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch('https://www.devvcsappcloud.com/verkoopnou/api/admin/deleteuser', {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + getToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({ id: id })
      }).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findAllDealers() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/findalldealers',
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'ADMIN_FIND_ALLDEALERS',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function deleteDealer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/deletedealer',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function approveDealer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/approvedealer',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function activateDealer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/activatedealer',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function deactivateDealer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/deactivatedealer',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function activatePrices(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/activateprices',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function deactivatePrices(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/deactivateprices',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findCalendar() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/findcalendar',
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'ADMIN_FIND_CALENDAR',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function saveCalendar(calendar) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/admin/savecalendar',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ calendar: calendar })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve({ message: await res.text() });
      });
    }).catch(err => {
      throw err.message;
    });
}
