import React from 'react';
import styled from 'styled-components';
import {
  Icon,
  Select,
  Row,
  Col,
  Spin,
  Input,
  Collapse,
  DatePicker,
  Card,
  Button,
  Upload,
  Radio,
  Popover,
  Descriptions,
  Modal,
  Tooltip
} from 'antd';
import { CirclePicker } from 'react-color';
import Resizer from 'react-image-file-resizer';
import { Stage, Layer, Text, Image, Circle, Group } from 'react-konva';
import Konva from 'konva';
import { ButtonSquare } from 'src/components/elements/Button';
import Notify from 'src/components/meta/Notification';
import halfWornTyre from 'src/static/tyreImages/halfWorn.jpg';
import newTyre from 'src/static/tyreImages/new.jpg';
import wornTyre from 'src/static/tyreImages/worn.jpg';
import dropImage from 'src/static/icons/carImageUpload/placeholder.png';
import leftSedan from 'src/static/damageImages/Sedan_Left.svg';
import rightSedan from 'src/static/damageImages/Sedan_Right.svg';
import frontSedan from 'src/static/damageImages/Sedan_Front.svg';
import backSedan from 'src/static/damageImages/Sedan_Back.svg';
import topSedan from 'src/static/damageImages/Sedan_Top.svg';
import leftSUV from 'src/static/damageImages/SUV_Left.svg';
import rightSUV from 'src/static/damageImages/SUV_Right.svg';
import frontSUV from 'src/static/damageImages/SUV_Front.svg';
import backSUV from 'src/static/damageImages/SUV_Back.svg';
import topSUV from 'src/static/damageImages/SUV_Top.svg';
import leftHatchback from 'src/static/damageImages/Hatchback_Left.svg';
import rightHatchback from 'src/static/damageImages/Hatchback_Right.svg';
import frontHatchback from 'src/static/damageImages/Hatchback_Front.svg';
import backHatchback from 'src/static/damageImages/Hatchback_Back.svg';
import topHatchback from 'src/static/damageImages/Hatchback_Top.svg';
import leftBakkie from 'src/static/damageImages/Bakkie_Left.svg';
import rightBakkie from 'src/static/damageImages/Bakkie_Right.svg';
import frontBakkie from 'src/static/damageImages/Bakkie_Front.svg';
import backBakkie from 'src/static/damageImages/Bakkie_Back.svg';
import topBakkie from 'src/static/damageImages/Bakkie_Top.svg';
import leftVan from 'src/static/damageImages/Van_Left.svg';
import rightVan from 'src/static/damageImages/Van_Right.svg';
import frontVan from 'src/static/damageImages/Van_Front.svg';
import backVan from 'src/static/damageImages/Van_Back.svg';
import topVan from 'src/static/damageImages/Van_Top.svg';
import upArrow from 'src/static/icons/collapse/up-arrow.svg';
import downArrow from 'src/static/icons/collapse/down-arrow.svg';
import prepDefinition from 'src/components/meta/PdfDefinition';
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
const damageImageObject = {
  leftSedan: leftSedan,
  rightSedan: rightSedan,
  frontSedan: frontSedan,
  backSedan: backSedan,
  topSedan: topSedan,
  leftSUV: leftSUV,
  rightSUV: rightSUV,
  frontSUV: frontSUV,
  backSUV: backSUV,
  topSUV: topSUV,
  leftHatchback: leftHatchback,
  rightHatchback: rightHatchback,
  frontHatchback: frontHatchback,
  backHatchback: backHatchback,
  topHatchback: topHatchback,
  leftBakkie: leftBakkie,
  rightBakkie: rightBakkie,
  frontBakkie: frontBakkie,
  backBakkie: backBakkie,
  topBakkie: topBakkie,
  leftVan: leftVan,
  rightVan: rightVan,
  frontVan: frontVan,
  backVan: backVan,
  topVan: topVan
};
const numeral = require('numeral');
const Option = Select.Option;
const { Meta } = Card;
const Selection = styled(Select)`
  .ant-select-selection {
    border: 1px solid #bdb2b2;
    height: 45px;
    font-size: 12px;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .ant-select-selection__placeholder {
    top: 21px;
  }
  .ant-select-selection__rendered {
    height: 44px;
  }
  .ant-select-selection-selected-value {
    position: relative;
    top: 6px;
  }
`;
const DatePick = styled(DatePicker)`
  .ant-calendar-picker-input {
    border: 1px solid #bdb2b2;
    height: 45px;
    font-size: 12px;
  }
`;

const ArrowIcon = styled(Icon)`
  color: #00888e;
  position: absolute;
  margin-left: 8%;
  margin-top: 0.5em;
  font-size: 20px;
  @media only screen and (max-width: 770px) {
    display: none;
  }
`;
const RadioButtonNo = styled(Radio.Button)`
  color: red !important;
  border: 1px solid red !important;
  border-radius: 10% !important;
  margin-top: 0.7em !important;
  &&.ant-radio-button-wrapper-checked {
    background: red;
    color: white !important;
    border: 1px solid red !important;
    box-shadow: none;
  }
  &&.ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
`;
const RadioButtonYes = styled(Radio.Button)`
  color: green !important;
  margin-top: 0.7em !important;
  margin-right: 1em !important;
  border: 1px solid green !important;
  border-radius: 10% !important;
  &&.ant-radio-button-wrapper-checked {
    background: green;
    color: white !important;
    border: 1px solid green !important;
    box-shadow: none;
  }
  &&.ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
`;
const Cards = styled(Card)`
  .ant-card-body {
    display: none !important;
  }
`;
const DescriptionsStyle = styled(Descriptions)`
  .ant-descriptions-view {
    background: white;
  }
`;

const DescriptionsStyle2 = styled(Descriptions)`
  .ant-descriptions-title {
    text-align: center;
  }
  .ant-descriptions-view {
    background: white;
  }
  &&.ant-descriptions-bordered .ant-descriptions-item-label,
  .ant-descriptions-bordered .ant-descriptions-item-content {
    padding: 5px 24px !important;
    border-right: 1px solid #e8e8e8;
  }
  &&.ant-descriptions-bordered .ant-descriptions-item-content {
    padding: 5px 24px !important;
    border-right: 1px solid #e8e8e8;
  }
`;

const customPanelStyle = {
  background: 'white',
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
  marginBottom: 7,
  border: 0,
  overflow: 'hidden',
  color: '#00B4BC',
  paddingLeft: 20,
  paddingRight: 20
};
const customInputStyle = {
  border: '1px solid #BDB2B2',
  height: 45,
  fontSize: 12,
  marginBottom: '0.5em',
  marginTop: '0.5em'
};
class URLImage extends React.Component {
  state = {
    image: null
  };
  componentDidMount() {
    this.loadImage();
  }
  componentDidUpdate(oldProps) {
    if (oldProps.src !== this.props.src) {
      this.loadImage();
    }
  }
  componentWillUnmount() {
    this.image.removeEventListener('load', this.handleLoad);
  }
  loadImage() {
    this.image = new window.Image();
    this.image.setAttribute('crossOrigin', 'anonymous');
    this.image.src = this.props.src;
    this.image.addEventListener('load', this.handleLoad);
  }
  handleLoad = () => {
    this.setState({
      image: this.image
    });
  };
  render() {
    return (
      <Image
        preventDefault={false}
        width={this.props.width}
        height={this.props.height}
        x={this.props.x}
        y={this.props.y}
        image={this.state.image}
        ref={node => {
          this.imageNode = node;
        }}
      />
    );
  }
}
class EditVehicle extends React.Component {
  exportToPdf = () => {
    this.setState({ loading: true });
    const { vfs } = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;
    let data = Object.assign({}, this.state);
    if (this.stageRef.current) {
      data.damage = this.stageRef.current.toDataURL({
        width: 950,
        height: window.innerWidth <= 770 ? 880 : 470
      });
    }
    prepDefinition(data).then(definition => {
      pdfMake.createPdf(definition).download('Vehicle_Details.pdf', () => {
        this.setState({ loading: false });
      });
    });
  };

  editVehicle = event => {
    this.setState(
      {
        loading: true,
        whereBought:
          this.state.whereBoughtSelection + ' - ' + this.state.whereBought,
        licenseExpire: this.state.licenseExpireDate
          ? this.state.licenseExpire + ' - ' + this.state.licenseExpireDate
          : this.state.licenseExpire
      },
      () => {
        let data = this.state;
        if (this.stageRef.current) {
          data.damage = this.stageRef.current.toDataURL({
            width: 950,
            height: window.innerWidth <= 770 ? 880 : 470
          });
        }
        this.props
          .saveVehicle(data)
          .then(vehicleId => {
            this.setState({ loading: false });
            Notify('success', 'Vehicle saved succesfully');
            this.props.push('/user/myvehicles');
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      }
    );
  };
  componentWillMount = () => {
    this.setState({ ...this.props.editVehicle });
  };
  componentDidMount = () => {
    this.componentDidUpdate();
  };
  componentWillUnmount = () => {
    this.props.setEditVehicle(undefined);
  };
  update = true;
  componentDidUpdate = (prevProps, prevState) => {
    if (this.update) {
      this.update = false;
      let progress = 0;
      const {
        make,
        model,
        variant,
        year,
        colour,
        province,
        city,
        transmission,
        mileage,
        sellingReason,
        owners,
        licenseExpire,
        fuel,
        whereBought
      } = this.state;
      if (
        make !== undefined &&
        model !== undefined &&
        variant !== undefined &&
        year !== undefined &&
        fuel !== undefined &&
        province !== undefined &&
        city !== undefined &&
        transmission !== undefined &&
        mileage !== '' &&
        whereBought !== undefined
      ) {
        this.setState({
          panel1Status: window.i18n.translate('Complete'),
          panel1Color: 'green'
        });
      } else if (
        make !== undefined ||
        model !== undefined ||
        variant !== undefined ||
        year !== undefined ||
        fuel !== undefined ||
        province !== undefined ||
        city !== undefined ||
        transmission !== undefined ||
        mileage !== '' ||
        whereBought !== undefined
      ) {
        this.setState({
          panel1Status: window.i18n.translate('Incomplete'),
          panel1Color: 'red'
        });
      } else {
        this.setState({
          panel1Status: window.i18n.translate('Outstanding'),
          panel1Color: '#BDB2B2'
        });
      }
      progress = make !== undefined ? progress + 1 : progress;
      progress = model !== undefined ? progress + 1 : progress;
      progress = variant !== undefined ? progress + 1 : progress;
      progress = year !== undefined ? progress + 1 : progress;
      progress = fuel !== undefined ? progress + 1 : progress;
      progress = province !== undefined ? progress + 1 : progress;
      progress = city !== undefined ? progress + 1 : progress;
      progress = transmission !== undefined ? progress + 1 : progress;
      progress = mileage !== '' ? progress + 1 : progress;
      progress = whereBought !== undefined ? progress + 1 : progress;
      const {
        condition,
        spareKeys,
        mechanicalIssues,
        bodyWork,
        windscreenDamage,
        magsDamage
      } = this.state;
      if (
        spareKeys !== '' &&
        mechanicalIssues !== '' &&
        bodyWork !== undefined &&
        windscreenDamage !== undefined &&
        magsDamage !== ''
      ) {
        this.setState({
          panel3Status: window.i18n.translate('Complete'),
          panel3Color: 'green'
        });
      } else if (
        spareKeys !== '' ||
        mechanicalIssues !== '' ||
        bodyWork !== undefined ||
        windscreenDamage !== undefined ||
        magsDamage !== ''
      ) {
        this.setState({
          panel3Status: window.i18n.translate('Incomplete'),
          panel3Color: 'red'
        });
      } else {
        this.setState({
          panel3Status: window.i18n.translate('Outstanding'),
          panel3Color: '#BDB2B2'
        });
      }
      progress = spareKeys !== '' ? progress + 1 : progress;
      progress = mechanicalIssues !== '' ? progress + 1 : progress;
      progress = bodyWork !== undefined ? progress + 1 : progress;
      progress = windscreenDamage !== undefined ? progress + 1 : progress;
      progress = magsDamage !== '' ? progress + 1 : progress;
      const { serviceHistory, financed, servicePlan, warranty } = this.state;
      if (
        serviceHistory !== undefined &&
        financed !== '' &&
        servicePlan !== '' &&
        warranty !== ''
      ) {
        this.setState({
          panel4Status: window.i18n.translate('Complete'),
          panel4Color: 'green'
        });
      } else if (
        serviceHistory !== undefined ||
        financed !== '' ||
        servicePlan !== '' ||
        warranty !== ''
      ) {
        this.setState({
          panel4Status: window.i18n.translate('Incomplete'),
          panel4Color: 'red'
        });
      } else {
        this.setState({
          panel4Status: window.i18n.translate('Outstanding'),
          panel4Color: '#BDB2B2'
        });
      }
      progress = serviceHistory !== undefined ? progress + 1 : progress;
      progress = financed !== '' ? progress + 1 : progress;
      progress = servicePlan !== '' ? progress + 1 : progress;
      progress = warranty !== '' ? progress + 1 : progress;
      const { frontTyres, backTyres } = this.state;
      if (frontTyres !== undefined && backTyres !== undefined) {
        this.setState({
          panelTyreStatus: window.i18n.translate('Complete'),
          panelTyreColor: 'green'
        });
      } else if (frontTyres !== undefined || backTyres !== undefined) {
        this.setState({
          panelTyreStatus: window.i18n.translate('Incomplete'),
          panelTyreColor: 'red'
        });
      } else {
        this.setState({
          panelTyreStatus: window.i18n.translate('Outstanding'),
          panelTyreColor: '#BDB2B2'
        });
      }
      progress = frontTyres !== undefined ? progress + 1 : progress;
      progress = backTyres !== undefined ? progress + 1 : progress;
      const { vehicleType } = this.state;
      if (vehicleType !== undefined) {
        this.setState({
          panelDamageStatus: window.i18n.translate('Complete'),
          panelDamageColor: 'green'
        });
      } else if (vehicleType !== undefined) {
        this.setState({
          panelDamageStatus: window.i18n.translate('Incomplete'),
          panelDamageColor: 'red'
        });
      } else {
        this.setState({
          panelDamageStatus: window.i18n.translate('Outstanding'),
          panelDamageColor: '#BDB2B2'
        });
      }
      progress = vehicleType !== undefined ? progress + 1 : progress;
      const {
        mainImage,
        frontImage,
        leftImage,
        rightImage,
        backImage,
        interiorImage,
        engineImage,
        bootImage,
        service1Image,
        service2Image
      } = this.state;
      if (mainImage !== undefined) {
        this.setState({
          panel5Status: window.i18n.translate('Complete'),
          panel5Color: 'green'
        });
      } else if (
        mainImage !== undefined ||
        frontImage !== undefined ||
        leftImage !== undefined ||
        rightImage !== undefined ||
        backImage !== undefined ||
        interiorImage !== undefined ||
        engineImage !== undefined ||
        bootImage !== undefined ||
        service1Image !== undefined ||
        service2Image !== undefined
      ) {
        this.setState({
          panel5Status: window.i18n.translate('Incomplete'),
          panel5Color: 'red'
        });
      } else {
        this.setState({
          panel5Status: window.i18n.translate('Outstanding'),
          panel5Color: '#BDB2B2'
        });
      }
      progress = mainImage !== undefined ? progress + 1 : progress;
      const { price } = this.state;
      if (price !== '') {
        this.setState({
          panel6Status: window.i18n.translate('Complete'),
          panel6Color: 'green'
        });
      } else if (price !== '') {
        this.setState({
          panel6Status: window.i18n.translate('Incomplete'),
          panel6Color: 'red'
        });
      } else {
        this.setState({
          panel6Status: window.i18n.translate('Outstanding'),
          panel6Color: '#BDB2B2'
        });
      }
      progress = price !== '' ? progress + 1 : progress;
      this.setState({ overallProgress: ((progress / 24) * 100).toFixed(0) });
    }
    setTimeout(() => {
      this.update = true;
    }, 50);
  };
  constructor(props) {
    super(props);
    this.stageRef = React.createRef();
    this.state = {
      vehicleType: undefined,
      whereBoughtSelection: undefined,
      colourVisible: false,
      loading: false,
      review: false,
      overallProgress: 0,
      panel1Status: window.i18n.translate('Outstanding'),
      panel2Status: window.i18n.translate('Outstanding'),
      panel3Status: window.i18n.translate('Outstanding'),
      panel4Status: window.i18n.translate('Outstanding'),
      panelTyreStatus: window.i18n.translate('Outstanding'),
      panelDamageStatus: window.i18n.translate('Outstanding'),
      panel5Status: window.i18n.translate('Outstanding'),
      panel6Status: window.i18n.translate('Outstanding'),
      panel1Color: '#BDB2B2',
      panel2Color: '#BDB2B2',
      panel3Color: '#BDB2B2',
      panel4Color: '#BDB2B2',
      panelTyreColor: '#BDB2B2',
      panelDamageColor: '#BDB2B2',
      panel5Color: '#BDB2B2',
      panel6Color: '#BDB2B2',
      make: undefined,
      model: undefined,
      variant: undefined,
      year: undefined,
      colour: '',
      fuel: undefined,
      extras: '',
      province: undefined,
      city: undefined,
      transmission: undefined,
      mileage: '',
      whereBought: undefined,
      sellingReason: '',
      owners: '',
      licenseExpire: '',
      comments: '',
      condition: '',
      spareKeys: '',
      mechanicalIssues: '',
      interiorCondition: '',
      bodyWork: undefined,
      windscreenDamage: undefined,
      magsDamage: '',
      serviceHistory: undefined,
      financed: '',
      servicePlan: '',
      warranty: '',
      frontTyres: undefined,
      backTyres: undefined,
      mainImage: undefined,
      frontImage: undefined,
      leftImage: undefined,
      rightImage: undefined,
      backImage: undefined,
      interiorImage: undefined,
      engineImage: undefined,
      bootImage: undefined,
      service1Image: undefined,
      service2Image: undefined,
      price: '',
      damageType: {
        Spray: [],
        Scratch: [],
        Chip: [],
        Dent: [],
        Rust: [],
        Hail: [],
        Cracked: [],
        Broken: []
      },
      currentStep: '-1'
    };
  }

  onResetForm = e => {
    this.setState({
      whereBoughtSelection: undefined,
      loading: false,
      overallProgress: 0,
      panel1Status: window.i18n.translate('Outstanding'),
      panel2Status: window.i18n.translate('Outstanding'),
      panel3Status: window.i18n.translate('Outstanding'),
      panel4Status: window.i18n.translate('Outstanding'),
      panelTyreStatus: window.i18n.translate('Outstanding'),
      panelDamageStatus: window.i18n.translate('Outstanding'),
      panel5Status: window.i18n.translate('Outstanding'),
      panel6Status: window.i18n.translate('Outstanding'),
      panel1Color: '#BDB2B2',
      panel2Color: '#BDB2B2',
      panel3Color: '#BDB2B2',
      panel4Color: '#BDB2B2',
      panelTyreColor: '#BDB2B2',
      panelDamageColor: '#BDB2B2',
      panel5Color: '#BDB2B2',
      panel6Color: '#BDB2B2',
      make: undefined,
      model: undefined,
      variant: undefined,
      year: undefined,
      colour: '',
      fuel: undefined,
      extras: '',
      province: undefined,
      city: undefined,
      transmission: undefined,
      mileage: '',
      whereBought: undefined,
      sellingReason: '',
      owners: '',
      licenseExpire: '',
      comments: '',
      condition: '',
      spareKeys: '',
      mechanicalIssues: '',
      interiorCondition: '',
      bodyWork: undefined,
      windscreenDamage: undefined,
      magsDamage: '',
      serviceHistory: undefined,
      financed: '',
      servicePlan: '',
      warranty: '',
      frontTyres: undefined,
      backTyres: undefined,
      mainImage: undefined,
      frontImage: undefined,
      leftImage: undefined,
      rightImage: undefined,
      backImage: undefined,
      interiorImage: undefined,
      engineImage: undefined,
      bootImage: undefined,
      service1Image: undefined,
      service2Image: undefined,
      price: '',
      damageType: {
        Spray: [],
        Scratch: [],
        Chip: [],
        Dent: [],
        Rust: [],
        Hail: [],
        Cracked: [],
        Broken: []
      }
    });
  };
  handleDragStart = e => {
    e.target.setAttrs({
      shadowOffset: {
        x: 15,
        y: 15
      },
      scaleX: 1.1,
      scaleY: 1.1
    });
  };
  handleDragEnd = e => {
    e.target.to({
      duration: 0.5,
      easing: Konva.Easings.ElasticEaseOut,
      scaleX: 1,
      scaleY: 1,
      shadowOffsetX: 5,
      shadowOffsetY: 5
    });
  };
  addDamageType = type => {
    let damageType = this.state.damageType;
    damageType[type].push({ key: damageType[type].length + 1 });
    this.setState({
      damageType
    });
  };
  removeAllDamage = view => {
    let damageType = this.state.damageType;
    let exist = this.state;
    exist['damage'] = undefined;
    damageType['Spray'] = [];
    damageType['Scratch'] = [];
    damageType['Chip'] = [];
    damageType['Dent'] = [];
    damageType['Rust'] = [];
    damageType['Hail'] = [];
    damageType['Cracked'] = [];
    damageType['Broken'] = [];
    this.setState({
      damageType,
      exist
    });
  };
  onSelectMake = e => {
    this.setState({
      loading: true,
      variant: undefined,
      model: undefined,
      make: e
    });
    this.props
      .findModels(e, this.state.year)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(e => {
        console.log(e);
      });
  };
  onSelectModel = e => {
    this.setState({ loading: true, variant: undefined, model: e });
    this.props
      .findVariants(e, this.state.year)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(e => {
        console.log(e);
      });
  };
  onSelectVariant = e => {
    this.setState({ variant: e });
  };
  onSelectYear = e => {
    this.setState({
      loading: true,
      make: undefined,
      variant: undefined,
      model: undefined,
      year: e
    });
    this.props
      .findMakes(e)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(e => {
        console.log(e);
      });
  };
  onSelectProvince = e => {
    this.setState({ loading: true, province: e });
    this.props
      .findCities(e)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(e => {
        console.log(e);
      });
  };
  handleMainImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        mainImage: imageUrl
      });
    });
  };
  handleFrontImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        frontImage: imageUrl
      });
    });
  };
  handleLeftImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        leftImage: imageUrl
      });
    });
  };
  handleRightImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        rightImage: imageUrl
      });
    });
  };
  handleBackImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        backImage: imageUrl
      });
    });
  };
  handleInteriorImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        interiorImage: imageUrl
      });
    });
  };
  handleEngineImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        engineImage: imageUrl
      });
    });
  };
  handleBootImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        bootImage: imageUrl
      });
    });
  };
  handleService1Image = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        service1Image: imageUrl
      });
    });
  };
  handleService2Image = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        service2Image: imageUrl
      });
    });
  };
  handleImageUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };
  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      Notify('error', window.i18n.translate('ImageSmaller'));
    }
    return false;
  };
  getBase64 = (img, callback) => {
    Resizer.imageFileResizer(img, 800, 800, 'JPG', 100, 0, uri => {
      callback(uri);
    });
  };
  onCancel = e => {
    this.props.push('/user/myvehicles');
  };
  checkIfNumber = e => {
    if (e.currentTarget.value.length > 12) {
      if (Number.isInteger(parseInt(e.currentTarget.value.substr(12)))) {
        this.setState({ owners: e.currentTarget.value.substr(12) });
      } else {
        this.setState({ owners: "I don't know" });
      }
    } else if (e.currentTarget.value === "I don't kno") {
      this.setState({ owners: '' });
    } else if (Number.isInteger(parseInt(e.currentTarget.value))) {
      this.setState({ owners: e.currentTarget.value });
    } else {
      this.setState({ owners: "I don't know" });
    }
  };
  getColour = e => {
    const colours = [
      { hex: '#f0efe8', string: window.i18n.translate('White') },
      { hex: '#191919', string: window.i18n.translate('Black') },
      { hex: '#d01f05', string: window.i18n.translate('Red') },
      { hex: '#006bea', string: window.i18n.translate('Blue') },
      { hex: '#1ac736', string: window.i18n.translate('Green') },
      { hex: '#565656', string: window.i18n.translate('Grey') },
      { hex: '#d0b485', string: window.i18n.translate('Champagne') },
      { hex: '#bbbbbb', string: window.i18n.translate('Silver') },
      { hex: '#f9dc2b', string: window.i18n.translate('Yellow') },
      { hex: '#ea961d', string: window.i18n.translate('Orange') },
      { hex: '#75591e', string: window.i18n.translate('Brown') },
      { hex: '#63142c', string: window.i18n.translate('Burgandy') }
    ];
    colours.forEach(colour => {
      if (colour.hex === e.hex) {
        this.setState({
          colour: colour.string,
          hex: colour.hex,
          colourVisible: false
        });
      }
    });
  };
  handleVisibleChange = colourVisible => {
    this.setState({ colourVisible });
  };
  validateRequired = () => {
    if (
      this.state.make === undefined ||
      this.state.model === undefined ||
      this.state.variant === undefined ||
      this.state.year === undefined ||
      this.state.transmission === undefined ||
      this.state.mileage === '' ||
      this.state.fuel === undefined ||
      this.state.province === undefined ||
      this.state.city === undefined ||
      this.state.whereBought === undefined ||
      this.state.spareKeys === '' ||
      this.state.mechanicalIssues === '' ||
      this.state.bodyWork === undefined ||
      this.state.windscreenDamage === undefined ||
      this.state.magsDamage === '' ||
      this.state.serviceHistory === undefined ||
      this.state.financed === '' ||
      this.state.servicePlan === '' ||
      this.state.warranty === '' ||
      this.state.mainImage === undefined ||
      this.state.price === '' ||
      this.state.reviewForAuction
    ) {
      return true;
    } else {
      return false;
    }
  };
  handleSendToAuction = () => {
    this.setState(
      {
        loading: true,
        whereBought:
          this.state.whereBoughtSelection + ' - ' + this.state.whereBought,
        licenseExpire: this.state.licenseExpireDate
          ? this.state.licenseExpire + ' - ' + this.state.licenseExpireDate
          : this.state.licenseExpire,
        reviewForAuction: true
      },
      () => {
        let data = this.state;
        if (this.stageRef.current) {
          data.damage = this.stageRef.current.toDataURL({
            width: 950,
            height: window.innerWidth <= 770 ? 880 : 470
          });
        }
        data.pdf = true;
        this.props
          .saveVehicle(data)
          .then(vehicleId => {
            this.props
              .sendNotification(
                'admin',
                ['admin'],
                window.i18n.translate('VehicleSubmittedForReview'),

                'review',
                vehicleId,
                true,
                'User added vehicle'
              )
              .then(() => {
                this.setState({ loading: false });
                Notify(
                  'success',
                  window.i18n.translate('VehicleSentreViewSuccesfully')
                );
                var that = this;
                Modal.info({
                  centered: true,
                  title: 'Vehicle Submitted for Review',
                  content: (
                    <div>
                      <h4>
                        {window.i18n.translate(
                          'YourVehicleWasSubmittedForReviewToBePlacedOnAuction'
                        )}
                      </h4>
                      <h4>
                        {window.i18n.translate(
                          'YouWillBeNotifiedOnceThisIsDone'
                        )}
                      </h4>
                    </div>
                  ),
                  onOk() {
                    that.props.push('/user/myvehicles');
                  }
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      }
    );
  };
  getImageType = (type, view) => {
    if (
      this.state[view + 'Damage'] &&
      this.state[view + 'Damage'].search(this.state._id) > -1
    ) {
      return (
        'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
        this.state[view + 'Damage']
      );
    } else {
      return damageImageObject[view + type];
    }
  };
  getViewImageTypes = view => {
    if (this.state[view + 'Image'].search(this.state._id) > -1) {
      return (
        'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
        this.state[view + 'Image']
      );
    } else {
      return this.state[view + 'Image'];
    }
  };
  stageGroup = (i, text, colour) => {
    return (
      <Group
        key={i.key}
        draggable
        onDragStart={this.handleDragStart}
        onDragEnd={this.handleDragEnd}
        x={50}
        y={50}
      >
        <Circle
          key={i.key}
          x={8}
          y={8}
          numPoints={5}
          radius={8}
          fill={colour}
          opacity={0.8}
          shadowColor="black"
          shadowBlur={10}
          shadowOpacity={0.6}
        />
        <Text
          text={text}
          fontSize={13}
          x={20}
          y={2}
          fontWeight={700}
          fontFamily="Calibri"
          fill="#19999c"
          shadowColor="black"
          shadowBlur={10}
          shadowOpacity={0.6}
        />
      </Group>
    );
  };
  processReview = () => {
    let data = this.state;
    if (this.stageRef.current) {
      data.damage = this.stageRef.current.toDataURL({
        width: 950,
        height: window.innerWidth <= 770 ? 880 : 470
      });
    }
    this.setState({ data, review: !this.state.review });
  };
  changeStep = e => {
    // this.setState({ currentStep: e });
    setTimeout(() => {
      if (e === '3') {
        let elmnt = document.getElementById('damageAsRow');
        elmnt.scrollIntoView();
      } else if (e === '4') {
        let elmnt = document.getElementById('serviceRow');
        elmnt.scrollIntoView();
      } else if (e === 'Tyre') {
        let elmnt = document.getElementById('tyreRow');
        elmnt.scrollIntoView();
      } else if (e === 'Damage') {
        let elmnt = document.getElementById('damageRow');
        elmnt.scrollIntoView();
      } else if (e === '5') {
        let elmnt = document.getElementById('imageRow');
        elmnt.scrollIntoView();
      } else if (e === '6') {
        let elmnt = document.getElementById('priceRow');
        elmnt.scrollIntoView();
      } else if (e === '7') {
        let elmnt = document.getElementById('auctionRow');
        elmnt.scrollIntoView();
      }
    }, 500);
  };
  render() {
    const MoreButtonContent = (
      <div>
        <Col
          style={{
            margin: 'auto',
            textAlign: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            type="primary"
            style={{
              height: 46,
              width: 171,
              marginRight: '0.5em',
              marginLeft: '0.5em',
              marginBottom: 15
            }}
            onClick={this.onResetForm}
          >
            Reset
          </Button>

          <Button
            style={{
              height: 46,
              width: 171,
              marginRight: '0.5em',
              marginLeft: '0.5em',
              marginBottom: 15
            }}
            onClick={this.onCancel}
          >
            Cancel
          </Button>

          <Button
            type="primary"
            style={{
              height: 46,
              width: 171,
              marginRight: '0.5em',
              marginLeft: '0.5em',
              marginBottom: 15
            }}
            onClick={this.processReview}
          >
            {this.state.review ? 'Close Review' : 'Review'}
          </Button>
          <Button
            style={{
              height: 46,
              width: 171,
              marginRight: '0.5em',
              marginLeft: '0.5em',
              marginBottom: 15
            }}
            disabled={
              this.validateRequired() && this.state.approved === false
                ? false
                : true
            }
            onClick={this.editVehicle}
          >
            Save Vehicle
          </Button>
          {this.validateRequired() ? (
            <Tooltip
              placement="top"
              title={
                this.state.reviewForAuction
                  ? 'Already sent to auction'
                  : 'Please fill in all the required details'
              }
            >
              <Button
                type="primary"
                style={{
                  height: 46,
                  width: 171,
                  marginRight: '0.5em',
                  marginLeft: '0.5em',
                  marginBottom: 15
                }}
                disabled={this.validateRequired()}
                onClick={this.handleSendToAuction}
              >
                {this.state.reviewForAuction
                  ? 'Sent to Auction'
                  : 'Send to Auction'}
              </Button>
            </Tooltip>
          ) : (
            <Button
              type="primary"
              style={{
                height: 46,
                width: 171,
                marginRight: '0.5em',
                marginLeft: '0.5em',
                marginBottom: 15
              }}
              disabled={this.validateRequired()}
              onClick={this.handleSendToAuction}
            >
              {this.state.reviewForAuction
                ? 'Sent to Auction'
                : 'Send to Auction'}
            </Button>
          )}

          <Button
            onClick={this.exportToPdf}
            style={{ height: 46, width: 171, marginRight: 15 }}
          >
            Export to PDF
          </Button>
        </Col>
      </div>
    );
    return (
      <div>
        <Row
          style={{ padding: '1em' }}
          type="flex"
          justify="start"
          align="middle"
        >
          <Col xs={24} md={5}>
            <h2
              style={{
                color: '#00888E',
                textAlign: 'center',
                marginBottom: '-0em'
              }}
            >
              <span style={{ color: 'black' }}>
                {' '}
                {window.i18n.translate('Edit')}
              </span>{' '}
              <br />
              {this.state.year + ' ' + this.state.make + ' ' + this.state.model}
            </h2>{' '}
            <div
              className="hidden-comps-webview"
              style={{ float: 'right', marginTop: '-4.5em' }}
            >
              <Popover
                placement="bottom"
                content={MoreButtonContent}
                trigger="click"
              >
                <Icon
                  style={{
                    fontSize: '2em'
                  }}
                  type="more"
                />
              </Popover>
            </div>
          </Col>
          <Col xs={24} md={5}>
            <h2 style={{ textAlign: 'center' }}>
              {window.i18n.translate('Progress')} |{' '}
              {this.state.overallProgress >= 100
                ? '100'
                : this.state.overallProgress}
              % {window.i18n.translate('Complete1')}
            </h2>
          </Col>
          <Col
            className="hidden-comps-mobile"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
            span={14}
          >
            <Button
              type="primary"
              style={{ height: 46, width: 171, marginRight: 15 }}
              onClick={this.onResetForm}
            >
              {window.i18n.translate('Reset')}
            </Button>

            <Button
              style={{ height: 46, width: 171, marginRight: 15 }}
              onClick={this.onCancel}
            >
              {window.i18n.translate('Cancel')}
            </Button>

            <Button
              type="primary"
              style={{ height: 46, width: 171, marginRight: 15 }}
              onClick={this.processReview}
            >
              {this.state.review
                ? window.i18n.translate('CloseReview')
                : window.i18n.translate('Review')}
            </Button>
            <Button
              style={{ height: 46, width: 171, marginRight: 15 }}
              disabled={
                this.validateRequired() && this.state.approved === false
                  ? false
                  : true
              }
              onClick={this.editVehicle}
            >
              {window.i18n.translate('SaveVehicle')}
            </Button>
            <Button
              type="primary"
              style={{ height: 46, width: 171, marginRight: 15 }}
              disabled={this.validateRequired()}
              onClick={this.handleSendToAuction}
            >
              {this.state.reviewForAuction
                ? window.i18n.translate('SentToAuction')
                : window.i18n.translate('SentToAuction')}
            </Button>
            <Button
              onClick={this.exportToPdf}
              style={{ height: 46, width: 171 }}
            >
              {window.i18n.translate('ExportToPDF')}
            </Button>
          </Col>
        </Row>
        <Spin spinning={this.state.loading}>
          {!this.state.review ? (
            <Row type="flex" justify="start" align="middle">
              <Col span={24}>
                <Collapse
                  onChange={this.changeStep}
                  defaultActiveKey={['1']}
                  expandIconPosition={'right'}
                  accordion
                  style={
                    window.innerWidth < 767
                      ? this.state.currentStep === '1'
                        ? {
                            position: 'fixed',
                            top: 165,
                            background: 'transparent'
                          }
                        : this.state.currentStep === '3'
                        ? {
                            position: 'fixed',
                            top: 0,
                            background: 'transparent'
                          }
                        : this.state.currentStep === '4'
                        ? {
                            position: 'fixed',
                            top: -80,
                            background: 'transparent'
                          }
                        : this.state.currentStep === 'Tyre'
                        ? {
                            position: 'fixed',
                            top: -160,
                            background: 'transparent'
                          }
                        : this.state.currentStep === 'Damage'
                        ? {
                            position: 'fixed',
                            top: -240,
                            background: 'transparent'
                          }
                        : this.state.currentStep === '5'
                        ? {
                            position: 'fixed',
                            top: -310,
                            background: 'transparent'
                          }
                        : this.state.currentStep === '6'
                        ? {
                            position: 'fixed',
                            top: -390,
                            background: 'transparent'
                          }
                        : this.state.currentStep === '7'
                        ? {
                            position: 'fixed',
                            top: -470,
                            background: 'transparent'
                          }
                        : { background: 'transparent' }
                      : { background: 'transparent' }
                  }
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <img alt="Up" src={upArrow} />
                    ) : (
                      <img alt="Down" src={downArrow} />
                    )
                  }
                >
                  <Collapse.Panel
                    className="collapse-panel-width"
                    style={customPanelStyle}
                    header={
                      <span
                        id="damageAsRow"
                        style={{ color: '#00B4BC', fontWeight: 700 }}
                      >
                        {' '}
                        {window.i18n.translate('Step') +
                          ' ' +
                          '1 - ' +
                          window.i18n.translate('GeneralInformation')}
                      </span>
                    }
                    key="1"
                    extra={
                      <div style={{ display: 'flex' }}>
                        <span style={{ marginRight: 10, color: '#6D6E70' }}>
                          {this.state.panel1Status}
                        </span>
                        <div
                          style={{
                            width: 15,
                            height: 15,
                            background: this.state.panel1Color,
                            borderRadius: '50%',
                            position: 'relative',
                            top: 4,
                            marginRight: 15
                          }}
                        />
                      </div>
                    }
                  >
                    <Row
                      style={
                        window.innerWidth < 767
                          ? {
                              height: 'calc(100vh - 370px)',
                              overflow: 'auto'
                            }
                          : null
                      }
                      gutter={24}
                      type="flex"
                      justify="space-between"
                      align="middle"
                    >
                      <Col xs={24} md={7}>
                        <span>
                          {'1. ' + window.i18n.translate('Year')}
                          <span style={{ color: 'red' }}>*</span>
                        </span>
                        <Selection
                          showSearch
                          value={this.state.year}
                          style={{ width: '100%' }}
                          placeholder={
                            window.i18n.translate('Select') +
                            ' ' +
                            window.i18n.translate('Year')
                          }
                          optionFilterProp="children"
                          onChange={this.onSelectYear}
                        >
                          {this.props.years.map(year => {
                            return (
                              <Option key={year} value={year}>
                                {year}
                              </Option>
                            );
                          })}
                        </Selection>{' '}
                        <ArrowIcon type="arrow-right" />
                      </Col>
                      <Col xs={24} md={7}>
                        <span>
                          {'2. ' + window.i18n.translate('Make')}{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </span>
                        <Selection
                          disabled={!this.state.year}
                          showSearch
                          value={this.state.make}
                          style={{ width: '100%' }}
                          placeholder={
                            window.i18n.translate('Select') +
                            ' ' +
                            window.i18n.translate('Make')
                          }
                          optionFilterProp="children"
                          onChange={this.onSelectMake}
                        >
                          {this.props.makes.map(make => {
                            return (
                              <Option key={make} value={make}>
                                {make}
                              </Option>
                            );
                          })}
                        </Selection>
                        <ArrowIcon type="arrow-right" />
                      </Col>
                      <Col xs={24} md={7}>
                        <span>
                          {'3. ' + window.i18n.translate('Model')}{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </span>
                        <Selection
                          disabled={!this.state.make}
                          showSearch
                          value={this.state.model}
                          style={{ width: '100%' }}
                          placeholder={
                            window.i18n.translate('Select') +
                            ' ' +
                            window.i18n.translate('Model')
                          }
                          optionFilterProp="children"
                          onChange={this.onSelectModel}
                        >
                          {this.props.models.map(model => {
                            return (
                              <Option key={model} value={model}>
                                {model}
                              </Option>
                            );
                          })}
                        </Selection>
                      </Col>
                      <Col xs={24} md={7}>
                        <span>
                          {'4. ' + window.i18n.translate('ModelType')}{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </span>
                        <Selection
                          disabled={!this.state.model}
                          showSearch
                          value={this.state.variant}
                          style={{ width: '100%' }}
                          placeholder={
                            window.i18n.translate('Select') +
                            ' ' +
                            window.i18n.translate('ModelType')
                          }
                          optionFilterProp="children"
                          onChange={this.onSelectVariant}
                        >
                          {this.props.variants.map(variant => {
                            return (
                              <Option key={variant} value={variant}>
                                {variant}
                              </Option>
                            );
                          })}
                        </Selection>{' '}
                        <ArrowIcon type="arrow-right" />
                      </Col>
                      <Col xs={24} md={7}>
                        <span>
                          {'5. ' + window.i18n.translate('Transmission')}{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </span>
                        <Selection
                          showSearch
                          value={this.state.transmission}
                          style={{ width: '100%' }}
                          placeholder={
                            window.i18n.translate('Select') +
                            ' ' +
                            window.i18n.translate('Transmission')
                          }
                          optionFilterProp="children"
                          onChange={e => this.setState({ transmission: e })}
                        >
                          <Option value="Automatic">
                            {' '}
                            {window.i18n.translate('Automatic')}
                          </Option>
                          <Option value="Manual">
                            {' '}
                            {window.i18n.translate('Manual')}
                          </Option>
                        </Selection>{' '}
                        <ArrowIcon type="arrow-right" />
                      </Col>
                      <Col xs={24} md={7}>
                        <span>
                          {'6. ' + window.i18n.translate('Mileage')}
                          <span style={{ color: 'red' }}>*</span>
                        </span>
                        <Input
                          style={{ ...customInputStyle, borderRadius: '0.3em' }}
                          suffix="KM"
                          placeholder={window.i18n.translate('Mileage')}
                          value={this.state.mileage}
                          onChange={e =>
                            this.setState({
                              mileage: numeral(e.currentTarget.value).format(
                                '0,0'
                              )
                            })
                          }
                        />
                      </Col>
                      <Col xs={24} md={7}>
                        <Col style={{ padding: '0px' }} span={23}>
                          <span>
                            {' '}
                            {'7. ' +
                              window.i18n.translate('Colour') +
                              ' ' +
                              window.i18n.translate('Optional')}
                          </span>
                        </Col>

                        <Col
                          style={{
                            display: 'flex',
                            height: 45,
                            padding: '0px'
                          }}
                          span={6}
                        >
                          <Popover
                            content={
                              <CirclePicker
                                color={this.state.hex}
                                width={'100%'}
                                colors={[
                                  '#f0efe8',
                                  '#191919',
                                  '#d01f05',
                                  '#006bea',
                                  '#1ac736',
                                  '#565656',
                                  '#d0b485',
                                  '#bbbbbb',
                                  '#f9dc2b',
                                  '#ea961d',
                                  '#75591e',
                                  '#63142c'
                                ]}
                                onChange={this.getColour}
                              />
                            }
                            title="Colour"
                            trigger="click"
                            visible={this.state.colourVisible}
                            onVisibleChange={this.handleVisibleChange}
                          >
                            <ButtonSquare
                              style={{
                                marginTop: '0.38em',
                                height: 45,
                                minWidth: '6em',
                                width: '90%'
                              }}
                              type="primary"
                            >
                              {window.i18n.translate('Select')}
                            </ButtonSquare>
                          </Popover>
                        </Col>
                        <Col span={18} style={{ padding: '0px' }}>
                          <Input
                            style={{
                              ...customInputStyle,

                              width: '100%'
                            }}
                            placeholder={
                              window.i18n.translate('Other') +
                              ' ' +
                              window.i18n.translate('Colour')
                            }
                            value={this.state.colour}
                            onChange={e =>
                              this.setState({
                                colour: e.currentTarget.value,
                                hex: ''
                              })
                            }
                          />{' '}
                          <ArrowIcon
                            type="arrow-right"
                            style={{ marginLeft: '13%' }}
                          />
                        </Col>
                      </Col>
                      <Col xs={24} md={7}>
                        <span>
                          {'8. ' + window.i18n.translate('FuelType')}{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </span>
                        <Selection
                          showSearch
                          value={this.state.fuel}
                          style={{ width: '100%' }}
                          placeholder={
                            window.i18n.translate('Select') +
                            ' ' +
                            window.i18n.translate('FuelType')
                          }
                          optionFilterProp="children"
                          onChange={e => this.setState({ fuel: e })}
                        >
                          <Option key={'Petrol'} value={'Petrol'}>
                            {'Petrol'}
                          </Option>
                          <Option key={'Diesel'} value={'Diesel'}>
                            {'Diesel'}
                          </Option>
                          <Option key={'Hybrid'} value={'Hybrid'}>
                            {window.i18n.translate('Hybrid')}
                          </Option>
                          <Option key={'Electric'} value={'Electric'}>
                            {window.i18n.translate('Electric')}
                          </Option>
                        </Selection>{' '}
                        <ArrowIcon type="arrow-right" />
                      </Col>
                      <Col xs={24} md={7}>
                        <span>
                          {' '}
                          {'9. ' +
                            window.i18n.translate('OptionalExtras') +
                            '' +
                            window.i18n.translate('Optional')}
                        </span>
                        <Input
                          style={customInputStyle}
                          placeholder={
                            window.i18n.translate('Eg') +
                            ' ' +
                            window.i18n.translate('SunRoof') +
                            ', Mags'
                          }
                          value={this.state.extras}
                          onChange={e =>
                            this.setState({
                              extras: e.currentTarget.value
                            })
                          }
                        />
                      </Col>
                      <Col xs={24} md={7}>
                        <span>
                          {'10. ' + window.i18n.translate('Province')}{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </span>
                        <Selection
                          showSearch
                          value={this.state.province}
                          style={{ width: '100%' }}
                          placeholder={
                            window.i18n.translate('Select') +
                            ' ' +
                            window.i18n.translate('Province')
                          }
                          optionFilterProp="children"
                          onChange={this.onSelectProvince}
                        >
                          <Option key={'GP'} value={'Gauteng'}>
                            {'Gauteng'}
                          </Option>
                          <Option key={'MP'} value={'Mpumalanga'}>
                            {'Mpumalanga'}
                          </Option>
                          <Option key={'LP'} value={'Limpopo'}>
                            {'Limpopo'}
                          </Option>
                          <Option key={'KZN'} value={'KwaZulu Natal'}>
                            {'KwaZulu Natal'}
                          </Option>
                          <Option key={'FS'} value={'Free State'}>
                            {'Free State'}
                          </Option>
                          <Option key={'NW'} value={'North West'}>
                            {'North West'}
                          </Option>
                          <Option key={'NC'} value={'Northern Cape'}>
                            {'Northern Cape'}
                          </Option>
                          <Option key={'EC'} value={'Eastern Cape'}>
                            {'Eastern Cape'}
                          </Option>
                          <Option key={'WC'} value={'Western Cape'}>
                            {'Western Cape'}
                          </Option>
                        </Selection>{' '}
                        <ArrowIcon type="arrow-right" />
                      </Col>
                      <Col xs={24} md={7}>
                        <span>
                          {'11. ' + window.i18n.translate('CityTown')}{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </span>
                        <Selection
                          disabled={!this.state.province}
                          showSearch
                          value={this.state.city}
                          style={{ width: '100%' }}
                          placeholder={
                            window.i18n.translate('Select') +
                            ' ' +
                            window.i18n.translate('CityTown')
                          }
                          optionFilterProp="children"
                          onChange={e => this.setState({ city: e })}
                        >
                          {this.props.cities.map(city => {
                            return (
                              <Option key={city} value={city}>
                                {city}
                              </Option>
                            );
                          })}
                        </Selection>{' '}
                        <ArrowIcon type="arrow-right" />
                      </Col>
                      <Col xs={24} md={7}>
                        <Col style={{ padding: '0px' }} span={23}>
                          <span>
                            {'12. ' + window.i18n.translate('WhereDidYou')}
                            <span style={{ color: 'red' }}>*</span>
                          </span>
                        </Col>
                        <Col span={11} style={{ padding: '0px' }}>
                          <Selection
                            showSearch
                            // value={this.state.whereBought}
                            style={{ width: '100%' }}
                            placeholder={
                              window.i18n.translate('Select') +
                              ' ' +
                              window.i18n.translate('an') +
                              ' ' +
                              window.i18n.translate('option')
                            }
                            optionFilterProp="children"
                            onChange={e => {
                              this.setState({
                                whereBoughtSelection: e,
                                whereBought: ''
                              });
                            }}
                          >
                            <Option key={'Dealer'} value={'Dealer'}>
                              {window.i18n.translate('Dealer')}
                            </Option>
                            <Option key={'Private'} value={'Private'}>
                              {window.i18n.translate('Private')}
                            </Option>
                            <Option key={'Auction'} value={'Auction'}>
                              {window.i18n.translate('Auction')}
                            </Option>
                            <Option key={'Other'} value={'Other'}>
                              {window.i18n.translate('Other')}
                            </Option>
                          </Selection>
                        </Col>
                        <Col span={11}>
                          <Input
                            style={{
                              ...customInputStyle,
                              display: 'block',
                              position: 'absolute',
                              width: '110%'
                            }}
                            placeholder={
                              window.i18n.translate('Provide') +
                              ' ' +
                              window.i18n.translate('Details')
                            }
                            value={this.state.whereBought}
                            onChange={e =>
                              this.setState({
                                whereBought: e.currentTarget.value
                              })
                            }
                          />
                        </Col>
                      </Col>
                      <Col xs={24} md={7}>
                        <span>
                          {' '}
                          {'13. ' +
                            window.i18n.translate('ReasonFor') +
                            ' ' +
                            window.i18n.translate('Selling') +
                            ' ' +
                            window.i18n.translate('Optional')}
                        </span>
                        <Input
                          style={customInputStyle}
                          placeholder={
                            window.i18n.translate('ReasonFor') +
                            ' ' +
                            window.i18n.translate('Selling')
                          }
                          value={this.state.sellingReason}
                          onChange={e =>
                            this.setState({
                              sellingReason: e.currentTarget.value
                            })
                          }
                        />{' '}
                        <ArrowIcon type="arrow-right" />
                      </Col>
                      <Col xs={24} md={7}>
                        <span>
                          {'14. ' +
                            window.i18n.translate('IncludingYouText') +
                            window.i18n.translate('Optional')}
                        </span>
                        <Input
                          style={customInputStyle}
                          placeholder={
                            window.i18n.translate('NumberOfOwners') +
                            '/' +
                            window.i18n.translate('IDontKnow')
                          }
                          value={this.state.owners}
                          onChange={this.checkIfNumber}
                        />{' '}
                        <ArrowIcon type="arrow-right" />
                      </Col>
                      <Col xs={24} md={7}>
                        <Col style={{ padding: '0px' }} span={23}>
                          <span>
                            {' '}
                            {'15. ' +
                              window.i18n.translate('LicenseExpiry') +
                              ' ' +
                              window.i18n.translate('Optional')}
                          </span>
                        </Col>
                        <Col span={14} style={{ padding: '0px' }}>
                          <Selection
                            showSearch
                            style={{
                              width: '100%'
                            }}
                            placeholder={
                              window.i18n.translate('Select') +
                              ' ' +
                              window.i18n.translate('an') +
                              ' ' +
                              window.i18n.translate('option')
                            }
                            optionFilterProp="children"
                            onChange={e => {
                              this.setState({
                                licenseExpire: e
                              });
                            }}
                          >
                            <Option
                              key={'Not sure but up to date'}
                              value={'Not sure but up to date'}
                            >
                              {window.i18n.translate('UpToDate')}
                            </Option>
                            <Option
                              key={'Not sure but expired'}
                              value={'Not sure but expired'}
                            >
                              {window.i18n.translate('ButExpired')}
                            </Option>
                            <Option
                              key={'As per selected date'}
                              value={'As per selected date'}
                            >
                              {window.i18n.translate('SelectedDate')}
                            </Option>
                          </Selection>
                        </Col>
                        <Col
                          span={9}
                          style={{
                            padding: '0px',
                            marginTop: '0.4em',
                            marginLeft: '1em'
                          }}
                        >
                          <DatePick
                            placeholder={
                              window.i18n.translate('Select') +
                              ' ' +
                              window.i18n.translate('Date')
                            }
                            onChange={e =>
                              this.setState({
                                licenseExpireDate: e.format('YYYYMMDD')
                              })
                            }
                          />
                        </Col>
                      </Col>
                      <Col xs={24} md={7}>
                        <span>
                          {' '}
                          {'16. ' +
                            window.i18n.translate('AdditionalComment') +
                            ' ' +
                            window.i18n.translate('Optional')}
                        </span>
                        <Input
                          style={customInputStyle}
                          placeholder={window.i18n.translate(
                            'AdditionalComment'
                          )}
                          value={this.state.comments}
                          onChange={e =>
                            this.setState({
                              comments: e.currentTarget.value
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </Collapse.Panel>

                  <Collapse.Panel
                    className="collapse-panel-width"
                    style={customPanelStyle}
                    header={
                      <span
                        id="serviceRow"
                        style={{ color: '#00B4BC', fontWeight: 700 }}
                      >
                        {window.i18n.translate('Step') +
                          ' ' +
                          '2 - ' +
                          window.i18n.translate('DamageAssessment')}
                        {this.state.panel1Status !==
                        window.i18n.translate('Complete')
                          ? ' - ' +
                            window.i18n.translate('Complete') +
                            ' ' +
                            window.i18n.translate('Step') +
                            ' 1 ' +
                            window.i18n.translate('ToUnlock')
                          : ''}
                      </span>
                    }
                    key="3"
                    disabled={
                      this.state.panel1Status !==
                      window.i18n.translate('Complete')
                    }
                    extra={
                      <div style={{ display: 'flex' }}>
                        <span style={{ marginRight: 10, color: '#6D6E70' }}>
                          {this.state.panel3Status}
                        </span>
                        <div
                          style={{
                            width: 15,
                            height: 15,
                            background: this.state.panel3Color,
                            borderRadius: '50%',
                            position: 'relative',
                            top: 4,
                            marginRight: 15
                          }}
                        />
                      </div>
                    }
                  >
                    <Row
                      style={
                        window.innerWidth < 767
                          ? {
                              height: 'calc(100vh - 310px)',
                              overflow: 'auto'
                            }
                          : null
                      }
                      type="flex"
                      justify="space-between"
                      align="middle"
                    >
                      <Col xs={24} md={11}>
                        <span>
                          {' '}
                          {window.i18n.translate('VehicleCondition') +
                            ' ' +
                            window.i18n.translate('Optional')}
                        </span>
                        <Selection
                          showSearch
                          value={this.state.condition}
                          style={{ width: '100%' }}
                          placeholder={
                            window.i18n.translate('Select') +
                            ' ' +
                            window.i18n.translate('rating')
                          }
                          optionFilterProp="children"
                          dropdownMenuStyle={{ maxHeight: '100%' }}
                          onChange={e => this.setState({ condition: e })}
                        >
                          <Option value="1">
                            1 - {window.i18n.translate('Bad')}
                          </Option>
                          <Option value="2">2</Option>
                          <Option value="3">3</Option>
                          <Option value="4">4</Option>
                          <Option value="5">
                            5 - {window.i18n.translate('Good')}
                          </Option>
                          <Option value="6">6</Option>
                          <Option value="7">7</Option>
                          <Option value="8">8</Option>
                          <Option value="9">9</Option>
                          <Option value="10">
                            10 - {window.i18n.translate('Exellent')}
                          </Option>
                        </Selection>
                      </Col>
                      <Col xs={24} md={11}>
                        <Col span={23}>
                          <span>
                            {window.i18n.translate('SpareKeys')}
                            <span style={{ color: 'red' }}>*</span>
                          </span>
                        </Col>
                        <Col
                          style={{ display: 'flex', height: 45 }}
                          xs={10}
                          md={6}
                        >
                          <Radio.Group
                            style={{ margin: 'auto' }}
                            onChange={e =>
                              this.setState({ spareKeys: e.target.value })
                            }
                            defaultValue={
                              this.state.spareKeys === 'No' ? 'No' : 'Yes'
                            }
                          >
                            <RadioButtonYes value="Yes">
                              {window.i18n.translate('Yes')}
                            </RadioButtonYes>
                            <RadioButtonNo value="No">
                              {window.i18n.translate('No')}
                            </RadioButtonNo>
                          </Radio.Group>
                        </Col>
                        <Col xs={14} md={18}>
                          <Input
                            disabled={this.state.spareKeys.search('No') > -1}
                            style={customInputStyle}
                            type="number"
                            placeholder={window.i18n.translate(
                              'NumberSpareKeys'
                            )}
                            value={this.state.spareKeys}
                            onChange={e => {
                              if (e.currentTarget.value.search('-') === -1) {
                                this.setState({
                                  spareKeys: e.currentTarget.value
                                });
                              }
                            }}
                          />
                        </Col>
                      </Col>
                      <Col xs={24} md={11}>
                        <Col span={23}>
                          <span>
                            {window.i18n.translate('MechanicalIssues')}
                            <span style={{ color: 'red' }}>*</span>
                          </span>
                        </Col>
                        <Col
                          style={{ display: 'flex', height: 45 }}
                          xs={10}
                          md={6}
                        >
                          <Radio.Group
                            style={{ margin: 'auto' }}
                            onChange={e => {
                              e.target.value === 'Yes'
                                ? this.setState({ mechanicalIssues: undefined })
                                : this.setState({
                                    mechanicalIssues: e.target.value
                                  });
                            }}
                            defaultValue={
                              this.state.mechanicalIssues === 'No'
                                ? 'No'
                                : 'Yes'
                            }
                          >
                            <RadioButtonYes value="Yes">
                              {window.i18n.translate('Yes')}
                            </RadioButtonYes>
                            <RadioButtonNo value="No">
                              {window.i18n.translate('No')}
                            </RadioButtonNo>
                          </Radio.Group>
                        </Col>
                        <Col xs={14} md={18}>
                          <Input
                            disabled={
                              this.state.mechanicalIssues
                                ? this.state.mechanicalIssues.search('No') > -1
                                : false
                            }
                            style={customInputStyle}
                            placeholder={window.i18n.translate('IfYes')}
                            value={this.state.mechanicalIssues}
                            onChange={e =>
                              this.setState({
                                mechanicalIssues: e.currentTarget.value
                              })
                            }
                          />
                        </Col>
                      </Col>
                      <Col xs={24} md={11}>
                        <span>
                          {' '}
                          {window.i18n.translate('InteriorCondition') +
                            ' ' +
                            window.i18n.translate('Optional')}
                        </span>
                        <Selection
                          showSearch
                          value={this.state.interiorCondition}
                          style={{ width: '100%' }}
                          placeholder={
                            window.i18n.translate('Select') +
                            ' ' +
                            window.i18n.translate('Rating')
                          }
                          optionFilterProp="children"
                          onChange={e =>
                            this.setState({ interiorCondition: e })
                          }
                        >
                          <Option value="Excellent">
                            {' '}
                            {window.i18n.translate('Exellent')}
                          </Option>
                          <Option value="Good">
                            {window.i18n.translate('Good')}
                          </Option>
                          <Option value="Average">
                            {window.i18n.translate('Average')}
                          </Option>
                          <Option value="Poor">
                            {window.i18n.translate('Poor')}
                          </Option>
                        </Selection>
                      </Col>
                      <Col xs={24} md={11}>
                        <Col span={23}>
                          <span>
                            {window.i18n.translate('EverBeenInAnAccident')}{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </span>
                        </Col>
                        <Col
                          style={{ display: 'flex', height: 45 }}
                          xs={10}
                          md={6}
                        >
                          <Radio.Group
                            style={{ margin: 'auto' }}
                            onChange={e => {
                              e.target.value === 'Yes'
                                ? this.setState({ bodyWork: undefined })
                                : this.setState({ bodyWork: e.target.value });
                            }}
                            defaultValue={
                              this.state.bodyWork === 'No' ? 'No' : 'Yes'
                            }
                          >
                            <RadioButtonYes value="Yes">
                              {' '}
                              {window.i18n.translate('Yes')}
                            </RadioButtonYes>
                            <RadioButtonNo value="No">
                              {window.i18n.translate('No')}
                            </RadioButtonNo>
                          </Radio.Group>
                        </Col>
                        <Col xs={14} md={18}>
                          <Selection
                            showSearch
                            disabled={
                              this.state.bodyWork
                                ? this.state.bodyWork.search('No') > -1
                                : false
                            }
                            value={this.state.bodyWork}
                            style={{ width: '100%' }}
                            placeholder={
                              window.i18n.translate('Select') +
                              ' ' +
                              window.i18n.translate('Type')
                            }
                            optionFilterProp="children"
                            onChange={e => this.setState({ bodyWork: e })}
                          >
                            <Option value="Accident">
                              {window.i18n.translate('Accident')}
                            </Option>
                            <Option value="Hail damage">
                              {window.i18n.translate('HailDamage')}
                            </Option>
                            <Option value="Cosmetic Spraywork to Fix scratches">
                              {window.i18n.translate('CosmeticSpraywork')}
                            </Option>
                          </Selection>
                        </Col>
                      </Col>
                      <Col xs={24} md={11}>
                        <Col span={23}>
                          <span>
                            {window.i18n.translate('WindscreenCracks')}
                            <span style={{ color: 'red' }}>*</span>
                          </span>
                        </Col>
                        <Col
                          style={{ display: 'flex', height: 45 }}
                          xs={10}
                          md={6}
                        >
                          <Radio.Group
                            style={{ margin: 'auto' }}
                            onChange={e => {
                              e.target.value === 'Yes'
                                ? this.setState({ windscreenDamage: undefined })
                                : this.setState({
                                    windscreenDamage: e.target.value
                                  });
                            }}
                            defaultValue={
                              this.state.windscreenDamage === 'No'
                                ? 'No'
                                : 'Yes'
                            }
                          >
                            <RadioButtonYes value="Yes">
                              {window.i18n.translate('Yes')}
                            </RadioButtonYes>
                            <RadioButtonNo value="No">
                              {window.i18n.translate('No')}
                            </RadioButtonNo>
                          </Radio.Group>
                        </Col>
                        <Col xs={14} md={18}>
                          <Selection
                            showSearch
                            disabled={
                              this.state.windscreenDamage
                                ? this.state.windscreenDamage.search('No') > -1
                                : false
                            }
                            value={this.state.windscreenDamage}
                            style={{ width: '100%' }}
                            placeholder={
                              window.i18n.translate('Select') +
                              ' ' +
                              window.i18n.translate('Type')
                            }
                            optionFilterProp="children"
                            onChange={e =>
                              this.setState({ windscreenDamage: e })
                            }
                          >
                            <Option value="Can be fixed">Can be fixed</Option>
                            <Option value="Needs to be replaced">
                              Needs to be replaced
                            </Option>
                          </Selection>
                        </Col>
                      </Col>
                      <Col xs={24} md={11}>
                        <Col span={23}>
                          <span>
                            {window.i18n.translate('RimsScratched')}
                            <span style={{ color: 'red' }}>*</span>
                          </span>
                        </Col>
                        <Col
                          style={{ display: 'flex', height: 45 }}
                          xs={10}
                          md={6}
                        >
                          <Radio.Group
                            style={{ margin: 'auto' }}
                            onChange={e => {
                              e.target.value === 'Yes'
                                ? this.setState({ magsDamage: undefined })
                                : this.setState({ magsDamage: e.target.value });
                            }}
                            defaultValue={
                              this.state.magsDamage === 'No' ? 'No' : 'Yes'
                            }
                          >
                            <RadioButtonYes value="Yes">
                              {window.i18n.translate('Yes')}
                            </RadioButtonYes>
                            <RadioButtonNo value="No">
                              {window.i18n.translate('No')}
                            </RadioButtonNo>
                          </Radio.Group>
                        </Col>
                        <Col xs={14} md={18}>
                          <Input
                            disabled={
                              this.state.magsDamage
                                ? this.state.magsDamage.search('No') > -1
                                : false
                            }
                            style={customInputStyle}
                            placeholder={window.i18n.translate('IfYes')}
                            value={this.state.magsDamage}
                            onChange={e =>
                              this.setState({
                                magsDamage: e.currentTarget.value
                              })
                            }
                          />
                        </Col>
                      </Col>
                    </Row>
                  </Collapse.Panel>
                  <Collapse.Panel
                    className="collapse-panel-width"
                    style={customPanelStyle}
                    header={
                      <span
                        id="tyreRow"
                        style={{ color: '#00B4BC', fontWeight: 700 }}
                      >
                        {window.i18n.translate('Step') +
                          ' ' +
                          '3 - ' +
                          window.i18n.translate('ServiceHistory')}
                        {this.state.panel3Status !==
                        window.i18n.translate('Complete')
                          ? ' - ' +
                            window.i18n.translate('Complete') +
                            ' ' +
                            window.i18n.translate('Step') +
                            ' 2 ' +
                            window.i18n.translate('ToUnlock')
                          : ''}
                      </span>
                    }
                    key="4"
                    disabled={
                      this.state.panel3Status !==
                      window.i18n.translate('Complete')
                    }
                    extra={
                      <div style={{ display: 'flex' }}>
                        <span style={{ marginRight: 10, color: '#6D6E70' }}>
                          {this.state.panel4Status}
                        </span>
                        <div
                          style={{
                            width: 15,
                            height: 15,
                            background: this.state.panel4Color,
                            borderRadius: '50%',
                            position: 'relative',
                            top: 4,
                            marginRight: 15
                          }}
                        />
                      </div>
                    }
                  >
                    <Row type="flex" justify="space-between" align="middle">
                      <Col xs={24} md={11}>
                        <span>
                          {window.i18n.translate('MyServiceHistory')}
                          <span style={{ color: 'red' }}>*</span>
                        </span>
                        <Selection
                          showSearch
                          value={this.state.serviceHistory}
                          style={{ width: '100%' }}
                          placeholder={
                            window.i18n.translate('Select') +
                            ' ' +
                            window.i18n.translate('Type')
                          }
                          optionFilterProp="children"
                          onChange={e => this.setState({ serviceHistory: e })}
                        >
                          <Option value="Full Service History with agents">
                            {window.i18n.translate('FullServiceHistory')}

                            {this.state.make
                              ? this.state.make
                              : window.i18n.translate('Agents')}
                          </Option>
                          <Option value="Full Service History ( Agents and Private workshops)">
                            {window.i18n.translate('FullServiceHistory')}

                            {this.state.make
                              ? this.state.make
                              : window.i18n.translate('Agents')}
                            {window.i18n.translate('PrivateWorkshops')}
                          </Option>
                          <Option value="Partial Service History">
                            {window.i18n.translate('PartialServiceHistory')}
                          </Option>
                          <Option value="No Service History">
                            {window.i18n.translate('NoServiceHistory')}
                          </Option>
                        </Selection>
                      </Col>
                      <Col xs={24} md={11}>
                        <Col span={23}>
                          <span>
                            {window.i18n.translate('VehicleFinanced')}
                            <span style={{ color: 'red' }}>*</span>
                          </span>
                        </Col>
                        <Col
                          style={{ display: 'flex', height: 45 }}
                          xs={10}
                          md={6}
                        >
                          <Radio.Group
                            style={{ margin: 'auto' }}
                            onChange={e => {
                              e.target.value === 'Yes'
                                ? this.setState({ financed: undefined })
                                : this.setState({ financed: e.target.value });
                            }}
                            defaultValue={
                              this.state.financed === 'No' ? 'No' : 'Yes'
                            }
                          >
                            <RadioButtonYes value="Yes">
                              {window.i18n.translate('Yes')}
                            </RadioButtonYes>
                            <RadioButtonNo value="No">
                              {window.i18n.translate('No')}
                            </RadioButtonNo>
                          </Radio.Group>
                        </Col>
                        <Col xs={14} md={18}>
                          <Input
                            disabled={
                              this.state.financed
                                ? this.state.financed.search('No') > -1
                                : false
                            }
                            style={customInputStyle}
                            placeholder="Outstanding amount"
                            value={this.state.financed}
                            onChange={e =>
                              this.setState({
                                financed:
                                  'R' +
                                  numeral(e.currentTarget.value).format('0,0')
                              })
                            }
                          />
                        </Col>
                      </Col>
                      <Col xs={24} md={11}>
                        <Col span={23}>
                          <span>
                            {window.i18n.translate('ServicePlan')}
                            <span style={{ color: 'red' }}>*</span>
                          </span>
                        </Col>
                        <Col
                          style={{ display: 'flex', height: 45 }}
                          xs={10}
                          md={6}
                        >
                          <Radio.Group
                            style={{ margin: 'auto' }}
                            onChange={e => {
                              e.target.value === 'Yes'
                                ? this.setState({ servicePlan: undefined })
                                : this.setState({
                                    servicePlan: e.target.value
                                  });
                            }}
                            defaultValue={
                              this.state.servicePlan === 'No' ? 'No' : 'Yes'
                            }
                          >
                            <RadioButtonYes value="Yes">
                              {' '}
                              {window.i18n.translate('Yes')}
                            </RadioButtonYes>
                            <RadioButtonNo value="No">
                              {' '}
                              {window.i18n.translate('No')}
                            </RadioButtonNo>
                          </Radio.Group>
                        </Col>
                        <Col xs={14} md={18}>
                          <Input
                            disabled={
                              this.state.servicePlan
                                ? this.state.servicePlan.search('No') > -1
                                : false
                            }
                            style={customInputStyle}
                            placeholder={
                              window.i18n.translate('Provide') +
                              ' ' +
                              window.i18n.translate('Details')
                            }
                            value={this.state.servicePlan}
                            onChange={e =>
                              this.setState({
                                servicePlan: e.currentTarget.value
                              })
                            }
                          />
                        </Col>
                      </Col>
                      <Col xs={24} md={11}>
                        <Col span={23}>
                          <span>
                            {window.i18n.translate('UnderWarranty')}
                            <span style={{ color: 'red' }}>*</span>
                          </span>
                        </Col>
                        <Col
                          style={{ display: 'flex', height: 45 }}
                          xs={10}
                          md={6}
                        >
                          <Radio.Group
                            style={{ margin: 'auto' }}
                            onChange={e => {
                              e.target.value === 'Yes'
                                ? this.setState({ warranty: undefined })
                                : this.setState({ warranty: e.target.value });
                            }}
                            defaultValue={
                              this.state.warranty === 'No' ? 'No' : 'Yes'
                            }
                          >
                            <RadioButtonYes value="Yes">
                              {' '}
                              {window.i18n.translate('Yes')}
                            </RadioButtonYes>
                            <RadioButtonNo value="No">
                              {' '}
                              {window.i18n.translate('No')}
                            </RadioButtonNo>
                          </Radio.Group>
                        </Col>
                        <Col xs={14} md={18}>
                          <Input
                            disabled={
                              this.state.warranty
                                ? this.state.warranty.search('No') > -1
                                : false
                            }
                            style={customInputStyle}
                            placeholder={
                              window.i18n.translate('Provide') +
                              ' ' +
                              window.i18n.translate('Details')
                            }
                            value={this.state.warranty}
                            onChange={e =>
                              this.setState({ warranty: e.currentTarget.value })
                            }
                          />
                        </Col>
                      </Col>
                    </Row>
                  </Collapse.Panel>
                  <Collapse.Panel
                    className="collapse-panel-width"
                    style={customPanelStyle}
                    header={
                      <span
                        id="damageRow"
                        style={{ color: '#00B4BC', fontWeight: 700 }}
                      >
                        {window.i18n.translate('Step') +
                          ' ' +
                          '4 - ' +
                          window.i18n.translate('TyreCondition')}
                        {this.state.panel4Status !==
                        window.i18n.translate('Complete')
                          ? ' - ' +
                            window.i18n.translate('Complete') +
                            ' ' +
                            window.i18n.translate('Step') +
                            ' 3 ' +
                            window.i18n.translate('ToUnlock')
                          : ''}
                      </span>
                    }
                    key="Tyre"
                    disabled={
                      this.state.panel4Status !==
                      window.i18n.translate('Complete')
                    }
                    extra={
                      <div style={{ display: 'flex' }}>
                        <span style={{ marginRight: 10, color: '#6D6E70' }}>
                          {this.state.panelTyreStatus}
                        </span>
                        <div
                          style={{
                            width: 15,
                            height: 15,
                            background: this.state.panelTyreColor,
                            borderRadius: '50%',
                            position: 'relative',
                            top: 4,
                            marginRight: 15
                          }}
                        />
                      </div>
                    }
                  >
                    <div
                      style={
                        window.innerWidth < 767
                          ? {
                              height: 'calc(100vh - 295px)',
                              overflow: 'auto'
                            }
                          : null
                      }
                    >
                      <Row
                        type="flex"
                        justify="space-between"
                        style={{
                          marginBottom: 30
                        }}
                      >
                        <Col style={{ marginBottom: 10 }} span={24}>
                          <span style={{ fontWeight: 700 }}>
                            {window.i18n.translate('FrontTyres') +
                              ' ' +
                              window.i18n.translate('Optional')}
                          </span>
                        </Col>
                        <Col xs={8} md={7}>
                          <Card
                            id="TyreCard"
                            hoverable
                            onClick={e =>
                              this.setState({
                                frontTyres: window.i18n.translate('New')
                              })
                            }
                            style={{
                              width: '98%',
                              marginBottom: '0.5em',
                              border:
                                this.state.frontTyres ===
                                window.i18n.translate('New')
                                  ? '2px solid green'
                                  : '2px solid #00888e',
                              boxShadow:
                                this.state.frontTyres ===
                                window.i18n.translate('New')
                                  ? '0px 0px 10px 4px green'
                                  : 'none',
                              borderRadius: 10
                            }}
                            cover={
                              <img
                                style={{
                                  width: '100%',
                                  borderRadius: 10
                                }}
                                src={newTyre}
                              />
                            }
                          >
                            <Meta
                              title={window.i18n.translate('New')}
                              description={
                                window.i18n.translate('TyresAre') +
                                ' ' +
                                window.i18n.translate('New')
                              }
                            />
                          </Card>
                        </Col>
                        <Col xs={8} md={7}>
                          <Card
                            id="TyreCard"
                            hoverable
                            onClick={e =>
                              this.setState({
                                frontTyres: window.i18n.translate('HalfWorn')
                              })
                            }
                            style={{
                              width: '98%',
                              marginBottom: '0.5em',
                              border:
                                this.state.frontTyres ===
                                window.i18n.translate('HalfWorn')
                                  ? '2px solid green'
                                  : '2px solid #00888e',
                              boxShadow:
                                this.state.frontTyres ===
                                window.i18n.translate('HalfWorn')
                                  ? '0px 0px 10px 4px green'
                                  : 'none',
                              borderRadius: 10
                            }}
                            cover={
                              <img
                                style={{
                                  width: '100%',
                                  borderRadius: 10
                                }}
                                src={halfWornTyre}
                              />
                            }
                          >
                            <Meta
                              title={window.i18n.translate('HalfWorn')}
                              description={
                                window.i18n.translate('TyresAre') +
                                ' ' +
                                window.i18n.translate('HalfWorn')
                              }
                            />
                          </Card>
                        </Col>
                        <Col xs={8} md={7}>
                          <Card
                            id="TyreCard"
                            hoverable
                            onClick={e =>
                              this.setState({
                                frontTyres: window.i18n.translate('Worn')
                              })
                            }
                            style={{
                              width: '98%',
                              marginBottom: '0.5em',
                              border:
                                this.state.frontTyres ===
                                window.i18n.translate('Worn')
                                  ? '2px solid green'
                                  : '2px solid #00888e',
                              boxShadow:
                                this.state.frontTyres ===
                                window.i18n.translate('Worn')
                                  ? '0px 0px 10px 4px green'
                                  : 'none',
                              borderRadius: 10
                            }}
                            cover={
                              <img
                                style={{
                                  width: '100%',
                                  borderRadius: 10
                                }}
                                src={wornTyre}
                              />
                            }
                          >
                            <Meta
                              title={window.i18n.translate('Worn')}
                              description={
                                window.i18n.translate('TyresAre') +
                                ' ' +
                                window.i18n.translate('Worn')
                              }
                            />
                          </Card>
                        </Col>
                      </Row>
                      <Row type="flex" justify="space-between">
                        <Col style={{ marginBottom: 10 }} span={24}>
                          <span style={{ fontWeight: 700 }}>
                            {window.i18n.translate('BackTyres') +
                              ' ' +
                              window.i18n.translate('Optional')}
                          </span>
                        </Col>
                        <Col xs={8} md={7}>
                          <Card
                            id="TyreCard"
                            hoverable
                            onClick={e =>
                              this.setState({
                                backTyres: window.i18n.translate('New')
                              })
                            }
                            style={{
                              width: '98%',
                              marginBottom: '0.5em',
                              border:
                                this.state.backTyres ===
                                window.i18n.translate('New')
                                  ? '2px solid green'
                                  : '2px solid #00888e',
                              boxShadow:
                                this.state.backTyres ===
                                window.i18n.translate('New')
                                  ? '0px 0px 10px 4px green'
                                  : 'none',
                              borderRadius: 10
                            }}
                            cover={
                              <img
                                style={{
                                  width: '100%',
                                  borderRadius: 10
                                }}
                                src={newTyre}
                              />
                            }
                          >
                            <Meta
                              title={window.i18n.translate('New')}
                              description={
                                window.i18n.translate('TyresAre') +
                                ' ' +
                                window.i18n.translate('New')
                              }
                            />
                          </Card>
                        </Col>
                        <Col xs={8} md={7}>
                          <Card
                            id="TyreCard"
                            hoverable
                            onClick={e =>
                              this.setState({
                                backTyres: window.i18n.translate('HalfWorn')
                              })
                            }
                            style={{
                              width: '98%',
                              marginBottom: '0.5em',
                              border:
                                this.state.backTyres ===
                                window.i18n.translate('HalfWorn')
                                  ? '2px solid green'
                                  : '2px solid #00888e',
                              boxShadow:
                                this.state.backTyres ===
                                window.i18n.translate('HalfWorn')
                                  ? '0px 0px 10px 4px green'
                                  : 'none',
                              borderRadius: 10
                            }}
                            cover={
                              <img
                                style={{
                                  width: '100%',
                                  borderRadius: 10
                                }}
                                src={halfWornTyre}
                              />
                            }
                          >
                            <Meta
                              title={window.i18n.translate('HalfWorn')}
                              description={
                                window.i18n.translate('TyresAre') +
                                ' ' +
                                window.i18n.translate('HalfWorn')
                              }
                            />
                          </Card>
                        </Col>
                        <Col xs={8} md={7}>
                          <Card
                            id="TyreCard"
                            hoverable
                            onClick={e =>
                              this.setState({
                                backTyres: window.i18n.translate('Worn')
                              })
                            }
                            style={{
                              width: '98%',
                              marginBottom: '0.5em',
                              border:
                                this.state.backTyres ===
                                window.i18n.translate('Worn')
                                  ? '2px solid green'
                                  : '2px solid #00888e',
                              boxShadow:
                                this.state.backTyres ===
                                window.i18n.translate('Worn')
                                  ? '0px 0px 10px 4px green'
                                  : 'none',
                              borderRadius: 10
                            }}
                            cover={
                              <img
                                style={{
                                  width: '100%',
                                  borderRadius: 10
                                }}
                                src={wornTyre}
                              />
                            }
                          >
                            <Meta
                              title={window.i18n.translate('Worn')}
                              description={
                                window.i18n.translate('TyresAre') +
                                ' ' +
                                window.i18n.translate('Worn')
                              }
                            />
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  </Collapse.Panel>
                  <Collapse.Panel
                    className="collapse-panel-width"
                    style={customPanelStyle}
                    header={
                      <span
                        id="imageRow"
                        style={{ color: '#00B4BC', fontWeight: 700 }}
                      >
                        {' '}
                        {window.i18n.translate('Step') +
                          ' ' +
                          '5 - ' +
                          window.i18n.translate('VehicleDamage')}
                        {this.state.panel4Status !==
                        window.i18n.translate('Complete')
                          ? ' - ' +
                            window.i18n.translate('Complete') +
                            ' ' +
                            window.i18n.translate('Step') +
                            ' 4 ' +
                            window.i18n.translate('ToUnlock')
                          : ''}
                      </span>
                    }
                    key="Damage"
                    disabled={
                      this.state.panel4Status !==
                      window.i18n.translate('Complete')
                    }
                    extra={
                      <div style={{ display: 'flex' }}>
                        <span style={{ marginRight: 10, color: '#6D6E70' }}>
                          {this.state.panelDamageStatus}
                        </span>
                        <div
                          style={{
                            width: 15,
                            height: 15,
                            background: this.state.panelDamageColor,
                            borderRadius: '50%',
                            position: 'relative',
                            top: 4,
                            marginRight: 15
                          }}
                        />
                      </div>
                    }
                  >
                    <div
                      style={
                        window.innerWidth < 767
                          ? this.state.vehicleType &&
                            this.state.vehicleType !== 'No Damage'
                            ? {
                                height: 'calc(100vh - 240px)',
                                overflow: 'auto'
                              }
                            : null
                          : null
                      }
                    >
                      <Row
                        type="flex"
                        justify="space-between"
                        style={{ marginBottom: 30 }}
                      >
                        <Col xs={24} md={24} style={{ display: 'flex' }}>
                          <span>
                            {' '}
                            {window.i18n.translate('DamageOrBodyWork')}
                          </span>
                        </Col>
                        <Col xs={24} md={24} style={{ display: 'flex' }}>
                          <Radio.Group
                            style={{}}
                            onChange={e => {
                              if (e.target.value === 'Yes') {
                                this.setState({
                                  vehicleType: undefined,
                                  selectedDamage: true
                                });
                              } else {
                                this.setState({
                                  vehicleType: 'No Damage',
                                  selectedDamage: false
                                });
                              }
                            }}
                          >
                            <RadioButtonYes value="Yes">
                              {' '}
                              {window.i18n.translate('Yes')}
                            </RadioButtonYes>
                            <RadioButtonNo value="No">
                              {' '}
                              {window.i18n.translate('No')}
                            </RadioButtonNo>
                          </Radio.Group>
                        </Col>
                        {this.state.selectedDamage ? (
                          <Col span={24}>
                            <Col span={14}>
                              <span>
                                {' '}
                                {window.i18n.translate('ClosestMatch')}
                              </span>
                            </Col>
                            <Col xs={24} md={11}>
                              <Selection
                                showSearch
                                value={this.state.vehicleType}
                                style={{ width: '100%' }}
                                placeholder={
                                  window.i18n.translate('Select') +
                                  ' ' +
                                  window.i18n.translate('Type')
                                }
                                optionFilterProp="children"
                                onChange={e =>
                                  this.setState({ vehicleType: e }, () => {
                                    let elmnt = document.getElementById(
                                      'damageRow'
                                    );
                                    elmnt.scrollIntoView();
                                  })
                                }
                              >
                                <Option value="Sedan">Sedan</Option>
                                <Option value="Bakkie">Bakkie</Option>
                                <Option value="Hatchback">
                                  {window.i18n.translate('Hatchback')}
                                </Option>
                                <Option value="SUV">SUV</Option>
                                <Option value="Van">
                                  {window.i18n.translate('VanCombi')}
                                </Option>
                              </Selection>
                            </Col>
                          </Col>
                        ) : null}
                        {this.state.vehicleType &&
                        this.state.vehicleType !== 'No Damage' ? (
                          <Col
                            style={{
                              textAlign: 'center',
                              marginTop: 20,
                              fontSize: 15,
                              fontWeight: 700
                            }}
                            span={24}
                          >
                            <span style={{ color: 'red', fontSize: 18 }}>
                              {window.i18n.translate('TypeOfDamage')}
                            </span>
                          </Col>
                        ) : null}
                      </Row>
                      {this.state.vehicleType &&
                      this.state.vehicleType !== 'No Damage' ? (
                        <div>
                          <Row
                            type="flex"
                            justify="space-between"
                            align="middle"
                            style={{
                              marginBottom: 10,
                              borderBottom: '1px solid #00000024'
                            }}
                          >
                            <Col span={22}>
                              <Row
                                type="flex"
                                justify="space-between"
                                align="middle"
                                style={{
                                  marginBottom: 10
                                }}
                              >
                                {/* <span style={{ margin: 'auto', fontWeight: 700 }}>
                                Left View
                              </span> */}
                              </Row>
                              <Row
                                type="flex"
                                justify="space-around"
                                align="middle"
                                style={{
                                  marginBottom: 10
                                }}
                              >
                                <Col
                                  xs={24}
                                  md={16}
                                  style={{
                                    textAlign: 'center',
                                    margin: 'auto'
                                  }}
                                >
                                  <Button
                                    style={{
                                      margin: 'auto',
                                      marginRight: 15,
                                      marginBottom: 15
                                    }}
                                    onClick={e => this.addDamageType('Spray')}
                                  >
                                    {' '}
                                    {window.i18n.translate('SprayworkDone')}
                                  </Button>
                                  <Button
                                    style={{
                                      margin: 'auto',
                                      marginRight: 15,
                                      marginBottom: 15
                                    }}
                                    onClick={e => this.addDamageType('Scratch')}
                                  >
                                    {window.i18n.translate('Scratch')}
                                  </Button>
                                  <Button
                                    style={{
                                      margin: 'auto',
                                      marginRight: 15,
                                      marginBottom: 15
                                    }}
                                    onClick={e => this.addDamageType('Chip')}
                                  >
                                    {window.i18n.translate('Chips')}
                                  </Button>
                                  <Button
                                    style={{
                                      margin: 'auto',
                                      marginRight: 15,
                                      marginBottom: 15
                                    }}
                                    onClick={e => this.addDamageType('Dent')}
                                  >
                                    {window.i18n.translate('Dents')}
                                  </Button>
                                  <Button
                                    style={{
                                      margin: 'auto',
                                      marginRight: 15,
                                      marginBottom: 15
                                    }}
                                    onClick={e => this.addDamageType('Rust')}
                                  >
                                    {window.i18n.translate('Rust')}
                                  </Button>
                                  <Button
                                    style={{
                                      margin: 'auto',
                                      marginRight: 15,
                                      marginBottom: 15
                                    }}
                                    onClick={e => this.addDamageType('Hail')}
                                  >
                                    {window.i18n.translate('Hail')}
                                  </Button>
                                  <Button
                                    style={{
                                      margin: 'auto',
                                      marginRight: 15,
                                      marginBottom: 15
                                    }}
                                    onClick={e => this.addDamageType('Cracked')}
                                  >
                                    {window.i18n.translate('Cracked')}
                                  </Button>
                                  <Button
                                    style={{
                                      margin: 'auto',
                                      marginRight: 15,
                                      marginBottom: 15
                                    }}
                                    onClick={e => this.addDamageType('Broken')}
                                  >
                                    {window.i18n.translate('Broken')}
                                  </Button>
                                </Col>
                              </Row>
                              <Row
                                type="flex"
                                justify="space-between"
                                align="middle"
                                style={{
                                  marginBottom: 10
                                }}
                              >
                                <ButtonSquare
                                  type="primary"
                                  style={{ margin: 'auto', zIndex: 99 }}
                                  onClick={e => this.removeAllDamage()}
                                >
                                  {window.i18n.translate('RemoveAll')}
                                </ButtonSquare>
                              </Row>
                              <Row
                                className="car-view-pic-row-web"
                                type="flex"
                                justify="space-between"
                                align="middle"
                                style={{
                                  marginBottom: 10,
                                  overflow: 'scroll'
                                }}
                              >
                                <Stage
                                  style={{ margin: 'auto' }}
                                  ref={this.stageRef}
                                  width={950}
                                  height={470}
                                >
                                  <Layer>
                                    <URLImage
                                      width={440}
                                      height={140}
                                      x={30}
                                      src={this.getImageType(
                                        this.state.vehicleType,
                                        'left'
                                      )}
                                      preventDefault={false}
                                    />
                                    <URLImage
                                      width={440}
                                      height={140}
                                      x={480}
                                      src={this.getImageType(
                                        this.state.vehicleType,
                                        'right'
                                      )}
                                      preventDefault={false}
                                    />
                                    <URLImage
                                      width={250}
                                      height={150}
                                      x={140}
                                      y={140}
                                      src={this.getImageType(
                                        this.state.vehicleType,
                                        'front'
                                      )}
                                      preventDefault={false}
                                    />
                                    <URLImage
                                      width={250}
                                      height={150}
                                      x={580}
                                      y={140}
                                      src={this.getImageType(
                                        this.state.vehicleType,
                                        'back'
                                      )}
                                      preventDefault={false}
                                    />
                                    <URLImage
                                      width={440}
                                      height={180}
                                      y={300}
                                      x={280}
                                      src={this.getImageType(
                                        this.state.vehicleType,
                                        'top'
                                      )}
                                      preventDefault={false}
                                    />
                                    {this.state.damageType.Spray.map(i =>
                                      this.stageGroup(
                                        i,
                                        'Spraywork Done',
                                        'red'
                                      )
                                    )}
                                    {this.state.damageType.Scratch.map(i =>
                                      this.stageGroup(i, 'Scratch', 'yellow')
                                    )}
                                    {this.state.damageType.Chip.map(i =>
                                      this.stageGroup(i, 'Chip(s)', 'orange')
                                    )}
                                    {this.state.damageType.Dent.map(i =>
                                      this.stageGroup(i, 'Dent(s)', 'blue')
                                    )}
                                    {this.state.damageType.Rust.map(i =>
                                      this.stageGroup(i, 'Rust', 'purple')
                                    )}
                                    {this.state.damageType.Hail.map(i =>
                                      this.stageGroup(i, 'Hail', 'grey')
                                    )}
                                    {this.state.damageType.Cracked.map(i =>
                                      this.stageGroup(i, 'Cracked', 'brown')
                                    )}
                                    {this.state.damageType.Broken.map(i =>
                                      this.stageGroup(i, 'Broken', 'green')
                                    )}
                                  </Layer>
                                </Stage>
                              </Row>{' '}
                              <Row
                                className="car-view-pic-row-mobile"
                                type="flex"
                                justify="space-between"
                                align="middle"
                                style={{
                                  marginBottom: 10,
                                  overflow: 'scroll'
                                }}
                              >
                                <div style={{ zIndex: 1 }}>
                                  <Stage
                                    style={{
                                      margin: 'auto',
                                      overflow: 'scroll'
                                    }}
                                    ref={this.stageRef}
                                    width={950}
                                    height={880}
                                  >
                                    <Layer>
                                      <URLImage
                                        width={380}
                                        height={125}
                                        x={10}
                                        y={20}
                                        src={this.getImageType(
                                          this.state.vehicleType,
                                          'left'
                                        )}
                                      />
                                      <URLImage
                                        width={380}
                                        height={125}
                                        x={10}
                                        y={160}
                                        src={this.getImageType(
                                          this.state.vehicleType,
                                          'right'
                                        )}
                                      />
                                      <URLImage
                                        width={250}
                                        height={150}
                                        x={80}
                                        y={300}
                                        src={this.getImageType(
                                          this.state.vehicleType,
                                          'front'
                                        )}
                                      />
                                      <URLImage
                                        width={250}
                                        height={150}
                                        x={80}
                                        y={470}
                                        src={this.getImageType(
                                          this.state.vehicleType,
                                          'back'
                                        )}
                                      />
                                      <URLImage
                                        width={380}
                                        height={170}
                                        x={10}
                                        y={640}
                                        src={this.getImageType(
                                          this.state.vehicleType,
                                          'top'
                                        )}
                                      />
                                      {this.state.damageType.Spray.map(i =>
                                        this.stageGroup(
                                          i,
                                          'Spraywork Done',
                                          'red'
                                        )
                                      )}
                                      {this.state.damageType.Scratch.map(i =>
                                        this.stageGroup(i, 'Scratch', 'yellow')
                                      )}
                                      {this.state.damageType.Chip.map(i =>
                                        this.stageGroup(i, 'Chip(s)', 'orange')
                                      )}
                                      {this.state.damageType.Dent.map(i =>
                                        this.stageGroup(i, 'Dent(s)', 'blue')
                                      )}
                                      {this.state.damageType.Rust.map(i =>
                                        this.stageGroup(i, 'Rust', 'purple')
                                      )}
                                      {this.state.damageType.Hail.map(i =>
                                        this.stageGroup(i, 'Hail', 'grey')
                                      )}
                                      {this.state.damageType.Cracked.map(i =>
                                        this.stageGroup(i, 'Cracked', 'brown')
                                      )}
                                      {this.state.damageType.Broken.map(i =>
                                        this.stageGroup(i, 'Broken', 'green')
                                      )}
                                    </Layer>
                                  </Stage>
                                </div>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      ) : null}
                    </div>
                  </Collapse.Panel>
                  <Collapse.Panel
                    className="collapse-panel-width"
                    style={customPanelStyle}
                    header={
                      <span
                        id="priceRow"
                        style={{ color: '#00B4BC', fontWeight: 700 }}
                      >
                        {' '}
                        {window.i18n.translate('Step') +
                          ' ' +
                          '6 - ' +
                          window.i18n.translate('VehicleImages')}
                        {this.state.panelDamageStatus !==
                        window.i18n.translate('Complete')
                          ? ' - ' +
                            window.i18n.translate('Complete') +
                            ' ' +
                            window.i18n.translate('Step') +
                            ' 5 ' +
                            window.i18n.translate('ToUnlock')
                          : ''}
                      </span>
                    }
                    key="5"
                    disabled={
                      this.state.panelDamageStatus !==
                      window.i18n.translate('Complete')
                    }
                    extra={
                      <div style={{ display: 'flex' }}>
                        <span style={{ marginRight: 10, color: '#6D6E70' }}>
                          {this.state.panel5Status}
                        </span>
                        <div
                          style={{
                            width: 15,
                            height: 15,
                            background: this.state.panel5Color,
                            borderRadius: '50%',
                            position: 'relative',
                            top: 4,
                            marginRight: 15
                          }}
                        />
                      </div>
                    }
                  >
                    <Row
                      style={
                        window.innerWidth < 767
                          ? {
                              height: 'calc(100vh - 290px)',
                              overflow: 'auto'
                            }
                          : null
                      }
                      type="flex"
                      justify="space-between"
                      align="middle"
                    >
                      <Col
                        style={{
                          textAlign: 'center',
                          marginTop: 5,
                          fontSize: 15,
                          fontWeight: 700,
                          marginBottom: 20
                        }}
                        span={24}
                      >
                        <span>
                          {window.i18n.translate('MainViewImageRequired')}
                        </span>
                      </Col>
                      <Col xs={24} md={7}>
                        <Cards
                          style={{
                            width: '100%',
                            border: '1px solid #00888e',
                            marginBottom: 10
                          }}
                          extra={
                            this.state.mainImage ? (
                              <div
                                onClick={e =>
                                  this.setState({ mainImage: undefined })
                                }
                              >
                                <Icon
                                  style={{ color: 'red' }}
                                  type="close-circle"
                                  theme="filled"
                                />
                                <span style={{ color: 'red' }}>
                                  {window.i18n.translate('Remove')}
                                </span>
                              </div>
                            ) : (
                              ''
                            )
                          }
                          title={
                            <span style={{ color: '#00888E' }}>
                              {window.i18n.translate('MainView')}
                              <span style={{ color: 'red' }}>*</span>
                            </span>
                          }
                          cover={
                            this.state.mainImage ? (
                              <img
                                alt="example"
                                src={
                                  this.state.mainImage.search('data') > -1
                                    ? this.state.mainImage
                                    : 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                      this.state.mainImage
                                }
                              />
                            ) : (
                              <img alt="example" src={dropImage} />
                            )
                          }
                          actions={[
                            <Upload
                              name="mainImage"
                              showUploadList={false}
                              beforeUpload={this.beforeUpload}
                              customRequest={this.handleImageUpload}
                              onChange={this.handleMainImage}
                              loading={this.state.loading}
                              accept="image/*"
                            >
                              <ButtonSquare>
                                {' '}
                                {window.i18n.translate('UploadImage')}
                              </ButtonSquare>
                            </Upload>,
                            <Upload
                              name="mainImage"
                              showUploadList={false}
                              beforeUpload={this.beforeUpload}
                              customRequest={this.handleImageUpload}
                              onChange={this.handleMainImage}
                              loading={this.state.loading}
                              accept="image/*;capture=camera"
                              capture
                            >
                              <ButtonSquare>
                                {window.i18n.translate('TakeImage')}
                              </ButtonSquare>
                            </Upload>
                          ]}
                        />
                      </Col>
                      <Col xs={24} md={7}>
                        <Cards
                          style={{
                            width: '100%',
                            border: '1px solid #00888e',
                            marginBottom: 15
                          }}
                          extra={
                            this.state.frontImage ? (
                              <div
                                onClick={e =>
                                  this.setState({ frontImage: undefined })
                                }
                              >
                                <Icon
                                  style={{ color: 'red' }}
                                  type="close-circle"
                                  theme="filled"
                                />
                                <span style={{ color: 'red' }}>
                                  {' '}
                                  {window.i18n.translate('Remove')}
                                </span>
                              </div>
                            ) : (
                              ''
                            )
                          }
                          title={
                            <span style={{ color: '#00888E' }}>
                              {window.i18n.translate('FrontView') +
                                ' ' +
                                window.i18n.translate('Optional')}
                            </span>
                          }
                          cover={
                            this.state.frontImage ? (
                              <img
                                alt="example"
                                src={
                                  this.state.frontImage.search('data') > -1
                                    ? this.state.frontImage
                                    : 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                      this.state.frontImage
                                }
                              />
                            ) : (
                              <img alt="example" src={dropImage} />
                            )
                          }
                          actions={[
                            <Upload
                              name="frontImage"
                              showUploadList={false}
                              beforeUpload={this.beforeUpload}
                              customRequest={this.handleImageUpload}
                              onChange={this.handleFrontImage}
                              loading={this.state.loading}
                              accept="image/*"
                            >
                              <Button>
                                {window.i18n.translate('UploadImage')}
                              </Button>
                            </Upload>,
                            <Upload
                              name="frontImage"
                              showUploadList={false}
                              beforeUpload={this.beforeUpload}
                              customRequest={this.handleImageUpload}
                              onChange={this.handleFrontImage}
                              loading={this.state.loading}
                              accept="image/*;capture=camera"
                              capture
                            >
                              <ButtonSquare>
                                {window.i18n.translate('TakeImage')}
                              </ButtonSquare>
                            </Upload>
                          ]}
                        />
                      </Col>
                      <Col xs={24} md={7}>
                        <Cards
                          style={{
                            width: '100%',
                            border: '1px solid #00888e',
                            marginBottom: 15
                          }}
                          extra={
                            this.state.leftImage ? (
                              <div
                                onClick={e =>
                                  this.setState({ mainImage: undefined })
                                }
                              >
                                <Icon
                                  style={{ color: 'red' }}
                                  type="close-circle"
                                  theme="filled"
                                />
                                <span style={{ color: 'red' }}>
                                  {' '}
                                  {window.i18n.translate('Remove')}
                                </span>
                              </div>
                            ) : (
                              ''
                            )
                          }
                          title={
                            <span style={{ color: '#00888E' }}>
                              {window.i18n.translate('LeftView') +
                                ' ' +
                                window.i18n.translate('Optional')}
                            </span>
                          }
                          cover={
                            this.state.leftImage ? (
                              <img
                                alt="example"
                                src={
                                  this.state.leftImage.search('data') > -1
                                    ? this.state.leftImage
                                    : 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                      this.state.leftImage
                                }
                              />
                            ) : (
                              <img alt="example" src={dropImage} />
                            )
                          }
                          actions={[
                            <Upload
                              name="leftImage"
                              showUploadList={false}
                              beforeUpload={this.beforeUpload}
                              customRequest={this.handleImageUpload}
                              onChange={this.handleLeftImage}
                              loading={this.state.loading}
                              accept="image/*"
                            >
                              <Button>
                                {window.i18n.translate('UploadImage')}
                              </Button>
                            </Upload>,
                            <Upload
                              name="leftImage"
                              showUploadList={false}
                              beforeUpload={this.beforeUpload}
                              customRequest={this.handleImageUpload}
                              onChange={this.handleLeftImage}
                              loading={this.state.loading}
                              accept="image/*;capture=camera"
                              capture
                            >
                              <ButtonSquare>
                                {window.i18n.translate('TakeImage')}
                              </ButtonSquare>
                            </Upload>
                          ]}
                        />
                      </Col>
                      <Col xs={24} md={7}>
                        <Cards
                          style={{
                            width: '100%',
                            border: '1px solid #00888e',
                            marginBottom: 15
                          }}
                          extra={
                            this.state.rightImage ? (
                              <div
                                onClick={e =>
                                  this.setState({ rightImage: undefined })
                                }
                              >
                                <Icon
                                  style={{ color: 'red' }}
                                  type="close-circle"
                                  theme="filled"
                                />
                                <span style={{ color: 'red' }}>
                                  {' '}
                                  {window.i18n.translate('Remove')}
                                </span>
                              </div>
                            ) : (
                              ''
                            )
                          }
                          title={
                            <span style={{ color: '#00888E' }}>
                              {window.i18n.translate('RightView') +
                                ' ' +
                                window.i18n.translate('Optional')}
                            </span>
                          }
                          cover={
                            this.state.rightImage ? (
                              <img
                                alt="example"
                                src={
                                  this.state.rightImage.search('data') > -1
                                    ? this.state.rightImage
                                    : 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                      this.state.rightImage
                                }
                              />
                            ) : (
                              <img alt="example" src={dropImage} />
                            )
                          }
                          actions={[
                            <Upload
                              name="rightImage"
                              showUploadList={false}
                              beforeUpload={this.beforeUpload}
                              customRequest={this.handleImageUpload}
                              onChange={this.handleRightImage}
                              loading={this.state.loading}
                              accept="image/*"
                            >
                              <Button>
                                {window.i18n.translate('UploadImage')}
                              </Button>
                            </Upload>,
                            <Upload
                              name="rightImage"
                              showUploadList={false}
                              beforeUpload={this.beforeUpload}
                              customRequest={this.handleImageUpload}
                              onChange={this.handleRightImage}
                              loading={this.state.loading}
                              accept="image/*;capture=camera"
                              capture
                            >
                              <ButtonSquare>
                                {window.i18n.translate('TakeImage')}
                              </ButtonSquare>
                            </Upload>
                          ]}
                        />
                      </Col>
                      <Col xs={24} md={7}>
                        <Cards
                          style={{
                            width: '100%',
                            border: '1px solid #00888e',
                            marginBottom: 15
                          }}
                          extra={
                            this.state.backImage ? (
                              <div
                                onClick={e =>
                                  this.setState({ backImage: undefined })
                                }
                              >
                                <Icon
                                  style={{ color: 'red' }}
                                  type="close-circle"
                                  theme="filled"
                                />
                                <span style={{ color: 'red' }}>
                                  {' '}
                                  {window.i18n.translate('Remove')}
                                </span>
                              </div>
                            ) : (
                              ''
                            )
                          }
                          title={
                            <span style={{ color: '#00888E' }}>
                              {window.i18n.translate('RearView') +
                                ' ' +
                                window.i18n.translate('Optional')}
                            </span>
                          }
                          cover={
                            this.state.backImage ? (
                              <img
                                alt="example"
                                src={
                                  this.state.backImage.search('data') > -1
                                    ? this.state.backImage
                                    : 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                      this.state.backImage
                                }
                              />
                            ) : (
                              <img alt="example" src={dropImage} />
                            )
                          }
                          actions={[
                            <Upload
                              name="backImage"
                              showUploadList={false}
                              beforeUpload={this.beforeUpload}
                              customRequest={this.handleImageUpload}
                              onChange={this.handleBackImage}
                              loading={this.state.loading}
                              accept="image/*"
                            >
                              <Button>
                                {window.i18n.translate('UploadImage')}
                              </Button>
                            </Upload>,
                            <Upload
                              name="backImage"
                              showUploadList={false}
                              beforeUpload={this.beforeUpload}
                              customRequest={this.handleImageUpload}
                              onChange={this.handleBackImage}
                              loading={this.state.loading}
                              accept="image/*;capture=camera"
                              capture
                            >
                              <ButtonSquare>
                                {window.i18n.translate('TakeImage')}
                              </ButtonSquare>
                            </Upload>
                          ]}
                        />
                      </Col>
                      <Col xs={24} md={7}>
                        <Cards
                          style={{
                            width: '100%',
                            border: '1px solid #00888e',
                            marginBottom: 15
                          }}
                          extra={
                            this.state.interiorImage ? (
                              <div
                                onClick={e =>
                                  this.setState({ interiorImage: undefined })
                                }
                              >
                                <Icon
                                  style={{ color: 'red' }}
                                  type="close-circle"
                                  theme="filled"
                                />
                                <span style={{ color: 'red' }}>
                                  {' '}
                                  {window.i18n.translate('Remove')}
                                </span>
                              </div>
                            ) : (
                              ''
                            )
                          }
                          title={
                            <span style={{ color: '#00888E' }}>
                              {window.i18n.translate('Interior') +
                                ' ' +
                                window.i18n.translate('Optional')}
                            </span>
                          }
                          cover={
                            this.state.interiorImage ? (
                              <img
                                alt="example"
                                src={
                                  this.state.interiorImage.search('data') > -1
                                    ? this.state.interiorImage
                                    : 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                      this.state.interiorImage
                                }
                              />
                            ) : (
                              <img alt="example" src={dropImage} />
                            )
                          }
                          actions={[
                            <Upload
                              name="interiorImage"
                              showUploadList={false}
                              beforeUpload={this.beforeUpload}
                              customRequest={this.handleImageUpload}
                              onChange={this.handleInteriorImage}
                              loading={this.state.loading}
                              accept="image/*"
                            >
                              <Button>
                                {window.i18n.translate('UploadImage')}
                              </Button>
                            </Upload>,
                            <Upload
                              name="interiorImage"
                              showUploadList={false}
                              beforeUpload={this.beforeUpload}
                              customRequest={this.handleImageUpload}
                              onChange={this.handleInteriorImage}
                              loading={this.state.loading}
                              accept="image/*;capture=camera"
                              capture
                            >
                              <ButtonSquare>
                                {window.i18n.translate('TakeImage')}
                              </ButtonSquare>
                            </Upload>
                          ]}
                        />
                      </Col>
                      <Col xs={24} md={7}>
                        <Cards
                          style={{
                            width: '100%',
                            border: '1px solid #00888e',
                            marginBottom: 15
                          }}
                          extra={
                            this.state.engineImage ? (
                              <div
                                onClick={e =>
                                  this.setState({ engineImage: undefined })
                                }
                              >
                                <Icon
                                  style={{ color: 'red' }}
                                  type="close-circle"
                                  theme="filled"
                                />
                                <span style={{ color: 'red' }}>
                                  {' '}
                                  {window.i18n.translate('Remove')}
                                </span>
                              </div>
                            ) : (
                              ''
                            )
                          }
                          title={
                            <span style={{ color: '#00888E' }}>
                              {window.i18n.translate('Engine') +
                                ' ' +
                                window.i18n.translate('Optional')}
                            </span>
                          }
                          cover={
                            this.state.engineImage ? (
                              <img
                                alt="example"
                                src={
                                  this.state.engineImage.search('data') > -1
                                    ? this.state.engineImage
                                    : 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                      this.state.engineImage
                                }
                              />
                            ) : (
                              <img alt="example" src={dropImage} />
                            )
                          }
                          actions={[
                            <Upload
                              name="engineImage"
                              showUploadList={false}
                              beforeUpload={this.beforeUpload}
                              customRequest={this.handleImageUpload}
                              onChange={this.handleEngineImage}
                              loading={this.state.loading}
                              accept="image/*"
                            >
                              <Button>
                                {window.i18n.translate('UploadImage')}
                              </Button>
                            </Upload>,
                            <Upload
                              name="engineImage"
                              showUploadList={false}
                              beforeUpload={this.beforeUpload}
                              customRequest={this.handleImageUpload}
                              onChange={this.handleEngineImage}
                              loading={this.state.loading}
                              accept="image/*;capture=camera"
                              capture
                            >
                              <ButtonSquare>
                                {window.i18n.translate('TakeImage')}
                              </ButtonSquare>
                            </Upload>
                          ]}
                        />
                      </Col>
                      <Col xs={24} md={7}>
                        <Cards
                          style={{
                            width: '100%',
                            border: '1px solid #00888e',
                            marginBottom: 15
                          }}
                          extra={
                            this.state.bootImage ? (
                              <div
                                onClick={e =>
                                  this.setState({ bootImage: undefined })
                                }
                              >
                                <Icon
                                  style={{ color: 'red' }}
                                  type="close-circle"
                                  theme="filled"
                                />
                                <span style={{ color: 'red' }}>
                                  {' '}
                                  {window.i18n.translate('Remove')}
                                </span>
                              </div>
                            ) : (
                              ''
                            )
                          }
                          title={
                            <span style={{ color: '#00888E' }}>
                              {window.i18n.translate('Boot') +
                                ' ' +
                                window.i18n.translate('Interior') +
                                ' ' +
                                window.i18n.translate('Optional')}
                            </span>
                          }
                          cover={
                            this.state.bootImage ? (
                              <img
                                alt="example"
                                src={
                                  this.state.bootImage.search('data') > -1
                                    ? this.state.bootImage
                                    : 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                      this.state.bootImage
                                }
                              />
                            ) : (
                              <img alt="example" src={dropImage} />
                            )
                          }
                          actions={[
                            <Upload
                              name="bootImage"
                              showUploadList={false}
                              beforeUpload={this.beforeUpload}
                              customRequest={this.handleImageUpload}
                              onChange={this.handleBootImage}
                              loading={this.state.loading}
                              accept="image/*"
                            >
                              <Button>
                                {window.i18n.translate('UploadImage')}
                              </Button>
                            </Upload>,
                            <Upload
                              name="bootImage"
                              showUploadList={false}
                              beforeUpload={this.beforeUpload}
                              customRequest={this.handleImageUpload}
                              onChange={this.handleBootImage}
                              loading={this.state.loading}
                              accept="image/*;capture=camera"
                              capture
                            >
                              <ButtonSquare>
                                {window.i18n.translate('TakeImage')}
                              </ButtonSquare>
                            </Upload>
                          ]}
                        />
                      </Col>
                      <Col xs={24} md={7}>
                        <Cards
                          style={{
                            width: '100%',
                            border: '1px solid #00888e',
                            marginBottom: 15
                          }}
                          extra={
                            this.state.service1Image ? (
                              <div
                                onClick={e =>
                                  this.setState({ service1Image: undefined })
                                }
                              >
                                <Icon
                                  style={{ color: 'red' }}
                                  type="close-circle"
                                  theme="filled"
                                />
                                <span style={{ color: 'red' }}>
                                  {' '}
                                  {window.i18n.translate('Remove')}
                                </span>
                              </div>
                            ) : (
                              ''
                            )
                          }
                          title={
                            <span style={{ color: '#00888E' }}>
                              {window.i18n.translate('ServiceBook') +
                                ' 1 ' +
                                window.i18n.translate('Stamped') +
                                ' ' +
                                window.i18n.translate('Optional')}
                            </span>
                          }
                          cover={
                            this.state.service1Image ? (
                              <img
                                alt="example"
                                src={
                                  this.state.service1Image.search('data') > -1
                                    ? this.state.service1Image
                                    : 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                      this.state.service1Image
                                }
                              />
                            ) : (
                              <img alt="example" src={dropImage} />
                            )
                          }
                          actions={[
                            <Upload
                              name="service1Image"
                              showUploadList={false}
                              beforeUpload={this.beforeUpload}
                              customRequest={this.handleImageUpload}
                              onChange={this.handleService1Image}
                              loading={this.state.loading}
                              accept="image/*"
                            >
                              <Button>
                                {window.i18n.translate('UploadImage')}
                              </Button>
                            </Upload>,
                            <Upload
                              name="service1Image"
                              showUploadList={false}
                              beforeUpload={this.beforeUpload}
                              customRequest={this.handleImageUpload}
                              onChange={this.handleService1Image}
                              loading={this.state.loading}
                              accept="image/*;capture=camera"
                              capture
                            >
                              <ButtonSquare>
                                {window.i18n.translate('TakeImage')}
                              </ButtonSquare>
                            </Upload>
                          ]}
                        />
                      </Col>
                      <Col xs={24} md={7}>
                        <Cards
                          style={{
                            width: '100%',
                            border: '1px solid #00888e',
                            marginBottom: 15
                          }}
                          extra={
                            this.state.service2Image ? (
                              <div
                                onClick={e =>
                                  this.setState({ service2Image: undefined })
                                }
                              >
                                <Icon
                                  style={{ color: 'red' }}
                                  type="close-circle"
                                  theme="filled"
                                />
                                <span style={{ color: 'red' }}>
                                  {' '}
                                  {window.i18n.translate('Remove')}
                                </span>
                              </div>
                            ) : (
                              ''
                            )
                          }
                          title={
                            <span style={{ color: '#00888E' }}>
                              {window.i18n.translate('ServiceBook') +
                                ' 2 ' +
                                window.i18n.translate('Stamped') +
                                ' ' +
                                window.i18n.translate('Optional')}
                            </span>
                          }
                          cover={
                            this.state.service2Image ? (
                              <img
                                alt="example"
                                src={
                                  this.state.service2Image.search('data') > -1
                                    ? this.state.service2Image
                                    : 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                      this.state.service2Image
                                }
                              />
                            ) : (
                              <img alt="example" src={dropImage} />
                            )
                          }
                          actions={[
                            <Upload
                              name="service2Image"
                              showUploadList={false}
                              beforeUpload={this.beforeUpload}
                              customRequest={this.handleImageUpload}
                              onChange={this.handleService2Image}
                              loading={this.state.loading}
                              accept="image/*"
                            >
                              <Button>
                                {window.i18n.translate('UploadImage')}
                              </Button>
                            </Upload>,
                            <Upload
                              name="service2Image"
                              showUploadList={false}
                              beforeUpload={this.beforeUpload}
                              customRequest={this.handleImageUpload}
                              onChange={this.handleService2Image}
                              loading={this.state.loading}
                              accept="image/*;capture=camera"
                              capture
                            >
                              <ButtonSquare>
                                {window.i18n.translate('TakeImage')}
                              </ButtonSquare>
                            </Upload>
                          ]}
                        />
                      </Col>
                    </Row>
                  </Collapse.Panel>
                  <Collapse.Panel
                    className="collapse-panel-width"
                    style={customPanelStyle}
                    header={
                      <span
                        id="auctionRow"
                        style={{ color: '#00B4BC', fontWeight: 700 }}
                      >
                        {' '}
                        {window.i18n.translate('Step') +
                          ' ' +
                          '7 - ' +
                          window.i18n.translate('AskingPrice')}
                        {this.state.panel5Status !==
                        window.i18n.translate('Complete')
                          ? ' - ' +
                            window.i18n.translate('Complete') +
                            ' ' +
                            window.i18n.translate('Step') +
                            ' 6 ' +
                            window.i18n.translate('ToUnlock')
                          : ''}
                      </span>
                    }
                    key="6"
                    disabled={
                      this.state.panel5Status !==
                      window.i18n.translate('Complete')
                    }
                    extra={
                      <div style={{ display: 'flex' }}>
                        <span style={{ marginRight: 10, color: '#6D6E70' }}>
                          {this.state.panel6Status}
                        </span>
                        <div
                          style={{
                            width: 15,
                            height: 15,
                            background: this.state.panel6Color,
                            borderRadius: '50%',
                            position: 'relative',
                            top: 4,
                            marginRight: 15
                          }}
                        />
                      </div>
                    }
                  >
                    <Row type="flex" justify="space-between" align="middle">
                      <Col span={24}>
                        <span>
                          {' '}
                          {window.i18n.translate('GiveIndication')}
                          <span style={{ color: 'red' }}>*</span>
                        </span>
                      </Col>
                    </Row>
                    <Row
                      type="flex"
                      justify="space-between"
                      align="middle"
                      style={{ marginBottom: 10 }}
                    >
                      <Col span={24}>
                        <Input
                          style={customInputStyle}
                          value={this.state.price}
                          onChange={e =>
                            this.setState({
                              price:
                                'R' +
                                numeral(e.currentTarget.value).format('0,0')
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </Collapse.Panel>
                  <Collapse.Panel
                    className="collapse-panel-width"
                    style={customPanelStyle}
                    header={
                      <span style={{ color: '#00B4BC', fontWeight: 700 }}>
                        {window.i18n.translate('Step') +
                          ' ' +
                          '8 - ' +
                          window.i18n.translate('SendToAuction')}

                        {this.state.panel6Status !==
                        window.i18n.translate('Complete')
                          ? ' - ' +
                            window.i18n.translate('Complete') +
                            ' ' +
                            window.i18n.translate('Step') +
                            ' 7 ' +
                            window.i18n.translate('ToUnlock')
                          : ''}
                      </span>
                    }
                    key="7"
                    disabled={
                      this.state.panel6Status !==
                      window.i18n.translate('Complete')
                    }
                    extra={
                      <div style={{ display: 'flex' }}>
                        <span style={{ marginRight: 10, color: '#6D6E70' }}>
                          {this.state.panel7Status}
                        </span>
                        <div
                          style={{
                            width: 15,
                            height: 15,
                            background: this.state.panel7Color,
                            borderRadius: '50%',
                            position: 'relative',
                            top: 4,
                            marginRight: 15
                          }}
                        />
                      </div>
                    }
                  >
                    <Row type="flex" justify="space-between" align="middle">
                      <Col span={12} style={{ display: 'flex' }}>
                        {this.validateRequired() ? (
                          <div
                            style={{
                              margin: 'auto'
                            }}
                          >
                            <Tooltip
                              placement="top"
                              title={
                                this.state.reviewForAuction
                                  ? window.i18n.translate('AlreadySent')
                                  : 'Please fill in all the required details'
                              }
                            >
                              <Button
                                type="primary"
                                style={{
                                  height: 46,
                                  width: 171,
                                  margin: 'auto',
                                  border: '1px solid rgb(14, 177, 27)',
                                  color: 'rgb(14, 177, 27)',
                                  backgroundColor: 'white'
                                }}
                                disabled={this.validateRequired()}
                                onClick={this.handleSendToAuction}
                              >
                                {window.i18n.translate('SendToAuction')}
                              </Button>
                            </Tooltip>
                          </div>
                        ) : (
                          <Button
                            type="primary"
                            style={{
                              height: 46,
                              width: 171,
                              margin: 'auto',
                              border: '1px solid rgb(14, 177, 27)',
                              color: 'rgb(14, 177, 27)',
                              backgroundColor: 'white'
                            }}
                            disabled={this.validateRequired()}
                            onClick={this.handleSendToAuction}
                          >
                            {window.i18n.translate('SendToAuction')}
                          </Button>
                        )}
                      </Col>
                      <Col span={12} style={{ display: 'flex' }}>
                        <Button
                          type="primary"
                          style={{
                            height: 46,
                            width: 171,
                            margin: 'auto',
                            border: '1px solid #ce2828',
                            color: '#ce2828',
                            backgroundColor: 'white'
                          }}
                          onClick={e => this.setState({ review: true })}
                        >
                          {window.i18n.translate('Review')}
                        </Button>
                      </Col>
                    </Row>
                  </Collapse.Panel>
                </Collapse>
              </Col>
            </Row>
          ) : null}
          {this.state.review ? (
            <Row type="flex" justify="start" align="middle">
              <Col span={24}>
                <DescriptionsStyle
                  title="Review"
                  bordered
                  column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                >
                  <Descriptions.Item label={window.i18n.translate('Year')}>
                    {this.state.year}
                  </Descriptions.Item>
                  <Descriptions.Item label={window.i18n.translate('Make')}>
                    {this.state.make}
                  </Descriptions.Item>
                  <Descriptions.Item label={window.i18n.translate('Model')}>
                    {this.state.model}
                  </Descriptions.Item>
                  <Descriptions.Item label={window.i18n.translate('ModelType')}>
                    {this.state.variant}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={window.i18n.translate('Transmission')}
                  >
                    {this.state.transmission}
                  </Descriptions.Item>
                  <Descriptions.Item label={window.i18n.translate('Mileage')}>
                    {this.state.mileage}
                  </Descriptions.Item>
                  <Descriptions.Item label={window.i18n.translate('Colour')}>
                    {this.state.colour}
                  </Descriptions.Item>
                  <Descriptions.Item label={window.i18n.translate('FuelType')}>
                    {this.state.fuel}
                  </Descriptions.Item>
                  <Descriptions.Item label={window.i18n.translate('Province')}>
                    {this.state.province}
                  </Descriptions.Item>
                  <Descriptions.Item label={window.i18n.translate('CityTown')}>
                    {this.state.city}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={window.i18n.translate('VehicleBought')}
                  >
                    {this.state.whereBoughtSelection && this.state.whereBought
                      ? this.state.whereBoughtSelection +
                        ' - ' +
                        this.state.whereBought
                      : ''}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      window.i18n.translate('ReasonFor') +
                      ' ' +
                      window.i18n.translate('Selling')
                    }
                  >
                    {this.state.sellingReason}
                  </Descriptions.Item>
                  <Descriptions.Item label={window.i18n.translate('Owners')}>
                    {this.state.owners}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={window.i18n.translate('LicenseExpiry')}
                  >
                    {this.state.licenseExpire}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={window.i18n.translate('VehicleCondition')}
                  >
                    {this.state.condition}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={window.i18n.translate('SpareKeys1')}
                  >
                    {this.state.spareKeys}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={window.i18n.translate('MechanicalIssues1')}
                  >
                    {this.state.mechanicalIssues}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={window.i18n.translate('DamageRepair')}
                  >
                    {this.state.bodyWork}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={window.i18n.translate('WindscreenCracks1')}
                  >
                    {this.state.windscreenDamage}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={window.i18n.translate('MagsRimsDamage')}
                  >
                    {this.state.magsDamage}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={window.i18n.translate('MyServiceHistory')}
                  >
                    {this.state.serviceHistory}
                  </Descriptions.Item>
                  <Descriptions.Item label={window.i18n.translate('Finance')}>
                    {this.state.financed}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={window.i18n.translate('ServiceMaintenance')}
                  >
                    {this.state.servicePlan}
                  </Descriptions.Item>
                  <Descriptions.Item label={window.i18n.translate('Warranty')}>
                    {this.state.warranty}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={window.i18n.translate('FrontTyres')}
                  >
                    {this.state.frontTyres}
                  </Descriptions.Item>
                  <Descriptions.Item label={window.i18n.translate('RearTyres')}>
                    {this.state.backTyres}
                  </Descriptions.Item>
                  {this.state.vehicleType &&
                  this.state.vehicleType !== 'No Damage' ? (
                    <Descriptions.Item
                      label={window.i18n.translate('VehicleDamage')}
                    >
                      <img alt="Damage" src={this.state.damage} />
                    </Descriptions.Item>
                  ) : (
                    <Descriptions.Item
                      label={window.i18n.translate('VehicleDamage')}
                    >
                      {window.i18n.translate('NoDamage')}
                    </Descriptions.Item>
                  )}
                  <Descriptions.Item
                    label={window.i18n.translate('VehicleImages')}
                  >
                    {this.state.mainImage ? (
                      <img
                        alt="Main"
                        style={{ maxWidth: '100%' }}
                        src={this.state.mainImage}
                      />
                    ) : (
                      ''
                    )}
                    {this.state.frontImage ? (
                      <img
                        alt="Main"
                        style={{ maxWidth: '100%' }}
                        src={this.state.frontImage}
                      />
                    ) : (
                      ''
                    )}
                    {this.state.leftImage ? (
                      <img
                        alt="Main"
                        style={{ maxWidth: '100%' }}
                        src={this.state.leftImage}
                      />
                    ) : (
                      ''
                    )}
                    {this.state.rightImage ? (
                      <img
                        alt="Main"
                        style={{ maxWidth: '100%' }}
                        src={this.state.rightImage}
                      />
                    ) : (
                      ''
                    )}
                    {this.state.backImage ? (
                      <img
                        alt="Main"
                        style={{ maxWidth: '100%' }}
                        src={this.state.backImage}
                      />
                    ) : (
                      ''
                    )}
                    {this.state.interiorImage ? (
                      <img
                        alt="Main"
                        style={{ maxWidth: '100%' }}
                        src={this.state.interiorImage}
                      />
                    ) : (
                      ''
                    )}
                    {this.state.engineImage ? (
                      <img
                        alt="Main"
                        style={{ maxWidth: '100%' }}
                        src={this.state.engineImage}
                      />
                    ) : (
                      ''
                    )}
                    {this.state.bootImage ? (
                      <img
                        alt="Main"
                        style={{ maxWidth: '100%' }}
                        src={this.state.bootImage}
                      />
                    ) : (
                      ''
                    )}
                    {this.state.service1Image ? (
                      <img
                        alt="Main"
                        style={{ maxWidth: '100%' }}
                        src={this.state.service1Image}
                      />
                    ) : (
                      ''
                    )}
                    {this.state.service2Image ? (
                      <img
                        alt="Main"
                        style={{ maxWidth: '100%' }}
                        src={this.state.service2Image}
                      />
                    ) : (
                      ''
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={window.i18n.translate('AskingPrice')}
                  >
                    {this.state.price}
                  </Descriptions.Item>
                </DescriptionsStyle>
                <Row type="flex" justify="space-between" align="middle">
                  <Col span={24} style={{ display: 'flex' }}>
                    <Button
                      type="primary"
                      style={{
                        height: 46,
                        width: 171,
                        margin: 'auto',
                        marginTop: '1em',
                        marginBottom: '1em',
                        border: '1px solid rgb(14, 177, 27)',
                        color: 'rgb(14, 177, 27)',
                        backgroundColor: 'white'
                      }}
                      disabled={this.validateRequired()}
                      onClick={this.handleSendToAuction}
                    >
                      {window.i18n.translate('SendToAuction')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : null}
        </Spin>
      </div>
    );
  }
}

export default EditVehicle;
