/* global window */
import React from 'react';
import { Layout, Menu, Icon, Col } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Cookies } from 'react-cookie';
import { getSidebarCollapsed } from 'src/redux/selectors';
import { collapseSidebar } from 'src/redux/actions/status';
import AddCarIcon from 'src/static/icons/add-car-icon.png';

const { Sider } = Layout;

const StyledLink = styled(Link)`
  color: #fff;
  :hover {
    text-decoration: none !important;
  }
  .anticon {
    color: #00888e;
  }
`;

const Sidebar = props => {
  const signOut = () => {
    if (window.cordova) {
      window.FirebasePlugin.getToken(
        token => {
          window.pubnub.push.deleteDevice(
            {
              device: token,
              pushGateway: 'gcm' // apns, gcm, mpns
            },
            function(status) {
              if (status.error) {
                console.log('operation failed w/ error:', status);
              }
            }
          );
        },
        error => {
          console.error(error);
        }
      );
    }

    const cookies = new Cookies();
    cookies.remove('token');
    cookies.remove('basic');
    localStorage.clear();
    window.location.reload();
  };

  return (
    <Sider
      width="72"
      collapsedWidth="80"
      trigger={null}
      collapsible
      collapsed={false}
      id="dealerSidebarId"
      // width={170}

      style={{
        border: '1px solid #00888e3b',
        overflow: 'hidden',
        zIndex: 9,
        position: 'fixed',
        background: 'white',
        height: 'calc(100vh - 60px)',
        left: 0,
        top: 60
      }}
    >
      <Menu
        defaultSelectedKeys={['landingpage']}
        style={{ background: 'none' }}
        theme="light"
        mode="inline"
      >
        <Menu.Item key="landingpage" style={{ marginTop: '12px' }}>
          <StyledLink to="/user/landingpage">
            <Icon
              type="appstore"
              theme="filled"
              style={{ fontSize: '20px', paddingRight: '12px' }}
            />
            <span style={{ fontSize: '15px' }}>Verkoopnou</span>
          </StyledLink>
        </Menu.Item>
        {/*  <Menu.Item key="dashboard">
          <StyledLink to="/user/dashboard">
            <Icon
              type="appstore"
              theme="filled"
              style={{ fontSize: '20px', paddingRight: '15px' }}
            />
            <span>Dashboard</span>
          </StyledLink>
    </Menu.Item> */}
        <Menu.Item key="mycars">
          <StyledLink to="/user/myvehicles">
            <Icon
              type="car"
              theme="filled"
              style={{ fontSize: '20px', paddingRight: '12px' }}
            />
            <span style={{ fontSize: '15px' }}>
              {window.i18n.translate('MyVehicles')}
            </span>
          </StyledLink>
        </Menu.Item>
        <Menu.Item key="addvehicle">
          <StyledLink to="/user/addvehicle">
            <img
              src={AddCarIcon}
              alt=""
              style={{
                height: 30,
                width: 38,
                marginLeft: '-10px',
                marginBottom: '10px',
                marginRight: '-75px'
              }}
            />

            <span style={{ fontSize: '15px', paddingLeft: '90px' }}>
              {window.i18n.translate('AddVehicle')}
            </span>
          </StyledLink>
        </Menu.Item>{' '}
        <Menu.Item onClick={signOut} key="signout">
          <Icon
            style={{
              transform: 'rotate(180deg)',

              marginLeft: '4.5px',

              fontSize: '21px',
              color: '#00888E'
            }}
            type="logout"
          />
          <span
            style={{
              fontSize: '16px',
              paddingLeft: '14px',
              verticalAlign: '0.2em',
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.65)'
            }}
          >
            {window.i18n.translate('SignOut')}
          </span>{' '}
        </Menu.Item>
        {/* <img   
                src={SideBarImg}
                alt=""
                style={{
                  bottom: '-20px',
                  width: '200px',
                  opacity: 0.4,
                  overflow: 'hidden'
                }}
              />
         </Menu> */}
        {/* <Menu style={{ background: "none", position: "absolute", bottom: 110 }} theme="light" mode="inline"> */}
        {/* <Menu.Item
          style={{
            position: 'absolute',
            bottom: 110,
            display: props.collapsed ? 'false' : 'block'
          }}
          key="account"
        >
          <StyledLink to="/user/account">
            <Icon type="setting" theme="filled" />
            <span>Account Settings</span>
          </StyledLink>
        </Menu.Item>
        <Menu.Item
          style={{
            position: 'absolute',
            bottom: 70,
            display: props.collapsed ? 'none' : 'block'
          }}
          onClick={signOut}
          key="signout"
        >
         <StyledLink to="/"> 
          <Icon type="poweroff" />
          <span>Sign Out</span>
          </StyledLink> 
        </Menu.Item> */}
      </Menu>
    </Sider>
  );
};

const mapStateToProps = state => {
  return {
    collapsed: getSidebarCollapsed(state)
  };
};

const mapDispatchToProps = {
  collapseSidebar
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Sidebar)
);
