import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  signIn,
  signInToken,
  signInGoogle,
  signInFacebook,
  changePassword
} from 'src/redux/actions/user';
import { getLocale } from 'src/redux/selectors';
import changeLocale from 'src/redux/actions/locale';

class SignIn extends Component {
  componentWillMount = () => {
    if (window.location.pathname.search('/admin') > -1) {
      this.props.push('/admin');
    }
    if (window.location.pathname.search('/signup') > -1) {
      this.props.push('/signup');
    }
  };
  render = () => {
    const {
      Layout,
      signIn,
      signInToken,
      currentLocale,
      changeLocale,
      signInGoogle,
      signInFacebook,
      push,
      changePassword
    } = this.props;

    return (
      <Layout
        signIn={signIn}
        signInToken={signInToken}
        currentLocale={currentLocale}
        changeLocale={changeLocale}
        signInGoogle={signInGoogle}
        signInFacebook={signInFacebook}
        push={push}
        changePassword={changePassword}
      />
    );
  };
}

const mapStateToProps = state => ({
  currentLocale: getLocale(state)
});

const mapDispatchToProps = {
  signIn,
  signInToken,
  signInGoogle,
  signInFacebook,
  changeLocale,
  push,
  changePassword
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);
