import React, { PureComponent } from 'react';
import {
  Layout,
  Row,
  Col,
  Icon,
  Popover,
  Menu,
  Modal,
  Upload,
  Badge,
  notification,
  List,
  Drawer,
  Sider
} from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import logo from 'src/static/logo.svg';
import { Cookies } from 'react-cookie';
import { saveAccountInfo } from 'src/redux/actions/user';
import {
  getSidebarCollapsed,
  getUser,
  getNotifications
} from 'src/redux/selectors';
import { ButtonSquare } from 'src/components/elements/Button';
import { push } from 'connected-react-router';
import { collapseSidebar } from 'src/redux/actions/status';
import Resizer from 'react-image-file-resizer';
import Notify from 'src/components/meta/Notification';
import { setVehicleDetailsId } from 'src/redux/actions/vehicles';
import {
  findNotifications,
  removeNotification
} from 'src/redux/actions/notifications';
import AddCarIcon from 'src/static/icons/add-car-icon.png';
const StyledLink = styled(Link)`
  color: #fff;
  :hover {
    text-decoration: none !important;
  }
  .anticon {
    color: #00888e;
  }
`;
const Logo = () => (
  <SVG
    className="header-logo"
    src={logo}
    style={{ margin: 'auto', height: 40, width: '400px' }}
  />
);
const Sidebar = props => (
  <Menu
    style={{ background: 'none' }}
    theme="light"
    mode="inline"
    defaultSelectedKeys={['auctionvehicles']}
  >
    {' '}
    <img
      className="header-logo"
      src={logo}
      style={{ margin: 'auto', height: 90, width: '100px' }}
    />
    <Menu.Item
      // disabled={props.user.approved === true ? false : true}
      style={{ marginTop: '15px' }}
      key="auctionvehicles"
    >
      <StyledLink to="/dealer/auctionvehicles">
        <Icon
          type="car"
          theme="filled"
          style={{ fontSize: '20px', paddingRight: '12px' }}
        />
        <span style={{ fontSize: '15px' }}>
          {' '}
          {window.i18n.translate('VehiclesOnAuction')}
        </span>
      </StyledLink>
    </Menu.Item>
    <Menu.Item
      //disabled={props.user.approved === true ? false : true}
      key="mybasket"
    >
      <StyledLink to="/dealer/mybasket">
        <Icon
          type="shopping-cart"
          style={{ fontSize: '20px', paddingRight: '12px' }}
        />
        <span style={{ fontSize: '15px' }}>
          {' '}
          {window.i18n.translate('MyBasket')}
        </span>
      </StyledLink>
    </Menu.Item>
    <Menu.Item
      //disabled={props.user.approved === true ? false : true}
      key="history"
    >
      <StyledLink to="/dealer/history">
        <Icon
          type="history"
          style={{ fontSize: '20px', paddingRight: '12px' }}
        />
        <span style={{ fontSize: '15px' }}>
          {' '}
          {window.i18n.translate('History')}
        </span>
      </StyledLink>
    </Menu.Item>
  </Menu>
);

export class ResHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      visibleDrawer: false,
      placement: 'left',
      imageVisible: false,
      notifVisible: false
    };
  }

  showDrawer = () => {
    this.setState({
      visibleDrawer: true
    });
  };

  onClose = () => {
    this.setState({
      visibleDrawer: false
    });
  };
  componentWillMount = () => {
    this.props

      .findNotifications()
      .then(() => {})
      .catch(e => {
        Notify('error', 'Error retrieving notifictation history');
      });
    window.pubnub.addListener({
      message: msg => {
        this.props
          .findNotifications()
          .then(() => {})
          .catch(e => {
            Notify('error', 'Error retrieving notifictation history');
          });
      }
    });
  };
  collapseSidebar = () => {
    this.props.collapseSidebar();
  };
  signOut = () => {
    if (window.cordova) {
      window.FirebasePlugin.getToken(
        token => {
          window.pubnub.push.deleteDevice(
            {
              device: token,
              pushGateway: 'gcm' // apns, gcm, mpns
            },
            function(status) {
              if (status.error) {
                console.log('operation failed w/ error:', status);
              }
            }
          );
        },
        error => {
          console.error(error);
        }
      );
    }

    const cookies = new Cookies();
    cookies.remove('token');
    cookies.remove('basic');
    localStorage.clear();
    window.location.reload();
  };
  cancelProfileImage = e => {
    this.setState({ imageVisible: false });
  };
  handleProfileImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        image: imageUrl
      });
    });
  };
  handleImageUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };
  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 3;
    if (!isLt2M) {
      Notify('error', window.i18n.translate('ImageSmaller'));
    }
    return false;
  };
  getBase64 = (img, callback) => {
    Resizer.imageFileResizer(img, 200, 200, 'PNG', 100, 0, uri => {
      callback(uri);
    });
  };
  removeImage = e => {
    this.setState({
      image: undefined
    });
  };
  handleAcceptProfileImage = e => {
    let user = this.props.user;
    user.image = this.state.image;
    this.props
      .saveAccountInfo(user)
      .then(() => {
        this.setState({ loading: false, imageVisible: false, visible: false });
        Notify('success', 'Success', 'Successfully saved account information');
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', window.i18n.translate('Notify3'), e);
      });
  };
  handleNotificationClick = e => {
    this.setState({ notifVisible: false });
    let item = JSON.parse(e.currentTarget.getAttribute('data'));
    if (item.message.type === 'review') {
      this.props.setVehicleDetailsId(JSON.parse(item.message.vehicleId));
      this.props.push('/admin/vehicledetails');
    }
    if (item.message.type === 'adminChanged') {
      this.props.setVehicleDetailsId(item.message.vehicleId);
      this.props.push('/user/myvehicles');
    }
    if (item.message.type === 'dealerWonBid') {
      this.props.setVehicleDetailsId(item.message.vehicleId);
      this.props.push('/user/myvehicles');
    }
    if (item.message.type === 'userAcceptedOffer') {
      this.props.setVehicleDetailsId(item.message.vehicleId);
      this.props.push('/dealer/history');
    }
    if (item.message.type === 'userRejectedOffer') {
      this.props.setVehicleDetailsId(item.message.vehicleId);
      this.props.push('/dealer/history');
    }
    if (item.message.type === 'dealerApproved') {
      this.setState({ loading: true });
      this.props
        .saveAccountInfo()
        .then(() => {
          this.setState({ loading: false });
          this.props.push('/dealer/dashboard');
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify('error', e);
        });
    }
    this.props
      .removeNotification(item._id)
      .then(() => {
        this.props
          .findNotifications()
          .then(() => {})
          .catch(e => {
            Notify('error', 'Error retrieving notifictation history');
          });
      })
      .catch(e => {
        Notify('error', 'Error removing notification');
      });
  };
  clearNotifications = e => {
    this.props
      .removeNotification()
      .then(() => {
        this.props
          .findNotifications()
          .then(() => {})
          .catch(e => {
            Notify('error', 'Error retrieving notifictation history');
          });
      })
      .catch(e => {
        Notify('error', 'Error removing notification');
      });
  };
  render() {
    return (
      <Layout.Header
        style={{
          zIndex: 99,
          background: 'white',
          height: 60,
          position: 'fixed',
          width: '100%',
          padding: 0,
          border: '1px solid #00878e57'
        }}
      >
        {/* <Row type='flex' align='middle' justify="start" style={{ height: '100%' }}> */}

        <Col
          style={{
            height: 60,
            width: 140,
            display: 'flex',
            borderBottom: '0px solid #E5E5E5'
          }}
          span={1}
        >
          <Logo />{' '}
          <Icon
            className="burger-menu"
            style={{
              visibility: 'hidden',
              fontSize: '2em',
              position: 'absolute',
              right: '2em',
              top: '0.7em'
            }}
            type="menu"
            onClick={this.showDrawer}
          />
          <Drawer
            style={{ textAlign: 'left' }}
            placement={this.state.placement}
            closable={true}
            onClose={this.onClose}
            visible={this.state.visibleDrawer}
          >
            {this.props.user.type === 'dealer' ? (
              <Sidebar />
            ) : (
              <Menu>
                <img
                  className="header-logo"
                  src={logo}
                  style={{ margin: 'auto', height: 90, width: '100px' }}
                />
                <Menu.Item key="landingpage" style={{ marginTop: '12px' }}>
                  <StyledLink to="/user/landingpage">
                    <Icon
                      type="appstore"
                      theme="filled"
                      style={{ fontSize: '20px', paddingRight: '12px' }}
                    />{' '}
                    <span style={{ fontSize: '15px' }}>Verkoopnou</span>
                  </StyledLink>
                </Menu.Item>
                <Menu.Item key="mycars">
                  <StyledLink to="/user/myvehicles">
                    <Icon
                      type="car"
                      theme="filled"
                      style={{ fontSize: '20px', paddingRight: '12px' }}
                    />{' '}
                    <span style={{ fontSize: '15px' }}>
                      {window.i18n.translate('MyVehicles')}
                    </span>
                  </StyledLink>
                </Menu.Item>
                <Menu.Item key="addvehicle">
                  <StyledLink to="/user/addvehicle">
                    <img
                      src={AddCarIcon}
                      alt=""
                      style={{
                        height: 28,
                        width: 35,
                        marginLeft: '-8px',
                        marginBottom: '10px'
                      }}
                    />{' '}
                    <span style={{ fontSize: '15px', paddingLeft: '15px' }}>
                      {window.i18n.translate('AddVehicle')}
                    </span>
                  </StyledLink>
                </Menu.Item>{' '}
                <Menu.Item onClick={this.signOut} key="signout">
                  <Icon
                    style={{
                      transform: 'rotate(180deg)',

                      marginLeft: '6px',

                      fontSize: '21px',
                      color: '#00888E'
                    }}
                    type="logout"
                  />
                  <span
                    style={{
                      fontSize: '16px',
                      paddingLeft: '15.5px',
                      verticalAlign: '0.2em',
                      fontWeight: 500,
                      color: 'rgba(0, 0, 0, 0.65)'
                    }}
                  >
                    {window.i18n.translate('SignOut')}
                  </span>{' '}
                </Menu.Item>
              </Menu>
            )}
          </Drawer>
        </Col>
        <Col
          span={1}
          style={{
            display: 'flex',
            height: 60,
            width: 60,
            borderRight: '0px solid #E5E5E5',
            borderLeft: '0px solid #E5E5E5',
            borderBottom: '0px solid #E5E5E5'
          }}
        >
          <Icon
            className="trigger"
            type={this.props.collapsed ? 'menu-unfold' : 'menu-fold'}
            onClick={this.collapseSidebar}
            style={{ fontSize: 26, margin: 'auto', visibility: 'hidden' }}
          />
        </Col>
        <Col
          span={1}
          style={{
            float: 'right',
            display: 'flex',
            height: 60,
            width: 60,
            borderLeft: '0px solid #E5E5E5'
          }}
        >
          <Popover
            placement="bottomRight"
            content={
              <div>
                <Row type="flex" justify="space-between" align="middle">
                  <Col span={6}>
                    {this.props.user.image ? (
                      <div
                        onClick={e =>
                          this.setState({
                            imageVisible: true,
                            image: this.props.user.image
                          })
                        }
                        style={{
                          border: '1px solid #00888e3b',
                          cursor: 'pointer',
                          height: 60,
                          width: 60,
                          margin: 'auto',
                          borderRadius: '50%',
                          display: 'flex',
                          overflow: 'hidden'
                        }}
                      >
                        <img
                          style={{ margin: 'auto', width: 60 }}
                          alt="Profile"
                          src={this.props.user.image}
                        />
                      </div>
                    ) : (
                      <div
                        onClick={e => this.setState({ imageVisible: true })}
                        style={{
                          cursor: 'pointer',
                          height: 60,
                          width: 60,
                          margin: 'auto',
                          borderRadius: '50%',
                          background: '#00888E',
                          display: 'flex'
                        }}
                      >
                        <span
                          style={{
                            margin: 'auto',
                            lineHeight: '60px',
                            fontSize: 35,
                            color: 'white'
                          }}
                        >
                          {this.props.user.name.substr(0, 1)}
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col span={17}>
                    <div style={{ margin: '0px 0px 8px 0px', width: 250 }}>
                      <span style={{ fontSize: 13 }}>
                        {this.props.user.username}
                      </span>
                    </div>

                    <ButtonSquare
                      style={{ height: 25 }}
                      onClick={e => {
                        if (this.props.user.type === 'dealer') {
                          this.props.push('/dealer/account');
                        } else if (this.props.user.type === 'user') {
                          this.props.push('/user/account');
                        } else if (this.props.user.type === 'admin') {
                          this.props.push('/admin/account');
                        }
                      }}
                      type="primary"
                    >
                      {window.i18n.translate('MySettings')}
                    </ButtonSquare>
                  </Col>
                </Row>
                <Row
                  type="flex"
                  justify="space-between"
                  align="middle"
                  style={{ marginTop: 20 }}
                >
                  <Menu
                    style={{ background: 'none' }}
                    theme="light"
                    mode="inline"
                  >
                    <Menu.Item onClick={this.signOut} key="signout">
                      <Icon type="poweroff" />
                      <span> {window.i18n.translate('SignOut')}</span>
                    </Menu.Item>
                  </Menu>
                </Row>
              </div>
            }
            trigger="click"
            // visible={this.state.visible}
          >
            {this.props.user.image ? (
              <div
                onClick={e => this.setState({ visible: !this.state.visible })}
                style={{
                  border: '1px solid #00888e3b',
                  cursor: 'pointer',
                  height: 40,
                  width: 40,
                  margin: 'auto',
                  borderRadius: '50%',
                  display: 'flex',
                  overflow: 'hidden'
                }}
              >
                <img
                  style={{ margin: 'auto', width: 40 }}
                  alt="Profile"
                  src={this.props.user.image}
                />
              </div>
            ) : (
              <div
                onClick={e => {
                  this.setState({ visible: !this.state.visible });
                }}
                style={{
                  cursor: 'pointer',
                  height: 40,
                  width: 40,
                  margin: 'auto',
                  borderRadius: '50%',
                  background: '#00888E',
                  display: 'flex'
                }}
              >
                <span
                  style={{
                    margin: 'auto',
                    lineHeight: '40px',
                    fontSize: 25,
                    color: 'white'
                  }}
                >
                  {this.props.user.name.substr(0, 1)}
                </span>
              </div>
            )}
          </Popover>
        </Col>
        {/* <Col span={3} style={{float: "right", display: "flex", height: 60}} >
            <span onClick={e => this.props.push("/account")} style={{cursor: "pointer", fontSize: 15, margin: "auto"}}>{this.props.user.name + " " + this.props.user.surname}</span>
            </Col> */}
        <Col
          span={1}
          style={{
            float: 'right',
            display: 'flex',
            height: 60,
            width: 60,
            borderRight: '1px solid #E5E5E5',
            borderLeft: '1px solid #E5E5E5'
          }}
        >
          <Popover
            title={window.i18n.translate('YourNotifications')}
            placement="bottomRight"
            content={
              <div>
                <a
                  onClick={this.clearNotifications}
                  style={{
                    position: 'absolute',
                    right: 10,
                    top: 15,
                    display:
                      this.props.notifications.length === 0 ? 'none' : 'block'
                  }}
                >
                  {window.i18n.translate('ClearAll')}
                </a>
                <Row type="flex" justify="space-between" align="middle">
                  <Col span={24}>
                    <List
                      locale={{ emptyText: 'No Notifications' }}
                      pagination={{
                        onChange: page => {
                          console.log(page);
                        },
                        pageSize: 5
                      }}
                      itemLayout="horizontal"
                      dataSource={this.props.notifications}
                      renderItem={item => (
                        <List.Item
                          style={{ cursor: 'pointer' }}
                          data={JSON.stringify(item)}
                          onClick={this.handleNotificationClick}
                        >
                          <Col span={5}>
                            <div
                              style={{
                                cursor: 'pointer',
                                height: 50,
                                width: 50,
                                margin: 'auto',
                                marginLeft: '0px',
                                borderRadius: '50%',
                                background: '#00888E',
                                display: 'flex'
                              }}
                            >
                              <span
                                style={{
                                  margin: 'auto',
                                  lineHeight: '0px',
                                  fontSize: 30,
                                  color: 'white'
                                }}
                              >
                                <Icon type="notification" />
                              </span>
                            </div>
                          </Col>
                          <List.Item.Meta
                            title={item.message.sentBy}
                            description={item.message.message}
                          />
                        </List.Item>
                      )}
                    />
                  </Col>
                </Row>
              </div>
            }
            trigger="click"
            // visible={this.state.notifVisible}
          >
            <a
              onClick={e =>
                this.setState({ notifVisible: !this.state.notifVisible })
              }
              style={{ margin: 'auto' }}
            >
              <Badge count={this.props.notifications.length}>
                <Icon type="bell" theme="filled" style={{ fontSize: 26 }} />
              </Badge>
            </a>
          </Popover>
        </Col>
        <Modal
          title={'Profile Image'}
          visible={this.state.imageVisible}
          onOk={this.handleAcceptProfileImage}
          onCancel={this.cancelProfileImage}
          okText={window.i18n.translate('Accept')}
          cancelText={window.i18n.translate('Cancel')}
        >
          {/* <div style={{ width: '100%', height: 154, display: 'flex' }}> */}
          <Row type="flex" justify="center">
            <Col style={{ display: 'flex', marginBottom: 20 }} span={24}>
              <div style={{ margin: 'auto' }}>
                {this.state.image ? (
                  <div
                    style={{
                      border: '1px solid #00888e3b',
                      height: 200,
                      width: 200,
                      margin: 'auto',
                      borderRadius: '50%',
                      display: 'flex',
                      overflow: 'hidden'
                    }}
                  >
                    <img
                      style={{ margin: 'auto', width: 200 }}
                      alt="Profile"
                      src={this.state.image}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      height: 200,
                      width: 200,
                      margin: 'auto',
                      borderRadius: '50%',
                      background: '#00888E',
                      display: 'flex'
                    }}
                  >
                    <span
                      style={{
                        margin: 'auto',
                        lineHeight: '200px',
                        fontSize: 130,
                        color: 'white'
                      }}
                    >
                      {this.props.user.name.substr(0, 1)}
                    </span>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col style={{ display: 'flex' }} span={24}>
              <div style={{ margin: 'auto' }}>
                <Upload
                  name="mainImage"
                  showUploadList={false}
                  beforeUpload={this.beforeUpload}
                  customRequest={this.handleImageUpload}
                  onChange={this.handleProfileImage}
                  accept="image/*"
                >
                  <ButtonSquare style={{ height: 45 }} type="primary">
                    {window.i18n.translate('UploadProfileImage')}
                  </ButtonSquare>
                </Upload>
                <ButtonSquare
                  style={{ marginLeft: 5, height: 45 }}
                  onClick={this.removeImage}
                >
                  {window.i18n.translate('Remove')}
                </ButtonSquare>
              </div>
            </Col>
          </Row>
          {/* </div> */}
        </Modal>
      </Layout.Header>
    );
  }
}

const mapStateToProps = state => ({
  collapsed: getSidebarCollapsed(state),
  notifications: getNotifications(state) || [],
  user: getUser(state) || {}
});

const mapDispatchToProps = {
  collapseSidebar,
  push,
  saveAccountInfo,
  setVehicleDetailsId,
  findNotifications,
  removeNotification
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ResHeader, Sidebar)
);
