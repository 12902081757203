import React from 'react';
import styled from 'styled-components';
import { Icon, Row, Col, Spin, Button, List, Tabs, Input, Table } from 'antd';
import moment from 'moment';
import Notify from 'src/components/meta/Notification';
import noImage from 'src/static/icons/carImageUpload/no-image-available.svg';
import Countdown from 'react-countdown-now';
const { Column, ColumnGroup } = Table;
const numeral = require('numeral');
const { TabPane } = Tabs;
const hammer = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6475 17.3418H1.65195C0.740018 17.3418 0 18.0817 0 18.9938V21.9977H14.2994V18.9938C14.2994 18.0817 13.5595 17.3418 12.6475 17.3418Z"
      fill="#AD5E00"
    />
    <path
      d="M21.7036 17.5744L11.6665 7.53725L13.6051 5.60203C13.9248 5.88391 14.4163 5.87013 14.7188 5.56766C15.0351 5.248 15.0351 4.7358 14.7188 4.41614L10.539 0.239743C10.2228 -0.0799143 9.70715 -0.0799143 9.39094 0.239743C9.07128 0.555998 9.07128 1.0716 9.39094 1.38781L9.35657 1.35344L4.04924 6.65728L4.08361 6.69505C3.76736 6.37539 3.25175 6.37539 2.93554 6.69505C2.61589 7.01131 2.61589 7.52691 2.93554 7.84317L7.11194 12.023C7.4316 12.3392 7.9472 12.3392 8.26346 12.023C8.57971 11.7033 8.57971 11.1912 8.26346 10.8715L8.29783 10.9058L10.2365 8.9706L20.2702 19.0043C20.6655 19.3996 21.3083 19.3996 21.7036 19.0043C22.0989 18.609 22.0989 17.9697 21.7036 17.5744Z"
      fill="#AD5E00"
    />
  </svg>
);

const StyledListItem = styled(List.Item)`
  transition: all 0.2s;
  cursor: pointer;
  :hover {
    transform: scale(1.02);
    > div:first-child {
      border: 1px solid rgb(0, 136, 142);
    }
  }
`;

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    text-align: center !important;
    line-height: 1em;
    font-size: 16px;
    color: grey;

    border-bottom: 1px solid#00878e34;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #e8e8e8;
    -webkit-transition: all 0.3s, border 0s;
    transition: all 0.3s, border 0s;
    font-weight: 700;
    font-size: 11px;
  }
  .ant-table-placeholder {
    display: none !important;
  }
`;

const StyledDiv = styled.div`
  margin: auto;
  &&span {
    display: flex !important;
  }
`;
class History extends React.Component {
  componentWillMount = () => {
    this.setState({ loading: true });
    this.props
      .findDealDoneVehicles()
      .then(() => {
        this.props
          .findBidData()
          .then(() => {
            let vehicleBidState = {};
            let vehicles = [];
            this.props.dealDoneVehicles.forEach(vehicle => {
              vehicleBidState[vehicle._id] = {
                bidAmount: 'R0',
                yourBidAmount: 'R0'
              };
              vehicles.push(vehicle);
            });
            const yourBidData = this.props.bidData.bids;
            for (var prop in yourBidData) {
              if (vehicleBidState[prop]) {
                vehicleBidState[prop].yourBidAmount = this.formatNumber(
                  yourBidData[prop]
                );
              }
            }
            this.setState({
              bidData: vehicleBidState,
              vehicles: vehicles,
              loading: false
            });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }
  parseString = string => {
    return parseInt(string.split('R')[1].replace(/,/g, ''));
  };
  formatNumber = number => {
    return 'R' + numeral(number).format('0,0');
  };

  handleSelectVehicle = e => {
    this.props.setVehicleDetailsId(
      e.currentTarget.getAttribute('data') + '-fromHistory'
    );
    this.props.push('/dealer/vehicledetails');
  };
  handleTabChange = e => {
    if (e === '1') {
      this.setState({ loading: true });
      this.props
        .findDealDoneVehicles()
        .then(() => {
          this.props
            .findBidData()
            .then(() => {
              let vehicleBidState = {};
              let vehicles = [];
              this.props.dealDoneVehicles.forEach(vehicle => {
                vehicleBidState[vehicle._id] = {
                  bidAmount: 'R0',
                  yourBidAmount: 'R0'
                };
                vehicles.push(vehicle);
              });
              const yourBidData = this.props.bidData.bids;
              for (var prop in yourBidData) {
                if (vehicleBidState[prop]) {
                  vehicleBidState[prop].yourBidAmount = this.formatNumber(
                    yourBidData[prop]
                  );
                }
              }
              this.setState({
                bidData: vehicleBidState,
                vehicles: vehicles,
                loading: false
              });
            })
            .catch(e => {
              this.setState({ loading: false });
              Notify('error', e);
            });
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify('error', e);
        });
    } else if (e === '2') {
      this.setState({ loading: true });
      this.props
        .findAuctionWonVehicles()
        .then(() => {
          this.props
            .findBidData()
            .then(() => {
              let vehicleBidState = {};
              let vehicles = [];
              this.props.wonVehicles.forEach(vehicle => {
                if (vehicle.wonBidder === this.props.user._id) {
                  vehicleBidState[vehicle._id] = {
                    bidAmount: 'R0',
                    yourBidAmount: 'R0'
                  };
                  vehicles.push(vehicle);
                }
              });
              const yourBidData = this.props.bidData.bids;
              for (var prop in yourBidData) {
                if (vehicleBidState[prop]) {
                  vehicleBidState[prop].yourBidAmount = this.formatNumber(
                    yourBidData[prop]
                  );
                }
              }
              this.setState({
                bidData: vehicleBidState,
                vehicles: vehicles,
                loading: false
              });
            })
            .catch(e => {
              this.setState({ loading: false });
              Notify('error', e);
            });
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify('error', e);
        });
    } else if (e === '3') {
      this.setState({ loading: true });
      this.props
        .findAuctionLostVehicles()
        .then(() => {
          this.props
            .findBidData()
            .then(() => {
              let vehicleBidState = {};
              let vehicles = [];
              this.props.lostVehicles.forEach(vehicle => {
                vehicleBidState[vehicle._id] = {
                  bidAmount: 'R0',
                  yourBidAmount: 'R0'
                };
                vehicles.push(vehicle);
              });
              const yourBidData = this.props.bidData.bids;
              for (var prop in yourBidData) {
                if (vehicleBidState[prop]) {
                  vehicleBidState[prop].yourBidAmount = this.formatNumber(
                    yourBidData[prop]
                  );
                }
              }
              this.setState({
                bidData: vehicleBidState,
                vehicles: vehicles,
                loading: false
              });
            })
            .catch(e => {
              this.setState({ loading: false });
              Notify('error', e);
            });
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify('error', e);
        });
    }
  };
  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let vehicles = this.state.vehicles;
    if (columnKey === 'make') {
      if (order === 'ascend') {
        vehicles.sort((a, b) =>
          ('' + a[columnKey]).localeCompare(b[columnKey])
        );
      } else if (order === 'descend') {
        vehicles.sort((a, b) =>
          ('' + b[columnKey]).localeCompare(a[columnKey])
        );
      }
    } else {
      if (order === 'ascend') {
        vehicles.sort(
          (a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey])
        );
      } else if (order === 'descend') {
        vehicles.sort(
          (a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey])
        );
      }
    }
    this.setState({ vehicles: vehicles });
  };
  handleDownloadPDF = vehicle => {
    fetch(
      'https://www.devvcsappcloud.com/verkoopnou/api/files/vehiclepdfs' +
        vehicle.pdf
    )
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'Vehicle_Details.pdf';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        Notify('success', 'Successfully downloaded PDF');
      })
      .catch(e => Notify('error', e));
  };
  render() {
    const { user } = this.props;
    if (this.state.bidData) {
      return (
        <div>
          <Row
            type="flex"
            justify="start"
            align="middle"
            className="hidden-comps-mobile"
          >
            <Col span={12}>
              <Icon
                style={{ fontSize: 36, color: '#00888E', marginRight: 20 }}
                type="history"
              />
              <span style={{ fontWeight: 750, fontSize: 36 }}>
                {window.i18n.translate('History')}
              </span>
            </Col>
          </Row>
          <Row
            type="flex"
            justify="start"
            align="middle"
            className="hidden-comps-webview"
          >
            <Col span={24} style={{ margin: 'auto', textAlign: 'center' }}>
              <Icon
                style={{ fontSize: 36, color: '#00888E', marginRight: 20 }}
                type="history"
              />
              <span style={{ fontWeight: 750, fontSize: 36 }}>
                {window.i18n.translate('History')}
              </span>
            </Col>
          </Row>
          <Spin spinning={this.state.loading}>
            <Tabs
              className="hidden-comps-mobile"
              style={{ overflow: 'visible' }}
              onChange={this.handleTabChange}
            >
              <TabPane tab={window.i18n.translate('AcceptedVehicles')} key="1">
                <Row
                  type="flex"
                  justify="start"
                  align="middle"
                  className="hidden-comps-mobile"
                >
                  <Col span={24}>
                    <StyledTable
                      style={{ background: 'white' }}
                      rowKey="_id"
                      size="middle"
                      onChange={this.handleSort}
                    >
                      <Column
                        sorter={() => {}}
                        title="ID"
                        dataIndex="displayId"
                        key="displayId"
                        width={'12.5%'}
                      />
                      <Column
                        title={window.i18n.translate('Image')}
                        width={'16.66%'}
                      />
                      <Column
                        sorter={() => {}}
                        title={window.i18n.translate('Details')}
                        width={'16.66%'}
                        dataIndex="make"
                        key="make"
                      />
                      <Column
                        sorter={() => {}}
                        title={window.i18n.translate('Mileage')}
                        dataIndex="mileage"
                        key="mileage"
                        width={'12.5%'}
                      />
                      <Column
                        title={window.i18n.translate('Location')}
                        width={'12.5%'}
                      />
                      <Column
                        sorter={() => {}}
                        title={window.i18n.translate('AcceptedPrice')}
                        dataIndex="highestBid"
                        key="highestBid"
                        width={'12.5%'}
                      />
                      <Column
                        title={window.i18n.translate('Action')}
                        width={'12.5%'}
                      />
                    </StyledTable>
                  </Col>
                </Row>
                <Row type="flex" justify="start" align="middle">
                  <Col span={24}>
                    <List
                      dataSource={this.state.vehicles}
                      pagination={{
                        onChange: page => {
                          console.log(page);
                        },
                        pageSize: 10
                      }}
                      renderItem={vehicle => (
                        <StyledListItem
                          data={vehicle._id}
                          onClick={this.handleSelectVehicle}
                        >
                          <div
                            style={{
                              width: '100%',
                              height: 93,
                              background: 'white',
                              display: 'flex',
                              borderTopLeftRadius: 10,
                              borderTopRightRadius: 10
                            }}
                          >
                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={3}
                            >
                              <h4>{vehicle.displayId}</h4>
                            </Col>
                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={4}
                            >
                              <img
                                alt="Main"
                                style={{
                                  maxHeight: 90,
                                  maxWidth: 90,
                                  cursor: 'pointer'
                                }}
                                src={
                                  vehicle.mainImage
                                    ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                      vehicle.mainImage
                                    : noImage
                                }
                              />
                            </Col>
                            <Col style={{ margin: 'auto' }} span={4}>
                              <h5 style={{ color: '#00888E', marginBottom: 1 }}>
                                {vehicle.year} {vehicle.make}
                              </h5>
                              <h5 style={{ color: '#00888E', marginBottom: 1 }}>
                                {vehicle.model}
                              </h5>
                              <h5 style={{ color: '#00888E', marginBottom: 1 }}>
                                {vehicle.variant.replace(
                                  ' ' + vehicle.variant.split(' ').pop(),
                                  ''
                                )}
                              </h5>
                              <h5>
                                {vehicle.colour} | {vehicle.transmission}
                              </h5>
                            </Col>
                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={3}
                            >
                              <h4>{vehicle.mileage} KM</h4>
                            </Col>
                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={3}
                            >
                              <h4 style={{ marginBottom: 4 }}>
                                {vehicle.province}
                              </h4>
                              <h4>{vehicle.city}</h4>
                            </Col>
                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={3}
                            >
                              <h4>{this.formatNumber(vehicle.highestBid)}</h4>
                            </Col>
                            <Col style={{ margin: 'auto' }} span={3}>
                              <div style={{ display: 'flex', margin: 'auto' }}>
                                <Button
                                  onClick={e => {
                                    e.stopPropagation();
                                    this.handleDownloadPDF(vehicle);
                                  }}
                                  style={{
                                    height: 46,
                                    background: '#E5E5E5',
                                    width: 171,
                                    margin: 'auto'
                                  }}
                                >
                                  <Icon
                                    style={{ color: '#6D6E70' }}
                                    type="file-pdf"
                                  />
                                  Download PDF
                                </Button>
                              </div>
                            </Col>
                          </div>
                        </StyledListItem>
                      )}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab={window.i18n.translate('AuctionsWon')} key="2">
                <Row type="flex" justify="start" align="middle">
                  <Col span={24}>
                    <StyledTable
                      style={{ background: 'white' }}
                      rowKey="_id"
                      size="middle"
                      onChange={this.handleSort}
                    >
                      <Column
                        sorter={() => {}}
                        title="ID"
                        dataIndex="displayId"
                        key="displayId"
                        width={'8.33%'}
                      />
                      <Column
                        title={window.i18n.translate('Image')}
                        width={'12.5%'}
                      />
                      <Column
                        sorter={() => {}}
                        title={window.i18n.translate('Details')}
                        width={'16.66%'}
                        dataIndex="make"
                        key="make"
                      />
                      <Column
                        sorter={() => {}}
                        title={window.i18n.translate('Mileage')}
                        dataIndex="mileage"
                        key="mileage"
                        width={'12.5%'}
                      />
                      <Column
                        title={window.i18n.translate('Location')}
                        width={'16.66%'}
                      />
                      <Column
                        title={window.i18n.translate('AuctionDate')}
                        width={'12.5%'}
                      />
                      <Column
                        sorter={() => {}}
                        title={window.i18n.translate('WinningBid')}
                        dataIndex="highestBid"
                        key="highestBid"
                        width={'12.5%'}
                      />
                    </StyledTable>
                  </Col>
                </Row>
                <Row type="flex" justify="start" align="middle">
                  <Col span={24}>
                    <List
                      dataSource={this.state.vehicles}
                      pagination={{
                        onChange: page => {
                          console.log(page);
                        },
                        pageSize: 10
                      }}
                      renderItem={vehicle => (
                        <StyledListItem
                          data={vehicle._id}
                          onClick={this.handleSelectVehicle}
                        >
                          <div
                            style={{
                              width: '100%',
                              height: 93,
                              background: 'white',
                              display: 'flex',
                              borderTopLeftRadius: 10,
                              borderTopRightRadius: 10
                            }}
                          >
                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={2}
                            >
                              <h4>{vehicle.displayId}</h4>
                            </Col>
                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={3}
                            >
                              <img
                                alt="Main"
                                style={{
                                  maxHeight: 90,
                                  maxWidth: 90,
                                  cursor: 'pointer'
                                }}
                                src={
                                  vehicle.mainImage
                                    ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                      vehicle.mainImage
                                    : noImage
                                }
                              />
                            </Col>
                            <Col style={{ margin: 'auto' }} span={5}>
                              <h5 style={{ color: '#00888E', marginBottom: 1 }}>
                                {vehicle.year} {vehicle.make}
                              </h5>
                              <h5 style={{ color: '#00888E', marginBottom: 1 }}>
                                {vehicle.model}
                              </h5>
                              <h5 style={{ color: '#00888E', marginBottom: 1 }}>
                                {vehicle.variant.replace(
                                  ' ' + vehicle.variant.split(' ').pop(),
                                  ''
                                )}
                              </h5>
                              <h5>
                                {vehicle.colour} | {vehicle.transmission}
                              </h5>
                            </Col>
                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={3}
                            >
                              <h4>{vehicle.mileage} KM</h4>
                            </Col>
                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={4}
                            >
                              <h4 style={{ marginBottom: 4 }}>
                                {vehicle.province}
                              </h4>
                              <h4>{vehicle.city}</h4>
                            </Col>
                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={3}
                            >
                              <h4>
                                {moment(vehicle.auctionDate).format(
                                  'YYYY/MM/DD'
                                )}
                              </h4>
                            </Col>
                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={3}
                            >
                              <h4>{this.formatNumber(vehicle.highestBid)}</h4>
                            </Col>
                          </div>
                        </StyledListItem>
                      )}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab={window.i18n.translate('AuctionsLost')} key="3">
                <Row type="flex" justify="start" align="middle">
                  <Col span={24}>
                    <StyledTable
                      style={{ background: 'white' }}
                      rowKey="_id"
                      size="middle"
                      onChange={this.handleSort}
                    >
                      <Column
                        sorter={() => {}}
                        title="ID"
                        dataIndex="displayId"
                        key="displayId"
                        width={'8.33%'}
                      />
                      <Column
                        title={window.i18n.translate('Image')}
                        width={'12.5%'}
                      />
                      <Column
                        sorter={() => {}}
                        title={window.i18n.translate('Details')}
                        width={'16.66%'}
                        dataIndex="make"
                        key="make"
                      />
                      <Column
                        sorter={() => {}}
                        title={window.i18n.translate('Mileage')}
                        dataIndex="mileage"
                        key="mileage"
                        width={'12.5%'}
                      />
                      <Column
                        title={window.i18n.translate('Location')}
                        width={'16.66%'}
                      />
                      <Column
                        title={window.i18n.translate('AuctionDate')}
                        width={'12.5%'}
                      />
                      <Column
                        sorter={() => {}}
                        title={window.i18n.translate('YourBid')}
                        dataIndex="highestBid"
                        key="highestBid"
                        width={'12.5%'}
                      />
                    </StyledTable>
                  </Col>
                </Row>
                <Row type="flex" justify="start" align="middle">
                  <Col span={24}>
                    <List
                      dataSource={this.state.vehicles}
                      pagination={{
                        onChange: page => {
                          console.log(page);
                        },
                        pageSize: 10
                      }}
                      renderItem={vehicle => (
                        <StyledListItem
                          data={vehicle._id}
                          onClick={this.handleSelectVehicle}
                        >
                          <div
                            style={{
                              width: '100%',
                              height: 93,
                              background: 'white',
                              display: 'flex',
                              borderTopLeftRadius: 10,
                              borderTopRightRadius: 10
                            }}
                          >
                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={2}
                            >
                              <h4>{vehicle.displayId}</h4>
                            </Col>
                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={3}
                            >
                              <img
                                alt="Main"
                                style={{
                                  maxHeight: 90,
                                  maxWidth: 90,
                                  cursor: 'pointer'
                                }}
                                src={
                                  vehicle.mainImage
                                    ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                      vehicle.mainImage
                                    : noImage
                                }
                              />
                            </Col>
                            <Col style={{ margin: 'auto' }} span={5}>
                              <h5 style={{ color: '#00888E', marginBottom: 1 }}>
                                {vehicle.year} {vehicle.make}
                              </h5>
                              <h5 style={{ color: '#00888E', marginBottom: 1 }}>
                                {vehicle.model}
                              </h5>
                              <h5 style={{ color: '#00888E', marginBottom: 1 }}>
                                {vehicle.variant.replace(
                                  ' ' + vehicle.variant.split(' ').pop(),
                                  ''
                                )}
                              </h5>
                              <h5>
                                {vehicle.colour} | {vehicle.transmission}
                              </h5>
                            </Col>
                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={3}
                            >
                              <h4>{vehicle.mileage} KM</h4>
                            </Col>
                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={4}
                            >
                              <h4 style={{ marginBottom: 4 }}>
                                {vehicle.province}
                              </h4>
                              <h4>{vehicle.city}</h4>
                            </Col>
                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={3}
                            >
                              <h4>
                                {moment(vehicle.auctionDate).format(
                                  'YYYY/MM/DD'
                                )}
                              </h4>
                            </Col>
                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={3}
                            >
                              <h4>
                                {this.formatNumber(
                                  this.state.bidData[vehicle._id].yourBidAmount
                                )}
                              </h4>
                            </Col>
                          </div>
                        </StyledListItem>
                      )}
                    />
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
            {/*Mobile View*/}
            <Tabs
              className="hidden-comps-webview"
              style={{ overflow: 'visible' }}
              onChange={this.handleTabChange}
            >
              <TabPane tab={window.i18n.translate('AcceptedVehicles')} key="1">
                <Row type="flex" justify="start" align="middle">
                  <Col span={24}>
                    <List
                      dataSource={this.state.vehicles}
                      pagination={{
                        onChange: page => {
                          console.log(page);
                        },
                        pageSize: 10
                      }}
                      renderItem={vehicle => (
                        <StyledListItem
                          data={vehicle._id}
                          onClick={this.handleSelectVehicle}
                        >
                          <div
                            style={{
                              width: '100%',

                              background: 'white',

                              borderTopLeftRadius: 10,
                              borderTopRightRadius: 10
                            }}
                          >
                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={24}
                            >
                              <img
                                alt="Main"
                                style={{
                                  maxHeight: 90,
                                  maxWidth: 90,
                                  cursor: 'pointer'
                                }}
                                src={
                                  vehicle.mainImage
                                    ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                      vehicle.mainImage
                                    : noImage
                                }
                              />
                            </Col>
                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={24}
                            >
                              <h5 style={{ color: '#00888E', marginBottom: 1 }}>
                                {vehicle.year} {vehicle.make}
                              </h5>
                              <h5 style={{ color: '#00888E', marginBottom: 1 }}>
                                {vehicle.model}
                              </h5>
                              <h5 style={{ color: '#00888E', marginBottom: 1 }}>
                                {vehicle.variant.replace(
                                  ' ' + vehicle.variant.split(' ').pop(),
                                  ''
                                )}
                              </h5>
                              <h5>
                                {vehicle.colour} | {vehicle.transmission}
                              </h5>
                            </Col>

                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={24}
                            >
                              {' '}
                              <h3 style={{ marginBottom: '-0em' }}>
                                Highest Bid
                              </h3>
                              <h4>{this.formatNumber(vehicle.highestBid)}</h4>
                            </Col>
                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={24}
                            >
                              <div style={{ margin: 'auto' }}>
                                <Button
                                  onClick={e => {
                                    e.stopPropagation();
                                    this.handleDownloadPDF(vehicle);
                                  }}
                                  style={{
                                    height: 46,
                                    background: '#E5E5E5',
                                    width: 171,
                                    margin: 'auto'
                                  }}
                                >
                                  <Icon
                                    style={{ color: '#6D6E70' }}
                                    type="file-pdf"
                                  />
                                  Download PDF
                                </Button>
                              </div>
                            </Col>
                          </div>
                        </StyledListItem>
                      )}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab={window.i18n.translate('AuctionsWon')} key="2">
                <Row type="flex" justify="start" align="middle">
                  <Col span={24}>
                    <List
                      dataSource={this.state.vehicles}
                      pagination={{
                        onChange: page => {
                          console.log(page);
                        },
                        pageSize: 10
                      }}
                      renderItem={vehicle => (
                        <StyledListItem
                          data={vehicle._id}
                          onClick={this.handleSelectVehicle}
                        >
                          <div
                            style={{
                              width: '100%',

                              background: 'white',

                              borderTopLeftRadius: 10,
                              borderTopRightRadius: 10
                            }}
                          >
                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={24}
                            >
                              <img
                                alt="Main"
                                style={{
                                  maxHeight: 90,
                                  maxWidth: 90,
                                  cursor: 'pointer'
                                }}
                                src={
                                  vehicle.mainImage
                                    ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                      vehicle.mainImage
                                    : noImage
                                }
                              />
                            </Col>
                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={24}
                            >
                              <h5 style={{ color: '#00888E', marginBottom: 1 }}>
                                {vehicle.year} {vehicle.make}
                              </h5>
                              <h5 style={{ color: '#00888E', marginBottom: 1 }}>
                                {vehicle.model}
                              </h5>
                              <h5 style={{ color: '#00888E', marginBottom: 1 }}>
                                {vehicle.variant.replace(
                                  ' ' + vehicle.variant.split(' ').pop(),
                                  ''
                                )}
                              </h5>
                              <h5>
                                {vehicle.colour} | {vehicle.transmission}
                              </h5>
                            </Col>

                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={24}
                            >
                              {' '}
                              <h3 style={{ marginBottom: '-0em' }}>
                                Winning Bid
                              </h3>
                              <h4>{this.formatNumber(vehicle.highestBid)}</h4>
                            </Col>
                          </div>
                        </StyledListItem>
                      )}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab={window.i18n.translate('AuctionsLost')} key="3">
                <Row type="flex" justify="start" align="middle">
                  <Col span={24}>
                    <List
                      dataSource={this.state.vehicles}
                      pagination={{
                        onChange: page => {
                          console.log(page);
                        },
                        pageSize: 10
                      }}
                      renderItem={vehicle => (
                        <StyledListItem
                          data={vehicle._id}
                          onClick={this.handleSelectVehicle}
                        >
                          <div
                            style={{
                              width: '100%',

                              background: 'white',

                              borderTopLeftRadius: 10,
                              borderTopRightRadius: 10
                            }}
                          >
                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={24}
                            >
                              <img
                                alt="Main"
                                style={{
                                  maxHeight: 90,
                                  maxWidth: 90,
                                  cursor: 'pointer'
                                }}
                                src={
                                  vehicle.mainImage
                                    ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                      vehicle.mainImage
                                    : noImage
                                }
                              />
                            </Col>
                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={24}
                            >
                              <h5 style={{ color: '#00888E', marginBottom: 1 }}>
                                {vehicle.year} {vehicle.make}
                              </h5>
                              <h5 style={{ color: '#00888E', marginBottom: 1 }}>
                                {vehicle.model}
                              </h5>
                              <h5 style={{ color: '#00888E', marginBottom: 1 }}>
                                {vehicle.variant.replace(
                                  ' ' + vehicle.variant.split(' ').pop(),
                                  ''
                                )}
                              </h5>
                              <h5>
                                {vehicle.colour} | {vehicle.transmission}
                              </h5>
                            </Col>

                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={24}
                            >
                              {' '}
                              <h3 style={{ marginBottom: '-0em' }}>
                                {window.i18n.translate('AuctionDate')}
                              </h3>
                              <h4>
                                {moment(vehicle.auctionDate).format(
                                  'YYYY/MM/DD'
                                )}
                              </h4>
                            </Col>
                            <Col
                              style={{ margin: 'auto', textAlign: 'center' }}
                              span={24}
                            >
                              {' '}
                              <h3 style={{ marginBottom: '-0em' }}>
                                Your Bid Amount
                              </h3>
                              <h4>
                                {this.formatNumber(
                                  this.state.bidData[vehicle._id].yourBidAmount
                                )}
                              </h4>
                            </Col>
                          </div>
                        </StyledListItem>
                      )}
                    />
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </Spin>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default History;
