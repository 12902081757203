import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  findApprovedVehicles,
  setVehicleDetailsId,
  auctionVehicle,
  saveCalendar,
  findCalendar,
  auctionVehicles,
  setVehicleAuctionDate
} from 'src/redux/actions/admin';
import { updateBidsList, findBidData } from 'src/redux/actions/bidding';
import {
  getLocale,
  getApprovedVehicles,
  getApprovedVehicleIds,
  getUser,
  getTimeStamp,
  getCustomTime,
  getBidData,
  getCalendar
} from 'src/redux/selectors';

class Auction extends Component {
  render = () => {
    const {
      Layout,
      currentLocale,
      vehicles,
      vehicleIds,
      auctionVehicle,
      auctionVehicles,
      user,
      findApprovedVehicles,
      setVehicleDetailsId,
      updateBidsList,
      findBidData,
      bidData,
      timeStamp,
      customTime,
      saveCalendar,
      findCalendar,
      calendar,
      setVehicleAuctionDate,
      push
    } = this.props;
    return (
      <Layout
        currentLocale={currentLocale}
        vehicles={vehicles}
        vehicleIds={vehicleIds}
        auctionVehicle={auctionVehicle}
        auctionVehicles={auctionVehicles}
        user={user}
        findApprovedVehicles={findApprovedVehicles}
        setVehicleDetailsId={setVehicleDetailsId}
        updateBidsList={updateBidsList}
        findBidData={findBidData}
        bidData={bidData}
        timeStamp={timeStamp}
        customTime={customTime}
        saveCalendar={saveCalendar}
        findCalendar={findCalendar}
        calendar={calendar}
        setVehicleAuctionDate={setVehicleAuctionDate}
        push={push}
      />
    );
  };
}

const mapStateToProps = state => ({
  currentLocale: getLocale(state) || {},
  vehicles: getApprovedVehicles(state) || {},
  vehicleIds: getApprovedVehicleIds(state) || [],
  bidData: getBidData(state) || {},
  timeStamp: getTimeStamp(state) || '',
  customTime: getCustomTime(state) || '',
  user: getUser(state) || {},
  calendar: getCalendar(state) || {}
});

const mapDispatchToProps = {
  getLocale,
  findApprovedVehicles,
  setVehicleDetailsId,
  auctionVehicle,
  auctionVehicles,
  updateBidsList,
  findBidData,
  findCalendar,
  saveCalendar,
  setVehicleAuctionDate,
  push
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Auction);
