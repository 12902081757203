import React from 'react';
import styled from 'styled-components';
import {
  Form,
  Icon,
  Row,
  Col,
  Spin,
  Input,
  Button,
  Tabs,
  Modal,
  Checkbox,
  Select,
  Radio
} from 'antd';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import {
  FacebookLoginButton,
  GoogleLoginButton
} from 'react-social-login-buttons';
import { ButtonRadius } from 'src/components/elements/Button';
import Notify from 'src/components/meta/Notification';
import CarPic from 'src/static/icons/login-car-pic.png';
import logo from 'src/static/logo.svg';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import zIndex from '@material-ui/core/styles/zIndex';
const { TabPane } = Tabs;
const FormItem = Form.Item;
const Inputs = styled(Input)`
  .ant-input:focus {
    border-bottom: 2px solid rgba(0, 136, 142, 0.2);
  }
`;
const FacebookButton = styled(Button)`
  height: 45px;
  border-radius: 5px;
  border: 0;
  color: white;
  background: #4267b2;
  span {
    font-size: 15px;
    position: relative;
    bottom: 3px;
  }
`;

const StyledSelect = styled(Select)`
  border: none;
  .ant-select-selection {
    border: none;
    box-shadow: none;
  }
  .ant-select-selection:hover {
    border: none;
    box-shadow: none;
  }
  .ant-select-selection:focus {
    border: none;
    box-shadow: none;
  }
  .ant-select-selection:active {
    border: none;
    box-shadow: none;
  }
  .ant-select-focused {
    border: none;
    box-shadow: none;
  }
`;

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      passShow: 'password',
      resetVisible: false,
      resetPassVisible: false,
      activeKey: '1'
    };
  }
  componentWillMount = () => {
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('reset')) {
      this.setState({
        resetPassVisible: true,
        token: searchParams.get('token')
      });
    }
    if (searchParams.has('dealeroffer')) {
      this.props
        .signInToken(searchParams.get('token'))
        .then(() => {
          this.setState({ loading: false });
          this.props.push('/user/myvehicles' + window.location.search);
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify('error', 'Failed', e);
        });
    }
    if (searchParams.has('adminoffer')) {
      this.props
        .signInToken(searchParams.get('token'))
        .then(() => {
          this.setState({ loading: false });
          this.props.push('/user/myvehicles' + window.location.search);
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify('error', 'Failed', e);
        });
    }
  };
  componentDidMount = () => {
    if (new URLSearchParams(window.location.search).has('type')) {
      const type = new URLSearchParams(window.location.search).get('type');
      if (type === 'dealer') {
        this.setState({ activeKey: '2' });
      }
    }
  };
  handleSubmitUser = event => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err || (!err.username && !err.password)) {
        this.signIn(values, 'user');
      }
    });
  };

  handleSubmitDealer = event => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err || (!err.username && !err.password)) {
        this.signIn(values, 'dealer');
      }
    });
  };

  forgotPassword = event => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err || !err.username) {
        fetch(
          'https://www.devvcsappcloud.com/verkoopnou/api/user/resetrequest',
          {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(values)
          }
        )
          .then(async res => {
            if (!res.ok) {
              Notify('error', 'Failed', await res.text());
            } else {
              this.setState({ resetVisible: false });
              Notify('success', 'Success', await res.text());
            }
          })
          .catch(async error => {
            Notify('error', 'Failed', error);
          });
      }
    });
  };

  resetPassword = event => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        this.props
          .changePassword(values, this.state.token)
          .then(() => {
            this.setState({ loading: false, resetPassVisible: false });
            Notify('success', 'Success', 'Successfully changed password');
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', window.i18n.translate('Notify3'), e);
          });
      }
    });
  };

  signIn = (values, type) => {
    this.setState({ loading: true });
    this.props
      .signIn(values, type)
      .then(() => {
        this.setState({ loading: false });
        if (type === 'user') {
          this.props.push('/' + type + '/landingpage');
        } else {
          this.props.push('/' + type + '/dashboard');
        }
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', 'Failed', e);
      });
  };

  onGoogleSuccessUser = googleUser => {
    const token = googleUser.getAuthResponse().id_token;
    this.props
      .signInGoogle(token, 'user')
      .then(() => {
        this.setState({ loading: false });
        this.props.push('/user/dashboard');
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', 'Failed', e);
      });
  };

  onGoogleSuccessDealer = googleUser => {
    const token = googleUser.getAuthResponse().id_token;
    this.props
      .signInGoogle(token, 'dealer')
      .then(() => {
        this.setState({ loading: false });
        this.props.push('/dealer/dashboard');
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', 'Failed', e);
      });
  };

  onGoogleFailure = error => {
    Notify('error', 'Failed', error.details);
  };

  onFacebookSuccessUser = facebookUser => {
    const name = facebookUser.name;
    const username = facebookUser.email;
    this.props
      .signInFacebook(name, username, 'user')
      .then(() => {
        this.setState({ loading: false });
        this.props.push('/user/dashboard');
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', 'Failed', e);
      });
  };

  onFacebookSuccessDealer = facebookUser => {
    const name = facebookUser.name;
    const username = facebookUser.email;
    this.props
      .signInFacebook(name, username, 'dealer')
      .then(() => {
        this.setState({ loading: false });
        this.props.push('/dealer/dashboard');
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', 'Failed', e);
      });
  };

  onFacebookFailure = error => {
    Notify('error', 'Failed', error.details);
  };

  toggleShowPass = () => {
    this.state.passShow === 'password'
      ? this.setState({ passShow: 'text' })
      : this.setState({ passShow: 'password' });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('newPass')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirmPass'], { force: true });
    }
    callback();
  };
  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };
  showTC(e) {
    e.preventDefault();
    Modal.info({
      width: '90%',
      style: { top: 20 },
      title: 'TERMS and CONDITIONS',
      iconType: 'file-done',
      content: (
        <div style={{ height: '60vh', overflow: 'auto' }}>
          <iframe width="100%" height="100%" src="./tc.html" />
        </div>
      ),
      onOk() {}
    });
  }
  showCustomerTC(e) {
    e.preventDefault();
    Modal.info({
      width: '90%',
      style: { top: 20 },
      title: 'TERMS and CONDITIONS',
      iconType: 'file-done',
      content: (
        <div style={{ height: '60vh', overflow: 'auto' }}>
          <iframe width="100%" height="100%" src="./customerTc.html" />
        </div>
      ),
      onOk() {}
    });
  }
  handleLocaleSwitch = locale => {
    this.setState({ loading: true });

    this.props
      .changeLocale(locale.target.value)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(e => {
        console.log(e);
      });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div style={{ background: 'white' }}>
        <a href="https://www.devvcsappcloud.com/verkoopnouweb/">
          <img src={logo} className="sign-in-logo" />
        </a>
        <Row type="flex" justify="space-around" align="middle">
          <Col
            offset={5}
            xs={24}
            sm={24}
            md={7}
            pull={2}
            className="sign-up-right"
          >
            <Row type="flex" justify="space-around">
              <div
                className="hidden-comps-mobile"
                style={{
                  display: 'flex',
                  float: 'right',
                  borderRadius: '0.3em',
                  border: '1px solid #00888E',
                  marginBottom: '1em'
                }}
              >
                <Radio.Group
                  style={{ margin: 'auto' }}
                  buttonStyle="solid"
                  defaultValue={this.props.currentLocale}
                  onChange={this.handleLocaleSwitch}
                >
                  <Radio.Button value="af">Afrikaans</Radio.Button>
                  <Radio.Button value="en">English</Radio.Button>
                </Radio.Group>
              </div>

              <Radio.Group
                className="hidden-comps-webview"
                style={{
                  margin: 'auto',
                  marginBottom: '-4.5em',
                  display: 'flex',
                  float: 'right',
                  borderRadius: '0.3em',
                  border: '1px solid #00888E'
                }}
                buttonStyle="solid"
                defaultValue={this.props.currentLocale}
                onChange={this.handleLocaleSwitch}
              >
                <Radio.Button value="af">Afrikaans</Radio.Button>
                <Radio.Button value="en">English</Radio.Button>
              </Radio.Group>
            </Row>
            <h1
              className="welcome-sign-up-text"
              style={{
                lineHeight: 0.9,
                fontSize: '50px',
                color: '#808285',
                textAlign: 'center'
              }}
            >
              {window.i18n.translate('WelcomeBack')}
            </h1>{' '}
            <h1
              className="create-sign-up-text"
              style={{
                fontSize: '50px',
                color: '#808285',
                textAlign: 'center'
              }}
            >
              {window.i18n.translate('SignInHeading')}
            </h1>
            <Spin spinning={this.state.loading}>
              <Tabs
                type={'card'}
                onChange={e => this.setState({ activeKey: e })}
                activeKey={this.state.activeKey}
              >
                <TabPane
                  tab={
                    window.i18n.translate('Customer') +
                    ' ' +
                    window.i18n.translate('SignIn')
                  }
                  key="1"
                >
                  <Form onSubmit={this.handleSubmitUser}>
                    <FormItem
                    //   label={
                    //    <span style={{ color: "#00B4BC", fontSize: 20 }}>
                    //      Username
                    // </span>
                    //  }
                    >
                      {getFieldDecorator('username', {
                        rules: [
                          {
                            required: true,
                            message: window.i18n.translate('UsernameRequired')
                          }
                        ]
                      })(
                        <TextField
                          label={window.i18n.translate('Username')}
                          style={{
                            marginLeft: '1em',
                            marginTop: '1.5em',
                            width: '93%',
                            border: 0,
                            borderRadius: 0
                          }}
                        />
                      )}
                    </FormItem>
                    <FormItem
                      style={{ marginBottom: 0 }}
                      //   label={
                      //   <span style={{ color: "#00B4BC", fontSize: 20 }}>
                      //    Password
                      //   </span>
                      //   }
                    >
                      {getFieldDecorator('password', {
                        rules: [
                          {
                            required: true,
                            message: window.i18n.translate('PasswordRequired')
                          }
                        ]
                      })(
                        <TextField
                          label={window.i18n.translate('Password')}
                          type="password"
                          style={{
                            marginLeft: '1em',

                            width: '93%',
                            border: 0,
                            borderRadius: 0
                          }}
                        />
                      )}
                    </FormItem>
                    <Col span={12} style={{ zIndex: 9 }}>
                      {/*}  <FormItem>
                        {getFieldDecorator("TC", {
                          rules: [
                            {
                              transform: (value) => value || undefined,
                              type: "boolean",
                              required: true,
                              message: "Accept terms and conditions",
                            },
                          ],
                        })(
                          <Checkbox
                            style={{
                              float: "left",
                              paddingLeft: "1em",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Accept{" "}
                            <a className="tc" onClick={this.showCustomerTC}>
                              Terms & Conditions
                            </a>
                            ?
                          </Checkbox>
                        )}
                          </FormItem> */}
                    </Col>
                    <Col span={12} style={{ zIndex: 9 }}>
                      <FormItem>
                        <a
                          style={{
                            float: 'right',
                            color: 'grey',
                            paddingRight: '1em'
                          }}
                          onClick={e => this.setState({ resetVisible: true })}
                        >
                          {window.i18n.translate('Forgot')}
                        </a>
                      </FormItem>
                    </Col>

                    <FormItem
                      style={{
                        margin: 'auto',
                        textAlign: 'center'
                      }}
                    >
                      <ButtonRadius
                        style={{
                          height: 80,
                          fontSize: 30,
                          width: '90%'
                        }}
                        className="submit-button-customer"
                        type="primary"
                        htmlType="submit"
                        size="large"
                        block
                      >
                        {window.i18n.translate('SignIn') +
                          ' ' +
                          window.i18n.translate('Customer')}
                      </ButtonRadius>
                      <ButtonRadius
                        style={{
                          height: 80,
                          fontSize: 30,
                          width: 300,
                          color: '#00888e'
                        }}
                        onClick={() => {
                          this.props.push('/signup');
                        }}
                        className="submit-button-sign-up"
                        htmlType="submit"
                        size="large"
                        block
                      >
                        <span style={{ fontSize: '1.4em' }}>
                          {window.i18n.translate('SignUp')}
                        </span>
                      </ButtonRadius>
                    </FormItem>
                  </Form>
                  <Row type="flex" justify="space-around" align="middle">
                    <h1
                      style={{
                        color: '#00B4BC',
                        marginTop: '1em',
                        fontSize: '19px'
                      }}
                    >
                      {window.i18n.translate('Or')}
                    </h1>
                  </Row>
                  <Row
                    type="flex"
                    justify="space-around"
                    align="middle"
                    style={{ flexFlow: 'nowrap', marginLeft: '0.7em' }}
                  >
                    <FacebookLogin
                      appId="2480002022050755"
                      autoLoad={false}
                      fields="name,email,picture"
                      callback={this.onFacebookSuccessUser}
                      onFailure={this.onFacebookFailure}
                      render={renderProps => (
                        <FacebookLoginButton
                          text={
                            window.i18n.translate('Login') +
                            ' ' +
                            window.i18n.translate('WithFacebook')
                          }
                          style={{
                            borderRadius: '100px',
                            paddingLeft: '22px',
                            height: '40px',
                            width: '180px',
                            fontSize: '11px',
                            lineHeight: '17px'
                          }}
                          style={{
                            borderRadius: '100px',
                            paddingLeft: '20px',
                            height: '40px',
                            width: '180px',
                            fontSize: '11px',
                            lineHeight: '17px'
                          }}
                          onClick={renderProps.onClick}
                        />
                      )}
                    />

                    <GoogleLogin
                      clientId="862153260204-lilqocnp0di59mob1m1pigdis2g8k4m5.apps.googleusercontent.com"
                      text={
                        window.i18n.translate('Login') +
                        ' ' +
                        window.i18n.translate('WithGoogle')
                      }
                      onSuccess={this.onGoogleSuccessUser}
                      onFailure={this.onGoogleFailure}
                      cookiePolicy={'single_host_origin'}
                      responseType={'id_token'}
                      render={renderProps => (
                        <GoogleLoginButton
                          text={
                            window.i18n.translate('Login') +
                            ' ' +
                            window.i18n.translate('WithGoogle')
                          }
                          style={{
                            borderRadius: '100px',
                            paddingLeft: '22px',
                            height: '40px',
                            width: '180px',
                            fontSize: '11px',
                            lineHeight: '17px'
                          }}
                          onClick={renderProps.onClick}
                        />
                      )}
                    />
                  </Row>
                </TabPane>
                <TabPane
                  tab={
                    window.i18n.translate('Dealer') +
                    ' ' +
                    window.i18n.translate('SignIn')
                  }
                  key="2"
                >
                  <Form onSubmit={this.handleSubmitDealer}>
                    <FormItem
                    //label={
                    //   <span style={{ color: "#00B4BC", fontSize: 20 }}>
                    //   Username
                    //  </span>
                    // }
                    >
                      {getFieldDecorator('username', {
                        rules: [
                          {
                            required: true,
                            message: window.i18n.translate('UsernameRequired')
                          }
                        ]
                      })(
                        <TextField
                          label={window.i18n.translate('Username')}
                          style={{
                            marginTop: '1.5em',
                            marginLeft: '1em',
                            width: '93%',
                            border: 0,
                            borderRadius: 0
                          }}
                        />
                      )}
                    </FormItem>
                    <FormItem
                      style={{ marginBottom: 0 }}
                      //  label={
                      //  <span style={{ color: "#00B4BC", fontSize: 20 }}>
                      //    Password
                      // </span>
                      //  }
                    >
                      {getFieldDecorator('password', {
                        rules: [
                          {
                            required: true,
                            message: window.i18n.translate('PasswordRequired')
                          }
                        ]
                      })(
                        <TextField
                          label={window.i18n.translate('Password')}
                          type="password"
                          style={{
                            marginLeft: '1em',
                            width: '93%',
                            border: 0,
                            borderRadius: 0
                          }}
                        />
                      )}
                    </FormItem>
                    <Col span={12} style={{ zIndex: 9 }}>
                      {/* <FormItem>
                        {getFieldDecorator("TC", {
                          rules: [
                            {
                              transform: (value) => value || undefined,
                              type: "boolean",
                              required: true,
                              message: "Accept terms and conditions",
                            },
                          ],
                        })(
                          <Checkbox
                            style={{
                              float: "left",
                              paddingLeft: "1em",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Accept{" "}
                            <a className="tc" onClick={this.showTC}>
                              Terms & Conditions
                            </a>
                            ?
                          </Checkbox>
                        )}
                          </FormItem> */}
                    </Col>
                    <Col span={12} style={{ zIndex: 9 }}>
                      <FormItem>
                        <a
                          style={{
                            float: 'right',
                            color: 'grey',
                            paddingRight: '1em'
                          }}
                          onClick={e => this.setState({ resetVisible: true })}
                        >
                          {window.i18n.translate('Forgot')}
                        </a>
                      </FormItem>
                    </Col>
                    <FormItem
                      style={{
                        margin: 'auto',
                        textAlign: 'center'
                      }}
                    >
                      <ButtonRadius
                        style={{ height: 80, fontSize: 30, width: '90%' }}
                        className="submit-button-customer"
                        type="primary"
                        htmlType="submit"
                        size="large"
                        block
                      >
                        {window.i18n.translate('SignIn') +
                          ' ' +
                          window.i18n.translate('Dealer')}
                      </ButtonRadius>
                      <ButtonRadius
                        style={{
                          height: 80,
                          fontSize: 30,
                          width: 300,
                          color: '#00888e'
                        }}
                        onClick={() => {
                          this.props.push('/signup');
                        }}
                        className="submit-button-sign-up"
                        htmlType="submit"
                        size="large"
                        block
                      >
                        <span style={{ fontSize: '1.4em' }}>
                          {window.i18n.translate('SignUp')}
                        </span>
                      </ButtonRadius>
                    </FormItem>
                  </Form>
                  <Row type="flex" justify="space-around" align="middle">
                    <h1 style={{ color: '#00B4BC' }}>or</h1>
                  </Row>
                  <Row
                    type="flex"
                    justify="space-around"
                    align="middle"
                    style={{ flexFlow: 'nowrap', marginLeft: '0.7em' }}
                  >
                    <FacebookLogin
                      appId="2480002022050755"
                      autoLoad={false}
                      fields="name,email,picture"
                      callback={this.onFacebookSuccessDealer}
                      onFailure={this.onFacebookFailure}
                      render={renderProps => (
                        <FacebookLoginButton
                          style={{
                            borderRadius: '100px',
                            paddingLeft: '20px',
                            height: '40px',
                            width: '180px',
                            fontSize: '11px',
                            lineHeight: '17px'
                          }}
                          onClick={renderProps.onClick}
                        />
                      )}
                    />

                    <GoogleLogin
                      clientId="862153260204-lilqocnp0di59mob1m1pigdis2g8k4m5.apps.googleusercontent.com"
                      buttonText="Sign in with Google"
                      onSuccess={this.onGoogleSuccessDealer}
                      onFailure={this.onGoogleFailure}
                      cookiePolicy={'single_host_origin'}
                      responseType={'id_token'}
                      render={renderProps => (
                        <GoogleLoginButton
                          style={{
                            borderRadius: '100px',
                            paddingLeft: '22px',
                            height: '40px',
                            width: '180px',
                            fontSize: '11px',
                            lineHeight: '17px'
                          }}
                          onClick={renderProps.onClick}
                        />
                      )}
                    />
                  </Row>
                </TabPane>
              </Tabs>
            </Spin>
          </Col>
          <Col
            className="sign-in-left"
            style={{
              background: '#00888E',
              display: 'flex',
              height: '100vh'
            }}
            xs={0}
            sm={0}
            md={10}
          >
            {' '}
            <img
              src={CarPic}
              alt="S"
              height="100%"
              width="70%"
              style={{
                position: 'absolute',
                left: '30%'
              }}
            />
            <div style={{ margin: 'auto', textAlign: 'center' }}>
              <h1 style={{ color: 'white', fontSize: '55px' }}>
                {window.i18n.translate('WelcomeBack') + '!'}
              </h1>
              <p style={{ color: 'white', fontSize: '25px' }}>
                {window.i18n.translate('RegisterNow')}
              </p>
              <ButtonRadius
                style={{
                  height: 80,
                  fontSize: 30,
                  width: 300,
                  color: '#00888e'
                }}
                onClick={() => {
                  this.props.push('/signup');
                }}
                className="submit-button"
                htmlType="submit"
                size="large"
                block
              >
                {window.i18n.translate('SignUp')}
              </ButtonRadius>
            </div>
          </Col>
        </Row>
        <Modal
          title={
            window.i18n.translate('Reset') +
            ' ' +
            window.i18n.translate('Password')
          }
          visible={this.state.resetVisible}
          onOk={this.forgotPassword}
          onCancel={e => this.setState({ resetVisible: false })}
          okText={window.i18n.translate('Reset')}
          cancelText={window.i18n.translate('Cancel')}
        >
          <Form>
            <FormItem
              label={
                <span style={{ color: '#00B4BC', fontSize: 20 }}>
                  {window.i18n.translate('EnterEmail')}
                </span>
              }
            >
              {getFieldDecorator('username', {
                rules: [
                  {
                    required: true,
                    message: window.i18n.translate('EmailRequired')
                  }
                ]
              })(<Inputs />)}
            </FormItem>
          </Form>
        </Modal>
        <Modal
          title={'Reset Password'}
          visible={this.state.resetPassVisible}
          onOk={this.resetPassword}
          onCancel={e => this.setState({ resetPassVisible: false })}
          okText={window.i18n.translate('Reset')}
          cancelText={window.i18n.translate('Cancel')}
        >
          <Form>
            <Row type="flex" justify="space-between" align="middle">
              <Col span={11}>
                <FormItem
                  label={
                    <span style={{ color: '#6D6E70', fontSize: 16 }}>
                      {window.i18n.translate('New') +
                        ' ' +
                        window.i18n.translate('Password')}
                    </span>
                  }
                  hasFeedback
                >
                  {this.props.form.getFieldDecorator('newPass', {
                    rules: [
                      {
                        required: true,
                        message: window.i18n.translate('NewPasswordRequired')
                      },
                      { validator: this.validateToNextPassword }
                    ]
                  })(
                    <Input.Password
                      onBlur={this.handleConfirmBlur}
                      type="password"
                      style={{ borderColor: '#BDB2B2', height: 45 }}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row type="flex" justify="space-between" align="middle">
              <Col span={11}>
                <FormItem
                  label={
                    <span style={{ color: '#6D6E70', fontSize: 16 }}>
                      {window.i18n.translate('Confirm') +
                        ' ' +
                        window.i18n.translate('New1') +
                        ' ' +
                        window.i18n.translate('Password')}
                    </span>
                  }
                  hasFeedback
                >
                  {this.props.form.getFieldDecorator('confirmPass', {
                    rules: [
                      {
                        required: true,
                        message:
                          window.i18n.translate('Confirm') +
                          ' ' +
                          window.i18n.translate('New1') +
                          ' ' +
                          window.i18n.translate('Password')
                      },
                      { validator: this.compareToFirstPassword }
                    ]
                  })(
                    <Input.Password
                      type="password"
                      style={{ borderColor: '#BDB2B2', height: 45 }}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}

const WrappedSignIn = Form.create()(SignIn);

export default WrappedSignIn;
