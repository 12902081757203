import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  findAllUsers,
  deleteUser,
  findAllDealers,
  deleteDealer,
  approveDealer,
  activateDealer,
  deactivateDealer,
  activatePrices,
  deactivatePrices
} from 'src/redux/actions/admin';
import {
  getLocale,
  getAllUsers,
  getAllDealers,
  getUser
} from 'src/redux/selectors';
import { sendNotification } from 'src/redux/actions/notifications';

class Users extends Component {
  render = () => {
    const {
      Layout,
      currentLocale,
      users,
      dealers,
      user,
      findAllUsers,
      deleteUser,
      findAllDealers,
      deleteDealer,
      approveDealer,
      sendNotification,
      activateDealer,
      deactivateDealer,
      push,
      activatePrices,
      deactivatePrices
    } = this.props;
    return (
      <Layout
        currentLocale={currentLocale}
        users={users}
        dealers={dealers}
        user={user}
        findAllUsers={findAllUsers}
        deleteUser={deleteUser}
        findAllDealers={findAllDealers}
        deleteDealer={deleteDealer}
        approveDealer={approveDealer}
        sendNotification={sendNotification}
        activateDealer={activateDealer}
        deactivateDealer={deactivateDealer}
        push={push}
        activatePrices={activatePrices}
        deactivatePrices={deactivatePrices}
      />
    );
  };
}

const mapStateToProps = state => ({
  currentLocale: getLocale(state) || {},
  users: getAllUsers(state) || {},
  dealers: getAllDealers(state) || {},
  user: getUser(state) || {}
});

const mapDispatchToProps = {
  getLocale,
  findAllUsers,
  deleteUser,
  findAllDealers,
  deleteDealer,
  approveDealer,
  sendNotification,
  activateDealer,
  deactivateDealer,
  push,
  activatePrices,
  deactivatePrices
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users);
