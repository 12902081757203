import React from 'react';
import styled from 'styled-components';
import { Row, Layout, Menu, Breadcrumb, Button, Col } from 'antd';
import { ButtonRadius } from 'src/components/elements/Button';
import Notify from 'src/components/meta/Notification';
import HammerIcon from 'src/static/icons/hammer-icon.png';
import CarIcon from 'src/static/icons/car-icon.png';
import CheckIcon from 'src/static/icons/check-icon.png';
import { getUser } from 'src/redux/selectors';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const { Content } = Layout;

const ButtonToDashboard = styled(Button)`
  top: 2em ;

  margin-bottom: 0.5em ;
  
  }
`;
const ButtonToAddVehicles = styled(Button)`
  top: 2em ;


  }
  
`;
class LandingPage extends React.Component {
  render() {
    return (
      <div
        style={{
          textAlign: 'center'
        }}
      >
        <h1
          className="landing-page-header-text"
          style={{
            color: 'black',
            paddingTop: '5px',
            paddingLeft: '0px',
            fontSize: '28px',
            position: 'absolute',
            textAlign: 'center'
          }}
        >
          Hi {this.props.user.name}
        </h1>
        <Row type="flex" justify="space-around" align="middle" />

        <div
          style={{
            maxWidth: '100%',

            height: 600,
            background: 'white',
            display: 'flex',
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            marginTop: 70
          }}
        >
          <Content
            style={{
              padding: '0 20px',
              maxWidth: '80em',
              margin: 'auto',
              marginTop: 0
            }}
          >
            <h1
              className="landing-page-paragraph-text"
              style={{
                color: 'darkgrey',
                paddingTop: '30px',

                fontSize: '17px',
                textAlign: 'center'
              }}
            >
              {window.i18n.translate('WelcomeToHeading')}
              <br />
              {window.i18n.translate('VettedByHeading')}
              <br /> <br />
              {window.i18n.translate('WithVerkoopnouHeading')}
            </h1>
            <Row span={24}>
              <Col xs={12} md={8}>
                <img
                  src={HammerIcon}
                  alt=""
                  style={{
                    marginTop: '15px',
                    marginBottom: '10px',
                    width: '7em'
                  }}
                />
                <br />{' '}
                <h1
                  className="landing-page-paragraph-text"
                  style={{
                    color: 'darkgrey',
                    paddingTop: '30px',
                    fontSize: '17px',
                    textAlign: 'center'
                  }}
                >
                  {window.i18n.translate('ReceiveBids')}
                  <br />
                  {window.i18n.translate('OnlineAuction')}
                </h1>
              </Col>
              <Col xs={12} md={8}>
                <img
                  src={CheckIcon}
                  alt=""
                  style={{
                    marginTop: '15px',
                    marginBottom: '10px',
                    width: '7em'
                  }}
                />
                <br />
                <h1
                  className="landing-page-paragraph-text"
                  style={{
                    color: 'darkgrey',
                    paddingTop: '30px',
                    fontSize: '17px',
                    textAlign: 'center'
                  }}
                >
                  {window.i18n.translate('AcceptBids')}
                  <br />
                  {window.i18n.translate('AndYour')}
                </h1>
              </Col>
              <Col xs={24} md={8}>
                <img
                  src={CarIcon}
                  alt=""
                  style={{
                    marginTop: '15px',
                    marginBottom: '10px',
                    width: '7em'
                  }}
                />
                <br />
                <h1
                  className="landing-page-paragraph-text"
                  style={{
                    color: 'darkgrey',
                    paddingTop: '30px',
                    marginLeft: '-20px',
                    fontSize: '17px',

                    textAlign: 'center'
                  }}
                >
                  {window.i18n.translate('TellUs')} <br />
                  {window.i18n.translate('HowMuch')}
                </h1>
              </Col>
            </Row>{' '}
            <Row
              span={24}
              style={{ margin: 'auto', textAlign: 'center', width: '100%' }}
            >
              <Col span={12}>
                <ButtonToDashboard
                  style={{
                    height: 80,
                    fontSize: 30,
                    width: 320,
                    borderRadius: 80
                  }}
                  className="go-dashboard-button"
                  type="primary"
                  size="large"
                  block
                  onClick={e => this.props.push('/user/myvehicles')}
                >
                  {window.i18n.translate('GoTo') +
                    ' ' +
                    window.i18n.translate('MyCars')}
                </ButtonToDashboard>
              </Col>{' '}
              <Col span={12}>
                <ButtonToAddVehicles
                  style={{
                    height: 80,
                    fontSize: 30,
                    width: 320,
                    borderRadius: 80
                  }}
                  className="add-car-button"
                  type="primary"
                  size="large"
                  block
                  onClick={e => this.props.push('/user/addvehicle')}
                >
                  {window.i18n.translate('AddCar') +
                    ' ' +
                    window.i18n.translate('ToSell')}
                </ButtonToAddVehicles>
              </Col>{' '}
            </Row>
          </Content>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  user: getUser(state) || {}
});

export default withRouter(connect(mapStateToProps)(LandingPage));
