import React from 'react';
import { Layout } from 'antd';

import { connect } from 'react-redux';
import UserSidebar from './UserSidebar';
import DealerSidebar from './DealerSidebar';
import AdminSidebar from './AdminSidebar';
import ResHeader from 'src/components/layout/ResHeader';
import ResFooter from 'src/components/layout/ResFooter';
import { getSidebarCollapsed, getUser } from 'src/redux/selectors';
import BackgroundImage from 'src/static/background.svg';
const { Content } = Layout;

const Template = props => (
  <div>
    <Layout style={{ minHeight: '100vh' }}>
      <ResHeader />
      <Layout
        style={{
          marginTop: 60
        }}
      >
        {props.user.type === 'user' ? (
          <UserSidebar />
        ) : props.user.type === 'dealer' ? (
          <DealerSidebar />
        ) : (
          <AdminSidebar />
        )}
        <Layout
          style={{
            ...(props.background && {
              backgroundImage: `url(${BackgroundImage})`
            }),
            marginLeft: props.collapsed ? 0 : 0,

            transition: 'all 0.2s'
          }}
        >
          <Content
            className="mobile-content-layout"
            style={{
              transition: 'all 0.2s',
              margin: 20,
              marginLeft: 92
            }}
          >
            {props.children}
          </Content>
          <ResFooter />
        </Layout>
      </Layout>
    </Layout>
  </div>
);

const mapStateToProps = state => {
  return {
    collapsed: getSidebarCollapsed(state),
    user: getUser(state) || {}
  };
};
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Template);
