/* global window */
import React from 'react';
import { Layout, Menu, Icon } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Cookies } from 'react-cookie';
import { getSidebarCollapsed, getUser } from 'src/redux/selectors';
import { collapseSidebar } from 'src/redux/actions/status';

const { Sider } = Layout;

const StyledLink = styled(Link)`
  color: #fff;
  :hover {
    text-decoration: none !important;
  }
  .anticon {
    color: #00888e;
  }
`;

const Sidebar = props => {
  const signOut = () => {
    if (window.cordova) {
      window.FirebasePlugin.getToken(
        token => {
          window.pubnub.push.deleteDevice(
            {
              device: token,
              pushGateway: 'gcm' // apns, gcm, mpns
            },
            function(status) {
              if (status.error) {
                console.log('operation failed w/ error:', status);
              }
            }
          );
        },
        error => {
          console.error(error);
        }
      );
    }

    const cookies = new Cookies();
    cookies.remove('token');
    cookies.remove('basic');
    localStorage.clear();
    window.location.reload();
  };

  return (
    <Sider
      width="72"
      collapsedWidth="80"
      trigger={null}
      collapsible
      collapsed={false}
      id="dealerSidebarId"
      // width={170}
      style={{
        border: '1px solid #00888e3b',
        zIndex: 9,
        position: 'fixed',
        background: 'white',
        height: 'calc(100vh - 60px)',
        left: 0,
        top: 60,
        borderRight: ' 0.5px solid rgba(122, 122, 122, 0.39) '
      }}
    >
      <Menu
        style={{ background: 'none' }}
        theme="light"
        mode="inline"
        defaultSelectedKeys={['auctionvehicles']}
      >
        {/*     <Menu.Item
          style={{ marginTop: '15px' }}
          key="dashboard" 
        >
         <StyledLink to="/dealer/dashboard">
            <Icon type="appstore" theme="filled" style={{ fontSize: '25px', paddingRight: '15px' }} />
            <span>Dashboard</span>
          </StyledLink>
        </Menu.Item>*/}
        <Menu.Item
          disabled={props.user.approved === true ? false : true}
          style={{ marginTop: '15px' }}
          key="auctionvehicles"
          id="dealerSidebarId"
        >
          <StyledLink to="/dealer/auctionvehicles">
            <Icon
              type="car"
              theme="filled"
              style={{ fontSize: '20px', paddingRight: '12px' }}
            />
            <span style={{ fontSize: '15px' }}>
              {window.i18n.translate('VehiclesOnAuction')}
            </span>
          </StyledLink>
        </Menu.Item>
        <Menu.Item
          disabled={props.user.approved === true ? false : true}
          key="mybasket"
        >
          <StyledLink to="/dealer/mybasket">
            <Icon
              type="shopping-cart"
              style={{ fontSize: '20px', paddingRight: '12px' }}
            />
            <span style={{ fontSize: '15px' }}>
              {window.i18n.translate('MyBasket')}
            </span>
          </StyledLink>
        </Menu.Item>
        <Menu.Item
          disabled={props.user.approved === true ? false : true}
          key="history"
        >
          <StyledLink to="/dealer/history">
            <Icon
              type="history"
              style={{ fontSize: '20px', paddingRight: '12px' }}
            />
            <span style={{ fontSize: '15px' }}>
              {window.i18n.translate('History')}
            </span>
          </StyledLink>
        </Menu.Item>{' '}
        <Menu.Item onClick={signOut} key="signout">
          <Icon
            style={{
              transform: 'rotate(180deg)',

              marginLeft: '4.5px',

              fontSize: '21px',
              color: '#00888E'
            }}
            type="logout"
          />
          <span
            style={{
              fontSize: '16px',
              paddingLeft: '14px',
              verticalAlign: '0.2em',
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.65)'
            }}
          >
            {window.i18n.translate('SignOut')}
          </span>{' '}
        </Menu.Item>
        {/*    <Menu.Item style={{ position: 'absolute', bottom: 110 }} key="account">
          <StyledLink to="/dealer/account">
            <Icon type="setting" theme="filled" />
            <span>Account Settings</span>
          </StyledLink>
        </Menu.Item>
        <Menu.Item
          style={{ position: 'absolute', bottom: 70 }}
          onClick={signOut}
          key="signout"
        >
          {/* <StyledLink to="/"> 
          <Icon type="poweroff" />
          <span>Sign Out</span>
          {/* </StyledLink>
        </Menu.Item> */}
      </Menu>
    </Sider>
  );
};

const mapStateToProps = state => {
  return {
    collapsed: getSidebarCollapsed(state),
    user: getUser(state) || {}
  };
};

const mapDispatchToProps = {
  collapseSidebar
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Sidebar)
);
