import { getToken } from 'src/redux/selectors';

export function addVehicle(values, activateDisplayId) {
  if (activateDisplayId) {
    values.activateDisplayId = true;
  }
  values.lang = window.i18n.props.locale;
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/vehicles/addvehicle',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify(values)
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to add vehicle' });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function saveVehicle(values, activateDisplayId) {
  if (activateDisplayId) {
    values.activateDisplayId = true;
  }
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/vehicles/savevehicle',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify(values)
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to edit vehicle' });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function deleteVehicle(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/vehicles/deletevehicle',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id })
        }
      )
        .then(res => {
          if (!res.ok) {
            return reject({ message: 'Failed to delete vehicle' });
          }
          res.text();
        })
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            return resolve({ message: response });
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function addBasketVehicle(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/vehicles/addbasketvehicle',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'USER_UPDATE',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function removeBasketVehicle(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/vehicles/removebasketvehicle',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'USER_UPDATE',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findBasketVehicles() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/vehicles/findbasketvehicles',
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: 'VEHICLES_FIND_BASKETVEHICLES',
                data: parsedResponse
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findMyVehicles(make) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/vehicles/findmyvehicles',
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: 'VEHICLES_FIND_MYVEHICLES',
                data: parsedResponse
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findVehicleDetails(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/vehicles/findvehicledetails?id=' +
          id,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: 'VEHICLES_FIND_VEHICLEDETAILS',
                data: parsedResponse
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findAuctionVehicles() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/vehicles/findauctionvehicles',
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'VEHICLES_FIND_AUCTIONVEHICLES',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findDealDoneVehicles() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/vehicles/finddealdonevehicles',
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'VEHICLES_FIND_DEALDONEVEHICLES',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findAuctionWonVehicles() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/vehicles/findauctionwonvehicles',
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'VEHICLES_FIND_WONVEHICLES',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findAuctionLostVehicles() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/vehicles/findauctionlostvehicles',
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: 'VEHICLES_FIND_LOSTVEHICLES',
            data: await res.json()
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function setEditVehicle(vehicle) {
  return dispatch =>
    dispatch({
      type: 'VEHICLES_SET_EDITVEHICLE',
      data: vehicle
    });
}

export function setVehicleDetailsId(id) {
  return dispatch =>
    dispatch({
      type: 'VEHICLES_SET_VEHICLEDETAILSID',
      data: id
    });
}

export function findVariants(model) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/cddata/findvariants?model=' +
          model,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: 'CDDATA_FIND_VARIANTS',
                data: parsedResponse
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findYears(variant) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/cddata/findyears?variant=' +
          variant,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: 'CDDATA_FIND_YEARS',
                data: parsedResponse
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function acceptOffer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/vehicles/acceptoffer',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to accept offer' });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function rejectOffer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/vehicles/rejectoffer',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to reject offer' });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function acceptAdminOffer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/vehicles/acceptadminoffer',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to accept offer' });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function rejectAdminOffer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        'https://www.devvcsappcloud.com/verkoopnou/api/vehicles/rejectadminoffer',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + getToken(),
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({ id: id })
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: 'Failed to reject offer' });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}
