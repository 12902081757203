import React from 'react';
import styled from 'styled-components';
import {
  Icon,
  Row,
  Col,
  Spin,
  Button,
  List,
  Tabs,
  Popover,
  Input,
  Table,
  Divider,
  Modal
} from 'antd';
import moment from 'moment';
import Notify from 'src/components/meta/Notification';
import noImage from 'src/static/icons/carImageUpload/no-image-available.svg';
import Countdown from 'react-countdown-now';
import Highlighter from 'react-highlight-words';
const { Column, ColumnGroup } = Table;
const { TextArea } = Input;
const numeral = require('numeral');
const { TabPane } = Tabs;
const hammer = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6475 17.3418H1.65195C0.740018 17.3418 0 18.0817 0 18.9938V21.9977H14.2994V18.9938C14.2994 18.0817 13.5595 17.3418 12.6475 17.3418Z"
      fill="#AD5E00"
    />
    <path
      d="M21.7036 17.5744L11.6665 7.53725L13.6051 5.60203C13.9248 5.88391 14.4163 5.87013 14.7188 5.56766C15.0351 5.248 15.0351 4.7358 14.7188 4.41614L10.539 0.239743C10.2228 -0.0799143 9.70715 -0.0799143 9.39094 0.239743C9.07128 0.555998 9.07128 1.0716 9.39094 1.38781L9.35657 1.35344L4.04924 6.65728L4.08361 6.69505C3.76736 6.37539 3.25175 6.37539 2.93554 6.69505C2.61589 7.01131 2.61589 7.52691 2.93554 7.84317L7.11194 12.023C7.4316 12.3392 7.9472 12.3392 8.26346 12.023C8.57971 11.7033 8.57971 11.1912 8.26346 10.8715L8.29783 10.9058L10.2365 8.9706L20.2702 19.0043C20.6655 19.3996 21.3083 19.3996 21.7036 19.0043C22.0989 18.609 22.0989 17.9697 21.7036 17.5744Z"
      fill="#AD5E00"
    />
  </svg>
);

const StyledTable = styled(Table)`
  // .ant-table-thead > tr > th {
  //   text-align: center !important;

  // }
  .ant-table-thead > tr > th {
    line-height: 1em;
    font-size: 16px;
    color: grey;

    border-bottom: 1px solid#00878e34;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #e8e8e8;
    -webkit-transition: all 0.3s, border 0s;
    transition: all 0.3s, border 0s;
    font-weight: 700;
    font-size: 11px;
  }
`;
class Users extends React.Component {
  componentWillMount = () => {
    this.setState({ loading: true });
    this.props
      .findAllUsers()
      .then(() => {
        this.props
          .findAllDealers()
          .then(() => {
            this.setState({ loading: false });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      rejectReason: ''
    };
  }

  stopProp = e => {
    e.stopPropagation();
  };

  handleSelectUser = e => {
    this.props.setUserDetailsId(e.currentTarget.getAttribute('data'));
    this.props.push('/admin/userdetails');
  };
  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let users = this.props.users;
    if (order === 'ascend') {
      users.sort((a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey]));
    } else if (order === 'descend') {
      users.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
    }
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, sorter);
    this.setState({
      sortedInfo: sorter
    });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  handleDeleteUser = e => {
    const id = e.currentTarget.getAttribute('data');
    var that = this;
    Modal.confirm({
      centered: true,
      content: (
        <div>
          <h4>
            Are your sure you want to delete this user and all of their
            vehicles?
          </h4>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .deleteUser(id)
          .then(() => {
            that.props
              .findAllUsers()
              .then(() => {
                that.setState({ loading: false });
                Notify('success', 'Successfully deleted user');
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify('error', e);
          });
      },
      oncancel() {}
    });
  };
  handleDeleteDealer = e => {
    const id = e.currentTarget.getAttribute('data');
    var that = this;
    Modal.confirm({
      centered: true,
      content: (
        <div>
          <h4>
            Are your sure you want to delete this dealer and all of their
            bidding data?
          </h4>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .deleteDealer(id)
          .then(() => {
            that.props
              .findAllDealers()
              .then(() => {
                that.setState({ loading: false });
                Notify('success', 'Successfully deleted dealer');
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify('error', e);
          });
      },
      oncancel() {}
    });
  };
  handleApproveDealer = e => {
    const id = e.currentTarget.getAttribute('data');
    this.setState({ loading: true });
    this.props
      .approveDealer(id)
      .then(() => {
        this.props
          .sendNotification(
            id,
            [id],
            window.i18n.translate('AdminApprovedDealerAccount'),

            'dealerApproved',
            null,
            false,
            'Verkoopnou Admin'
          )
          .then(() => {
            this.props
              .findAllDealers()
              .then(() => {
                this.setState({ loading: false });
                Notify('success', 'Successfully approved dealer');
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  handleDeactivateDealer = e => {
    const id = e.currentTarget.getAttribute('data');
    this.setState({ loading: true });
    this.props
      .deactivateDealer(id)
      .then(() => {
        this.props
          .sendNotification(
            id,
            [id],
            window.i18n.translate('AdminDectivatedDealerAccount'),
            'dealerDeactivated',
            null,
            true,
            'Verkoopnou Admin'
          )
          .then(() => {
            this.props
              .findAllDealers()
              .then(() => {
                this.setState({ loading: false });
                Notify(
                  'success',
                  window.i18n.translate('SuccessfullyDeactivatedDealer')
                );
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  handleActivateDealer = e => {
    const id = e.currentTarget.getAttribute('data');
    this.setState({ loading: true });
    this.props
      .activateDealer(id)
      .then(() => {
        this.props
          .sendNotification(
            id,
            [id],
            window.i18n.translate('AdminActivatedDealerAccount'),

            'dealerDeactivated',
            null,
            true,
            'Verkoopnou Admin'
          )
          .then(() => {
            this.props
              .findAllDealers()
              .then(() => {
                this.setState({ loading: false });
                Notify('success', 'Successfully activated dealer');
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  handleDeactivatePrices = e => {
    const id = e.currentTarget.getAttribute('data');
    this.setState({ loading: true });
    this.props
      .deactivatePrices(id)
      .then(() => {
        this.props
          .findAllDealers()
          .then(() => {
            this.setState({ loading: false });
            Notify(
              'success',
              window.i18n.translate('SuccessfullyDeactivatedDealerPrices')
            );
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  handleActivatePrices = e => {
    const id = e.currentTarget.getAttribute('data');
    this.setState({ loading: true });
    this.props
      .activatePrices(id)
      .then(() => {
        this.props
          .findAllDealers()
          .then(() => {
            this.setState({ loading: false });
            Notify(
              'success',
              window.i18n.translate('SuccessfullyActivatedDealerPrices')
            );
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  handleDownloadDoc = dealer => {
    fetch(
      'https://www.devvcsappcloud.com/verkoopnou/api/files/documents' +
        dealer.document.url
    )
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = dealer.document.name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        Notify('success', 'Successfully downloaded document');
      })
      .catch(e => Notify('error', e));
  };
  render() {
    const { user } = this.props;
    return (
      <div>
        <Row type="flex" justify="start" align="middle">
          <Col span={12}>
            <Icon
              style={{ fontSize: 36, color: '#00888E', marginRight: 20 }}
              type="team"
            />
            <span style={{ fontWeight: 750, fontSize: 36 }}>
              {window.i18n.translate('ManagePeople')}
            </span>
          </Col>
          {/* <Col span={12}>
              <Button type="primary" style={{ fontWeight: 750, fontSize: 24, float: "right" }}>{moment().format("HH:mm:ss")}</Button>
            </Col> */}
        </Row>
        <Spin spinning={this.state.loading}>
          <Tabs
            style={{ overflow: 'visible' }}
            // onChange={this.handleTabChange}
          >
            <TabPane tab="Users" key="1">
              <Row type="flex" justify="start" align="middle">
                <Col span={24}>
                  <StyledTable
                    style={{ background: 'white' }}
                    rowKey="_id"
                    size="middle"
                    dataSource={this.props.users}
                    onChange={this.handleSort}
                  >
                    {/* <Column title="ID" dataIndex="_id" key="_id" /> */}
                    <Column
                      {...this.getColumnSearchProps('username')}
                      title={window.i18n.translate('Username')}
                      dataIndex="username"
                      key="username"
                    />
                    <Column
                      {...this.getColumnSearchProps('name')}
                      title={window.i18n.translate('Name')}
                      dataIndex="name"
                      key="name"
                    />
                    <Column
                      {...this.getColumnSearchProps('surname')}
                      title={window.i18n.translate('Surname')}
                      dataIndex="surname"
                      key="surname"
                    />
                    <Column
                      {...this.getColumnSearchProps('nickname')}
                      title={window.i18n.translate('Nickname')}
                      dataIndex="nickname"
                      key="nickname"
                    />
                    <Column
                      {...this.getColumnSearchProps('number')}
                      title={window.i18n.translate('Number')}
                      dataIndex="number"
                      key="number"
                    />
                    <Column
                      {...this.getColumnSearchProps('altNumber')}
                      title={window.i18n.translate('AlternativeNumber')}
                      dataIndex="altNumber"
                      key="altNumber"
                    />
                    <Column
                      {...this.getColumnSearchProps('heardFrom')}
                      title={window.i18n.translate('HeardFrom')}
                      dataIndex="heardFrom"
                      key="heardFrom"
                    />
                    <Column
                      title={window.i18n.translate('Action')}
                      render={(text, user) => (
                        <span>
                          <a data={user._id} onClick={this.handleDeleteUser}>
                            {window.i18n.translate('Delete')}
                          </a>
                        </span>
                      )}
                    />
                  </StyledTable>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab={window.i18n.translate('Dealers')} key="2">
              <Row type="flex" justify="start" align="middle">
                <Col span={24}>
                  <StyledTable
                    style={{ background: 'white' }}
                    rowKey="_id"
                    size="middle"
                    dataSource={this.props.dealers}
                    onChange={this.handleSort}
                  >
                    {/* <Column title="ID" dataIndex="_id" key="_id" /> */}
                    <Column
                      {...this.getColumnSearchProps('username')}
                      title={window.i18n.translate('Username')}
                      dataIndex="username"
                      key="username"
                    />
                    <Column
                      {...this.getColumnSearchProps('name')}
                      title={window.i18n.translate('Name')}
                      dataIndex="name"
                      key="name"
                    />
                    <Column
                      {...this.getColumnSearchProps('surname')}
                      title={window.i18n.translate('Surname')}
                      dataIndex="surname"
                      key="surname"
                    />
                    <Column
                      {...this.getColumnSearchProps('nickname')}
                      title={window.i18n.translate('Nickname')}
                      dataIndex="nickname"
                      key="nickname"
                    />
                    <Column
                      {...this.getColumnSearchProps('number')}
                      title={window.i18n.translate('Number')}
                      dataIndex="number"
                      key="number"
                    />
                    <Column
                      {...this.getColumnSearchProps('altNumber')}
                      title={window.i18n.translate('AlternativeNumber')}
                      dataIndex="altNumber"
                      key="altNumber"
                    />
                    <Column
                      {...this.getColumnSearchProps('heardFrom')}
                      title={window.i18n.translate('HeardFrom')}
                      dataIndex="heardFrom"
                      key="heardFrom"
                    />

                    <Column
                      title={window.i18n.translate('CKDocument')}
                      render={(text, dealer) => (
                        <span>
                          {dealer.document ? (
                            <a
                              onClick={e => {
                                this.handleDownloadDoc(dealer);
                              }}
                            >
                              {dealer.document.name}
                            </a>
                          ) : (
                            window.i18n.translate('NotUploaded')
                          )}
                        </span>
                      )}
                    />
                    <Column
                      title={window.i18n.translate('Approved')}
                      render={(text, dealer) => (
                        <span
                          style={{ color: dealer.approved ? 'green' : 'red' }}
                        >
                          {dealer.approved ? 'True' : 'False'}
                        </span>
                      )}
                    />
                    <Column
                      title={window.i18n.translate('Active')}
                      render={(text, dealer) => (
                        <span
                          style={{ color: dealer.active ? 'green' : 'red' }}
                        >
                          {dealer.active ? 'True' : 'False'}
                        </span>
                      )}
                    />
                    <Column
                      title={window.i18n.translate('PricesActive')}
                      render={(text, dealer) => (
                        <span
                          style={{
                            color: dealer.canSeePrices ? 'green' : 'red'
                          }}
                        >
                          {dealer.canSeePrices ? 'True' : 'False'}
                        </span>
                      )}
                    />
                    <Column
                      title={window.i18n.translate('Action')}
                      render={(text, dealer) => (
                        <span>
                          <a
                            data={dealer._id}
                            onClick={this.handleApproveDealer}
                          >
                            {window.i18n.translate('Approve')}
                          </a>
                          <Divider type="vertical" />
                          <a
                            data={dealer._id}
                            onClick={
                              dealer.active
                                ? this.handleDeactivateDealer
                                : this.handleActivateDealer
                            }
                          >
                            {dealer.active
                              ? window.i18n.translate('Deactivate')
                              : window.i18n.translate('Activate')}
                          </a>
                          <Divider type="vertical" />
                          <a
                            data={dealer._id}
                            onClick={
                              dealer.canSeePrices
                                ? this.handleDeactivatePrices
                                : this.handleActivatePrices
                            }
                          >
                            {dealer.canSeePrices
                              ? window.i18n.translate('DeactivatePrices')
                              : window.i18n.translate('ActivatePrices')}
                          </a>
                          <Divider type="vertical" />
                          <a
                            data={dealer._id}
                            onClick={this.handleDeleteDealer}
                          >
                            {window.i18n.translate('Delete')}
                          </a>
                        </span>
                      )}
                    />
                  </StyledTable>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Spin>
      </div>
    );
  }
}

export default Users;
