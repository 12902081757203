import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  findDealVehicles,
  setVehicleDetailsId,
  approveVehicle,
  rejectVehicle,
  updateFollowUp,
  setFromHistory,
  completeDeal,
  auctionVehicle
} from 'src/redux/actions/admin';
import {
  updateBidsList,
  placeBid,
  findBidData
} from 'src/redux/actions/bidding';
import { sendNotification } from 'src/redux/actions/notifications';
import {
  getLocale,
  getDealVehicles,
  getAllVehicleIds,
  getUser,
  getTimeStamp,
  getBidData
} from 'src/redux/selectors';
import { deleteVehicle, acceptOffer } from 'src/redux/actions/vehicles';

class Deals extends Component {
  render = () => {
    const {
      Layout,
      currentLocale,
      vehicles,
      vehicleIds,
      user,
      auctionVehicle,
      findDealVehicles,
      setFromHistory,
      setVehicleDetailsId,
      updateFollowUp,
      completeDeal,
      deleteVehicle,
      sendNotification,
      acceptOffer,
      push
    } = this.props;
    return (
      <Layout
        currentLocale={currentLocale}
        vehicles={vehicles}
        vehicleIds={vehicleIds}
        user={user}
        auctionVehicle={auctionVehicle}
        findDealVehicles={findDealVehicles}
        setFromHistory={setFromHistory}
        setVehicleDetailsId={setVehicleDetailsId}
        updateFollowUp={updateFollowUp}
        completeDeal={completeDeal}
        deleteVehicle={deleteVehicle}
        sendNotification={sendNotification}
        acceptOffer={acceptOffer}
        push={push}
      />
    );
  };
}

const mapStateToProps = state => ({
  currentLocale: getLocale(state) || {},
  vehicles: getDealVehicles(state) || {},
  vehicleIds: getAllVehicleIds(state) || [],
  bidData: getBidData(state) || {},
  timeStamp: getTimeStamp(state) || '',
  user: getUser(state) || {}
});

const mapDispatchToProps = {
  getLocale,
  findDealVehicles,
  setFromHistory,
  setVehicleDetailsId,
  updateFollowUp,
  completeDeal,
  deleteVehicle,
  sendNotification,
  auctionVehicle,
  acceptOffer,
  push
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Deals);
