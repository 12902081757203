import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push, goBack } from 'connected-react-router';
import {
  findVehicleDetails,
  approveVehicle,
  rejectVehicle,
  makeAnOffer,
  setFromHistory,
  updateNotes,
  completeDeal,
  findAllDealers,
  changeDealer,
  findVehicleBidInfo,
  setReserve
} from 'src/redux/actions/admin';
import {
  findMakes,
  findModels,
  findVariants,
  findYears,
  findCities
} from 'src/redux/actions/cddata';
import { saveVehicle } from 'src/redux/actions/vehicles';
import { sendNotification } from 'src/redux/actions/notifications';
import {
  getLocale,
  getVehicleDetailsAdmin,
  getVehicleDetailsIdAdmin,
  getUser,
  getMakes,
  getModels,
  getVariants,
  getYears,
  getCities,
  getFromHistoryAdmin,
  getAllDealers,
  getVehicleBidInfo
} from 'src/redux/selectors';

class VehicleDetails extends Component {
  render = () => {
    const {
      Layout,
      user,
      currentLocale,
      vehicleDetails,
      findVehicleDetails,
      vehicleDetailsId,
      fromHistory,
      approveVehicle,
      setFromHistory,
      rejectVehicle,
      saveVehicle,
      completeDeal,
      sendNotification,
      makeAnOffer,
      findMakes,
      makes,
      findModels,
      models,
      findVariants,
      variants,
      findYears,
      findCities,
      cities,
      years,
      push,
      updateNotes,
      findAllDealers,
      dealers,
      changeDealer,
      biddingInfo,
      findVehicleBidInfo,
      setReserve,
      goBack
    } = this.props;
    return (
      <Layout
        currentLocale={currentLocale}
        user={user}
        vehicleDetails={vehicleDetails}
        findVehicleDetails={findVehicleDetails}
        vehicleDetailsId={vehicleDetailsId}
        setFromHistory={setFromHistory}
        fromHistory={fromHistory}
        approveVehicle={approveVehicle}
        rejectVehicle={rejectVehicle}
        saveVehicle={saveVehicle}
        completeDeal={completeDeal}
        sendNotification={sendNotification}
        makeAnOffer={makeAnOffer}
        findMakes={findMakes}
        makes={makes}
        findModels={findModels}
        models={models}
        findVariants={findVariants}
        variants={variants}
        findYears={findYears}
        cities={cities}
        findCities={findCities}
        years={years}
        push={push}
        updateNotes={updateNotes}
        findAllDealers={findAllDealers}
        dealers={dealers}
        changeDealer={changeDealer}
        biddingInfo={biddingInfo}
        findVehicleBidInfo={findVehicleBidInfo}
        setReserve={setReserve}
        goBack={goBack}
      />
    );
  };
}

const mapStateToProps = state => ({
  currentLocale: getLocale(state) || {},
  vehicleDetails: getVehicleDetailsAdmin(state) || {},
  vehicleDetailsId: getVehicleDetailsIdAdmin(state) || '',
  user: getUser(state) || {},
  makes: getMakes(state) || {},
  models: getModels(state) || {},
  variants: getVariants(state) || {},
  years: getYears(state) || {},
  cities: getCities(state) || [],
  fromHistory: getFromHistoryAdmin(state),
  dealers: getAllDealers(state) || [],
  biddingInfo: getVehicleBidInfo(state) || []
});

const mapDispatchToProps = {
  getLocale,
  findVehicleDetails,
  sendNotification,
  approveVehicle,
  setFromHistory,
  rejectVehicle,
  saveVehicle,
  completeDeal,
  makeAnOffer,
  findMakes,
  findModels,
  findVariants,
  findYears,
  findCities,
  push,
  updateNotes,
  findAllDealers,
  changeDealer,
  findVehicleBidInfo,
  setReserve,
  goBack
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleDetails);
