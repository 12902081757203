import React, { PureComponent } from 'react';
import { Layout } from 'antd';
export class ResFooter extends PureComponent {
  render() {
    return (
      <Layout.Footer
        style={{
          zIndex: 10,
          background: '#00888E',
          height: 50,

          textAlign: 'center',
          color: 'white'
        }}
      >
        <p style={{ marginTop: '-0.7em' }}>
          2020 {window.i18n.translate('Copyright')} Verkoopnou
        </p>
      </Layout.Footer>
    );
  }
}

export default ResFooter;
