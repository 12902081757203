/* global document */
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Spin } from 'antd';

import configureStore from 'src/redux/store';
import 'src/styles/global.scss';
import * as serviceWorker from 'src/serviceWorker';
import Routes from 'src/routes/routes';
import I18n from 'src/i18n/i18n';
// Components

// HIJACK CONSOLE AND ERROR LOG FOR PRODUCTION
if (process.env.NODE_ENV !== 'development')
  window.console = {
    log: () => {},
    error: () => {},
    warn: () => {}
  }; //HIJACK CONSOLE LOG

const { persistor, store, history } = configureStore();
let searchParams = new URLSearchParams(window.location.search);
if (searchParams.has('dealeroffer')) {
  persistor.purge();
}
if (searchParams.has('adminoffer')) {
  persistor.purge();
}
// persistor.purge(); // Debug to clear persist

const rootElement = document.getElementById('root');

const Root = () => (
  <Provider store={store}>
    <PersistGate loading={<Spin spinning />} persistor={persistor}>
      <I18n />
      <Routes history={history} />
    </PersistGate>
  </Provider>
);

if (window.cordova) {
  document.addEventListener(
    'deviceready',
    () => {
      render(<Root />, rootElement);
      serviceWorker.register();
    },
    false
  );
} else {
  render(<Root />, rootElement);
  serviceWorker.register();
}
