import user from './user';
import cddata from './cddata';
import vehicles from './vehicles';
import admin from './admin';
import status from './status';
import locale from './locale';
import notifications from './notifications';

const rehydrated = (state = false, action) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      if (action.payload) {
        if (action.payload.user) {
          if (action.payload.user.user) {
            /*eslint-disable */
            window.pubnub = new PubNub({
              publishKey: 'pub-c-6a0c1b45-c15b-4e6b-b985-37f40dc921b2',
              subscribeKey: 'sub-c-9f54222a-ac64-11e9-9c97-16f61f10341e'
            });
            /*eslint-enable */
            window.pubnub.setUUID(action.payload.user.user._id);
            let channels = [action.payload.user.user._id, 'global'];
            if (action.payload.user.user.type === 'admin') {
              channels.push('admin');
              channels.push('dealers');
            }
            if (action.payload.user.user.type === 'dealer') {
              channels.push('dealers');
            }
            window.pubnub.subscribe({
              channels: channels
            });
            if (window.cordova) {
              window.FirebasePlugin.getToken(
                token => {
                  window.pubnub.push.addChannels(
                    {
                      channels: channels,
                      device: token,
                      pushGateway: 'gcm' // apns, gcm, mpns
                    },
                    function(status) {
                      if (status.error) {
                        console.log('operation failed w/ error:', status);
                      }
                    }
                  );
                },
                error => {
                  console.error(error);
                }
              );
              window.FirebasePlugin.onNotificationOpen(
                notif => {
                  notif.message = JSON.parse(notif.message);
                  // this.props.setPubnub(notif)
                },
                error => {
                  console.error(error);
                }
              );
            } else {
            }
          }
        }
      }
      return true;
    default:
      return state;
  }
};

export default {
  rehydrated,
  user,
  cddata,
  vehicles,
  admin,
  status,
  locale,
  notifications
};
