import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getLocale } from 'src/redux/selectors';
import { push } from 'connected-react-router';

class LandingPage extends Component {
  render = () => {
    const { Layout, currentLocale, push } = this.props;
    return <Layout currentLocale={currentLocale} push={push} />;
  };
}

const mapStateToProps = state => ({
  currentLocale: getLocale(state) || {}
});

const mapDispatchToProps = {
  getLocale,
  push
};

export default connect(
  mapStateToProps,

  mapDispatchToProps
)(LandingPage);
