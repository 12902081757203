import { createSelector } from 'reselect';
import { Cookies } from 'react-cookie';

// SUBJECTS
const getNotificationsState = state => state.notifications.notifications;

export const getNotifications = createSelector(
  [getNotificationsState],
  notifications => {
    return notifications;
  }
);

const getMakesStates = state => state.cddata.makes;

export const getMakes = createSelector(
  [getMakesStates],
  makes => {
    return makes;
  }
);

const getModelsStates = state => state.cddata.models;

export const getModels = createSelector(
  [getModelsStates],
  models => {
    return models;
  }
);

const getVariantsStates = state => state.cddata.variants;

export const getVariants = createSelector(
  [getVariantsStates],
  variants => {
    return variants;
  }
);

const getYearsStates = state => state.cddata.years;

export const getYears = createSelector(
  [getYearsStates],
  years => {
    return years;
  }
);

const getCitiesStates = state => state.cddata.cities;

export const getCities = createSelector(
  [getCitiesStates],
  cities => {
    return cities;
  }
);

const getTimeStampStates = state => state.vehicles.timeStamp;

export const getTimeStamp = createSelector(
  [getTimeStampStates],
  timeStamp => {
    return timeStamp;
  }
);

const getCustomTimeStates = state => state.vehicles.customTime;

export const getCustomTime = createSelector(
  [getCustomTimeStates],
  customTime => {
    return customTime;
  }
);

const getMyVehicleStates = state => state.vehicles.myVehicles;

export const getMyVehicles = createSelector(
  [getMyVehicleStates],
  myVehicles => {
    return myVehicles;
  }
);

const getVehicleDetailsStates = state => state.vehicles.vehicleDetails;

export const getVehicleDetails = createSelector(
  [getVehicleDetailsStates],
  vehicleDetails => {
    return vehicleDetails;
  }
);

const getVehicleDetailsIdStates = state => state.vehicles.vehicleDetailsId;

export const getVehicleDetailsId = createSelector(
  [getVehicleDetailsIdStates],
  vehicleDetailsId => {
    return vehicleDetailsId;
  }
);

const getVehicleDetailsAdminStates = state => state.admin.vehicleDetails;

export const getVehicleDetailsAdmin = createSelector(
  [getVehicleDetailsAdminStates],
  vehicleDetails => {
    return vehicleDetails;
  }
);

const getVehicleBidInfoAdminStates = state => state.admin.biddingInfo;

export const getVehicleBidInfo = createSelector(
  [getVehicleBidInfoAdminStates],
  biddingInfo => {
    return biddingInfo;
  }
);

const getVehicleDetailsIdAdminStates = state => state.admin.vehicleDetailsId;

export const getVehicleDetailsIdAdmin = createSelector(
  [getVehicleDetailsIdAdminStates],
  vehicleDetailsId => {
    return vehicleDetailsId;
  }
);

const getFromHistoryAdminStates = state => state.admin.fromHistory;

export const getFromHistoryAdmin = createSelector(
  [getFromHistoryAdminStates],
  fromHistory => {
    return fromHistory;
  }
);

const getApprovedVehicleStates = state => state.admin.approvedVehicles;

export const getApprovedVehicles = createSelector(
  [getApprovedVehicleStates],
  approvedVehicles => {
    return approvedVehicles;
  }
);

const getApprovedVehicleIdsStates = state => state.admin.approvedVehicleIds;

export const getApprovedVehicleIds = createSelector(
  [getApprovedVehicleIdsStates],
  approvedVehicleIds => {
    return approvedVehicleIds;
  }
);

const getAuctionHistoryVehiclesStates = state =>
  state.admin.auctionHistoryVehicles;

export const getAuctionHistoryVehicles = createSelector(
  [getAuctionHistoryVehiclesStates],
  auctionHistoryVehicles => {
    return auctionHistoryVehicles;
  }
);

const getAllVehicleStates = state => state.admin.allVehicles;

export const getAllVehicles = createSelector(
  [getAllVehicleStates],
  allVehicles => {
    return allVehicles;
  }
);

const getDealVehicleStates = state => state.admin.dealVehicles;

export const getDealVehicles = createSelector(
  [getDealVehicleStates],
  dealVehicles => {
    return dealVehicles;
  }
);

const getAllVehicleIdsStates = state => state.admin.allVehicleIds;

export const getAllVehicleIds = createSelector(
  [getAllVehicleIdsStates],
  allVehicleIds => {
    return allVehicleIds;
  }
);

const getCalendarState = state => state.admin.calendar;

export const getCalendar = createSelector(
  [getCalendarState],
  calendar => {
    return calendar;
  }
);

const getDealDoneVehicleStates = state => state.vehicles.dealDoneVehicles;

export const getDealDoneVehicles = createSelector(
  [getDealDoneVehicleStates],
  dealDoneVehicles => {
    return dealDoneVehicles;
  }
);

const getAuctionWonVehicleStates = state => state.vehicles.wonVehicles;

export const getAuctionWonVehicles = createSelector(
  [getAuctionWonVehicleStates],
  wonVehicles => {
    return wonVehicles;
  }
);

const getAuctionLostVehicleStates = state => state.vehicles.lostVehicles;

export const getAuctionLostVehicles = createSelector(
  [getAuctionLostVehicleStates],
  lostVehicles => {
    return lostVehicles;
  }
);

const getAuctionVehicleStates = state => state.vehicles.auctionVehicles;

export const getAuctionVehicles = createSelector(
  [getAuctionVehicleStates],
  auctionVehicles => {
    return auctionVehicles;
  }
);

const getAuctionVehicleIdsStates = state => state.vehicles.auctionVehicleIds;

export const getAuctionVehicleIds = createSelector(
  [getAuctionVehicleIdsStates],
  auctionVehicleIds => {
    return auctionVehicleIds;
  }
);

const getBasketVehicleStates = state => state.vehicles.basketVehicles;

export const getBasketVehicles = createSelector(
  [getBasketVehicleStates],
  basketVehicles => {
    return basketVehicles;
  }
);

const getBasketVehicleIdsStates = state => state.vehicles.basketVehicleIds;

export const getBasketVehicleIds = createSelector(
  [getBasketVehicleIdsStates],
  basketVehicleIds => {
    return basketVehicleIds;
  }
);

const getEditVehicleStates = state => state.vehicles.editVehicle;

export const getEditVehicle = createSelector(
  [getEditVehicleStates],
  editVehicle => {
    return editVehicle;
  }
);

export const getToken = () => {
  const cookies = new Cookies();
  return cookies.get('token');
};

const getSidebarCollapsedStates = state => state.status.sidebarCollapsed;

export const getSidebarCollapsed = createSelector(
  [getSidebarCollapsedStates],
  collapsed => {
    return collapsed;
  }
);

const getUserState = state => state.user.user;

export const getUser = createSelector(
  [getUserState],
  user => {
    return user;
  }
);

const getAllUserStatesAdmin = state => state.admin.allUsers;

export const getAllUsers = createSelector(
  [getAllUserStatesAdmin],
  allUsers => {
    return allUsers;
  }
);

const getAllDealerStatesAdmin = state => state.admin.allDealers;

export const getAllDealers = createSelector(
  [getAllDealerStatesAdmin],
  allDealers => {
    return allDealers;
  }
);

const getBidDataState = state => state.user.bidData;

export const getBidData = createSelector(
  [getBidDataState],
  bidData => {
    return bidData;
  }
);

export const checkLoggedIn = createSelector(
  [getUserState],
  user => {
    const cookies = new Cookies();
    if (cookies.get('token') && user) {
      return true;
    } else {
      return false;
    }
  }
);

export const checkRemembered = createSelector(
  [getUserState],
  account => {
    const cookies = new Cookies();
    if (cookies.get('remember') === 'true' && account) {
      return true;
    } else {
      return false;
    }
  }
);

// LOCALE
const getLocaleStates = state => state.locale;

export const getLocale = createSelector(
  [getLocaleStates],
  locale => {
    return locale;
  }
);
