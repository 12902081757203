import React from 'react';
import styled from 'styled-components';
import {
  Icon,
  Row,
  Col,
  Spin,
  Button,
  Modal,
  Tabs,
  Descriptions,
  Rate,
  Carousel,
  Input
} from 'antd';
import moment from 'moment';
import Notify from 'src/components/meta/Notification';
import noImage from 'src/static/icons/carImageUpload/no-image-available.svg';
import Countdown from 'react-countdown-now';
const numeral = require('numeral');
const { TabPane } = Tabs;
const hammer = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6475 17.3418H1.65195C0.740018 17.3418 0 18.0817 0 18.9938V21.9977H14.2994V18.9938C14.2994 18.0817 13.5595 17.3418 12.6475 17.3418Z"
      fill="#AD5E00"
    />
    <path
      d="M21.7036 17.5744L11.6665 7.53725L13.6051 5.60203C13.9248 5.88391 14.4163 5.87013 14.7188 5.56766C15.0351 5.248 15.0351 4.7358 14.7188 4.41614L10.539 0.239743C10.2228 -0.0799143 9.70715 -0.0799143 9.39094 0.239743C9.07128 0.555998 9.07128 1.0716 9.39094 1.38781L9.35657 1.35344L4.04924 6.65728L4.08361 6.69505C3.76736 6.37539 3.25175 6.37539 2.93554 6.69505C2.61589 7.01131 2.61589 7.52691 2.93554 7.84317L7.11194 12.023C7.4316 12.3392 7.9472 12.3392 8.26346 12.023C8.57971 11.7033 8.57971 11.1912 8.26346 10.8715L8.29783 10.9058L10.2365 8.9706L20.2702 19.0043C20.6655 19.3996 21.3083 19.3996 21.7036 19.0043C22.0989 18.609 22.0989 17.9697 21.7036 17.5744Z"
      fill="#AD5E00"
    />
  </svg>
);

const CarouselStyled = styled(Carousel)`
  .slick-dots li button {
    background: black !important;
  }
  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    margin: auto !important;
  }
`;
class VehicleDetails extends React.Component {
  componentWillMount = () => {
    this.setState({ loading: true });
    let id = this.props.vehicleDetailsId;
    if (id.split('-')[1]) {
      id = id.split('-')[0];
      this.setState({ fromHistory: true });
    }
    this.props
      .findVehicleDetails(id)
      .then(() => {
        this.props
          .findBidData()
          .then(() => {
            let closed = false;
            let vehicleDetails = this.props.vehicleDetails;
            if (
              vehicleDetails.auctionDate.start !== null ||
              vehicleDetails.auctionDate.end !== null
            ) {
              if (
                parseInt(moment().format('YYYYMMDD')) <
                  vehicleDetails.auctionDate.start ||
                parseInt(moment().format('YYYYMMDD')) >
                  vehicleDetails.auctionDate.end
              ) {
                closed = true;
              }
            }
            let vehicleBidState = {
              bidAmount: vehicleDetails.reserve ? vehicleDetails.reserve : 'R0',
              autoBidAmount: 'R0',
              customBidAmount: undefined,
              customBidLow: true,
              yourBidAmount: 'R0',
              disableReduce: true,
              bidLow: true,
              autoBidLow: true,
              autoBidActive: false,
              previousBid: undefined,
              closed: closed,
              nextAuction: undefined,
              auctionStart: undefined
            };
            const yourBidData = this.props.bidData.bids;
            for (var prop in yourBidData) {
              if (prop === vehicleDetails._id) {
                vehicleBidState.yourBidAmount = this.formatNumber(
                  yourBidData[prop]
                );
              }
            }
            this.setState(vehicleBidState, () => {
              this.props
                .updateBid({ vehicle: id })
                .then(() => {
                  this.setState({
                    timeStamp: this.props.timeStamp,
                    loading: false
                  });
                  if (this.props.vehicleDetails.reserve) {
                    if (
                      this.parseString(this.props.vehicleDetails.reserve) <=
                      this.parseString(this.state.bidAmount) + 1000
                    ) {
                      {
                        this.setState({
                          autoBidAmount:
                            'R' +
                            (this.parseString(
                              this.props.vehicleDetails.reserve
                            ) +
                              1000),
                          bidAmount:
                            'R' +
                            (this.parseString(
                              this.props.vehicleDetails.reserve
                            ) +
                              1000),
                          bidLow: false
                        });
                      }
                    }
                  }
                  if (
                    this.parseString(this.state.bidAmount) <=
                    this.props.vehicleDetails.highestBid + 1000
                  ) {
                    this.setState({
                      autoBidAmount:
                        'R' + (this.props.vehicleDetails.highestBid + 1000),
                      bidAmount:
                        'R' + (this.props.vehicleDetails.highestBid + 1000),
                      bidLow: false
                    });
                  }
                  if (
                    this.props.vehicleDetails.highestBidder ===
                    this.props.user._id
                  ) {
                    this.setState({
                      timeStamp: this.props.timeStamp,
                      highestBid: this.formatNumber(
                        this.props.vehicleDetails.highestBid
                      ),
                      yourBidAmount: this.formatNumber(
                        this.props.vehicleDetails.highestBid
                      )
                    });
                  } else {
                    this.setState({
                      timeStamp: this.props.timeStamp,
                      highestBid: this.formatNumber(
                        this.props.vehicleDetails.highestBid
                      )
                    });
                  }
                  const timeLeft = this.timeStampTime(
                    this.props.vehicleDetails
                  ).valueOf();
                  const currentTime = moment(this.props.timeStamp).valueOf();
                  const timeDifference = (timeLeft - currentTime) / 60 / 1000;
                  if (timeDifference <= 0) {
                    this.setState({
                      closed: true
                    });
                  }
                  if (this.props.vehicleDetails.auctionDate.end !== null) {
                    if (
                      moment(
                        '' + this.props.vehicleDetails.auctionDate.end
                      ).isAfter(moment(this.props.timeStamp)) &&
                      this.state.closed
                    ) {
                      this.setState({
                        nextAuction: moment(
                          '' + this.props.vehicleDetails.auctionDate.end
                        ).format('YYYY-MM-DD'),
                        closed: true
                      });
                    }
                  }
                  if (this.props.vehicleDetails.auctionDate.start !== null) {
                    if (
                      moment(
                        '' + this.props.vehicleDetails.auctionDate.start
                      ).isAfter(moment(this.props.timeStamp))
                    ) {
                      this.setState({
                        auctionStart: moment(
                          '' + this.props.vehicleDetails.auctionDate.start
                        ).format('YYYY-MM-DD'),
                        closed: true
                      });
                    }
                  }
                })
                .catch(e => {
                  this.setState({ loading: false });
                  Notify('error', e);
                });
            });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  updateBidSignal;
  updateTimeInterval;
  componentDidMount = () => {
    this.updateBidSignal = {
      signal: msg => {
        let id = this.props.vehicleDetailsId;
        if (id.split('-')[1]) {
          id = id.split('-')[0];
        }
        this.props
          .updateBid({ vehicle: id })
          .then(() => {
            this.setState({ timeStamp: this.props.timeStamp });
            if (
              this.parseString(this.state.bidAmount) <=
              this.props.vehicleDetails.highestBid + 1000
            ) {
              this.setState({
                bidAmount: 'R' + (this.props.vehicleDetails.highestBid + 1000),
                bidLow: false
              });
            }
            if (
              this.props.vehicleDetails.highestBidder === this.props.user._id
            ) {
              this.setState({
                timeStamp: this.props.timeStamp,
                highestBid: this.formatNumber(
                  this.props.vehicleDetails.highestBid
                ),
                yourBidAmount: this.formatNumber(
                  this.props.vehicleDetails.highestBid
                )
              });
            } else {
              this.setState({
                timeStamp: this.props.timeStamp,
                highestBid: this.formatNumber(
                  this.props.vehicleDetails.highestBid
                )
              });
            }
            const timeLeft = this.timeStampTime(
              this.props.vehicleDetails
            ).valueOf();
            const currentTime = moment(this.props.timeStamp).valueOf();
            const timeDifference = (timeLeft - currentTime) / 60 / 1000;
            if (timeDifference <= 0) {
              this.setState({
                closed: true
              });
            }
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      }
    };
    window.pubnub.addListener(this.updateBidSignal);
    this.updateTimeInterval = setInterval(() => {
      this.setState({
        timeStamp: moment(this.state.timeStamp)
          .add(1, 'second')
          .valueOf()
      });
    }, 1000);
  };

  componentWillUnmount = () => {
    window.pubnub.removeListener(this.updateBidSignal);
    clearInterval(this.updateTimeInterval);
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      highestBid: 'R0',
      bidAmount: 'R0',
      autoBidAmount: 'R0',
      customBidAmount: undefined,
      customBidLow: true,
      yourBidAmount: 'R0',
      disableReduce: true,
      bidLow: true,
      autoBidLow: true,
      autoBidActive: false,
      previousBid: undefined,
      closed: false
    };
  }

  parseString = string => {
    return parseInt(string.split('R')[1].replace(/,/g, ''));
  };
  formatNumber = number => {
    return 'R' + numeral(number).format('0,0');
  };
  handleBid = e => {
    this.setState({ loading: true });
    let id = this.props.vehicleDetailsId;
    if (id.split('-')[1]) {
      id = id.split('-')[0];
    }
    let vehicle = this.props.vehicleDetails;
    const bidAmount = this.parseString(this.state.bidAmount);
    const timeLeft = this.timeStampTime(vehicle).valueOf();
    const currentTime = moment(this.state.timeStamp).valueOf();
    const timeDifference = (timeLeft - currentTime) / 60 / 1000;
    if (timeDifference > 0 && timeDifference < 5) {
      vehicle.addAuctionMinutes =
        vehicle.addAuctionMinutes + (5 - timeDifference);
    }
    this.props
      .placeBid({
        id: id,
        bidAmount: bidAmount,
        addAuctionMinutes: vehicle.addAuctionMinutes
      })
      .then(r => {
        this.setState({
          loading: false,
          yourBidAmount: this.formatNumber(this.state.bidAmount),
          bidAmount: this.formatNumber(
            this.parseString(this.state.bidAmount) + 1000
          )
        });
        Notify('success', 'Success', r);
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };

  handleCustomBid = e => {
    this.setState({ loading: true });
    let id = this.props.vehicleDetailsId;
    if (id.split('-')[1]) {
      id = id.split('-')[0];
    }
    let vehicle = this.props.vehicleDetails;
    const bidAmount = this.parseString(this.state.customBidAmount);
    const timeLeft = this.timeStampTime(vehicle).valueOf();
    const currentTime = moment(this.state.timeStamp).valueOf();
    const timeDifference = (timeLeft - currentTime) / 60 / 1000;
    if (timeDifference > 0 && timeDifference < 5) {
      vehicle.addAuctionMinutes =
        vehicle.addAuctionMinutes + (5 - timeDifference);
    }
    this.props
      .placeBid({
        id: id,
        bidAmount: bidAmount,
        addAuctionMinutes: vehicle.addAuctionMinutes
      })
      .then(r => {
        this.setState({
          loading: false,
          yourBidAmount: this.formatNumber(this.state.customBidAmount),
          customBidLow: true
        });
        Notify('success', 'Success', r);
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  autoBidInterval;
  handleAutoBid = e => {
    this.setState({ autoBidActive: !this.state.autoBidActive });
    this.autoBidInterval = setInterval(() => {
      if (!this.state.autoBidActive) {
        clearInterval(this.autoBidInterval);
      }
      if (
        this.state.closed ||
        this.parseString(this.state.autoBidAmount) ===
          this.parseString(this.state.yourBidAmount)
      ) {
        this.setState({ autoBidLow: true, autoBidActive: false });
        clearInterval(this.autoBidInterval);
      } else if (
        this.parseString(this.state.highestBid) >
        this.parseString(this.state.yourBidAmount)
      ) {
        let id = this.props.vehicleDetailsId;
        if (id.split('-')[1]) {
          id = id.split('-')[0];
        }
        const bidAmount = this.parseString(this.state.highestBid) + 1000;
        if (this.parseString(this.state.autoBidAmount) > bidAmount) {
          this.setState({ autoBidLow: true, autoBidActive: false });
          clearInterval(this.autoBidInterval);
        } else {
          this.setState({ loading: true });
          this.props
            .placeBid({ id: id, bidAmount: bidAmount })
            .then(r => {
              this.setState({
                loading: false,
                yourBidAmount: this.formatNumber(bidAmount)
              });
              Notify('success', 'Success', r);
            })
            .catch(e => {
              this.setState({ loading: false });
              Notify('error', e);
            });
        }
      }
    }, 500);
  };

  handleAddBasket = e => {
    e.stopPropagation();
    this.props
      .addBasketVehicle(e.currentTarget.getAttribute('data'))
      .then(() => {
        this.setState({ loading: false });
        Notify('success', 'Added vehicle to basket');
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };

  handleRemoveBasket = e => {
    e.stopPropagation();
    let id = e.currentTarget.getAttribute('data');
    this.props
      .removeBasketVehicle(id)
      .then(() => {
        this.setState({ loading: false });
        Notify('success', 'Removed vehicle from basket');
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };

  removeBidIncrement = e => {
    const currentBid = parseInt(this.parseString(this.state.bidAmount));
    let highestBid;
    if (this.props.vehicleDetails.reserve) {
      if (
        this.parseString(this.props.vehicleDetails.reserve) >
        this.parseString(this.state.highestBid)
      ) {
        highestBid = this.parseString(this.props.vehicleDetails.reserve);
      } else {
        highestBid = this.parseString(this.state.highestBid);
      }
    } else {
      highestBid = this.parseString(this.state.highestBid);
    }
    if (currentBid - 1000 !== highestBid) {
      if (currentBid - 2000 === highestBid) {
        this.setState({
          bidAmount:
            'R' +
            numeral(
              parseInt(this.state.bidAmount.split('R')[1].replace(/,/g, '')) -
                1000
            ).format('0,0'),
          disableReduce: true
        });
      } else {
        this.setState({
          bidAmount:
            'R' +
            numeral(
              parseInt(this.state.bidAmount.split('R')[1].replace(/,/g, '')) -
                1000
            ).format('0,0')
        });
      }
    } else {
      this.setState({ disableReduce: true });
    }
  };

  addBidIncrement = e => {
    this.setState({
      bidAmount:
        'R' +
        numeral(
          parseInt(this.state.bidAmount.split('R')[1].replace(/,/g, '')) + 1000
        ).format('0,0'),
      disableReduce: false
    });
    if (this.props.vehicleDetails.reserve) {
      if (
        this.parseString(this.props.vehicleDetails.reserve) >
        this.parseString(this.state.bidAmount)
      ) {
        {
          this.setState({
            bidLow: true
          });
        }
      }
    }
    if (
      parseInt(this.state.highestBid.split('R')[1].replace(/,/g, '')) >
      parseInt(this.state.bidAmount.split('R')[1].replace(/,/g, ''))
    ) {
      this.setState({
        bidLow: true
      });
    } else {
      this.setState({
        bidLow: false
      });
    }
  };

  removeAutoBidIncrement = e => {
    const currentBid = parseInt(
      this.state.autoBidAmount.split('R')[1].replace(/,/g, '')
    );

    let highestBid;
    if (this.props.vehicleDetails.reserve) {
      if (
        this.parseString(this.props.vehicleDetails.reserve) >
        this.parseString(this.state.highestBid)
      ) {
        highestBid = this.parseString(this.props.vehicleDetails.reserve);
      } else {
        highestBid = this.parseString(this.state.highestBid);
      }
    } else {
      highestBid = this.parseString(this.state.highestBid);
    }
    if (currentBid - 1000 !== highestBid) {
      if (currentBid - 2000 === highestBid) {
        this.setState({
          autoBidAmount:
            'R' +
            numeral(
              parseInt(
                this.state.autoBidAmount.split('R')[1].replace(/,/g, '')
              ) - 1000
            ).format('0,0'),
          disableReduce: true
        });
      } else {
        this.setState({
          autoBidAmount:
            'R' +
            numeral(
              parseInt(
                this.state.autoBidAmount.split('R')[1].replace(/,/g, '')
              ) - 1000
            ).format('0,0')
        });
      }
    } else {
      this.setState({ disableReduce: true });
    }
  };

  addAutoBidIncrement = e => {
    this.setState({
      autoBidAmount:
        'R' +
        numeral(
          parseInt(this.state.autoBidAmount.split('R')[1].replace(/,/g, '')) +
            1000
        ).format('0,0'),
      disableReduce: false
    });
    if (this.props.vehicleDetails.reserve) {
      if (
        this.parseString(this.props.vehicleDetails.reserve) >
        this.parseString(this.state.autoBidAmount)
      ) {
        {
          this.setState({
            autoBidLow: true
          });
        }
      }
    }
    if (
      parseInt(this.state.highestBid.split('R')[1].replace(/,/g, '')) >
      parseInt(this.state.autoBidAmount.split('R')[1].replace(/,/g, ''))
    ) {
      this.setState({
        autoBidLow: true
      });
    } else {
      this.setState({
        autoBidLow: false
      });
    }
  };
  largeCarouselRef = React.createRef();
  carouselRef = React.createRef();
  largeImage = e => {
    const { vehicleDetails } = this.props;
    const index = e.currentTarget.getAttribute('data');
    Modal.info({
      centered: true,
      width: '60vw',
      height: '60vh',
      okText: 'Close',
      content: (
        <div style={{ position: 'relative', left: -18 }}>
          <div
            style={{
              display: 'flex',
              position: 'absolute',
              top: 0,
              height: '100%'
            }}
          >
            <Icon
              onClick={e => this.largeCarouselRef.current.prev()}
              style={{
                margin: 'auto',
                fontSize: 50,
                zIndex: 999999,
                cursor: 'pointer'
              }}
              type="caret-left"
            />
          </div>

          <CarouselStyled ref={this.largeCarouselRef}>
            <div style={{ display: 'flex' }}>
              <img
                alt="Main"
                style={{ margin: 'auto' }}
                src={
                  vehicleDetails.mainImage
                    ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                      vehicleDetails.mainImage
                    : noImage
                }
              />
            </div>

            <div style={{ display: 'flex' }}>
              <img
                alt="Front"
                style={{ margin: 'auto' }}
                src={
                  vehicleDetails.frontImage
                    ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                      vehicleDetails.frontImage
                    : noImage
                }
              />
            </div>

            <div style={{ display: 'flex' }}>
              <img
                alt="Left"
                style={{ margin: 'auto' }}
                src={
                  vehicleDetails.leftImage
                    ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                      vehicleDetails.leftImage
                    : noImage
                }
              />
            </div>

            <div style={{ display: 'flex' }}>
              <img
                alt="Right"
                style={{ margin: 'auto' }}
                src={
                  vehicleDetails.rightImage
                    ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                      vehicleDetails.rightImage
                    : noImage
                }
              />
            </div>

            <div style={{ display: 'flex' }}>
              <img
                alt="Back"
                style={{ margin: 'auto' }}
                src={
                  vehicleDetails.backImage
                    ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                      vehicleDetails.backImage
                    : noImage
                }
              />
            </div>

            <div style={{ display: 'flex' }}>
              <img
                alt="Interior"
                style={{ margin: 'auto' }}
                src={
                  vehicleDetails.interiorImage
                    ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                      vehicleDetails.interiorImage
                    : noImage
                }
              />
            </div>

            <div style={{ display: 'flex' }}>
              <img
                alt="Engine"
                style={{ margin: 'auto' }}
                src={
                  vehicleDetails.engineImage
                    ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                      vehicleDetails.engineImage
                    : noImage
                }
              />
            </div>

            <div style={{ display: 'flex' }}>
              <img
                alt="Boot"
                style={{ margin: 'auto' }}
                src={
                  vehicleDetails.bootImage
                    ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                      vehicleDetails.bootImage
                    : noImage
                }
              />
            </div>

            <div style={{ display: 'flex' }}>
              <img
                alt="Service1"
                style={{ margin: 'auto' }}
                src={
                  vehicleDetails.service1Image
                    ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                      vehicleDetails.service1Image
                    : noImage
                }
              />
            </div>

            <div style={{ display: 'flex' }}>
              <img
                alt="Service2"
                style={{ margin: 'auto' }}
                src={
                  vehicleDetails.service2Image
                    ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                      vehicleDetails.service2Image
                    : noImage
                }
              />
            </div>
          </CarouselStyled>

          <div
            style={{
              display: 'flex',
              position: 'absolute',
              top: 0,
              right: 0,
              height: '100%'
            }}
          >
            <Icon
              onClick={e => this.largeCarouselRef.current.next()}
              style={{
                margin: 'auto',
                fontSize: 50,
                zIndex: 999999,
                cursor: 'pointer'
              }}
              type="caret-right"
            />
          </div>
        </div>
        // <div style={{ display: 'flex', position: 'relative', left: -18 }}>
        //   <img alt="Expanded" style={{ margin: 'auto' }} src={image} />
        // </div>
      ),
      onOk() {}
    });
    setTimeout(() => {
      this.largeCarouselRef.current.goTo(index - 1, true);
    }, 100);
  };
  previousBid;
  handleCustomBidAmount = e => {
    let value;
    var that = this;
    if (this.previousBid) {
      let check1;
      if (this.previousBid === '0') {
        check1 =
          'R' +
          numeral(parseInt(this.previousBid.replace(/,/g, ''))).format('0,0');
      } else {
        check1 =
          'R' +
          numeral(parseInt(this.previousBid.replace(/,/g, ''))).format('0,0') +
          '000';
      }
      const check2 = e.currentTarget.value.replace(/[,]/g, '');
      if (check1.length > check2.length) {
        if (check2.length <= 4) {
          this.previousBid = '0';
        } else {
          this.previousBid = this.previousBid.substr(
            0,
            this.previousBid.length - 1
          );
        }
      } else {
        this.previousBid =
          this.previousBid +
          e.currentTarget.value.substr(e.currentTarget.value.length - 1, 1);
      }
      value = this.previousBid + '000';
    } else {
      this.previousBid = e.currentTarget.value;
      value = e.currentTarget.value + '000';
    }
    let check3 = parseInt(
      this.state.highestBid.split('R')[1].replace(/,/g, '')
    );
    if (this.props.vehicleDetails.reserve) {
      debugger;
      if (check3 < this.parseString(this.props.vehicleDetails.reserve)) {
        check3 = this.parseString(this.props.vehicleDetails.reserve);
      }
    }

    const check4 = value.split('R')[1]
      ? parseInt(value.split('R')[1].replace(/,/g, ''))
      : parseInt(value.replace(/,/g, ''));
    if (check3 >= check4) {
      this.setState({
        customBidAmount:
          'R' + numeral(parseInt(value.replace(/,/g, ''))).format('0,0'),
        customBidLow: true
      });
    } else {
      this.setState({
        customBidAmount:
          'R' + numeral(parseInt(value.replace(/,/g, ''))).format('0,0'),
        customBidLow: false
      });
    }
  };
  timeStampTime = vehicle => {
    let time = moment(this.props.timeStamp)
      .hour(parseInt(this.props.customTime.timeEnd.split(':')[0]))
      .minute(parseInt(this.props.customTime.timeEnd.split(':')[1]))
      .second(parseInt(this.props.customTime.timeEnd.split(':')[2]));
    time.add(vehicle.addAuctionMinutes, 'minutes');
    return time;
  };
  processComplete = () => {
    if (this.props.vehicleDetails.auctionDate.end !== null) {
      if (
        moment('' + this.props.vehicleDetails.auctionDate.end).isAfter(
          moment(this.props.timeStamp)
        )
      ) {
        this.setState({
          nextAuction: moment(
            '' + this.props.vehicleDetails.auctionDate.end
          ).format('YYYY-MM-DD'),
          closed: true
        });
      }
    } else {
      this.setState({
        closed: true
      });
    }
  };
  render() {
    const { vehicleDetails } = this.props;
    if (this.state.highestBid) {
      return (
        <div>
          <Row
            type="flex"
            justify="start"
            align="middle"
            className="hidden-comps-mobile"
          >
            <Col span={1}>
              <Icon
                style={{ fontSize: 30, color: '#00888e' }}
                onClick={e => this.props.goBack()}
                type="arrow-left"
              />
            </Col>
            <Col span={12}>
              <Icon
                style={{ fontSize: 36, color: '#00888E', marginRight: 20 }}
                type="car"
                theme="filled"
              />
              <span style={{ fontWeight: 750, fontSize: 36 }}>
                {window.i18n.translate('VehicleDetails')}
              </span>
            </Col>
          </Row>
          <Spin spinning={this.state.loading}>
            <Row
              type="flex"
              justify="space-between"
              align="top"
              className="hidden-comps-mobile"
            >
              <Col span={18}>
                <Tabs>
                  <TabPane
                    style={{ background: 'white' }}
                    tab={window.i18n.translate('General')}
                    key="1"
                  >
                    <Descriptions
                      bordered
                      column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                    >
                      <Descriptions.Item span={1} label="ID">
                        {vehicleDetails.displayId}
                      </Descriptions.Item>

                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Condition')}
                      >
                        {vehicleDetails.condition === '' ? (
                          window.i18n.translate('NotProvided')
                        ) : (
                          <Rate
                            count={10}
                            defaultValue={parseInt(vehicleDetails.condition)}
                            disabled={true}
                          />
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Year')}
                      >
                        {vehicleDetails.year === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.year}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Vehicle')}
                      >
                        {vehicleDetails.make +
                          ' ' +
                          vehicleDetails.model +
                          ' ' +
                          vehicleDetails.variant}
                      </Descriptions.Item>
                      <Descriptions.Item span={1} label="km">
                        {vehicleDetails.mileage === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.mileage}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('ServiceHistory')}
                      >
                        {vehicleDetails.serviceHistory === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.serviceHistory}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('MMCode')}
                      >
                        {vehicleDetails.mmCode === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.mmCode}
                      </Descriptions.Item>
                      {/* <Descriptions.Item span={1} label="Customer Asking Price">
                        {vehicleDetails.price === ''
                          ? 'Not Provided'
                          : vehicleDetails.price}
                      </Descriptions.Item> */}
                      {/* <Descriptions.Item span={1} label="Reserve Price">
                        {vehicleDetails.reserve === ''
                          ? 'Not Provided'
                          : vehicleDetails.reserve}
                      </Descriptions.Item> */}
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('TradePrice')}
                      >
                        {vehicleDetails.valuation &&
                        this.props.user.canSeePrices
                          ? 'R' +
                            numeral(
                              vehicleDetails.valuation['s:Envelope']['s:Body']
                                .GetConvergedDataRequestResponse.ConvergedData[
                                'd4p1:VehicleValueInfo'
                              ]['d4p1:VehicleValue']['d4p1:TradePrice']['_text']
                            ).format('0,0')
                          : 'N/A'}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('RetailPrice')}
                      >
                        {vehicleDetails.valuation &&
                        this.props.user.canSeePrices
                          ? 'R' +
                            numeral(
                              vehicleDetails.valuation['s:Envelope']['s:Body']
                                .GetConvergedDataRequestResponse.ConvergedData[
                                'd4p1:VehicleValueInfo'
                              ]['d4p1:VehicleValue']['d4p1:RetailPrice'][
                                '_text'
                              ]
                            ).format('0,0')
                          : 'N/A'}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('NewPrice')}
                      >
                        {vehicleDetails.valuation &&
                        this.props.user.canSeePrices
                          ? 'R' +
                            numeral(
                              vehicleDetails.valuation['s:Envelope']['s:Body']
                                .GetConvergedDataRequestResponse.ConvergedData[
                                'd4p1:VehicleValueInfo'
                              ]['d4p1:VehicleValue']['d4p1:NewListPrice'][
                                '_text'
                              ]
                            ).format('0,0')
                          : 'N/A'}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Colour')}
                      >
                        {vehicleDetails.colour === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.colour}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Owners')}
                      >
                        {vehicleDetails.owners === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.owners}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Transmission')}
                      >
                        {vehicleDetails.transmission === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.transmission}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('FuelType')}
                      >
                        {vehicleDetails.fuel === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.fuel}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('CityTown')}
                      >
                        {vehicleDetails.city === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.city}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Province')}
                      >
                        {vehicleDetails.province === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.province}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Warranty')}
                      >
                        {vehicleDetails.warranty === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.warranty}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('ServiceMaintenance')}
                      >
                        {vehicleDetails.servicePlan === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.servicePlan}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('LicenseExpiry')}
                      >
                        {vehicleDetails.licenseExpire === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.licenseExpire}
                      </Descriptions.Item>
                      {/* <Descriptions.Item span={1} label="Selling Reason">
                        {vehicleDetails.sellingReason === ''
                          ? 'Not Provided'
                          : vehicleDetails.sellingReason}
                      </Descriptions.Item> */}
                    </Descriptions>
                  </TabPane>
                  <TabPane
                    style={{ background: 'white' }}
                    tab={window.i18n.translate('Recon')}
                    key="2"
                  >
                    <Descriptions
                      bordered
                      column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                      layout="vertical"
                    >
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('SpareKeys1')}
                      >
                        {vehicleDetails.spareKeys === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.spareKeys}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('MechanicalIssues1')}
                      >
                        {vehicleDetails.mechanicalIssues === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.mechanicalIssues}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('PreviousBodyWork')}
                      >
                        {vehicleDetails.bodyWork === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.bodyWork}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('WindscreenCracks1')}
                      >
                        {vehicleDetails.windscreenDamage === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.windscreenDamage}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('MagsRimsDamage')}
                      >
                        {vehicleDetails.magsDamage === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.magsDamage}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('FrontTyres')}
                      >
                        {vehicleDetails.frontTyres === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.frontTyres}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('BackTyres')}
                      >
                        {vehicleDetails.backTyres === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.backTyres}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        style={{ display: 'flex' }}
                        label={window.i18n.translate('Damage')}
                      >
                        <div
                          data={vehicleDetails.damage}
                          onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Left"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.damage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.damage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                    </Descriptions>
                  </TabPane>
                  <TabPane
                    style={{ background: 'white' }}
                    tab={window.i18n.translate('Images')}
                    key="3"
                  >
                    <Descriptions
                      bordered
                      column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                      layout="vertical"
                    >
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('MainView')}
                      >
                        <div
                          data={1}
                          onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Main"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.mainImage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.mainImage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('FrontView')}
                      >
                        <div
                          data={2}
                          onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Front"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.frontImage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.frontImage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('LeftView')}
                      >
                        <div
                          data={3}
                          onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Left"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.leftImage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.leftImage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('RightView')}
                      >
                        <div
                          data={4}
                          onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Right"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.rightImage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.rightImage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('RearView')}
                      >
                        <div
                          data={5}
                          onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Back"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.backImage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.backImage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Interior')}
                      >
                        <div
                          data={6}
                          onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Interior"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.interiorImage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.interiorImage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Engine')}
                      >
                        <div
                          data={7}
                          onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Engine"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.engineImage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.engineImage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Boot')}
                      >
                        <div
                          data={8}
                          onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Boot"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.bootImage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.bootImage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('ServiceBook') + ' ' + '1'}
                      >
                        <div
                          data={9}
                          onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Service1"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.service1Image
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.service1Image
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('ServiceBook') + ' ' + '2'}
                      >
                        <div
                          data={10}
                          onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Service2"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.service2Image
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.service2Image
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                    </Descriptions>
                  </TabPane>
                </Tabs>
              </Col>
              <Col span={5}>
                <Tabs
                  style={{ display: this.state.fromHistory ? 'none' : 'block' }}
                >
                  <TabPane style={{ background: 'white' }} tab="Bid" key="1">
                    <div
                      style={{
                        margin: 'auto',
                        textAlign: 'center',
                        padding: 10
                      }}
                    >
                      <Row style={{ marginBottom: 10 }}>
                        <span style={{ fontWeight: 600, fontSize: 15 }}>
                          Highest Bid: R
                          {numeral(this.state.highestBid).format('0,0')}
                        </span>
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <span
                          style={{
                            fontWeight: 600,
                            fontSize: 15,
                            color:
                              this.parseString(this.state.yourBidAmount) ===
                              this.parseString(this.state.highestBid)
                                ? 'green'
                                : 'red'
                          }}
                        >
                          Your Bid: R
                          {numeral(this.state.yourBidAmount).format('0,0')}
                        </span>
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <span style={{ fontWeight: 600, fontSize: 15 }}>
                          Bid Amount: R
                          {numeral(this.state.bidAmount).format('0,0')}
                        </span>
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <Button
                          onClick={this.addBidIncrement}
                          style={{ marginRight: 10 }}
                          type="primary"
                          shape="circle"
                          icon="plus"
                        />
                        <Button
                          disabled={this.state.disableReduce}
                          onClick={this.removeBidIncrement}
                          shape="circle"
                          icon="minus"
                        />
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <Button
                          disabled={this.state.closed || this.state.bidLow}
                          data={vehicleDetails._id}
                          onClick={this.handleBid}
                          style={{
                            background: '#E5E5E5',
                            width: 112,
                            margin: 'auto',
                            color: '#6D6E70'
                          }}
                        >
                          <Icon
                            style={{ color: '#6D6E70' }}
                            theme="filled"
                            component={hammer}
                          />
                          Place Bid
                        </Button>
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <Input
                          style={{ width: 150 }}
                          value={this.state.customBidAmount}
                          onChange={this.handleCustomBidAmount}
                          placeholder="Custom bid amount"
                        />
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <Button
                          disabled={
                            this.state.closed || this.state.customBidLow
                          }
                          data={vehicleDetails._id}
                          onClick={this.handleCustomBid}
                          style={{
                            background: '#E5E5E5',
                            width: 160,
                            margin: 'auto',
                            color: '#6D6E70'
                          }}
                        >
                          <Icon
                            style={{ color: '#6D6E70' }}
                            theme="filled"
                            component={hammer}
                          />
                          Place Custom Bid
                        </Button>
                      </Row>
                      {this.state.auctionStart ? (
                        <div>
                          <h4>Auction Starts</h4>
                          <h4>{this.state.auctionStart}</h4>
                        </div>
                      ) : this.state.nextAuction ? (
                        <div>
                          <h4>Next Auction</h4>
                          <h4>{this.state.nextAuction}</h4>
                        </div>
                      ) : (this.props.customTime &&
                          !this.props.customTime.status) ||
                        this.state.closed ? (
                        'Closed'
                      ) : (
                        <div>
                          <h4>Time Left</h4>
                          <Countdown
                            onComplete={e => this.processComplete()}
                            style={{ fontSize: '1.5em' }}
                            date={this.timeStampTime(vehicleDetails).valueOf()}
                            now={e => moment(this.state.timeStamp).valueOf()}
                          />
                        </div>
                      )}
                    </div>
                  </TabPane>
                  <TabPane
                    style={{ background: 'white' }}
                    tab="Auto Bid"
                    key="2"
                  >
                    <div
                      style={{
                        margin: 'auto',
                        textAlign: 'center',
                        padding: 10
                      }}
                    >
                      <Row style={{ marginBottom: 10 }}>
                        <span style={{ fontWeight: 600, fontSize: 15 }}>
                          Highest Bid: R
                          {numeral(this.state.highestBid).format('0,0')}
                        </span>
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <span
                          style={{
                            fontWeight: 600,
                            fontSize: 15,
                            color:
                              this.parseString(this.state.yourBidAmount) ===
                              this.parseString(this.state.highestBid)
                                ? 'green'
                                : 'red'
                          }}
                        >
                          Your Bid: R
                          {numeral(this.state.yourBidAmount).format('0,0')}
                        </span>
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <span style={{ fontWeight: 600, fontSize: 15 }}>
                          Bid Until Amount: R
                          {numeral(this.state.autoBidAmount).format('0,0')}
                        </span>
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <Button
                          onClick={this.addAutoBidIncrement}
                          style={{ marginRight: 10 }}
                          type="primary"
                          shape="circle"
                          icon="plus"
                        />
                        <Button
                          disabled={this.state.disableReduce}
                          onClick={this.removeAutoBidIncrement}
                          shape="circle"
                          icon="minus"
                        />
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <Button
                          disabled={this.state.closed || this.state.autoBidLow}
                          data={vehicleDetails._id}
                          onClick={this.handleAutoBid}
                          style={{
                            background: '#E5E5E5',
                            width: 150,
                            margin: 'auto',
                            color: '#6D6E70'
                          }}
                        >
                          <Icon
                            style={{ color: '#6D6E70' }}
                            theme="filled"
                            component={hammer}
                          />
                          {this.state.autoBidActive
                            ? 'Cancel'
                            : 'Place Auto Bid'}
                        </Button>
                      </Row>
                      {this.state.auctionStart ? (
                        <div>
                          <h4>Auction Starts</h4>
                          <h4>{this.state.auctionStart}</h4>
                        </div>
                      ) : this.state.nextAuction ? (
                        <div>
                          <h4>Next Auction</h4>
                          <h4>{this.state.nextAuction}</h4>
                        </div>
                      ) : (this.props.customTime &&
                          !this.props.customTime.status) ||
                        this.state.closed ? (
                        'Closed'
                      ) : (
                        <div>
                          <h4>Time Left</h4>
                          <Countdown
                            onComplete={e => this.processComplete()}
                            style={{ fontSize: '1.5em' }}
                            date={this.timeStampTime(vehicleDetails).valueOf()}
                            now={e => moment(this.state.timeStamp).valueOf()}
                          />
                        </div>
                      )}
                    </div>
                  </TabPane>
                  {/* <TabPane
                    style={{ background: 'white' }}
                    tab="Make Offer"
                    key="3"
                  /> */}
                </Tabs>
                <div
                  style={{
                    margin: 'auto',
                    textAlign: 'center',
                    background: 'white',
                    width: '100%',
                    height: '100%'
                  }}
                >
                  <Button
                    data={vehicleDetails._id}
                    onClick={
                      this.props.user.basket.indexOf(vehicleDetails._id) > -1
                        ? this.handleRemoveBasket
                        : this.handleAddBasket
                    }
                    style={{
                      display: this.state.fromHistory ? 'none' : 'block',
                      background: '#E5E5E5',
                      width: '100%',
                      margin: 'auto',
                      color: '#6D6E70'
                    }}
                  >
                    <Icon style={{ color: '#6D6E70' }} type="shopping-cart" />
                    {this.props.user.basket.indexOf(vehicleDetails._id) > -1
                      ? 'Remove from Basket'
                      : 'Add to Basket'}
                  </Button>
                  <div style={{ position: 'relative' }}>
                    <div
                      style={{
                        display: 'flex',
                        position: 'absolute',
                        top: 0,
                        height: '100%'
                      }}
                    >
                      <Icon
                        onClick={e => this.carouselRef.current.prev()}
                        style={{
                          margin: 'auto',
                          fontSize: 25,
                          zIndex: 9,
                          cursor: 'pointer'
                        }}
                        type="caret-left"
                      />
                    </div>
                    <CarouselStyled ref={this.carouselRef} autoplay={true}>
                      <div
                        data={1}
                        onClick={this.largeImage}
                        style={{ display: 'flex' }}
                      >
                        <img
                          alt="Main"
                          style={{ maxWidth: '80%', margin: 'auto' }}
                          src={
                            vehicleDetails.mainImage
                              ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                vehicleDetails.mainImage
                              : noImage
                          }
                        />
                      </div>

                      <div
                        data={2}
                        onClick={this.largeImage}
                        style={{ display: 'flex' }}
                      >
                        <img
                          alt="Front"
                          style={{ maxWidth: '80%', margin: 'auto' }}
                          src={
                            vehicleDetails.frontImage
                              ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                vehicleDetails.frontImage
                              : noImage
                          }
                        />
                      </div>

                      <div
                        data={3}
                        onClick={this.largeImage}
                        style={{ display: 'flex' }}
                      >
                        <img
                          alt="Left"
                          style={{ maxWidth: '80%', margin: 'auto' }}
                          src={
                            vehicleDetails.leftImage
                              ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                vehicleDetails.leftImage
                              : noImage
                          }
                        />
                      </div>

                      <div
                        data={4}
                        onClick={this.largeImage}
                        style={{ display: 'flex' }}
                      >
                        <img
                          alt="Right"
                          style={{ maxWidth: '80%', margin: 'auto' }}
                          src={
                            vehicleDetails.rightImage
                              ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                vehicleDetails.rightImage
                              : noImage
                          }
                        />
                      </div>

                      <div
                        data={5}
                        onClick={this.largeImage}
                        style={{ display: 'flex' }}
                      >
                        <img
                          alt="Back"
                          style={{ maxWidth: '80%', margin: 'auto' }}
                          src={
                            vehicleDetails.backImage
                              ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                vehicleDetails.backImage
                              : noImage
                          }
                        />
                      </div>

                      <div
                        data={6}
                        onClick={this.largeImage}
                        style={{ display: 'flex' }}
                      >
                        <img
                          alt="Interior"
                          style={{ maxWidth: '80%', margin: 'auto' }}
                          src={
                            vehicleDetails.interiorImage
                              ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                vehicleDetails.interiorImage
                              : noImage
                          }
                        />
                      </div>

                      <div
                        data={7}
                        onClick={this.largeImage}
                        style={{ display: 'flex' }}
                      >
                        <img
                          alt="Engine"
                          style={{ maxWidth: '80%', margin: 'auto' }}
                          src={
                            vehicleDetails.engineImage
                              ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                vehicleDetails.engineImage
                              : noImage
                          }
                        />
                      </div>

                      <div
                        data={8}
                        onClick={this.largeImage}
                        style={{ display: 'flex' }}
                      >
                        <img
                          alt="Boot"
                          style={{ maxWidth: '80%', margin: 'auto' }}
                          src={
                            vehicleDetails.bootImage
                              ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                vehicleDetails.bootImage
                              : noImage
                          }
                        />
                      </div>

                      <div
                        data={9}
                        onClick={this.largeImage}
                        style={{ display: 'flex' }}
                      >
                        <img
                          alt="Service1"
                          style={{ maxWidth: '80%', margin: 'auto' }}
                          src={
                            vehicleDetails.service1Image
                              ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                vehicleDetails.service1Image
                              : noImage
                          }
                        />
                      </div>

                      <div
                        data={10}
                        onClick={this.largeImage}
                        style={{ display: 'flex' }}
                      >
                        <img
                          alt="Service2"
                          style={{ maxWidth: '80%', margin: 'auto' }}
                          src={
                            vehicleDetails.service2Image
                              ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                vehicleDetails.service2Image
                              : noImage
                          }
                        />
                      </div>
                    </CarouselStyled>
                    <div
                      style={{
                        display: 'flex',
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        height: '100%'
                      }}
                    >
                      <Icon
                        onClick={e => this.carouselRef.current.next()}
                        style={{
                          margin: 'auto',
                          fontSize: 25,
                          zIndex: 9,
                          cursor: 'pointer'
                        }}
                        type="caret-right"
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>{' '}
            {/* MOBILE VIEW*/}
            <Row
              type="flex"
              justify="start"
              align="middle"
              className="hidden-comps-webview"
            >
              <Col
                span={24}
                style={{
                  textAlign: 'center',
                  margin: 'auto'
                }}
              >
                {' '}
                <Icon
                  style={{
                    fontSize: 25,
                    color: '#00888e',
                    float: 'left',
                    padding: '0.5em'
                  }}
                  onClick={e => this.props.goBack()}
                  type="arrow-left"
                />
                <span
                  style={{
                    fontWeight: 750,
                    fontSize: 30,
                    marginLeft: '-1em'
                  }}
                >
                  {window.i18n.translate('VehicleDetails')}
                </span>
              </Col>
            </Row>
            <Row
              type="flex"
              justify="space-between"
              align="top"
              className="hidden-comps-webview"
            >
              {' '}
              <Col span={24}>
                {/*  <Tabs
                  style={{ display: this.state.fromHistory ? "none" : "block" }}
                >
                  <TabPane style={{ background: "white" }} tab="Bid" key="1">
                    <div
                      style={{
                        margin: "auto",
                        textAlign: "center",
                        padding: 10,
                      }}
                    >
                      <Row style={{ marginBottom: 10 }}>
                        <span style={{ fontWeight: 600, fontSize: 15 }}>
                          Highest Bid: R
                          {numeral(this.state.highestBid).format("0,0")}
                        </span>
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <span
                          style={{
                            fontWeight: 600,
                            fontSize: 15,
                            color:
                              this.parseString(this.state.yourBidAmount) ===
                              this.parseString(this.state.highestBid)
                                ? "green"
                                : "red",
                          }}
                        >
                          Your Bid: R
                          {numeral(this.state.yourBidAmount).format("0,0")}
                        </span>
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <span style={{ fontWeight: 600, fontSize: 15 }}>
                          Bid Amount: R
                          {numeral(this.state.bidAmount).format("0,0")}
                        </span>
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <Button
                          onClick={this.addBidIncrement}
                          style={{ marginRight: 10 }}
                          type="primary"
                          shape="circle"
                          icon="plus"
                        />
                        <Button
                          disabled={this.state.disableReduce}
                          onClick={this.removeBidIncrement}
                          shape="circle"
                          icon="minus"
                        />
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <Button
                          disabled={this.state.closed || this.state.bidLow}
                          data={vehicleDetails._id}
                          onClick={this.handleBid}
                          style={{
                            background: "#E5E5E5",
                            width: 112,
                            margin: "auto",
                            color: "#6D6E70",
                          }}
                        >
                          <Icon
                            style={{ color: "#6D6E70" }}
                            theme="filled"
                            component={hammer}
                          />
                          Place Bid
                        </Button>
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <Input
                          style={{ width: 150 }}
                          value={this.state.customBidAmount}
                          onChange={this.handleCustomBidAmount}
                          placeholder="Custom bid amount"
                        />
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <Button
                          disabled={
                            this.state.closed || this.state.customBidLow
                          }
                          data={vehicleDetails._id}
                          onClick={this.handleCustomBid}
                          style={{
                            background: "#E5E5E5",
                            width: 160,
                            margin: "auto",
                            color: "#6D6E70",
                          }}
                        >
                          <Icon
                            style={{ color: "#6D6E70" }}
                            theme="filled"
                            component={hammer}
                          />
                          Place Custom Bid
                        </Button>
                      </Row>
                      {this.state.auctionStart ? (
                        <div>
                          <h4>Auction Starts</h4>
                          <h4>{this.state.auctionStart}</h4>
                        </div>
                      ) : this.state.nextAuction ? (
                        <div>
                          <h4>Next Auction</h4>
                          <h4>{this.state.nextAuction}</h4>
                        </div>
                      ) : (this.props.customTime &&
                          !this.props.customTime.status) ||
                        this.state.closed ? (
                        "Closed"
                      ) : (
                        <div>
                          <h4>Time Left</h4>
                          <Countdown
                            onComplete={(e) => this.processComplete()}
                            style={{ fontSize: "1.5em" }}
                            date={this.timeStampTime(vehicleDetails).valueOf()}
                            now={(e) => moment(this.state.timeStamp).valueOf()}
                          />
                        </div>
                      )}
                    </div>
                  </TabPane>
                  <TabPane
                    style={{ background: "white" }}
                    tab="Auto Bid"
                    key="2"
                  >
                    <div
                      style={{
                        margin: "auto",
                        textAlign: "center",
                        padding: 10,
                      }}
                    >
                      <Row style={{ marginBottom: 10 }}>
                        <span style={{ fontWeight: 600, fontSize: 15 }}>
                          Highest Bid: R
                          {numeral(this.state.highestBid).format("0,0")}
                        </span>
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <span
                          style={{
                            fontWeight: 600,
                            fontSize: 15,
                            color:
                              this.parseString(this.state.yourBidAmount) ===
                              this.parseString(this.state.highestBid)
                                ? "green"
                                : "red",
                          }}
                        >
                          Your Bid: R
                          {numeral(this.state.yourBidAmount).format("0,0")}
                        </span>
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <span style={{ fontWeight: 600, fontSize: 15 }}>
                          Bid Until Amount: R
                          {numeral(this.state.autoBidAmount).format("0,0")}
                        </span>
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <Button
                          onClick={this.addAutoBidIncrement}
                          style={{ marginRight: 10 }}
                          type="primary"
                          shape="circle"
                          icon="plus"
                        />
                        <Button
                          disabled={this.state.disableReduce}
                          onClick={this.removeAutoBidIncrement}
                          shape="circle"
                          icon="minus"
                        />
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <Button
                          disabled={this.state.closed || this.state.autoBidLow}
                          data={vehicleDetails._id}
                          onClick={this.handleAutoBid}
                          style={{
                            background: "#E5E5E5",
                            width: 150,
                            margin: "auto",
                            color: "#6D6E70",
                          }}
                        >
                          <Icon
                            style={{ color: "#6D6E70" }}
                            theme="filled"
                            component={hammer}
                          />
                          {this.state.autoBidActive
                            ? "Cancel"
                            : "Place Auto Bid"}
                        </Button>
                      </Row>
                      {this.state.auctionStart ? (
                        <div>
                          <h4>Auction Starts</h4>
                          <h4>{this.state.auctionStart}</h4>
                        </div>
                      ) : this.state.nextAuction ? (
                        <div>
                          <h4>Next Auction</h4>
                          <h4>{this.state.nextAuction}</h4>
                        </div>
                      ) : (this.props.customTime &&
                          !this.props.customTime.status) ||
                        this.state.closed ? (
                        "Closed"
                      ) : (
                        <div>
                          <h4>Time Left</h4>
                          <Countdown
                            onComplete={(e) => this.processComplete()}
                            style={{ fontSize: "1.5em" }}
                            date={this.timeStampTime(vehicleDetails).valueOf()}
                            now={(e) => moment(this.state.timeStamp).valueOf()}
                          />
                        </div>
                      )}
                    </div>
                  </TabPane>
                  {/* <TabPane
                style={{ background: 'white' }}
                tab="Make Offer"
                key="3"
              /> 
                </Tabs>*/}
                <div
                  style={{
                    margin: 'auto',
                    textAlign: 'center',
                    background: 'white',
                    width: '100%',
                    height: '100%'
                  }}
                >
                  {/*} <Button
                    data={vehicleDetails._id}
                    onClick={
                      this.props.user.basket.indexOf(vehicleDetails._id) > -1
                        ? this.handleRemoveBasket
                        : this.handleAddBasket
                    }
                    style={{
                      display: this.state.fromHistory ? "none" : "block",
                      background: "#E5E5E5",
                      width: "100%",
                      margin: "auto",
                      color: "#6D6E70",
                    }}
                  >
                    <Icon style={{ color: "#6D6E70" }} type="shopping-cart" />
                    {this.props.user.basket.indexOf(vehicleDetails._id) > -1
                      ? "Remove from Basket"
                      : "Add to Basket"}
                  </Button>{" "}
                  */}
                  <div style={{ position: 'relative' }}>
                    <div
                      style={{
                        display: 'flex',
                        position: 'absolute',
                        top: 0,
                        height: '100%'
                      }}
                    >
                      <Icon
                        onClick={e => this.carouselRef.current.prev()}
                        style={{
                          margin: 'auto',
                          fontSize: 25,
                          zIndex: 9,
                          cursor: 'pointer'
                        }}
                        type="caret-left"
                      />
                    </div>
                    <CarouselStyled ref={this.carouselRef} autoplay={true}>
                      <div
                        data={1}
                        //onClick={this.largeImage}
                        style={{ display: 'flex' }}
                      >
                        <img
                          alt="Main"
                          style={{ maxWidth: '80%', margin: 'auto' }}
                          src={
                            vehicleDetails.mainImage
                              ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                vehicleDetails.mainImage
                              : noImage
                          }
                        />
                      </div>

                      <div
                        data={2}
                        //onClick={this.largeImage}
                        style={{ display: 'flex' }}
                      >
                        <img
                          alt="Front"
                          style={{ maxWidth: '80%', margin: 'auto' }}
                          src={
                            vehicleDetails.frontImage
                              ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                vehicleDetails.frontImage
                              : noImage
                          }
                        />
                      </div>

                      <div
                        data={3}
                        //    onClick={this.largeImage}
                        style={{ display: 'flex' }}
                      >
                        <img
                          alt="Left"
                          style={{ maxWidth: '80%', margin: 'auto' }}
                          src={
                            vehicleDetails.leftImage
                              ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                vehicleDetails.leftImage
                              : noImage
                          }
                        />
                      </div>

                      <div
                        data={4}
                        //  onClick={this.largeImage}
                        style={{ display: 'flex' }}
                      >
                        <img
                          alt="Right"
                          style={{ maxWidth: '80%', margin: 'auto' }}
                          src={
                            vehicleDetails.rightImage
                              ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                vehicleDetails.rightImage
                              : noImage
                          }
                        />
                      </div>

                      <div
                        data={5}
                        // onClick={this.largeImage}
                        style={{ display: 'flex' }}
                      >
                        <img
                          alt="Back"
                          style={{ maxWidth: '80%', margin: 'auto' }}
                          src={
                            vehicleDetails.backImage
                              ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                vehicleDetails.backImage
                              : noImage
                          }
                        />
                      </div>

                      <div
                        data={6}
                        //  onClick={this.largeImage}
                        style={{ display: 'flex' }}
                      >
                        <img
                          alt="Interior"
                          style={{ maxWidth: '80%', margin: 'auto' }}
                          src={
                            vehicleDetails.interiorImage
                              ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                vehicleDetails.interiorImage
                              : noImage
                          }
                        />
                      </div>

                      <div
                        data={7}
                        //  onClick={this.largeImage}
                        style={{ display: 'flex' }}
                      >
                        <img
                          alt="Engine"
                          style={{ maxWidth: '80%', margin: 'auto' }}
                          src={
                            vehicleDetails.engineImage
                              ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                vehicleDetails.engineImage
                              : noImage
                          }
                        />
                      </div>

                      <div
                        data={8}
                        //   onClick={this.largeImage}
                        style={{ display: 'flex' }}
                      >
                        <img
                          alt="Boot"
                          style={{ maxWidth: '80%', margin: 'auto' }}
                          src={
                            vehicleDetails.bootImage
                              ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                vehicleDetails.bootImage
                              : noImage
                          }
                        />
                      </div>

                      <div
                        data={9}
                        //onClick={this.largeImage}
                        style={{ display: 'flex' }}
                      >
                        <img
                          alt="Service1"
                          style={{ maxWidth: '80%', margin: 'auto' }}
                          src={
                            vehicleDetails.service1Image
                              ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                vehicleDetails.service1Image
                              : noImage
                          }
                        />
                      </div>

                      <div
                        data={10}
                        // onClick={this.largeImage}
                        style={{ display: 'flex' }}
                      >
                        <img
                          alt="Service2"
                          style={{ maxWidth: '80%', margin: 'auto' }}
                          src={
                            vehicleDetails.service2Image
                              ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                vehicleDetails.service2Image
                              : noImage
                          }
                        />
                      </div>
                    </CarouselStyled>
                    <div
                      style={{
                        display: 'flex',
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        height: '100%'
                      }}
                    >
                      <Icon
                        onClick={e => this.carouselRef.current.next()}
                        style={{
                          margin: 'auto',
                          fontSize: 25,
                          zIndex: 9,
                          cursor: 'pointer'
                        }}
                        type="caret-right"
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <Tabs>
                  <TabPane
                    style={{ background: 'white' }}
                    tab="General"
                    key="1"
                  >
                    <Descriptions
                      bordered
                      column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                    >
                      <Descriptions.Item span={1} label="ID">
                        {vehicleDetails.displayId}
                      </Descriptions.Item>

                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Condition')}
                      >
                        {vehicleDetails.condition === '' ? (
                          window.i18n.translate('NotProvided')
                        ) : (
                          <Rate
                            count={10}
                            defaultValue={parseInt(vehicleDetails.condition)}
                            disabled={true}
                          />
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Year')}
                      >
                        {vehicleDetails.year === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.year}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Vehicle')}
                      >
                        {vehicleDetails.make +
                          ' ' +
                          vehicleDetails.model +
                          ' ' +
                          vehicleDetails.variant}
                      </Descriptions.Item>
                      <Descriptions.Item span={1} label="km">
                        {vehicleDetails.mileage === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.mileage}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('ServiceHistory')}
                      >
                        {vehicleDetails.serviceHistory === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.serviceHistory}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('MMCode')}
                      >
                        {vehicleDetails.mmCode === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.mmCode}
                      </Descriptions.Item>
                      {/* <Descriptions.Item span={1} label="Customer Asking Price">
                        {vehicleDetails.price === ''
                          ? 'Not Provided'
                          : vehicleDetails.price}
                      </Descriptions.Item> */}
                      {/* <Descriptions.Item span={1} label="Reserve Price">
                        {vehicleDetails.reserve === ''
                          ? 'Not Provided'
                          : vehicleDetails.reserve}
                      </Descriptions.Item> */}
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('TradePrice')}
                      >
                        {vehicleDetails.valuation &&
                        this.props.user.canSeePrices
                          ? 'R' +
                            numeral(
                              vehicleDetails.valuation['s:Envelope']['s:Body']
                                .GetConvergedDataRequestResponse.ConvergedData[
                                'd4p1:VehicleValueInfo'
                              ]['d4p1:VehicleValue']['d4p1:TradePrice']['_text']
                            ).format('0,0')
                          : 'N/A'}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('RetailPrice')}
                      >
                        {vehicleDetails.valuation &&
                        this.props.user.canSeePrices
                          ? 'R' +
                            numeral(
                              vehicleDetails.valuation['s:Envelope']['s:Body']
                                .GetConvergedDataRequestResponse.ConvergedData[
                                'd4p1:VehicleValueInfo'
                              ]['d4p1:VehicleValue']['d4p1:RetailPrice'][
                                '_text'
                              ]
                            ).format('0,0')
                          : 'N/A'}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('NewPrice')}
                      >
                        {vehicleDetails.valuation &&
                        this.props.user.canSeePrices
                          ? 'R' +
                            numeral(
                              vehicleDetails.valuation['s:Envelope']['s:Body']
                                .GetConvergedDataRequestResponse.ConvergedData[
                                'd4p1:VehicleValueInfo'
                              ]['d4p1:VehicleValue']['d4p1:NewListPrice'][
                                '_text'
                              ]
                            ).format('0,0')
                          : 'N/A'}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Colour')}
                      >
                        {vehicleDetails.colour === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.colour}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Owners')}
                      >
                        {vehicleDetails.owners === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.owners}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Transmission')}
                      >
                        {vehicleDetails.transmission === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.transmission}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('FuelType')}
                      >
                        {vehicleDetails.fuel === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.fuel}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('CityTown')}
                      >
                        {vehicleDetails.city === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.city}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Province')}
                      >
                        {vehicleDetails.province === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.province}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Warranty')}
                      >
                        {vehicleDetails.warranty === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.warranty}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('ServicePlan')}
                      >
                        {vehicleDetails.servicePlan === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.servicePlan}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('LicenseExpiry')}
                      >
                        {vehicleDetails.licenseExpire === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.licenseExpire}
                      </Descriptions.Item>
                      {/* <Descriptions.Item span={1} label="Selling Reason">
                        {vehicleDetails.sellingReason === ''
                          ? 'Not Provided'
                          : vehicleDetails.sellingReason}
                      </Descriptions.Item> */}
                    </Descriptions>
                  </TabPane>
                  <TabPane
                    style={{ background: 'white' }}
                    tab={window.i18n.translate('Recon')}
                    key="2"
                  >
                    <Descriptions
                      bordered
                      column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                      layout="vertical"
                    >
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('SpareKeys1')}
                      >
                        {vehicleDetails.spareKeys === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.spareKeys}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('MechanicalIssues1')}
                      >
                        {vehicleDetails.mechanicalIssues === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.mechanicalIssues}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('PreviousBodyWork')}
                      >
                        {vehicleDetails.bodyWork === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.bodyWork}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('WindscreenCracks')}
                      >
                        {vehicleDetails.windscreenDamage === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.windscreenDamage}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('MagsRimsDamage')}
                      >
                        {vehicleDetails.magsDamage === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.magsDamage}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('FrontTyres')}
                      >
                        {vehicleDetails.frontTyres === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.frontTyres}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('BackTyres')}
                      >
                        {vehicleDetails.backTyres === ''
                          ? window.i18n.translate('NotProvided')
                          : vehicleDetails.backTyres}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        style={{ display: 'flex' }}
                        label="Damage"
                      >
                        <div
                          data={vehicleDetails.damage}
                          // onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Left"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.damage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.damage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                    </Descriptions>
                  </TabPane>
                  <TabPane
                    style={{ background: 'white' }}
                    tab={window.i18n.translate('Images')}
                    key="3"
                  >
                    <Descriptions
                      bordered
                      column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                      layout="vertical"
                    >
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('MainView')}
                      >
                        <div
                          data={1}
                          // onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Main"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.mainImage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.mainImage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('FrontView')}
                      >
                        <div
                          data={2}
                          //  onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Front"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.frontImage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.frontImage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('LeftView')}
                      >
                        <div
                          data={3}
                          // onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Left"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.leftImage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.leftImage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('RightView')}
                      >
                        <div
                          data={4}
                          //  onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Right"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.rightImage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.rightImage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('RearView')}
                      >
                        <div
                          data={5}
                          // onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Back"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.backImage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.backImage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Interior')}
                      >
                        <div
                          data={6}
                          //  onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Interior"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.interiorImage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.interiorImage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Engine')}
                      >
                        <div
                          data={7}
                          //  onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Engine"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.engineImage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.engineImage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('Boot')}
                      >
                        <div
                          data={8}
                          // onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Boot"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.bootImage
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.bootImage
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('ServiceBook') + ' ' + '1'}
                      >
                        <div
                          data={9}
                          //  onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Service1"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.service1Image
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.service1Image
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={1}
                        label={window.i18n.translate('ServiceBook') + ' ' + '2'}
                      >
                        <div
                          data={10}
                          //  onClick={this.largeImage}
                          style={{ display: 'flex' }}
                        >
                          <img
                            alt="Service2"
                            style={{ maxWidth: '60%', margin: 'auto' }}
                            src={
                              vehicleDetails.service2Image
                                ? 'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' +
                                  vehicleDetails.service2Image
                                : noImage
                            }
                          />
                        </div>
                      </Descriptions.Item>
                    </Descriptions>
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </Spin>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default VehicleDetails;
