import React from 'react';
import styled from 'styled-components';
import {
  Form,
  Icon,
  Menu,
  Select,
  Row,
  Col,
  Spin,
  Input,
  List,
  Switch,
  Upload
} from 'antd';
import { Layout } from 'antd';
import Resizer from 'react-image-file-resizer';
import { ButtonSquare } from 'src/components/elements/Button';
import Notify from 'src/components/meta/Notification';
const { Content, Sider } = Layout;
const Selection = styled(Select)`
  .ant-select-selection {
    border: 1px solid #bdb2b2;
    height: 45px;
    font-size: 12px;
  }
  .ant-select-selection__placeholder {
    top: 21px;
  }

  .ant-select-selection-selected-value {
    position: relative;
    top: 6px;
  }
`;
const FormItem = Form.Item;
const Option = Select.Option;

const Account = props => {
  return (
    <Form onSubmit={props.handleAccountInfoSave}>
      {!props.props.user.document ? (
        <div
          style={{
            textAlign: 'center',
            fontSize: 17,
            color: 'red'
          }}
        >
          <span>
            Please fill in the required details below and upload your company CK
            document for approval before you can continue.
          </span>
        </div>
      ) : null}
      {!props.props.user.document || props.props.user.approved ? (
        <Row type="flex" justify="space-around" align="middle">
          <Col span={16}>
            <FormItem
              label={
                <span style={{ color: '#6D6E70', fontSize: 16 }}>
                  First Name
                </span>
              }
            >
              {props.props.form.getFieldDecorator('name', {
                rules: [{ required: true, message: 'Name required' }],
                initialValue: props.props.user.name
              })(<Input style={{ borderColor: '#BDB2B2', height: 45 }} />)}
            </FormItem>
          </Col>
          <Col span={16}>
            <FormItem
              label={
                <span style={{ color: '#6D6E70', fontSize: 16 }}>Surname</span>
              }
            >
              {props.props.form.getFieldDecorator('surname', {
                rules: [{ required: true, message: 'Surname required' }],
                initialValue: props.props.user.surname
              })(<Input style={{ borderColor: '#BDB2B2', height: 45 }} />)}
            </FormItem>
          </Col>
          <Col span={16}>
            <FormItem
              label={
                <span style={{ color: '#6D6E70', fontSize: 16 }}>Number</span>
              }
            >
              {props.props.form.getFieldDecorator('number', {
                rules: [{ required: true, message: 'Number required' }],
                initialValue: props.props.user.number
              })(<Input style={{ borderColor: '#BDB2B2', height: 45 }} />)}
            </FormItem>
          </Col>
          <Col span={16}>
            <FormItem
              label={
                <span style={{ color: '#6D6E70', fontSize: 16 }}>
                  CK Document Upload
                </span>
              }
            >
              {props.props.form.getFieldDecorator('document', {
                rules: [{ required: true, message: 'CK Document required' }],
                initialValue: props.props.user.document
              })(
                <Upload
                  name="CKDocument"
                  multiple={false}
                  fileList={props.document ? [props.document] : []}
                  showUploadList={true}
                  beforeUpload={props.beforeUpload}
                  customRequest={props.handleCKDocumentsUpload}
                  action={props.handleCKDocuments}
                  onRemove={props.removeDocument}
                >
                  <ButtonSquare style={{ height: 45 }} type="primary">
                    Upload CK Document
                  </ButtonSquare>
                </Upload>
              )}
            </FormItem>
          </Col>
          <Col span={16}>
            <FormItem
              label={
                <span style={{ color: '#6D6E70', fontSize: 16 }}>
                  Company Name
                </span>
              }
            >
              {props.props.form.getFieldDecorator('companyName', {
                rules: [{ required: true, message: 'Company name required' }],
                initialValue: props.props.user.companyName
              })(<Input style={{ borderColor: '#BDB2B2', height: 45 }} />)}
            </FormItem>
          </Col>
          <Col span={16}>
            <FormItem
              label={
                <span style={{ color: '#6D6E70', fontSize: 16 }}>
                  Company Registration Number
                </span>
              }
            >
              {props.props.form.getFieldDecorator('companyReg', {
                rules: [
                  {
                    required: true,
                    message: 'Company registration number required'
                  }
                ],
                initialValue: props.props.user.companyReg
              })(<Input style={{ borderColor: '#BDB2B2', height: 45 }} />)}
            </FormItem>
          </Col>
          <Col span={16}>
            <FormItem
              label={
                <span style={{ color: '#6D6E70', fontSize: 16 }}>
                  Company VAT Number
                </span>
              }
            >
              {props.props.form.getFieldDecorator('companyVAT', {
                rules: [
                  { required: true, message: 'Company VAT number required' }
                ],
                initialValue: props.props.user.companyVAT
              })(<Input style={{ borderColor: '#BDB2B2', height: 45 }} />)}
            </FormItem>
          </Col>
          <Col span={16}>
            <FormItem
              label={
                <span style={{ color: '#6D6E70', fontSize: 16 }}>
                  Company Street Address
                </span>
              }
            >
              {props.props.form.getFieldDecorator('companyAddress1', {
                rules: [
                  { required: true, message: 'Company street address required' }
                ],
                initialValue: props.props.user.companyAddress1
              })(<Input style={{ borderColor: '#BDB2B2', height: 45 }} />)}
            </FormItem>
          </Col>
          <Col span={16}>
            <FormItem
              label={
                <span style={{ color: '#6D6E70', fontSize: 16 }}>
                  Company Suburb
                </span>
              }
            >
              {props.props.form.getFieldDecorator('companyAddress2', {
                rules: [{ required: true, message: 'Company suburb required' }],
                initialValue: props.props.user.companyAddress2
              })(<Input style={{ borderColor: '#BDB2B2', height: 45 }} />)}
            </FormItem>
          </Col>
          <Col span={16}>
            <FormItem
              label={
                <span style={{ color: '#6D6E70', fontSize: 16 }}>
                  Company City/Town
                </span>
              }
            >
              {props.props.form.getFieldDecorator('companyAddress3', {
                rules: [
                  { required: true, message: 'Company city / town required' }
                ],
                initialValue: props.props.user.companyAddress3
              })(<Input style={{ borderColor: '#BDB2B2', height: 45 }} />)}
            </FormItem>
          </Col>
          <Col span={16}>
            <FormItem
              label={
                <span style={{ color: '#6D6E70', fontSize: 16 }}>
                  Company Postal Code
                </span>
              }
            >
              {props.props.form.getFieldDecorator('companyAddress4', {
                rules: [
                  { required: true, message: 'Company postal code required' }
                ],
                initialValue: props.props.user.companyAddress4
              })(<Input style={{ borderColor: '#BDB2B2', height: 45 }} />)}
            </FormItem>
          </Col>
          <Col span={16}>
            <FormItem
              label={
                <span style={{ color: '#6D6E70', fontSize: 16 }}>Nickname</span>
              }
            >
              {props.props.form.getFieldDecorator('nickname', {
                rules: [{ required: false }],
                initialValue: props.props.user.nickname
              })(<Input style={{ borderColor: '#BDB2B2', height: 45 }} />)}
            </FormItem>
          </Col>
          <Col span={16}>
            <FormItem
              label={
                <span style={{ color: '#6D6E70', fontSize: 16 }}>
                  Alternative Number
                </span>
              }
            >
              {props.props.form.getFieldDecorator('altNumber', {
                rules: [{ required: false }],
                initialValue: props.props.user.altNumber
              })(<Input style={{ borderColor: '#BDB2B2', height: 45 }} />)}
            </FormItem>
          </Col>
          <Col span={16}>
            <FormItem
              label={
                <span style={{ color: '#6D6E70', fontSize: 16 }}>
                  Where did you hear about us?
                </span>
              }
            >
              {props.props.form.getFieldDecorator('heardFrom', {
                rules: [{ required: false }],
                initialValue: props.props.user.heardFrom
              })(
                <Selection
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Select a place"
                  optionFilterProp="children"
                >
                  <Option key={'Radio'} value={'Radio'}>
                    {'Radio'}
                  </Option>
                  <Option key={'TV'} value={'TV'}>
                    {'TV'}
                  </Option>
                  <Option key={'Facebook'} value={'Facebook'}>
                    {'Facebook'}
                  </Option>
                  <Option key={'Twitter'} value={'Twitter'}>
                    {'Twitter'}
                  </Option>
                  <Option key={'Instagram'} value={'Instagram'}>
                    {'Instagram'}
                  </Option>
                  <Option
                    key={'Billboard or Streetpole ad'}
                    value={'Billboard or Streetpole ad'}
                  >
                    {'Billboard or Streetpole ad'}
                  </Option>
                  <Option key={'Word of mouth'} value={'Word of mouth'}>
                    {'Word of mouth'}
                  </Option>
                  <Option key={'Google'} value={'Google'}>
                    {'Google'}
                  </Option>
                </Selection>
              )}
            </FormItem>
          </Col>
          <Col span={16} style={{ display: 'flex' }}>
            <FormItem style={{ margin: 'auto' }}>
              <ButtonSquare
                style={{ height: 45 }}
                className="submit-button"
                type="primary"
                htmlType="submit"
              >
                Save Changes
              </ButtonSquare>
            </FormItem>
          </Col>
        </Row>
      ) : null}
      {!props.props.user.approved && props.props.user.document ? (
        <div
          style={{
            textAlign: 'center',
            fontSize: 17,
            color: 'red'
          }}
        >
          <span>
            Your account is under review for approval. You will be notified once
            this is done.
          </span>
        </div>
      ) : null}
    </Form>
  );
};
const Password = props => {
  return (
    <Form onSubmit={props.handlePasswordChange}>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                New Password
              </span>
            }
            hasFeedback
          >
            {props.props.form.getFieldDecorator('newPass', {
              rules: [
                { required: true, message: 'New password required' },
                { validator: props.validateToNextPassword }
              ]
            })(
              <Input.Password
                onBlur={props.handleConfirmBlur}
                type="password"
                style={{ borderColor: '#BDB2B2', height: 45 }}
              />
            )}
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                Confirm New Password
              </span>
            }
            hasFeedback
          >
            {props.props.form.getFieldDecorator('confirmPass', {
              rules: [
                { required: true, message: 'Confirm new password' },
                { validator: props.compareToFirstPassword }
              ]
            })(
              <Input.Password
                type="password"
                style={{ borderColor: '#BDB2B2', height: 45 }}
              />
            )}
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <FormItem>
          <ButtonSquare
            style={{ height: 45 }}
            className="submit-button"
            type="primary"
            htmlType="submit"
          >
            Save Changes
          </ButtonSquare>
        </FormItem>
      </Row>
    </Form>
  );
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 2 }
  }
};
const Notification = props => {
  return (
    <Form {...formItemLayout} onSubmit={props.handleNotificationSave}>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                Notify me when a customer accepts my winning bid
              </span>
            }
            hasFeedback
          >
            <Switch defaultChecked />
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                Notify me when Verkoopnou reviews my vehicle
              </span>
            }
            hasFeedback
          >
            <Switch defaultChecked />
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                Notify me of Verkoopnou updates
              </span>
            }
            hasFeedback
          >
            <Switch defaultChecked />
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};
class AccountSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      document: this.props.user.document,
      activeView: 'account'
    };
  }
  componentDidMount = () => {
    this.props
      .saveAccountInfo()
      .then(() => {})
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', window.i18n.translate('Notify3'), e);
      });
  };
  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('newPass')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirmPass'], { force: true });
    }
    callback();
  };
  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };
  handleAccountInfoSave = event => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        values.image = this.state.image;
        values.document = this.state.document;
        this.props
          .saveAccountInfo(values)
          .then(() => {
            this.setState({ loading: false });
            Notify(
              'success',
              'Success',
              'Successfully saved account information'
            );
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', window.i18n.translate('Notify3'), e);
          });
      }
    });
  };
  handlePasswordChange = event => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        this.props
          .changePassword(values)
          .then(() => {
            this.setState({ loading: false });
            Notify('success', 'Success', 'Successfully changed password');
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', window.i18n.translate('Notify3'), e);
          });
      }
    });
  };
  handleCKDocuments = file => {
    this.setState({
      loading: true
    });
    this.getBase64(file, b64 => {
      this.setState({
        loading: false,
        document: {
          uid: '-1',
          name: file.name,
          status: 'done',
          url: b64
        }
      });
    });
  };
  removeDocument = e => {
    this.setState({
      document: this.props.user.document
    });
  };
  handleCKDocumentsUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };
  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      Notify('error', 'File needs to be smaller than 10MB');
      return false;
    }
    return true;
  };
  getBase64 = (file, callback) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      callback(reader.result);
    };
    reader.onerror = function(error) {
      console.log('Error: ', error);
    };
  };
  render() {
    return (
      <div>
        <Row type="flex" justify="start" align="middle">
          <Col span={12} style={{ marginBottom: 20 }}>
            <Icon
              style={{ fontSize: 36, color: '#00888E', marginRight: 20 }}
              type="setting"
              theme="filled"
            />
            <span style={{ fontWeight: 750, fontSize: 36 }}>
              Account Settings
            </span>
          </Col>
        </Row>
        <Spin spinning={this.state.loading}>
          <Row type="flex" justify="start" align="middle">
            <Col span={24}>
              <Layout>
                <Layout>
                  <Sider
                    trigger={null}
                    width={160}
                    style={{
                      zIndex: 9,
                      background: 'white',
                      height: '100vh',
                      left: 0,
                      borderTopLeftRadius: 10,
                      borderRight: '1px solid #E5E5E5'
                    }}
                  >
                    <Menu
                      onClick={e => {
                        this.setState({ activeView: e.key });
                      }}
                      style={{ background: 'none', border: 0 }}
                      theme="light"
                      mode="inline"
                      defaultSelectedKeys={['account']}
                    >
                      <Menu.Item key="account">
                        <span style={{ fontSize: 16 }}>Account</span>
                      </Menu.Item>
                      <Menu.Item key="password">
                        <span style={{ fontSize: 16 }}>Password</span>
                      </Menu.Item>
                      {/* <Menu.Item key="notification">
                        <span style={{ fontSize: 16 }}>Notification</span>
                      </Menu.Item> */}
                    </Menu>
                  </Sider>
                  <Layout>
                    <Content
                      style={{
                        background: 'white',
                        padding: 40,
                        borderTopRightRadius: 10
                      }}
                    >
                      {this.state.activeView === 'account' ? (
                        <Account
                          props={this.props}
                          document={this.state.document}
                          beforeUpload={this.beforeUpload}
                          handleCKDocumentsUpload={this.handleCKDocumentsUpload}
                          handleCKDocuments={this.handleCKDocuments}
                          handleAccountInfoSave={this.handleAccountInfoSave}
                          removeDocument={this.removeDocument}
                        />
                      ) : this.state.activeView === 'password' ? (
                        <Password
                          props={this.props}
                          handlePasswordChange={this.handlePasswordChange}
                          compareToFirstPassword={this.compareToFirstPassword}
                          validateToNextPassword={this.validateToNextPassword}
                          handleConfirmBlur={this.handleConfirmBlur}
                        />
                      ) : // ) : this.state.activeView === "notification" ? (
                      //   <Notification
                      //     props={this.props}
                      //     handleAccountInfo={this.handleAccountInfo}
                      //   />
                      null}
                    </Content>
                  </Layout>
                </Layout>
              </Layout>
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}
const WrappedAccountSettings = Form.create()(AccountSettings);
export default WrappedAccountSettings;
