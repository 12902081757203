import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  findAuctionHistoryVehicles,
  auctionVehicle,
  setVehicleDetailsId,
  setFromHistory,
  approveVehicle,
  rejectVehicle,
  findVehicleBidInfo
} from 'src/redux/actions/admin';
import { updateBidsList } from 'src/redux/actions/bidding';
import { sendNotification } from 'src/redux/actions/notifications';
import {
  getLocale,
  getAuctionHistoryVehicles,
  getUser,
  getTimeStamp,
  getVehicleBidInfo
} from 'src/redux/selectors';

class AuctionHistory extends Component {
  render = () => {
    const {
      Layout,
      currentLocale,
      auctionHistoryVehicles,
      auctionVehicle,
      biddingInfo,
      user,
      findAuctionHistoryVehicles,
      findVehicleBidInfo,
      setVehicleDetailsId,
      setFromHistory,
      timeStamp,
      sendNotification,
      push
    } = this.props;
    return (
      <Layout
        currentLocale={currentLocale}
        auctionHistoryVehicles={auctionHistoryVehicles}
        auctionVehicle={auctionVehicle}
        biddingInfo={biddingInfo}
        user={user}
        findAuctionHistoryVehicles={findAuctionHistoryVehicles}
        findVehicleBidInfo={findVehicleBidInfo}
        setVehicleDetailsId={setVehicleDetailsId}
        setFromHistory={setFromHistory}
        timeStamp={timeStamp}
        sendNotification={sendNotification}
        push={push}
      />
    );
  };
}

const mapStateToProps = state => ({
  currentLocale: getLocale(state) || {},
  auctionHistoryVehicles: getAuctionHistoryVehicles(state) || [],
  timeStamp: getTimeStamp(state) || '',
  user: getUser(state) || {},
  biddingInfo: getVehicleBidInfo(state) || []
});

const mapDispatchToProps = {
  getLocale,
  findAuctionHistoryVehicles,
  findVehicleBidInfo,
  auctionVehicle,
  setVehicleDetailsId,
  setFromHistory,
  getVehicleBidInfo,
  sendNotification,
  push
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuctionHistory);
