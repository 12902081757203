import React from 'react';
import styled from 'styled-components';
import {
  Icon,
  Row,
  Col,
  Spin,
  Button,
  List,
  Tabs,
  Popover,
  Input,
  Table,
  Divider
} from 'antd';
import moment from 'moment';
import Notify from 'src/components/meta/Notification';
import noImage from 'src/static/icons/carImageUpload/no-image-available.svg';
import Countdown from 'react-countdown-now';
import Highlighter from 'react-highlight-words';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';
const { Column, ColumnGroup } = Table;
const { TextArea } = Input;
const numeral = require('numeral');
const { TabPane } = Tabs;
const hammer = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6475 17.3418H1.65195C0.740018 17.3418 0 18.0817 0 18.9938V21.9977H14.2994V18.9938C14.2994 18.0817 13.5595 17.3418 12.6475 17.3418Z"
      fill="#AD5E00"
    />
    <path
      d="M21.7036 17.5744L11.6665 7.53725L13.6051 5.60203C13.9248 5.88391 14.4163 5.87013 14.7188 5.56766C15.0351 5.248 15.0351 4.7358 14.7188 4.41614L10.539 0.239743C10.2228 -0.0799143 9.70715 -0.0799143 9.39094 0.239743C9.07128 0.555998 9.07128 1.0716 9.39094 1.38781L9.35657 1.35344L4.04924 6.65728L4.08361 6.69505C3.76736 6.37539 3.25175 6.37539 2.93554 6.69505C2.61589 7.01131 2.61589 7.52691 2.93554 7.84317L7.11194 12.023C7.4316 12.3392 7.9472 12.3392 8.26346 12.023C8.57971 11.7033 8.57971 11.1912 8.26346 10.8715L8.29783 10.9058L10.2365 8.9706L20.2702 19.0043C20.6655 19.3996 21.3083 19.3996 21.7036 19.0043C22.0989 18.609 22.0989 17.9697 21.7036 17.5744Z"
      fill="#AD5E00"
    />
  </svg>
);

const StyledTable = styled(Table)`
  // .ant-table-thead > tr > th {
  //   text-align: center !important;

  // }
  .ant-table-thead > tr > th {
    line-height: 1em;
    font-size: 16px;
    color: grey;

    border-bottom: 1px solid#00878e34;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #e8e8e8;
    -webkit-transition: all 0.3s, border 0s;
    transition: all 0.3s, border 0s;
    font-weight: 700;
    font-size: 11px;
  }
`;

class Deals extends React.Component {
  componentWillMount = () => {
    this.setState({ loading: true });
    this.props
      .findDealVehicles('open')
      .then(() => {
        let vehicles = this.props.vehicles;
        for (var i = 0; i < vehicles.length; i++) {
          vehicles[i].statusText =
            vehicles[i].adminOfferAccepted === true
              ? window.i18n.translate('AdminOfferAccepted')
              : vehicles[i].adminOfferAccepted === false
              ? window.i18n.translate('AdminOfferRejected')
              : vehicles[i].offerAccepted === true
              ? window.i18n.translate('DealerOfferAccepted')
              : vehicles[i].offerAccepted === false
              ? window.i18n.translate('DealerOfferRejected')
              : vehicles[i].sentAdminOffer === true
              ? window.i18n.translate('AwaitingOfferResponse')
              : vehicles[i].sentWinningBid === true
              ? window.i18n.translate('WinningBidSent')
              : window.i18n.translate('AwaitingApproval');
        }
        this.setState({ loading: false, vehicles: vehicles });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      filteredInfo: null,
      rejectReason: ''
    };
  }
  parseString = string => {
    return parseInt(string.split('R')[1].replace(/,/g, ''));
  };
  formatNumber = number => {
    return 'R' + numeral(number).format('0,0');
  };

  handleSelectVehicle = e => {
    this.props.setVehicleDetailsId(e.currentTarget.getAttribute('data'));
    this.props.setFromHistory(true);
    this.props.push('/admin/vehicledetails');
  };
  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let vehicles = this.state.vehicles;
    if (order === 'ascend') {
      vehicles.sort((a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey]));
    } else if (order === 'descend') {
      vehicles.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
    }
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };
  handleAuctionVehicle = vehicle => {
    this.setState({ loading: true });
    this.props
      .auctionVehicle(vehicle._id, false)
      .then(() => {
        this.props.findDealVehicles('open').then(() => {
          let vehicles = this.props.vehicles;
          for (var i = 0; i < vehicles.length; i++) {
            vehicles[i].statusText =
              vehicles[i].adminOfferAccepted === true
                ? window.i18n.translate('AdminOfferAccepted')
                : vehicles[i].adminOfferAccepted === false
                ? window.i18n.translate('AdminOfferRejected')
                : vehicles[i].offerAccepted === true
                ? window.i18n.translate('DealerOfferAccepted')
                : vehicles[i].offerAccepted === false
                ? window.i18n.translate('DealerOfferRejected')
                : vehicles[i].sentAdminOffer === true
                ? window.i18n.translate('AwaitingOfferResponse')
                : vehicles[i].sentWinningBid === true
                ? window.i18n.translate('WinningBidSent')
                : window.i18n.translate('AwaitingApproval');
          }
          this.setState({ loading: false, vehicles: vehicles });
        });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  handleUpdateFollowup = e => {
    this.setState({ loading: true });
    const id = e.currentTarget.getAttribute('data');
    this.props
      .updateFollowUp(id)
      .then(() => {
        this.props.findDealVehicles('open').then(() => {
          let vehicles = this.props.vehicles;
          for (var i = 0; i < vehicles.length; i++) {
            vehicles[i].statusText =
              vehicles[i].adminOfferAccepted === true
                ? window.i18n.translate('AdminOfferAccepted')
                : vehicles[i].adminOfferAccepted === false
                ? window.i18n.translate('AdminOfferRejected')
                : vehicles[i].offerAccepted === true
                ? window.i18n.translate('DealerOfferAccepted')
                : vehicles[i].offerAccepted === false
                ? window.i18n.translate('DealerOfferRejected')
                : vehicles[i].sentAdminOffer === true
                ? window.i18n.translate('AwaitingOfferResponse')
                : vehicles[i].sentWinningBid === true
                ? window.i18n.translate('WinningBidSent')
                : window.i18n.translate('AwaitingApproval');
          }
          this.setState({ loading: false, vehicles: vehicles });
        });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  handleCompleteDeal = e => {
    this.setState({ loading: true });
    const id = e.currentTarget.getAttribute('data');
    this.props
      .completeDeal(id)
      .then(() => {
        this.props.findDealVehicles('open').then(() => {
          let vehicles = this.props.vehicles;
          for (var i = 0; i < vehicles.length; i++) {
            vehicles[i].statusText =
              vehicles[i].adminOfferAccepted === true
                ? window.i18n.translate('AdminOfferAccepted')
                : vehicles[i].adminOfferAccepted === false
                ? window.i18n.translate('AdminOfferRejected')
                : vehicles[i].offerAccepted === true
                ? window.i18n.translate('DealerOfferAccepted')
                : vehicles[i].offerAccepted === false
                ? window.i18n.translate('DealerOfferRejected')
                : vehicles[i].sentAdminOffer === true
                ? window.i18n.translate('AwaitingOfferResponse')
                : vehicles[i].sentWinningBid === true
                ? window.i18n.translate('WinningBidSent')
                : window.i18n.translate('AwaitingApproval');
          }
          this.setState({ loading: false, vehicles: vehicles });
        });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  handleAcceptOffer = e => {
    this.setState({ loading: true });
    const id = e.currentTarget.getAttribute('data');
    this.props
      .acceptOffer(id)
      .then(() => {
        this.props.findDealVehicles('open').then(() => {
          let vehicles = this.props.vehicles;
          for (var i = 0; i < vehicles.length; i++) {
            vehicles[i].statusText =
              vehicles[i].adminOfferAccepted === true
                ? window.i18n.translate('AdminOfferAccepted')
                : vehicles[i].adminOfferAccepted === false
                ? window.i18n.translate('AdminOfferRejected')
                : vehicles[i].offerAccepted === true
                ? window.i18n.translate('DealerOfferAccepted')
                : vehicles[i].offerAccepted === false
                ? window.i18n.translate('DealerOfferRejected')
                : vehicles[i].sentAdminOffer === true
                ? window.i18n.translate('AwaitingOfferResponse')
                : vehicles[i].sentWinningBid === true
                ? window.i18n.translate('WinningBidSent')
                : window.i18n.translate('AwaitingApproval');
          }
          this.setState({ loading: false, vehicles: vehicles });
        });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  handleFailDeal = e => {
    this.setState({ loading: true });
    const id = e.currentTarget.getAttribute('data');
    this.props
      .completeDeal(id, true)
      .then(() => {
        this.props.findDealVehicles('open').then(() => {
          let vehicles = this.props.vehicles;
          for (var i = 0; i < vehicles.length; i++) {
            vehicles[i].statusText =
              vehicles[i].adminOfferAccepted === true
                ? window.i18n.translate('AdminOfferAccepted')
                : vehicles[i].adminOfferAccepted === false
                ? window.i18n.translate('AdminOfferRejected')
                : vehicles[i].offerAccepted === true
                ? window.i18n.translate('DealerOfferAccepted')
                : vehicles[i].offerAccepted === false
                ? window.i18n.translate('DealerOfferRejected')
                : vehicles[i].sentAdminOffer === true
                ? window.i18n.translate('AwaitingOfferResponse')
                : vehicles[i].sentWinningBid === true
                ? window.i18n.translate('WinningBidSent')
                : window.i18n.translate('AwaitingApproval');
          }
          this.setState({ loading: false, vehicles: vehicles });
        });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  handleTabChange = e => {
    if (e === '1') {
      this.props
        .findDealVehicles('open')
        .then(() => {
          let vehicles = this.props.vehicles;
          for (var i = 0; i < vehicles.length; i++) {
            vehicles[i].statusText =
              vehicles[i].adminOfferAccepted === true
                ? 'Admin Offer Accepted'
                : vehicles[i].adminOfferAccepted === false
                ? 'Admin Offer Rejected'
                : vehicles[i].offerAccepted === true
                ? 'Dealer Offer Accepted'
                : vehicles[i].offerAccepted === false
                ? 'Dealer Offer Rejected'
                : vehicles[i].sentAdminOffer === true
                ? 'Awaiting Offer Response'
                : vehicles[i].sentWinningBid === true
                ? 'Winning Bid Sent'
                : 'Awaiting Approval';
          }
          this.setState({ loading: false, vehicles: vehicles });
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify('error', e);
        });
    } else if (e === '2') {
      this.props
        .findDealVehicles('dealerOfferAccepted')
        .then(() => {
          let vehicles = this.props.vehicles;

          this.setState({ loading: false, vehicles: vehicles });
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify('error', e);
        });
    } else if (e === '3') {
      this.props
        .findDealVehicles('dealerOfferRejected')
        .then(() => {
          let vehicles = this.props.vehicles;

          this.setState({ loading: false, vehicles: vehicles });
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify('error', e);
        });
    } else if (e === '4') {
      this.props
        .findDealVehicles('dealDoneWithDealer')
        .then(() => {
          let vehicles = this.props.vehicles;

          this.setState({ loading: false, vehicles: vehicles });
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify('error', e);
        });
    } else if (e === '5') {
      this.props
        .findDealVehicles('dealNotDoneWithDealer')
        .then(() => {
          let vehicles = this.props.vehicles;

          this.setState({ loading: false, vehicles: vehicles });
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify('error', e);
        });
    } else if (e === '6') {
      this.props
        .findDealVehicles('vknOfferAccepted')
        .then(() => {
          let vehicles = this.props.vehicles;

          this.setState({ loading: false, vehicles: vehicles });
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify('error', e);
        });
    } else if (e === '7') {
      this.props
        .findDealVehicles('noBids')
        .then(() => {
          let vehicles = this.props.vehicles;

          this.setState({ loading: false, vehicles: vehicles });
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify('error', e);
        });
    }
  };
  handleDownloadDoc = vehicle => {
    fetch(
      'https://www.devvcsappcloud.com/verkoopnou/api/files/vehicleinvoices' +
        vehicle.invoice
    )
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = vehicle.invoice.split('/')[2];
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        Notify('success', 'Successfully downloaded invoice');
      })
      .catch(e => Notify('error', e));
  };
  render() {
    const { user } = this.props;
    let { sortedInfo, filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    return (
      <div>
        <Row type="flex" justify="start" align="middle">
          <Col span={12}>
            <Icon
              style={{ fontSize: 36, color: '#00888E', marginRight: 20 }}
              type="dollar"
            />
            <span style={{ fontWeight: 750, fontSize: 36 }}>
              {' '}
              {window.i18n.translate('ManageDeals')}
            </span>
          </Col>
          {/* <Col span={12}>
              <Button type="primary" style={{ fontWeight: 750, fontSize: 24, float: "right" }}>{moment().format("HH:mm:ss")}</Button>
            </Col> */}
        </Row>
        <Spin spinning={this.state.loading}>
          <Tabs style={{ overflow: 'visible' }} onChange={this.handleTabChange}>
            <TabPane tab={window.i18n.translate('DealOffersSent')} key="1">
              <Row type="flex" justify="start" align="middle">
                <Col span={24}>
                  <StyledTable
                    style={{ background: 'white' }}
                    rowKey="_id"
                    size="middle"
                    dataSource={this.state.vehicles}
                    onChange={this.handleSort}
                  >
                    <Column
                      sorter={() => {}}
                      title="ID"
                      dataIndex="displayId"
                      key="displayId"
                    />
                    {/* <Column title="Image" dataIndex="mainImage" key="mainImage" render={image => <img style={{maxWidth:40, maxHeight:40}} alt="Main" src={'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' + image} />} /> */}
                    <Column
                      {...this.getColumnSearchProps('make')}
                      title={window.i18n.translate('Make')}
                      dataIndex="make"
                      key="make"
                    />
                    <Column
                      {...this.getColumnSearchProps('model')}
                      title={window.i18n.translate('Model')}
                      dataIndex="model"
                      key="model"
                    />
                    <Column
                      {...this.getColumnSearchProps('variant')}
                      title={window.i18n.translate('Variant')}
                      dataIndex="variant"
                      key="variant"
                    />
                    <Column
                      sorter={() => {}}
                      title={window.i18n.translate('ClientName')}
                      render={(text, record) => (
                        <span>
                          {record.userName + ' ' + record.userSurname}
                        </span>
                      )}
                    />
                    <Column
                      {...this.getColumnSearchProps('userNumber')}
                      sorter={() => {}}
                      title={window.i18n.translate('ClientNumber')}
                      dataIndex="userNumber"
                      key="userNumber"
                    />
                    <Column
                      {...this.getColumnSearchProps('province')}
                      title={window.i18n.translate('Province')}
                      dataIndex="province"
                      key="province"
                    />
                    <Column
                      {...this.getColumnSearchProps('city')}
                      title={window.i18n.translate('City')}
                      dataIndex="city"
                      key="city"
                    />
                    <Column
                      {...this.getColumnSearchProps('wasOnAuctionDate')}
                      title={window.i18n.translate('AuctionDate')}
                      dataIndex="wasOnAuctionDate"
                      key="wasOnAuctionDate"
                      render={(text, record) => {
                        return <span>{moment(text).format('YYYY-MM-DD')}</span>;
                      }}
                    />
                    <Column
                      title={window.i18n.translate('OfferWonAmount')}
                      render={(text, record) => (
                        <span>
                          {record.offerAmount
                            ? this.formatNumber(record.offerAmount)
                            : this.formatNumber(record.highestBid)}
                        </span>
                      )}
                    />
                    <Column
                      {...this.getColumnSearchProps('lastFollowUp')}
                      title={window.i18n.translate('LastFollowUp')}
                      dataIndex="lastFollowUp"
                      key="lastFollowUp"
                      render={(text, record) => (
                        <span
                          style={{
                            color: !text || text === 'Never' ? 'red' : 'green'
                          }}
                        >
                          {!text || text === 'Never'
                            ? window.i18n.translate('Never')
                            : text}
                        </span>
                      )}
                    />
                    <Column
                      title={window.i18n.translate('Status')}
                      filters={[
                        {
                          text: window.i18n.translate('AdminOfferAccepted'),
                          value: 'Admin Offer Accepted'
                        },
                        {
                          text: window.i18n.translate('AdminOfferRejected'),
                          value: 'Admin Offer Rejected'
                        },
                        {
                          text: window.i18n.translate('DealerOfferAccepted'),
                          value: 'Dealer Offer Accepted'
                        },
                        {
                          text: window.i18n.translate('DealerOfferRejected'),
                          value: 'Dealer Offer Rejected'
                        },
                        {
                          text: window.i18n.translate('AwaitingOfferResponse'),
                          value: 'Awaiting Offer Response'
                        },
                        {
                          text: window.i18n.translate('WinningBidSent'),
                          value: 'Winning Bid Sent'
                        }
                      ]}
                      filteredValue={filteredInfo.statusText || null}
                      onFilter={(value, record) =>
                        record.statusText.includes(value)
                      }
                      dataIndex="statusText"
                      key="statusText"
                      render={(text, record) => (
                        <span
                          style={{
                            color:
                              record.adminOfferAccepted === true
                                ? 'green'
                                : record.adminOfferAccepted === false
                                ? 'red'
                                : record.offerAccepted === true
                                ? 'green'
                                : record.offerAccepted === false
                                ? 'red'
                                : record.sentAdminOffer === true
                                ? 'orange'
                                : record.sentWinningBid === true
                                ? 'orange'
                                : 'orange'
                          }}
                        >
                          {record.statusText}
                        </span>
                      )}
                    />
                    {/* <Column title="Time Left" /> */}
                    <Column
                      title={window.i18n.translate('Action')}
                      render={(text, vehicle) => (
                        <div>
                          <span>
                            <a
                              data={vehicle._id}
                              onClick={this.handleSelectVehicle}
                            >
                              {window.i18n.translate('Details')}
                            </a>
                            <Divider type="vertical" />
                            <a
                              data={vehicle._id}
                              onClick={this.handleUpdateFollowup}
                            >
                              {window.i18n.translate('FollowedUp')}
                            </a>
                            <Divider type="vertical" />
                            <a
                              data={vehicle._id}
                              onClick={this.handleAcceptOffer}
                            >
                              {window.i18n.translate('AcceptOfferCustomer')}
                            </a>
                            <Divider type="vertical" />
                            <a
                              data={vehicle._id}
                              onClick={this.handleCompleteDeal}
                            >
                              {' '}
                              {window.i18n.translate('DealDone')}
                            </a>
                          </span>
                          <span>
                            <Divider type="vertical" />
                            <a
                              onClick={e => this.handleAuctionVehicle(vehicle)}
                            >
                              {' '}
                              {window.i18n.translate('ReAuction')}
                            </a>
                          </span>
                        </div>
                      )}
                    />
                  </StyledTable>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab={window.i18n.translate('DealOffersAccepted')} key="2">
              <Row type="flex" justify="start" align="middle">
                <Col span={24}>
                  <StyledTable
                    style={{ background: 'white' }}
                    rowKey="_id"
                    size="middle"
                    dataSource={this.state.vehicles}
                    onChange={this.handleSort}
                  >
                    <Column
                      sorter={() => {}}
                      title="ID"
                      dataIndex="displayId"
                      key="displayId"
                    />
                    {/* <Column title="Image" dataIndex="mainImage" key="mainImage" render={image => <img style={{maxWidth:40, maxHeight:40}} alt="Main" src={'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' + image} />} /> */}
                    <Column
                      {...this.getColumnSearchProps('make')}
                      title={window.i18n.translate('Make')}
                      dataIndex="make"
                      key="make"
                    />
                    <Column
                      {...this.getColumnSearchProps('model')}
                      title={window.i18n.translate('Model')}
                      dataIndex="model"
                      key="model"
                    />
                    <Column
                      {...this.getColumnSearchProps('variant')}
                      title={window.i18n.translate('Variant')}
                      dataIndex="variant"
                      key="variant"
                    />
                    <Column
                      sorter={() => {}}
                      title={window.i18n.translate('ClientName')}
                      render={(text, record) => (
                        <span>
                          {record.userName + ' ' + record.userSurname}
                        </span>
                      )}
                    />
                    <Column
                      {...this.getColumnSearchProps('userNumber')}
                      sorter={() => {}}
                      title={window.i18n.translate('ClientNumber')}
                      dataIndex="userNumber"
                      key="userNumber"
                    />
                    <Column
                      {...this.getColumnSearchProps('province')}
                      title={window.i18n.translate('Province')}
                      dataIndex="province"
                      key="province"
                    />
                    <Column
                      {...this.getColumnSearchProps('city')}
                      title={window.i18n.translate('City')}
                      dataIndex="city"
                      key="city"
                    />
                    <Column
                      {...this.getColumnSearchProps('wasOnAuctionDate')}
                      title={window.i18n.translate('AuctionDate')}
                      dataIndex="wasOnAuctionDate"
                      key="wasOnAuctionDate"
                      render={(text, record) => {
                        return <span>{moment(text).format('YYYY-MM-DD')}</span>;
                      }}
                    />
                    <Column
                      title={window.i18n.translate('OfferWonAmount')}
                      render={(text, record) => (
                        <span>
                          {record.offerAmount
                            ? this.formatNumber(record.offerAmount)
                            : this.formatNumber(record.highestBid)}
                        </span>
                      )}
                    />
                    <Column
                      {...this.getColumnSearchProps('lastFollowUp')}
                      title={window.i18n.translate('LastFollowUp')}
                      dataIndex="lastFollowUp"
                      key="lastFollowUp"
                      render={(text, record) => (
                        <span
                          style={{
                            color: !text || text === 'Never' ? 'red' : 'green'
                          }}
                        >
                          {!text || text === 'Never'
                            ? window.i18n.translate('Never')
                            : text}
                        </span>
                      )}
                    />
                    <Column
                      title={window.i18n.translate('Action')}
                      render={(text, vehicle) => (
                        <div>
                          <span>
                            <a
                              data={vehicle._id}
                              onClick={this.handleSelectVehicle}
                            >
                              {window.i18n.translate('Details')}
                            </a>
                            <Divider type="vertical" />
                            <a
                              data={vehicle._id}
                              onClick={this.handleUpdateFollowup}
                            >
                              {window.i18n.translate('FollowedUp')}
                            </a>
                            <Divider type="vertical" />
                            <a
                              data={vehicle._id}
                              onClick={this.handleCompleteDeal}
                            >
                              {window.i18n.translate('DealDone')}
                            </a>
                            <Divider type="vertical" />
                            <a data={vehicle._id} onClick={this.handleFailDeal}>
                              {window.i18n.translate('DealFailed')}
                            </a>
                          </span>
                          <span>
                            <Divider type="vertical" />
                            <a
                              onClick={e => this.handleAuctionVehicle(vehicle)}
                            >
                              {window.i18n.translate('ReAuction')}
                            </a>
                          </span>
                        </div>
                      )}
                    />
                  </StyledTable>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab={window.i18n.translate('DealerOfferRejected')} key="3">
              <Row type="flex" justify="start" align="middle">
                <Col span={24}>
                  <StyledTable
                    style={{ background: 'white' }}
                    rowKey="_id"
                    size="middle"
                    dataSource={this.state.vehicles}
                    onChange={this.handleSort}
                  >
                    <Column
                      sorter={() => {}}
                      title="ID"
                      dataIndex="displayId"
                      key="displayId"
                    />
                    {/* <Column title="Image" dataIndex="mainImage" key="mainImage" render={image => <img style={{maxWidth:40, maxHeight:40}} alt="Main" src={'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' + image} />} /> */}
                    <Column
                      {...this.getColumnSearchProps('make')}
                      title={window.i18n.translate('Make')}
                      dataIndex="make"
                      key="make"
                    />
                    <Column
                      {...this.getColumnSearchProps('model')}
                      title={window.i18n.translate('Model')}
                      dataIndex="model"
                      key="model"
                    />
                    <Column
                      {...this.getColumnSearchProps('variant')}
                      title={window.i18n.translate('Variant')}
                      dataIndex="variant"
                      key="variant"
                    />
                    <Column
                      sorter={() => {}}
                      title={window.i18n.translate('ClientName')}
                      render={(text, record) => (
                        <span>
                          {record.userName + ' ' + record.userSurname}
                        </span>
                      )}
                    />
                    <Column
                      {...this.getColumnSearchProps('userNumber')}
                      sorter={() => {}}
                      title={window.i18n.translate('ClientNumber')}
                      dataIndex="userNumber"
                      key="userNumber"
                    />
                    <Column
                      {...this.getColumnSearchProps('province')}
                      title={window.i18n.translate('Province')}
                      dataIndex="province"
                      key="province"
                    />
                    <Column
                      {...this.getColumnSearchProps('city')}
                      title={window.i18n.translate('City')}
                      dataIndex="city"
                      key="city"
                    />
                    <Column
                      {...this.getColumnSearchProps('wasOnAuctionDate')}
                      title={window.i18n.translate('AuctionDate')}
                      dataIndex="wasOnAuctionDate"
                      key="wasOnAuctionDate"
                      render={(text, record) => {
                        return <span>{moment(text).format('YYYY-MM-DD')}</span>;
                      }}
                    />
                    <Column
                      title={window.i18n.translate('OfferWonAmount')}
                      render={(text, record) => (
                        <span>
                          {record.offerAmount
                            ? this.formatNumber(record.offerAmount)
                            : this.formatNumber(record.highestBid)}
                        </span>
                      )}
                    />
                    <Column
                      {...this.getColumnSearchProps('lastFollowUp')}
                      title={window.i18n.translate('LastFollowUp')}
                      dataIndex="lastFollowUp"
                      key="lastFollowUp"
                      render={(text, record) => (
                        <span
                          style={{
                            color: !text || text === 'Never' ? 'red' : 'green'
                          }}
                        >
                          {!text || text === 'Never'
                            ? window.i18n.translate('Never')
                            : text}
                        </span>
                      )}
                    />
                    <Column
                      title={window.i18n.translate('Action')}
                      render={(text, vehicle) => (
                        <div>
                          <span>
                            <a
                              data={vehicle._id}
                              onClick={this.handleSelectVehicle}
                            >
                              {window.i18n.translate('Details')}
                            </a>
                            <Divider type="vertical" />
                            <a
                              data={vehicle._id}
                              onClick={this.handleUpdateFollowup}
                            >
                              {window.i18n.translate('FollowedUp')}
                            </a>
                            <Divider type="vertical" />
                            <a
                              data={vehicle._id}
                              onClick={this.handleCompleteDeal}
                            >
                              {window.i18n.translate('DealDone')}
                            </a>
                          </span>
                          <span>
                            <Divider type="vertical" />
                            <a
                              onClick={e => this.handleAuctionVehicle(vehicle)}
                            >
                              {window.i18n.translate('ReAuction')}
                            </a>
                          </span>
                        </div>
                      )}
                    />
                  </StyledTable>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab={window.i18n.translate('DealDoneWithDealer')} key="4">
              <Row type="flex" justify="start" align="middle">
                <Col span={24}>
                  <StyledTable
                    style={{ background: 'white' }}
                    rowKey="_id"
                    size="middle"
                    dataSource={this.state.vehicles}
                    onChange={this.handleSort}
                  >
                    <Column
                      sorter={() => {}}
                      title="ID"
                      dataIndex="displayId"
                      key="displayId"
                    />
                    {/* <Column title="Image" dataIndex="mainImage" key="mainImage" render={image => <img style={{maxWidth:40, maxHeight:40}} alt="Main" src={'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' + image} />} /> */}
                    <Column
                      {...this.getColumnSearchProps('make')}
                      title={window.i18n.translate('Make')}
                      dataIndex="make"
                      key="make"
                    />
                    <Column
                      {...this.getColumnSearchProps('model')}
                      title={window.i18n.translate('Model')}
                      dataIndex="model"
                      key="model"
                    />
                    <Column
                      {...this.getColumnSearchProps('variant')}
                      title={window.i18n.translate('Variant')}
                      dataIndex="variant"
                      key="variant"
                    />
                    <Column
                      sorter={() => {}}
                      title={window.i18n.translate('ClientName')}
                      render={(text, record) => (
                        <span>
                          {record.userName + ' ' + record.userSurname}
                        </span>
                      )}
                    />
                    <Column
                      {...this.getColumnSearchProps('userNumber')}
                      sorter={() => {}}
                      title={window.i18n.translate('ClientNumber')}
                      dataIndex="userNumber"
                      key="userNumber"
                    />
                    <Column
                      {...this.getColumnSearchProps('province')}
                      title={window.i18n.translate('Province')}
                      dataIndex="province"
                      key="province"
                    />
                    <Column
                      {...this.getColumnSearchProps('city')}
                      title={window.i18n.translate('City')}
                      dataIndex="city"
                      key="city"
                    />
                    <Column
                      {...this.getColumnSearchProps('wasOnAuctionDate')}
                      title={window.i18n.translate('AuctionDate')}
                      dataIndex="wasOnAuctionDate"
                      key="wasOnAuctionDate"
                      render={(text, record) => {
                        return <span>{moment(text).format('YYYY-MM-DD')}</span>;
                      }}
                    />
                    <Column
                      {...this.getColumnSearchProps('dealAmount')}
                      title={window.i18n.translate('DealAmount')}
                      dataIndex="dealAmount"
                      key="dealAmount"
                      render={(text, record) => {
                        return <span>{this.formatNumber(text)}</span>;
                      }}
                    />
                    <Column
                      title={window.i18n.translate('Invoice')}
                      render={(text, record) => (
                        <span>
                          {record.invoice ? (
                            <a
                              onClick={e => {
                                this.handleDownloadDoc(record);
                              }}
                            >
                              {record.invoice.split('/')[2]}
                            </a>
                          ) : (
                            'Not uploaded'
                          )}
                        </span>
                      )}
                    />
                    <Column
                      title={window.i18n.translate('Status')}
                      render={(text, record) => (
                        <span>{window.i18n.translate('DealDone')}</span>
                      )}
                    />
                    <Column
                      title={window.i18n.translate('Action')}
                      render={(text, vehicle) => (
                        <div>
                          <span>
                            <a
                              data={vehicle._id}
                              onClick={this.handleSelectVehicle}
                            >
                              {window.i18n.translate('Details')}
                            </a>
                          </span>
                        </div>
                      )}
                    />
                  </StyledTable>
                </Col>
              </Row>
            </TabPane>
            <TabPane
              tab={window.i18n.translate('DealNotDoneWithDealer')}
              key="5"
            >
              <Row type="flex" justify="start" align="middle">
                <Col span={24}>
                  <StyledTable
                    style={{ background: 'white' }}
                    rowKey="_id"
                    size="middle"
                    dataSource={this.state.vehicles}
                    onChange={this.handleSort}
                  >
                    <Column
                      sorter={() => {}}
                      title="ID"
                      dataIndex="displayId"
                      key="displayId"
                    />
                    {/* <Column title="Image" dataIndex="mainImage" key="mainImage" render={image => <img style={{maxWidth:40, maxHeight:40}} alt="Main" src={'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' + image} />} /> */}
                    <Column
                      {...this.getColumnSearchProps('make')}
                      title={window.i18n.translate('Make')}
                      dataIndex="make"
                      key="make"
                    />
                    <Column
                      {...this.getColumnSearchProps('model')}
                      title={window.i18n.translate('Model')}
                      dataIndex="model"
                      key="model"
                    />
                    <Column
                      {...this.getColumnSearchProps('variant')}
                      title={window.i18n.translate('Variant')}
                      dataIndex="variant"
                      key="variant"
                    />
                    <Column
                      sorter={() => {}}
                      title={window.i18n.translate('ClientName')}
                      render={(text, record) => (
                        <span>
                          {record.userName + ' ' + record.userSurname}
                        </span>
                      )}
                    />
                    <Column
                      {...this.getColumnSearchProps('userNumber')}
                      sorter={() => {}}
                      title={window.i18n.translate('ClientNumber')}
                      dataIndex="userNumber"
                      key="userNumber"
                    />
                    <Column
                      {...this.getColumnSearchProps('province')}
                      title={window.i18n.translate('Province')}
                      dataIndex="province"
                      key="province"
                    />
                    <Column
                      {...this.getColumnSearchProps('city')}
                      title={window.i18n.translate('City')}
                      dataIndex="city"
                      key="city"
                    />
                    <Column
                      {...this.getColumnSearchProps('wasOnAuctionDate')}
                      title={window.i18n.translate('AuctionDate')}
                      dataIndex="wasOnAuctionDate"
                      key="wasOnAuctionDate"
                      render={(text, record) => {
                        return <span>{moment(text).format('YYYY-MM-DD')}</span>;
                      }}
                    />
                    <Column
                      title={window.i18n.translate('OfferWonAmount')}
                      render={(text, record) => (
                        <span>
                          {record.offerAmount
                            ? this.formatNumber(record.offerAmount)
                            : this.formatNumber(record.highestBid)}
                        </span>
                      )}
                    />
                    <Column
                      {...this.getColumnSearchProps('lastFollowUp')}
                      title={window.i18n.translate('LastFollowUp')}
                      dataIndex="lastFollowUp"
                      key="lastFollowUp"
                      render={(text, record) => (
                        <span
                          style={{
                            color: !text || text === 'Never' ? 'red' : 'green'
                          }}
                        >
                          {!text || text === 'Never'
                            ? window.i18n.translate('Never')
                            : text}
                        </span>
                      )}
                    />
                    <Column
                      title={window.i18n.translate('Action')}
                      render={(text, vehicle) => (
                        <div>
                          <span>
                            <a
                              data={vehicle._id}
                              onClick={this.handleSelectVehicle}
                            >
                              {window.i18n.translate('Details')}
                            </a>
                            <Divider type="vertical" />
                            <a
                              data={vehicle._id}
                              onClick={this.handleUpdateFollowup}
                            >
                              {window.i18n.translate('FollowedUp')}
                            </a>
                            <Divider type="vertical" />
                            <a
                              data={vehicle._id}
                              onClick={this.handleCompleteDeal}
                            >
                              {window.i18n.translate('DealDone')}
                            </a>
                          </span>
                          <span>
                            <Divider type="vertical" />
                            <a
                              onClick={e => this.handleAuctionVehicle(vehicle)}
                            >
                              {window.i18n.translate('ReAuction')}
                            </a>
                          </span>
                        </div>
                      )}
                    />
                  </StyledTable>
                </Col>
              </Row>
            </TabPane>
            <TabPane
              tab={window.i18n.translate('VerkoopnouOfferAccepted')}
              key="6"
            >
              <Row type="flex" justify="start" align="middle">
                <Col span={24}>
                  <StyledTable
                    style={{ background: 'white' }}
                    rowKey="_id"
                    size="middle"
                    dataSource={this.state.vehicles}
                    onChange={this.handleSort}
                  >
                    <Column
                      sorter={() => {}}
                      title="ID"
                      dataIndex="displayId"
                      key="displayId"
                    />
                    {/* <Column title="Image" dataIndex="mainImage" key="mainImage" render={image => <img style={{maxWidth:40, maxHeight:40}} alt="Main" src={'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' + image} />} /> */}
                    <Column
                      {...this.getColumnSearchProps('make')}
                      title={window.i18n.translate('Make')}
                      dataIndex="make"
                      key="make"
                    />
                    <Column
                      {...this.getColumnSearchProps('model')}
                      title={window.i18n.translate('Model')}
                      dataIndex="model"
                      key="model"
                    />
                    <Column
                      {...this.getColumnSearchProps('variant')}
                      title={window.i18n.translate('Variant')}
                      dataIndex="variant"
                      key="variant"
                    />
                    <Column
                      sorter={() => {}}
                      title={window.i18n.translate('ClientName')}
                      render={(text, record) => (
                        <span>
                          {record.userName + ' ' + record.userSurname}
                        </span>
                      )}
                    />
                    <Column
                      {...this.getColumnSearchProps('userNumber')}
                      sorter={() => {}}
                      title={window.i18n.translate('ClientNumber')}
                      dataIndex="userNumber"
                      key="userNumber"
                    />
                    <Column
                      {...this.getColumnSearchProps('province')}
                      title={window.i18n.translate('Province')}
                      dataIndex="province"
                      key="province"
                    />
                    <Column
                      {...this.getColumnSearchProps('city')}
                      title={window.i18n.translate('City')}
                      dataIndex="city"
                      key="city"
                    />
                    <Column
                      {...this.getColumnSearchProps('wasOnAuctionDate')}
                      title={window.i18n.translate('AuctionDate')}
                      dataIndex="wasOnAuctionDate"
                      key="wasOnAuctionDate"
                      render={(text, record) => {
                        return <span>{moment(text).format('YYYY-MM-DD')}</span>;
                      }}
                    />
                    <Column
                      {...this.getColumnSearchProps('dealAmount')}
                      title={window.i18n.translate('DealAmount')}
                      dataIndex="dealAmount"
                      key="dealAmount"
                      render={(text, record) => {
                        return <span>{this.formatNumber(text)}</span>;
                      }}
                    />
                    <Column
                      title={window.i18n.translate('Invoice')}
                      render={(text, record) => (
                        <span>
                          {record.invoice ? (
                            <a
                              onClick={e => {
                                this.handleDownloadDoc(record);
                              }}
                            >
                              {record.invoice.split('/')[2]}
                            </a>
                          ) : (
                            'Not uploaded'
                          )}
                        </span>
                      )}
                    />
                    <Column
                      title={window.i18n.translate('Status')}
                      render={(text, record) => (
                        <span>{window.i18n.translate('DealDone')}</span>
                      )}
                    />
                    <Column
                      title={window.i18n.translate('Action')}
                      render={(text, vehicle) => (
                        <div>
                          <span>
                            <a
                              data={vehicle._id}
                              onClick={this.handleSelectVehicle}
                            >
                              {window.i18n.translate('Details')}
                            </a>
                          </span>
                        </div>
                      )}
                    />
                  </StyledTable>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab={window.i18n.translate('NoBids')} key="7">
              <Row type="flex" justify="start" align="middle">
                <Col span={24}>
                  <StyledTable
                    style={{ background: 'white' }}
                    rowKey="_id"
                    size="middle"
                    dataSource={this.state.vehicles}
                    onChange={this.handleSort}
                  >
                    <Column
                      sorter={() => {}}
                      title="ID"
                      dataIndex="displayId"
                      key="displayId"
                    />
                    {/* <Column title="Image" dataIndex="mainImage" key="mainImage" render={image => <img style={{maxWidth:40, maxHeight:40}} alt="Main" src={'https://www.devvcsappcloud.com/verkoopnou/api/images/vehicles/' + image} />} /> */}
                    <Column
                      {...this.getColumnSearchProps('make')}
                      title={window.i18n.translate('Make')}
                      dataIndex="make"
                      key="make"
                    />
                    <Column
                      {...this.getColumnSearchProps('model')}
                      title={window.i18n.translate('Model')}
                      dataIndex="model"
                      key="model"
                    />
                    <Column
                      {...this.getColumnSearchProps('variant')}
                      title={window.i18n.translate('Variant')}
                      dataIndex="variant"
                      key="variant"
                    />
                    <Column
                      sorter={() => {}}
                      title={window.i18n.translate('ClientName')}
                      render={(text, record) => (
                        <span>
                          {record.userName + ' ' + record.userSurname}
                        </span>
                      )}
                    />
                    <Column
                      {...this.getColumnSearchProps('userNumber')}
                      sorter={() => {}}
                      title={window.i18n.translate('ClientNumber')}
                      dataIndex="userNumber"
                      key="userNumber"
                    />
                    <Column
                      {...this.getColumnSearchProps('province')}
                      title={window.i18n.translate('Province')}
                      dataIndex="province"
                      key="province"
                    />
                    <Column
                      {...this.getColumnSearchProps('city')}
                      title={window.i18n.translate('City')}
                      dataIndex="city"
                      key="city"
                    />
                    <Column
                      {...this.getColumnSearchProps('wasOnAuctionDate')}
                      title={window.i18n.translate('AuctionDate')}
                      dataIndex="wasOnAuctionDate"
                      key="wasOnAuctionDate"
                      render={(text, record) => {
                        return <span>{moment(text).format('YYYY-MM-DD')}</span>;
                      }}
                    />

                    <Column
                      {...this.getColumnSearchProps('lastFollowUp')}
                      title={window.i18n.translate('LastFollowUp')}
                      dataIndex="lastFollowUp"
                      key="lastFollowUp"
                      render={(text, record) => (
                        <span
                          style={{
                            color: !text || text === 'Never' ? 'red' : 'green'
                          }}
                        >
                          {!text || text === 'Never'
                            ? window.i18n.translate('Never')
                            : text}
                        </span>
                      )}
                    />
                    <Column
                      title={window.i18n.translate('Status')}
                      render={(text, record) => (
                        <span>{window.i18n.translate('NoBids')}</span>
                      )}
                    />
                    <Column
                      title={window.i18n.translate('Action')}
                      render={(text, vehicle) => (
                        <div>
                          <span>
                            <a
                              data={vehicle._id}
                              onClick={this.handleSelectVehicle}
                            >
                              {window.i18n.translate('Details')}
                            </a>
                            <Divider type="vertical" />
                            <a
                              data={vehicle._id}
                              onClick={this.handleUpdateFollowup}
                            >
                              {window.i18n.translate('FollowedUp')}
                            </a>
                          </span>
                          <span>
                            <Divider type="vertical" />
                            <a
                              onClick={e => this.handleAuctionVehicle(vehicle)}
                            >
                              {window.i18n.translate('ReAuction')}
                            </a>
                          </span>
                        </div>
                      )}
                    />
                  </StyledTable>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Spin>
      </div>
    );
  }
}

export default Deals;
